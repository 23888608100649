import React, { useEffect, useState } from "react";
import styles from "./styles/input.module.scss";
import xss from "xss";
import { useTranslation } from "react-i18next";

interface InputProp {
  type: "password" | "text" | "phone" | "dob" | "email" | "number";
  inputTitle?: string;
  placeholder: string;
  defaultVal?: string | number;
  // eslint-disable-next-line @typescript-eslint/ban-types
  passDataFunction?: (id: string) => void;
  onError?: boolean;
  bind?: string;
  pattern?: string;
  necessary?: boolean;
  keyword?: string;
  immutable?: boolean;
  errorMessage?: string;
  bigTitle?: string;
  autofocus?: boolean;
  background?: string;
  color?: string;
  placeholderColor?: string;
  blurCallback?: () => any;
  focusCallback?: () => any;
}

export const SWInputBar: React.FC<InputProp> = (props: InputProp) => {
  const {
    type,
    inputTitle,
    placeholder,
    passDataFunction,
    defaultVal,
    necessary,
    keyword,
    immutable,
    errorMessage,
    onError,
    bigTitle,
    autofocus,
    background,
    color,
    placeholderColor,
    blurCallback,
    focusCallback,
  } = props;
  const { t } = useTranslation();
  const setData = passDataFunction ? passDataFunction : () => undefined;
  const [message, setMessage] = useState(defaultVal);
  const [emptyError, setEmptyError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const types = {
    text: "text",
    email: "text",
    dob: "text",
    password: "password",
    number: "number",
    phone: "number",
  };
  useEffect(() => {
    if (defaultVal) {
      setMessage(defaultVal || "");
    }
  }, [defaultVal]);

  const showInputPwd = () => {
    setShowPassword((val) => !val);
  };

  return (
    <div>
      <div
        className={["phone", "number"].includes(type) ? styles["input-phone"] : styles.input}
        style={{
          border: props.onError || emptyError ? "1px solid #EB3661" : "",
          background: background || "#ffffff",
          borderRadius: "8px",
        }}
      >
        {bigTitle ? (
          <div
            style={{
              paddingBottom: "4px",
              color: placeholderColor === "dark" ? "rgba(0, 0, 0, 0.5)" : "rgba(255,255,255, 0.5)",
            }}
          >
            {bigTitle}
          </div>
        ) : null}
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {inputTitle ? (
            <div
              className={type === "phone" ? styles["input-phone-prefix"] : styles["input-title"]}
              style={{
                display: inputTitle ? "" : "none",
                color:
                  placeholderColor === "light" ? "rgba(255, 255, 255, 0.5)" : "rgba(0,0,0,0.5)",
              }}
            >
              {inputTitle || ""}
            </div>
          ) : null}
          <input
            style={{
              background: background || "",
              color: color || "",
              flex: 1,
            }}
            autoFocus={!!autofocus}
            type={showPassword ? "text" : types[type]}
            placeholder={placeholder || ""}
            value={message || ""}
            readOnly={!!immutable}
            onBlur={() => {
              if (blurCallback) blurCallback();
              if (!message && necessary) {
                setEmptyError(true);
              }
            }}
            onFocus={() => {
              if (focusCallback) focusCallback();
              setEmptyError(false);
            }}
            onInput={(event) => {
              const target = event.target as HTMLTextAreaElement;
              const val = xss(target.value || "");
              setMessage(val);
              setData(val);
            }}
            className={`${placeholderColor === "light" ? "light_placeholder" : "dark_placeholder"}`}
          />
          {type === "password" ? (
            <img
              src={
                showPassword
                  ? require("../assets/images/theme/show_hide_light_close.png")
                  : require("../assets/images/theme/show_hide_light.png")
              }
              style={{
                width: "22px",
                height: "22px",
                margin: "0 4px",
              }}
              alt={"password"}
              onClick={showInputPwd}
            />
          ) : null}
        </div>
      </div>
      <div
        className={styles.error_area}
        style={{
          display: emptyError || onError ? "" : "none",
        }}
      >
        <img src={require("../assets/images/error_icon.png")} alt="" />
        <span>
          {emptyError
            ? t(`Please enter your {{var}}`, { var: keyword || inputTitle })
            : errorMessage}
        </span>
      </div>
    </div>
  );
};
