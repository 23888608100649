import { refresh, setServerError } from "../store/state";
import { AnyAction, Dispatch } from "redux";
import createXhr from "./index";
import store from "store2";

export const errorHandler = (
  e: { status: number; [key: string]: unknown },
  dispatch: Dispatch<AnyAction>,
) => {
  console.error(e);
  const status = e.status;
  if (!status) {
    dispatch(
      setServerError({
        error: `Network Error`,
      }),
    );
    if ((window as any)?.showOffline) {
      (window as any)?.showOffline();
    }
    return;
  }
  if (e && typeof e === "object" && JSON.stringify(e).toLowerCase().includes("invalid")) {
    alert(e.message || JSON.stringify(e));
    if ((window as any)?.showServerError) {
      (window as any)?.showServerError({
        title: `Token Error:`,
        url: "",
        text: "Token is invalid",
      });
    }
    dispatch(
      setServerError({
        error: `Invalid token`,
      }),
    );
    return;
  }
  if (e && typeof e === "object" && JSON.stringify(e).toLowerCase().includes("expired")) {
    if ((window as Client).shakewin?.requestAccessToken) {
      (window as Client).shakewin?.requestAccessToken(true);
      return;
    }
    createXhr({
      headers: {
        "X-CountryCode": store("chosen_country") || store("chosen_country") || "NG",
      },
    })
      .postRefreshToken({
        refreshToken: store("refresh_token"),
      })
      .then((tokens: Tokens) => {
        dispatch(refresh(tokens));
      })
      .catch((e: any) => {
        console.error(e);
        dispatch(
          setServerError({
            error: `Failed to refresh token`,
          }),
        );
      });
    return;
  }
  if (status < 500) {
    dispatch(
      setServerError({
        error: `${status}: ${(e.message as string) || "Request Error"}`,
      }),
    );
    if (status === 401 || status === 403) {
      store.remove("access_token");
      store.remove("refresh_token");
    }
  } else {
    if ((window as any)?.showServerError) {
      (window as any)?.showServerError({
        title: `Error ${status}:`,
        url: "",
        text: (e.message as string) || "Request Error",
      });
    }
    dispatch(
      setServerError({
        error: `${status}: ${(e.message as string) || "Server Service Unavailable"}`,
      }),
    );
  }
};
