import React from "react";
import { useTranslation } from "react-i18next";

interface BottomModalProps {
  title?: string;
  type?: "Steps to set" | "Grant time" | "Default browser mission success";
  bg?: string;
  margin?: string;
}

export const BottomModal: React.FC<BottomModalProps> = (props) => {
  const { t } = useTranslation();
  const product =
    (window as any)?.getClientName && (window as any)?.getClientName()?.toLowerCase() !== "mini"
      ? "Opera"
      : "Opera Mini";
  return (
    <div
      style={{
        background: props.bg || "#ffffff",
        borderRadius: "16px",
        margin: props.margin || "8px auto",
        position: "fixed",
        bottom: "8px",
        width: "calc(100vw - 24px)",
        left: "12px",
        zIndex: 1000,
        color: "#000000",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "calc(100% - 30px)",
        }}
      >
        <h2
          style={{
            display: props.title ? "" : "none",
            padding: "8px 8px 0 8px",
            boxSizing: "border-box",
            fontSize: "14px",
          }}
        >
          {props.title}
        </h2>
        {props.type === "Steps to set" ? (
          <div
            style={{
              padding: "4px 8px 8px 12px",
              minHeight: "20px",
              display: "flex",
              alignItems: "center",
              width: "max-content",
              maxWidth: "100%",
              boxSizing: "border-box",
            }}
          >
            <img
              src={require("../assets/images/opera.png")}
              alt=""
              style={{
                width: "14px",
                height: "14px",
                margin: "0",
              }}
            />
            <span
              style={{
                margin: "0 4px",
                fontSize: "13px",
                lineHeight: "16px",
                fontWeight: 600,
                textAlign: "left",
              }}
            >
              {t("setDefaultStep", { product })}
            </span>
          </div>
        ) : null}
        {props.type === "Grant time" ? (
          <div
            style={{
              padding: "8px 8px 8px 12px",
              minHeight: "20px",
              display: "flex",
              alignItems: "center",
              width: "max-content",
              maxWidth: "100%",
              boxSizing: "border-box",
            }}
          >
            <img
              src={require("../assets/images/shake_win_br/next_shake.png")}
              alt=""
              style={{
                width: "22px",
                height: "22px",
                margin: "0",
              }}
            />
            <span
              style={{
                margin: "0 4px",
                fontSize: "13px",
                lineHeight: "16px",
                fontWeight: 600,
                textAlign: "left",
              }}
            >
              {t("setDefaultStepResult", { product })}
            </span>
          </div>
        ) : null}
        {props.type === "Default browser mission success" ? (
          <div
            style={{
              padding: "8px 8px 8px 12px",
              minHeight: "20px",
              display: "flex",
              alignItems: "center",
              width: "max-content",
              maxWidth: "100%",
              boxSizing: "border-box",
            }}
          >
            <img
              src={require("../assets/images/shake_win_br/next_shake.png")}
              alt=""
              style={{
                width: "22px",
                height: "22px",
                margin: "0",
              }}
            />
            <span
              style={{
                margin: "0 4px",
                fontSize: "13px",
                lineHeight: "16px",
                fontWeight: 600,
                textAlign: "left",
              }}
            >
              {t("defaultBrowserMissionSuccess", { product })}
            </span>
          </div>
        ) : null}
      </div>
      <img
        src={require("../assets/images/tick_blue.png")}
        alt=""
        style={{
          width: "22px",
          height: "22px",
          marginRight: "12px",
        }}
      />
    </div>
  );
};
