import React from "react";
import { Home } from "../pages/Home";
import { HomeEnd } from "../pages/HomeEnd";
import { Shaking } from "../pages/Shaking";
import { Rules } from "../pages/Rules";
import { Result } from "../pages/Result";
import { Prizes } from "../pages/Prizes";
import { PrizeWinnableDetail } from "../pages/PrizeWinnableDetail";
import { PrizeWonDetail } from "../pages/PrizeWonDetail";
import { MissionDetail } from "../pages/MissionDetail";
import { ClaimForm } from "../pages/ClaimForm";
import { NotRightCountry } from "../pages/NotRightCountry";
// import { TcForBR } from "../components/v2/TcForBR";
import { NotFound } from "../pages/404";
import { Qa } from "../pages/Qa";
import { PrizesEnd } from "../pages/PrizesEnd";
import { Feedback } from "../pages/Feedback";
import { OneTimePassword } from "../pages/v2/OneTimePassword";
import { VerifyPhoneV2 } from "../pages/v2/VerifyPhoneV2";
import { Oauth } from "../pages/v2/Oauth";
import { TermsAndConditions } from "../pages/v2/TermsAndConditions";
import { Profile } from "../pages/v2/Profile";
import { ChangePassword } from "../pages/v2/ChangePassword";
import { LanguageCountrySetting } from "../pages/v2/LanguageCountrySetting";
import { AuthFlow } from "../pages/v2/AuthFlow";
import { NotOpera } from "../pages/NotOpera";
import { MissionList } from "../pages/v2/MissionList";
import { PrizeOptions } from "../pages/v2/PrizeOptions";
import { WelcomePage } from "../pages/v2/WelcomePage";
import { PostVerify } from "../pages/v2/PostVerify";
import { GiveAway } from "../pages/giveaway/GiveAway";
import { MissionStats } from "../pages/v2/MissionStats";

const RouteTable: Map<Phase, Route[]> = new Map();
RouteTable.set("PENDING", [{ path: "*", component: <div></div> }]);

RouteTable.set("NOT_OPR", [
  { path: "/", component: <NotOpera /> },
  { path: "/not-in-opera", component: <NotOpera /> },
  { path: "/qa", component: <Qa /> },
  { path: "/tc", component: <TermsAndConditions /> },
  { path: "/feedback", component: <Feedback /> },
]);

RouteTable.set("NOT_START", [
  { path: "/", component: <Home /> },
  { path: "/shaking", component: <Home /> },
  { path: "/tc", component: <TermsAndConditions /> },
  { path: "/qa", component: <Qa /> },
  { path: "*", component: <NotFound /> },
  { path: "/feedback", component: <Feedback /> },
]);

RouteTable.set("IN_PROGRESS", [
  { path: "/", component: <Home /> },
  { path: "/not-in-opera", component: <NotOpera /> },
  { path: "/feedback", component: <Feedback /> },
  { path: "/tc", component: <TermsAndConditions /> },
  { path: "/qa", component: <Qa /> },
  { path: "/shaking", component: <Shaking name={""} /> },
  { path: "/result", component: <Result /> },
  { path: "/giveaway", component: <GiveAway /> },
  { path: "/winnable", component: <PrizeWinnableDetail /> },
  { path: "/winnable/options", component: <PrizeOptions /> },
  { path: "/won", component: <PrizeWonDetail /> },
  { path: "/rules", component: <Rules /> },
  { path: "/prizes", component: <Prizes /> },
  { path: "/missions/:questId", component: <MissionDetail /> },
  { path: "/missions", component: <MissionList /> },
  { path: "/prize/claim", component: <ClaimForm /> },
  { path: "/login", component: <AuthFlow /> },
  { path: "/auth", component: <AuthFlow /> },
  { path: "/login/verify", component: <VerifyPhoneV2 /> },
  { path: "/login/onetime", component: <OneTimePassword /> },
  { path: "/profile", component: <Profile /> },
  { path: "/profile/password", component: <ChangePassword /> },
  { path: "/shakewin/callback", component: <Oauth /> },
  { path: "/select", component: <LanguageCountrySetting /> },
  { path: "/welcome", component: <WelcomePage /> },
  { path: "/stats", component: <MissionStats /> },
  { path: "/prize/claim/verify", component: <PostVerify /> },
  { path: "*", component: <NotFound /> },
]);

RouteTable.set("CLAIM_ONLY", [
  { path: "/", component: <PrizesEnd /> },
  { path: "/prizes", component: <PrizesEnd /> },
  { path: "/tc", component: <TermsAndConditions /> },
  { path: "/qa", component: <Qa /> },
  { path: "/won", component: <PrizeWonDetail /> },
  { path: "/prize/claim", component: <ClaimForm /> },
  { path: "/rules", component: <Rules /> },
  { path: "/auth", component: <AuthFlow /> },
  { path: "/login", component: <AuthFlow /> },
  { path: "/login/verify", component: <VerifyPhoneV2 /> },
  { path: "/login/onetime", component: <OneTimePassword /> },
  { path: "/profile", component: <Profile /> },
  { path: "/profile/password", component: <ChangePassword /> },
  { path: "/missions/:questId", component: <MissionDetail /> },
  { path: "/missions", component: <MissionList /> },
  { path: "/shakewin/callback", component: <Oauth /> },
  { path: "/select", component: <LanguageCountrySetting /> },
  { path: "/feedback", component: <Feedback /> },
  { path: "/prize/claim/verify", component: <PostVerify /> },
  { path: "/welcome", component: <WelcomePage /> },
  { path: "/shaking", component: <PrizesEnd /> },
  { path: "*", component: <NotFound /> },
]);

RouteTable.set("FINISHED", [
  { path: "/", component: <HomeEnd /> },
  { path: "/tc", component: <TermsAndConditions /> },
  { path: "/qa", component: <Qa /> },
  { path: "/prizes", component: <PrizesEnd /> },
  { path: "/won", component: <PrizeWonDetail /> },
  { path: "/feedback", component: <Feedback /> },
  { path: "*", component: <NotFound /> },
]);

RouteTable.set("NOT_IN_COUNTRY", [{ path: "/", component: <NotRightCountry /> }]);

export default RouteTable;
