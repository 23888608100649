import React from "react";

export const QaEnAf: React.FC = () => {
  return (
    <div
      style={{
        margin: "0 16px 0 16px",
        padding: "70px 0",
      }}
    >
      <h2
        style={{
          color: "#FFB906",
          fontWeight: 900,
          fontSize: "18px",
        }}
      >
        Frequently Asked Questions
      </h2>
      <section style={{ padding: "16px 0" }}>
        <h2
          style={{
            color: "#ffffff",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "18px",
          }}
        >
          {"I’m stuck on the login screen!"}
        </h2>
        <p
          style={{
            color: "rgba(255,255,255,0.75)",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            marginTop: "16px",
          }}
        >{`Please update to the latest version of Opera Mini, this will fix your login issues.`}</p>
      </section>
      <section style={{ padding: "16px 0" }}>
        <h2
          style={{
            color: "#ffffff",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "18px",
          }}
        >
          {"Didn’t receive a free shake after setting wallpaper"}
        </h2>
        <p
          style={{
            color: "rgba(255,255,255,0.75)",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            marginTop: "16px",
          }}
        >
          {
            "This mission is only for people who are setting a wallpaper for the first time. If you have configured your wallpaper settings in the past, you will not receive a free shake."
          }
        </p>
      </section>
      <section style={{ padding: "16px 0" }}>
        <h2
          style={{
            color: "#ffffff",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "18px",
          }}
        >
          {"I won a bet9ja  Reward but I don’t have a bet9ja account"}
        </h2>
        <p
          style={{
            color: "rgba(255,255,255,0.75)",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            marginTop: "16px",
          }}
        >
          {
            "You’ll need to provide a bet9ja account ID to claim your bet9ja reward. To create a bet9ja account, simply follow the steps displayed on the bet9ja rewards screen. Ensure you know your bet9ja account ID before submitting the claim form."
          }
        </p>
      </section>
      <section style={{ padding: "16px 0" }}>
        <h2
          style={{
            color: "#ffffff",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "18px",
          }}
        >
          {"I don’t know my  bet9ja account ID. Can I use my username or my phone number instead?"}
        </h2>
        <p
          style={{
            color: "rgba(255,255,255,0.75)",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            marginTop: "16px",
          }}
        >
          {
            'No. You’ll lose your reward if you submit a username, phone number or email address in place of your bet9ja account ID.  To find your bet9ja ID number, log into your bet9ja account, tap "my account" at the top right corner, wait for the page to load, then scroll down. Submitting anything other than a bet9ja account ID is an automatic forfeiture of your reward. Forfeited rewards will be returned to the prize pool for the public to win.'
          }
        </p>
      </section>
      <section style={{ padding: "16px 0" }}>
        <h2
          style={{
            color: "#ffffff",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "18px",
          }}
        >
          {"I selected the wrong country"}
        </h2>
        <p
          style={{
            color: "rgba(255,255,255,0.75)",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            marginTop: "16px",
          }}
        >
          {
            "Please scroll to the bottom of the screen and  tap the country button, you’ll see a list of countries where Shake&Win is currently available. Select your country and verify your account. "
          }
        </p>
      </section>
      <section style={{ padding: "16px 0" }}>
        <h2
          style={{
            color: "#ffffff",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "18px",
          }}
        >
          {"KENYA: My Airtime code is not working"}
        </h2>
        <p
          style={{
            color: "rgba(255,255,255,0.75)",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            marginTop: "16px",
          }}
        >
          {
            "Apologies for the inconvenience. We’re currently investigating this issue, please watch this space for updates. It will be resolved shortly."
          }
        </p>
      </section>
      <section style={{ padding: "16px 0" }}>
        <h2
          style={{
            color: "#ffffff",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "18px",
          }}
        >
          {"Why is my phone number invalid? Why didn’t I receive my OTP code?"}
        </h2>
        <p
          style={{
            color: "rgba(255,255,255,0.75)",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            marginTop: "16px",
          }}
        >
          {"This can happen for a number of reasons. Here are some possible reasons: "}
        </p>
        <ul
          style={{
            color: "rgba(255,255,255,0.75)",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            marginTop: "16px",
            marginLeft: "16px",
          }}
        >
          <li>There is another account associated with your number.</li>
          <li>You have entered an incorrect phone number.</li>
          <li>You are using only wifi (sometimes a mobile internet connection required)</li>
          <li>
            You are making too many attempts to verify your account in a short period of time. Try
            again after an hour or two.
          </li>
        </ul>
        <p
          style={{
            color: "rgba(255,255,255,0.75)",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            marginTop: "16px",
          }}
        >
          {"If the problem persists, please use the feedback form to notify our team."}
        </p>
      </section>
      <section style={{ padding: "16px 0" }}>
        <h2
          style={{
            color: "#ffffff",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "18px",
          }}
        >
          {"Nigeria: Airtime code is not working."}
        </h2>
        <p
          style={{
            color: "rgba(255,255,255,0.75)",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            marginTop: "16px",
          }}
        >
          {"Use *258*pin#. This will work for all available networks."}
        </p>
      </section>
      <section style={{ padding: "16px 0" }}>
        <h2
          style={{
            color: "#ffffff",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "18px",
          }}
        >
          {"I referred my referred my friends but I am yet to receive my free shake"}
        </h2>
        <p
          style={{
            color: "rgba(255,255,255,0.75)",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            marginTop: "16px",
          }}
        >
          {
            "You will receive your free shake when the person you referred verifies their phone number inside Shake & Win."
          }
        </p>
      </section>
      <section style={{ padding: "16px 0" }}>
        <h2
          style={{
            color: "#ffffff",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "18px",
          }}
        >
          {"I have a verified account but I lost my progress!"}
        </h2>
        <p
          style={{
            color: "rgba(255,255,255,0.75)",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            marginTop: "16px",
          }}
        >{`It’s possible your account was logged out. No worries your progress is still there, simply use the login button on the homepage to sign into your account again. Ensure you’re using the correct email address.`}</p>
      </section>
      <section style={{ padding: "16px 0" }}>
        <h2
          style={{
            color: "#ffffff",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "18px",
          }}
        >
          {`What does "in progress" mean?`}
        </h2>
        <p
          style={{
            color: "rgba(255,255,255,0.75)",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            marginTop: "16px",
          }}
        >{`This means you have not yet collected all the pieces required to claim a specific prize. You can see the number of pieces left to claim for that prize by tapping the name of the prize in the Available Prizes section.`}</p>
      </section>
      <section style={{ padding: "16px 0" }}>
        <h2
          style={{
            color: "#ffffff",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "18px",
          }}
        >
          {`What does “duplicate pieces count towards additional rewards” mean?`}
        </h2>
        <p
          style={{
            color: "rgba(255,255,255,0.75)",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            marginTop: "16px",
          }}
        >{`This means you can win more than one unit of that prize if you are able to collect more than one complete set of the required pieces. The more times you collect all the pieces, the more units you can claim.`}</p>
      </section>
      <section style={{ padding: "16px 0" }}>
        <h2
          style={{
            color: "#ffffff",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "18px",
          }}
        >
          {`Why is it taking so long to receive my reward?`}
        </h2>
        <p
          style={{
            color: "rgba(255,255,255,0.75)",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            marginTop: "16px",
          }}
        >{`Due to the large number of people playing this game and the high number of claims processed daily, it can sometimes take up to 7 days to complete a batch of claims. You don’t have to worry though, this is perfectly normal. You can help our team speed up the process by making sure you provide the correct details in the claim form. Sorting out the bad claims from the good claims is one of the leading causes of delayed rewards.`}</p>
      </section>
      <section style={{ padding: "16px 0" }}>
        <h2
          style={{
            color: "#ffffff",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "18px",
          }}
        >
          {`I don’t have an OPay account, can I use another account?`}
        </h2>
        <p
          style={{
            color: "rgba(255,255,255,0.75)",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            marginTop: "16px",
          }}
        >{`No. In Nigeria we only support OPay for cash rewards. If you enter an invalid OPay number in the account number section, the payment will be flagged as failed and may be forfeited as a result. If you don’t currently have an OPay account, please visit the playstore, download and install OPay, then register for a new account before filling out the cash claim form.`}</p>
      </section>
      <section style={{ padding: "16px 0" }}>
        <h2
          style={{
            color: "#ffffff",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "18px",
          }}
        >
          {`Why can't I find my prizes?`}
        </h2>
        <p
          style={{
            color: "rgba(255,255,255,0.75)",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            marginTop: "16px",
          }}
        >{`You’ll lose your progress and saved prizes if you uninstall your Opera browser before verifying your account.`}</p>
      </section>
      <section style={{ padding: "16px 0" }}>
        <h2
          style={{
            color: "#ffffff",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "18px",
          }}
        >
          {`[KENYA ONLY] I don’t have an MPesa account, can I use another account?`}
        </h2>
        <p
          style={{
            color: "rgba(255,255,255,0.75)",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            marginTop: "16px",
          }}
        >{`No. In Kenya we only support MPesa for cash rewards. If you enter an invalid MPesa number in the account number section, the payment will be flagged as failed and may be forfeited as a result. If you don’t currently have an MPesa account, please register for a new account before filling out the cash claim form.`}</p>
      </section>
      <section style={{ padding: "16px 0" }}>
        <h2
          style={{
            color: "#ffffff",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "18px",
          }}
        >
          {`[SOUTH AFRICA ONLY] Why is there no option for account number?`}
        </h2>
        <p
          style={{
            color: "rgba(255,255,255,0.75)",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            marginTop: "16px",
          }}
        >{`Your cash reward will be sent to the phone number you provided as an sms containing a PIN that allows you initiate a cardless withdrawal. Please ensure you provide the correct phone number. Payments to a wrong phone number that you provided will not be reversed.`}</p>
      </section>
      <section style={{ padding: "16px 0" }}>
        <h2
          style={{
            color: "#ffffff",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "18px",
          }}
        >
          {`[SOUTH AFRICA ONLY] Why am I no longer able to use the PIN in SMS?`}
        </h2>
        <p
          style={{
            color: "rgba(255,255,255,0.75)",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            marginTop: "16px",
          }}
        >{`The PIN provided in an SMS for initiating a cardless withdrawal remains valid for only 30 days. After this period, the bank is required to invalidate it and process a reversal. Please make sure to initiate the claim process within 30 days from the date you receive the PIN in your SMS.`}</p>
      </section>
      <section style={{ padding: "16px 0" }}>
        <h2
          style={{
            color: "#ffffff",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "18px",
          }}
        >
          {`I asked  my friends to install Opera Mini but I did not use my referral link, will I still get the reward?`}
        </h2>
        <p
          style={{
            color: "rgba(255,255,255,0.75)",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            marginTop: "16px",
          }}
        >{`No. The only way to get the referral mission reward is to use your unique referral link. To get your unique link, simply visit the referral mission page and tap copy.`}</p>
      </section>
      <section style={{ padding: "16px 0" }}>
        <h2
          style={{
            color: "#ffffff",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "18px",
          }}
        >
          {`How Do I Play The Referral Mission?`}
        </h2>
        <p
          style={{
            color: "rgba(255,255,255,0.75)",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            marginTop: "16px",
          }}
        >{`Simply copy and send your unique referral link to your friends. If they use your link to install a supported Opera browser, you’ll earn a free shake after they verify their Shake & Win account. No shake will be awarded if they do not verify their account. `}</p>
      </section>
      <section style={{ padding: "16px 0" }}>
        <h2
          style={{
            color: "#ffffff",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "18px",
          }}
        >
          {`How Do I Play The “Make Browser Default” Mission?`}
        </h2>
        <p
          style={{
            color: "rgba(255,255,255,0.75)",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            marginTop: "16px",
          }}
        >{`Simply set and keep a supported Opera Browser as your default browser to unlock a free shake.`}</p>
      </section>
      <section style={{ padding: "16px 0" }}>
        <h2
          style={{
            color: "#ffffff",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "18px",
          }}
        >
          {`How Do I Play The “Hidden Shakes” Mission?`}
        </h2>
        <p
          style={{
            color: "rgba(255,255,255,0.75)",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            marginTop: "16px",
          }}
        >{`You can find 3 additional free shakes daily, and they are all hidden in the news article section of the Opera MIni browser. To get started, simply search through the recommended articles and read them from the top down. Please note that free shakes will not be awarded to users who just open and close an article. You must read the article thoroughly to unearth the hidden shakes. Plus, once you discover a shake, you'll be notified right away. Happy hunting!`}</p>
      </section>
    </div>
  );
};
