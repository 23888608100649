import React from "react";
import { Trans } from "react-i18next";
import { useSelector } from "../store";

export const ParticipantsCount: React.FC = () => {
  const amount = useSelector((state) => state.data.userCount);
  const theme = useSelector((state) => state.data.theme);
  return (
    <div
      style={{
        padding: "8px",
        boxSizing: "border-box",
        width: "100%",
        background: theme.secondaryBackgroundColor,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "36px",
      }}
    >
      <img
        style={{
          width: "22px",
          height: "22px",
        }}
        src={require("../assets/images/shake_win/spectators.png")}
        alt=""
      />
      <span
        style={{
          marginLeft: "8px",
          textAlign: "center",
        }}
      >
        <Trans
          i18nKey={"participating"}
          values={{ amount }}
          components={{
            1: <span style={{ fontWeight: 600, fontSize: "15px" }} />,
          }}
        >
          {`<1>{{ amount }}</1>`}
        </Trans>
      </span>
    </div>
  );
};
