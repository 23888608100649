import i18n from "../i18n";

export const countDown = () => {
  const t = i18n.t;
  return (diff: number): string => {
    if (diff <= 0) {
      return "00:00:00";
    }
    const hours = Math.floor(diff / 3600);
    const minutes = Math.floor((diff - 3600 * hours) / 60);
    const seconds = Math.floor(diff % 60);
    const days = Math.floor(hours / 24);
    const leftHours = days % 24;
    return days >= 1
      ? `${days} ${t("days")} ${leftHours} ${t("hours")}`
      : `${hours >= 10 ? hours : "0" + hours}:${minutes >= 10 ? minutes : "0" + minutes}:${
          seconds >= 10 ? seconds : "0" + seconds
        }`;
  };
};

export const toDMY: (timeString: string, lang?: string) => string = (timeString: string, lang) => {
  const language = lang || "en";
  if (isNaN(Date.parse(timeString))) {
    return "Invalid Date";
  }
  const date = new Date(timeString);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const obj = new Map<string, string>([
    ["en", `${month < 10 ? "0" + month : month}/${day < 10 ? "0" + day : day}/${year}`],
    ["pt-BR", `${day < 10 ? "0" + day : day}/${month < 10 ? "0" + month : month}/${year}`],
    ["pt", `${day < 10 ? "0" + day : day}/${month < 10 ? "0" + month : month}/${year}`],
  ]);
  return (
    obj.get(language) || `${month < 10 ? "0" + month : month}/${day < 10 ? "0" + day : day}/${year}`
  );
};
