import React, { useCallback, useEffect, useState } from "react";
import styles from "../styles/prizes.module.scss";
import { PrizeCard } from "../../components/PrizeCard";
import { clearNewGift, setShakes, setUnlockTime, shaking } from "../../store/state";
import { useDispatch } from "react-redux";
import { useSelector } from "../../store";
import { Warning } from "../../components/Warning";
import { Footer } from "../../components/Footer";
import { useTranslation } from "react-i18next";
import { setDot } from "../../utils/dot";
import { setGaDot } from "../../utils/gaDot";
import { ShakingIcon } from "../../components/ShakingIcon";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useShakes } from "../../utils/postShaking";
import { useUtmParamNavigate } from "../../utils/utmParamNavigate";
import { SWButton } from "../../components/SWButton";
import { countDown } from "../../utils/time";
import { clientName } from "../../config";

export const GiveAway: React.FC = () => {
  const dispatch = useDispatch();
  // const navigate = useUtmParamNavigate();
  // const shakes = useSelector((state) => state.data.shakes);
  const winnable: Reward[] = useSelector((state) => state.data.winnablePrizes);
  const won: Reward[] = useSelector((state) => state.data.wonPrizes);
  const loggedIn: boolean = useSelector((state) => state.data.loggedIn);
  const verified: boolean = useSelector((state) => state.data.verified);
  const theme = useSelector((state) => state.data.theme);
  const isShaking = useSelector((state) => state.data.isShaking);
  const [tab, setTab] = useState("winnable");
  const [prizeDom, setPrizeDom] = useState(<div></div>);
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const postShake = useShakes();
  const recaptchaRequired = useSelector((state) => state.data.recaptchaRequired);
  const shakes = useSelector((state) => state.data.shakes);
  const unlockTime = useSelector((state) => state.data.unlockedTime);
  const xhr = useSelector((state) => state.data.xhr);
  const navigate = useUtmParamNavigate();
  const timeCountDown = countDown();
  let user_status = "anno_user";
  if (verified) {
    user_status = "phone_verify";
  }
  const [diff, setDiff] = useState(unlockTime - new Date().getTime() / 1000);

  useEffect(() => {
    const timer = setInterval(() => {
      setDiff(unlockTime - new Date().getTime() / 1000);
      if (unlockTime - new Date().getTime() / 1000 <= 0) {
        if (shakes === 0) {
          xhr
            .getShakes()
            .then((res: ShakesInfo) => {
              const shakes = res?.shakesCount || 0;
              const recaptchaRequired = res.recaptchaRequired || false;
              dispatch(
                setShakes({
                  shakes: shakes,
                  recaptchaRequired,
                }),
              );
              dispatch(
                setUnlockTime({
                  unlockedTime: new Date(res.timeOfFreeShake).getTime() / 1000,
                }),
              );
            })
            .catch((e: { message: string }) => {
              console.error(e);
            });
        }
      }
    }, 500);
    return function () {
      clearInterval(timer);
    };
  }, [unlockTime, shakes, xhr]);

  // set the gift number to zero when going to gifts page
  useEffect(() => {
    setDot("pv", "pv_reward_list_page", "", "");
    setGaDot({ action: "pv_reward_list_page", value: {} });
    dispatch(clearNewGift());
  }, []);

  const onShakeSuccess = (res: any) => {
    // when shaking, some element in the background will be hidden

    if ((window as Client).shakewin_report?.reportInteraction && loggedIn) {
      if (verified) {
        (window as Client).shakewin_report?.reportInteraction("shake_logged_in");
      } else {
        (window as Client).shakewin_report?.reportInteraction("shake_logged_in_social_only");
      }
    }
    const resultId = res.shakeResultId;
    setDot("click", "shake_completed", "prize_won", res.prizeWon ? "1" : "0");
    setDot(
      "click",
      "shake_completed",
      "prize_type",
      res.prizeWon
        ? res.prizeWon.type === "puzzle" && !!res.prizeWon.prizeId
          ? "puzzle prize"
          : res.prizeWon.type
        : "null",
    );
    setDot(
      "click",
      "shake_completed",
      "puzzle_piece_id",
      res.prizeWon?.wonPuzzlePiece ? String(res.prizeWon?.wonPuzzlePiece) : "null",
    );
    setDot("click", "shake_completed", "user_status", user_status);
    setDot("click", "shake_completed", "", "");
    setGaDot({
      action: "shake_completed",
      value: {
        prize_won: res.prizeWon ? "1" : "0",
        prize_type: res.prizeWon
          ? res.prizeWon.type === "puzzle" && !!res.prizeWon.prizeId
            ? "puzzle prize"
            : res.prizeWon.type
          : "null",
        puzzle_piece_id: res.prizeWon?.wonPuzzlePiece || "null",
        user_status: user_status,
      },
    });
    const bg = document.getElementById("rewarding-transition");
    bg?.setAttribute(
      "style",
      "background: rgb(14, 130, 63); position: fixed; width: 100vw; height: 100vh; z-index: 1000; bottom: 0; transition: all 0.3s linear;",
    );
    setTimeout(() => {
      navigate("/result?result_id=" + resultId || "none");
      return;
    }, 600);
    return;
  };

  const onShakeError = () => {
    dispatch(shaking());
    setDot("click", "shake_completed", "prize_won", "0");
    setDot("click", "shake_completed", "prize_type", "null");
    setDot("click", "shake_completed", "puzzle_piece_id", "null");
    setDot("click", "shake_completed", "user_status", user_status);
    setDot("click", "shake_completed", "", "");
    setGaDot({
      action: "shake_completed",
      value: {
        prize_won: "0",
        prize_type: "null",
        puzzle_piece_id: "null",
        user_status: user_status,
      },
    });
    setTimeout(() => {
      navigate("/result?result_id=none");
    }, 600);
  };

  const shake = useCallback(async () => {
    if (recaptchaRequired && executeRecaptcha) {
      const recaptcha = await executeRecaptcha("USER_SHAKE");
      if (shakes > 0 && !isShaking) {
        postShake({
          recaptcha,
          callback: onShakeSuccess,
          onError: onShakeError,
        });
      }
    } else {
      postShake({
        recaptcha: "",
        callback: onShakeSuccess,
        onError: onShakeError,
      });
    }

    dispatch(shaking());
  }, []);

  // handle the tab switch
  useEffect(() => {
    if (tab === "winnable") {
      setPrizeDom(
        <>
          {Array.isArray(winnable)
            ? winnable.map((prize: Reward, index) => {
                return (
                  <div
                    key={prize?.id + "_rewards_" + index}
                    style={{
                      borderTop: index ? "1px solid rgba(255,255,255,0.15)" : "",
                    }}
                  >
                    <PrizeCard prize={prize} />
                  </div>
                );
              })
            : []}
        </>,
      );
    } else {
      setPrizeDom(
        won.length ? (
          <>
            {won.map((prize, index) => {
              return (
                <div
                  key={prize?.id + "_rewards_" + index}
                  style={{
                    borderTop: index ? "1px solid rgba(255,255,255,0.15)" : "",
                  }}
                >
                  <PrizeCard prize={prize} />
                </div>
              );
            })}
          </>
        ) : (
          <div
            style={{
              textAlign: "center",
              margin: "24px",
            }}
          >
            <img
              style={{
                width: "44px",
                height: "44px",
              }}
              src={require("../../assets/images/error_white.png")}
              alt=""
            />
            <div
              style={{
                marginTop: "8px",
                color: "#ffffff",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "20px",
              }}
            >
              {t("noPrizeYet")}
            </div>
          </div>
        ),
      );
    }
  }, [winnable, won, tab]);

  return (
    <div className={styles["main"]} style={{ background: theme.backgroundImage }}>
      <div className={styles["empty-header"]}></div>
      {shakes ? (
        <div
          style={{
            textAlign: "center",
            paddingTop: "16px",
          }}
        >
          <img
            src={require("../../assets/images/giveaway/gift_box_title.png")}
            alt=""
            style={{
              width: "280px",
              height: "auto",
              display: "block",
              margin: "8px auto",
            }}
          />

          <div style={{ margin: "8px auto", display: "flex", justifyContent: "center" }}>
            <ShakingIcon />
          </div>

          <div
            onClick={shake}
            style={{
              background: theme.secondaryBackgroundColor,
              padding: "12px",
              display: "flex",
              borderRadius: "8px",
              width: "110px",
              margin: "0 auto",
              color: "rgba(255, 255, 255, 0.75) ",
            }}
          >
            <img
              style={{ width: "22px", height: "22px", marginRight: "4px" }}
              src={require("../../assets/images/giveaway/finger.png")}
              alt=""
            />
            <span>tap to open</span>
          </div>
          <div id={"recaptch-shaking"}></div>
        </div>
      ) : (
        <div
          style={{
            paddingTop: "16px",
            margin: "16px",
            textAlign: "center",
          }}
        >
          <div style={{ margin: "16px 0" }}>
            <p style={{ fontWeight: 700, fontSize: "16px", lineHeight: "20px" }}>
              Next gift available in:
            </p>
            <div
              style={{
                margin: "8px auto 0",
                padding: "12px 0",
                width: "120px",
                background: theme.menuColor,
                fontSize: "22px",
                fontWeight: 900,
                borderRadius: "8px",
              }}
            >
              {timeCountDown(diff)}
            </div>
          </div>
          {clientName !== "mini" ? null : (
            <SWButton
              text={"Play more games"}
              callback={() => {
                (window as Client).shakewin?.openOperaMiniDeepLink(
                  "opera-mini://newsfeed/Games",
                  true,
                );
                return;
              }}
            />
          )}
        </div>
      )}

      <div className={styles["inventory"]}>
        {loggedIn ? null : <Warning noBg={true} margin={"0 auto 8px"} />}

        <div className={styles["tabs"]}>
          <div
            className={styles["tab"] + " " + (tab === "winnable" ? styles["chosen-tab"] : "")}
            onClick={() => {
              setTab("winnable");
            }}
          >
            {t("availablePrizes")}
          </div>
          <div
            className={styles["tab"] + " " + (tab === "won" ? styles["chosen-tab"] : "")}
            onClick={() => {
              setTab("won");
            }}
          >
            {t("won")}
          </div>
        </div>
        <div
          className={styles["container"]}
          style={{
            background:
              (tab === "won" && won.length) || (tab === "winnable" && winnable.length)
                ? theme.secondaryBackgroundColor
                : "",
            border:
              (tab === "won" && won.length) || (tab === "winnable" && winnable.length)
                ? `1px solid ${theme.menuColor}`
                : "",
            borderRadius:
              (tab === "won" && won.length) || (tab === "winnable" && winnable.length)
                ? "12px"
                : "",
          }}
        >
          {prizeDom}
        </div>
      </div>
      <div
        id={"rewarding-transition"}
        style={{
          background: theme.buttonColor,
          position: "fixed",
          width: "100vw",
          height: "100vh",
          zIndex: 1000,
          bottom: "-100vh",
          transition: "all linear 0.3s",
        }}
      ></div>
      <Footer />
    </div>
  );
};
