import axios from "axios";
import * as Sentry from "@sentry/react";
import { getClientName, oprVersion } from "../config";
import axiosRetry, { isNetworkOrIdempotentRequestError } from "axios-retry";
import store from "store2";

const createXhr: (params: { headers?: RequestHeaders }) => XHR = (params) => {
  const HAID = (window as Client).shakewin?.getHaid
    ? (window as Client).shakewin?.getHaid() || ""
    : "";
  // @ts-ignore
  const xhr = axios.create({
    timeout: 20000,
    headers: HAID
      ? {
          ...params.headers,
          "X-SW-Client": getClientName(),
          "X-SW-ClientVersion": oprVersion,
          "X-HAID": HAID,
        }
      : {
          ...params.headers,
          "X-SW-Client": getClientName(),
          "X-SW-ClientVersion": oprVersion,
        },
    baseURL: process.env.REACT_APP_PUBLIC_URL,
  });

  const xhrAuth = axios.create({
    timeout: 20000,
    headers: HAID
      ? {
          ...params.headers,
          "X-SW-Client": getClientName(),
          "X-SW-ClientVersion": oprVersion,
          "X-HAID": HAID,
        }
      : {
          ...params.headers,
          "X-SW-Client": getClientName(),
          "X-SW-ClientVersion": oprVersion,
        },
    baseURL: process.env.REACT_APP_PUBLIC_AUTH_URL,
  });

  axiosRetry(xhr, {
    retries: 3,
    retryDelay: (retryCount: number) => {
      return 1000 + 10 ** retryCount * (3 + Math.random());
    },
    retryCondition: (error) => {
      return isNetworkOrIdempotentRequestError(error) && (error?.response?.status || 0) >= 500;
    },
  });

  axiosRetry(xhrAuth, {
    retries: 3,
    retryDelay: (retryCount: number) => {
      return 1000 + 10 ** retryCount * (3 + Math.random());
    },
    retryCondition: (error) => {
      return isNetworkOrIdempotentRequestError(error) && (error?.response?.status || 0) >= 500;
    },
  });

  xhr.interceptors.response.use(
    function (response) {
      return { ...response.data };
    },
    function (error) {
      if (JSON.stringify(error.response).includes("campaign is not visible")) {
        (window as Client)?.shakewin?.onLogout();
        window.location.reload();
        return;
      }
      if (JSON.stringify(error.response).includes("merged into")) {
        (window as Client)?.shakewin?.onLogout();
        window.location.reload();
        return;
      }
      Sentry.captureException(error);
      return Promise.reject({ status: error.response.status, ...error.response.data });
    },
  );

  xhrAuth.interceptors.response.use(
    function (response) {
      return { ...response.data };
    },
    function (error) {
      if (JSON.stringify(error.response).includes("merged into")) {
        (window as Client)?.shakewin?.onLogout();
        store.remove("access_token");
        store.remove("refresh_token");
        store.remove("access_token");
        store.remove("auth_refreshToken");
        store.remove("auth_accessToken");
        store.remove("shake");
        store.remove("chosen_country");
        store.remove("selected_country");
        window.location.reload();
      }
      Sentry.captureException(error);
      return Promise.reject({ status: error.response.status, ...error.response.data });
    },
  );

  return {
    headers: { ...params.headers },
    getInfo() {
      return xhr.get("/api/v1/info");
    },
    getNewUser() {
      return xhr.get("/api/v1/user/new");
    },
    getShakes() {
      return xhr.get(`/api/v1/shakes`);
    },
    getShakeResult(params: { result_id: string }) {
      return xhr.get(`/api/v1/shakes/result/${params.result_id}`);
    },
    getUserInfo() {
      return xhr.get("/api/v1/user/me");
    },
    getPrizesWinnable() {
      return xhr.get(`/api/v1/prizes/winnable`);
    },

    getPrizesWon() {
      return xhr.get(`/api/v1/prizes/won`);
    },

    getPuzzles() {
      return xhr.get(`/api/v1/user/puzzles`);
    },

    getMissionIds() {
      return xhr.get("/api/v1/missions");
    },

    getMissionsPredictorToken(params) {
      return xhr.get("/api/v1/missions/predictor/token", { params });
    },

    getMissionsReferral(params) {
      return xhr.get(`/api/v1/missions/${params?.missionId}`, { params });
    },

    getMissionDetails(params) {
      return xhr.get(`/api/${params?.version || "v1"}/missions/${params?.missionId}`, { params });
    },
    postRefreshToken(param) {
      return xhr.post("/api/v1/user/refresh", param);
    },
    postUserCountry(data: { countryCode: string }) {
      return xhr.post(`/api/v1/user/country`, data);
    },
    postShakes(data) {
      return xhr.post(`/api/v1/shakes`, data);
    },
    postIdToken(params) {
      return xhr.post("/api/v1/phone/id-verify", params);
    },
    getClaimForm(params: { prize_type: string; prize_id: string }) {
      return xhr.get(`/api/v1/prizes/${params.prize_type}/${params.prize_id}/claim-form`);
    },
    postClaimForm(params: { prize_type: string; prize_id: string }, body) {
      return xhr.post(`/api/v1/prizes/${params.prize_type}/${params.prize_id}/claim-form`, body);
    },
    postOfADefaultBrowserAction() {
      return xhr.post(`/api/v1/missions/ofa-default-browser/submit`);
    },
    postMiniDefaultBrowserAction() {
      return xhr.post(`/api/v1/missions/mini-default-browser/submit`);
    },
    getPrizeCouponCode(params: { prize_type: string; prize_id: string }) {
      return xhr.get(`/api/v1/prizes/${params.prize_type}/${params.prize_id}/coupon-code`);
    },
    postFeedback(data: { countryCode: string }) {
      return xhr.post(`/api/v1/feedback`, data, {
        headers: { "Content-Type": "multipart/form-data", Accept: "application/json" },
      });
    },
    getAnonUser() {
      return xhr.get("/api/v2/user/anon");
    },

    postRefreshTokenV2(param) {
      return xhr.post("/api/v2/user/refresh", param);
    },

    postPhoneVerifyV2(data) {
      return xhrAuth.post("/api/v2/auth/login/phone", data);
    },

    postUserVerifyV2(data) {
      return xhr.post("/api/v2/user/verify", data);
    },

    getUser() {
      return xhrAuth.get("/api/v2/user");
    },

    putUserPassword(data) {
      return xhrAuth.put("/api/v2/user/password", data);
    },

    putUserEmail(data) {
      return xhrAuth.put("/api/v2/user/email", data);
    },

    postLoginWithPhonePassword(data) {
      return xhrAuth.post("/api/v2/auth/login/phone-password", data);
    },

    postLoginWithEmailPassword(data) {
      return xhrAuth.post("/api/v2/auth/login/email-password", data);
    },

    postAuthLoginEmailOtp(data: { email: string; token: string }) {
      return xhrAuth.post("/api/v2/auth/login/email-otp", data);
    },

    postAuthLoginEmailOtpRequest(data: { email: string; projectId: string }) {
      return xhrAuth.post("/api/v2/auth/login/email-otp/request", data);
    },

    postRefreshAuthToken(data) {
      return xhrAuth.post("/api/v2/auth/refresh", data);
    },

    postConnectAccount(data) {
      return xhrAuth.post("/api/v2/user/oauth/connect/shakewin/" + data.providerId, data);
    },

    postDisconnectAccount(param) {
      return xhrAuth.post("/api/v2/user/oauth/disconnect/shakewin/" + param.providerId);
    },

    postOauth2Login(data) {
      return xhrAuth.post(`/api/v2/auth/login/oauth2`, data);
    },

    postUserExists(data) {
      return xhrAuth.post(`/api/v2/user/login-methods`, data);
    },

    postMobileAuthLogin(data) {
      return xhr.post(`/api/v2/user/mobile-auth/login`, data);
    },

    postConnectPhoneNumber(data) {
      return xhrAuth.post("/api/v2/user/phone/verify", data);
    },

    postAuthLoginGoogleSignIn(data) {
      return xhrAuth.post("/api/v2/auth/login/google-signin", data);
    },

    postWallpaperMission(data) {
      return xhr.post("/api/v1/missions/set-wallpaper/submit", data);
    },

    postMiniPayStatus(data) {
      return xhr.post("/api/v1/missions/minipay-wallet/submit", data, {
        headers: { "Content-Type": "application/json", Accept: "application/json" },
      });
    },

    postAuthLoginOperaNews(data) {
      return xhrAuth.post("/api/v2/auth/login/opera-news", data);
    },

    getStats() {
      return xhr.get("/api/v1/stats");
    },

    getUserStats() {
      return xhr.get("/api/v1/user/stats");
    },
  };
};

export default createXhr;
