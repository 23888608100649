import React, { useEffect, useState } from "react";
import ReactCountryFlag from "react-country-flag";

interface SelectListProps {
  title?: string;
  currentItem?: ListItem;
  items?: {
    [key: string]: ListItem;
  };
  type: "countries" | "payment" | "issues";
  selectCb: (data: any) => void;
  disabled?: boolean;
  placeholder?: string;
  placeholderColor?: string;
  background?: string;
  color?: string;
  theme?: "dark" | "light";
}

export const SelectList: React.FC<SelectListProps> = (props) => {
  const {
    title,
    currentItem,
    items,
    type,
    selectCb,
    disabled,
    placeholder,
    placeholderColor,
    color,
    background,
    theme,
  } = props;
  const [chosenItem, setChosenItem] = useState<ListItem | undefined>(currentItem);
  const [toggled, setToggled] = useState<boolean>(false);
  const [itemList, setItemList] = useState(<div></div>);
  useEffect(() => {
    return setItemList(
      <div>
        {Object.keys(items || {}).map((code, index) => {
          // @ts-ignore
          const val = items[code];
          return (
            <div
              key={code + "_" + index}
              onClick={() => {
                // @ts-ignore
                if (items[code]) {
                  selectCb(code);
                  // @ts-ignore
                  setChosenItem(items[code]);
                  setToggled(false);
                }
              }}
              style={{
                display: chosenItem?.code === code ? "none" : "flex",
                color: color || "#000000",
                background: background || "#ffffff",
                height: "22px",
                lineHeight: "22px",
                padding: "12px",
              }}
            >
              <div
                key={code + "_"}
                style={{
                  display: type === "countries" || val.img ? "flex" : "none",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "22px",
                  height: "22px",
                  overflow: "hidden",
                  borderRadius: "50%",
                  marginRight: "12px",
                }}
              >
                {type === "countries" ? (
                  <ReactCountryFlag
                    countryCode={code}
                    key={code + "__"}
                    svg
                    style={{
                      width: "30px",
                      height: "45px",
                    }}
                  />
                ) : (
                  <img
                    src={val.img}
                    alt=""
                    style={{
                      width: "22px",
                      height: "22px",
                    }}
                  />
                )}
              </div>
              <div
                style={{
                  fontSize: "16px",
                }}
              >
                {val.name}
              </div>
            </div>
          );
        })}
      </div>,
    );
  }, [items, chosenItem, type]);
  return (
    <div
      style={{
        padding: "12px",
        background: background || "#ffffff",
        position: "relative",
        fontFamily: "Inter",
        borderRadius: "8px",
        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div
        style={{
          fontSize: "14px",
          fontWeight: 400,
          color: placeholderColor || "rgba(0, 0, 0, 0.5)",
          paddingBottom: "12px",
          display: title ? "" : "none",
        }}
      >
        {title}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        onClick={() => {
          if (disabled || Object.keys(items as any).length <= 1) {
            return;
          }
          setToggled((toggle) => !toggle);
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {!chosenItem ? (
            <span style={{ lineHeight: "22px", color: placeholderColor || "rgba(0, 0, 0, 0.5)" }}>
              {placeholder}
            </span>
          ) : (
            <div
              style={{
                display: chosenItem?.img || type === "countries" ? "flex" : "none",
                alignItems: "center",
                justifyContent: "center",
                width: "22px",
                height: "22px",
                overflow: "hidden",
                borderRadius: "50%",
                marginRight: "12px",
              }}
            >
              {type === "countries" ? (
                <ReactCountryFlag
                  countryCode={chosenItem?.code}
                  key={chosenItem?.code + "__"}
                  svg
                  style={{
                    width: "30px",
                    height: "45px",
                  }}
                />
              ) : (
                <img
                  src={chosenItem?.img}
                  alt=""
                  style={{
                    width: "22px",
                    height: "22px",
                  }}
                />
              )}
            </div>
          )}

          <span
            style={{
              fontSize: "16px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: color || "#000000",
            }}
          >
            {chosenItem?.name}
          </span>
        </div>
        <img
          src={
            theme !== "dark"
              ? require("../assets/images/arrow_down.png")
              : require("../assets/images/arrow_down_white.png")
          }
          alt=""
          style={{
            position: "absolute",
            width: "22px",
            height: "22px",
            right: "12px",
            transform: toggled ? "rotate(180deg)" : "",
            visibility: Object.keys(items || {}).length > 1 && !disabled ? "visible" : "hidden",
          }}
        />
      </div>
      <div
        style={{
          position: "absolute",
          left: 0,
          top: "98%",
          width: "99%",
          background: background || "#ffffff",
          display: toggled ? "" : "none",
          zIndex: 3,
          maxHeight: "100px",
          overflowY: "auto",
          paddingBottom: "8px",
          border: "solid rgba(100, 100, 100, 0.5) 1px",
          borderTop: "none",
        }}
      >
        {itemList}
      </div>
    </div>
  );
};
