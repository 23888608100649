import React, { useEffect, useState } from "react";
import styles from "./styles/display_slides.module.scss";

interface DisplaySlidesProps {
  name: string;
  img: string[];
}

export const ApexDisplaySlides: React.FC<DisplaySlidesProps> = (props) => {
  const slidesId = useState<string>(`${props.name}_slides`)[0];
  const [images, setImages] = useState<string[]>([...props.img, props.img[0]]);
  // const [images, setImages] = useState<string[]>([...props.img, ...props.img, ...props.img, props.img[0]]);
  const container = document.getElementById(slidesId);
  const [slides, setSlides] = useState(
    images.map((slide, index) => (
      <div className={styles["slide"]} key={slide + "_" + index}>
        <img src={slide || require("../assets/images/transparent.png")} alt={"img"} />
      </div>
    )),
  );
  const [timer, setTimer] = useState<any>(undefined);

  useEffect(() => {
    setImages([...props.img, props.img[0]]);
  }, [props.img]);

  useEffect(() => {
    setSlides(
      images.map((slide, index) => (
        <div className={styles["slide"]} key={slide + "_" + index}>
          <img src={slide || require("../assets/images/transparent.png")} alt={"img"} />
        </div>
      )),
    );
  }, [images]);

  useEffect(() => {
    clearInterval(timer);
    setTimer(undefined);
    if (container) {
      container.style.left = 0 + "";
    }
    let i = 0;
    const next_pic = () => {
      if (container) {
        i++;
        if (i === images.length - 1) {
          container.style.left = i * -100 + "vw";
          setTimeout(() => {
            i = 0;
            container.style.transition = "none";
            container.style.left = i * -100 + "vw";
          }, 250);
        }
        container.style.transition = ".25s ease";
        container.style.left = i * -100 + "vw";
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const time2 = setInterval(() => {
      next_pic();
    }, 2000);
    setTimer(time2);

    return function cleanup() {
      clearInterval(timer);
      setTimer(undefined);
    };
  }, [slides]);

  return (
    <div className={styles["main"]}>
      <div className={styles["container"]} id={slidesId}>
        {slides}
      </div>
    </div>
  );
};
