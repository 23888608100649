import React, { useCallback, useEffect, useState } from "react";
import { AppDispatch, useSelector } from "../store";
import { useDispatch } from "react-redux";
import { BottomModal } from "./BottomModal";
import { fetchMissions } from "../store/state";
import { getClientName } from "../config";

const modalDisplayTime = 10000; // 10 seconds
const browserCheckInterval = 5000; // 5 seconds

export const DefaultBrowserWatcher: React.FC = () => {
  const xhr = useSelector((state) => state.data.xhr);
  const missions = useSelector((state: any) => state.data.missions);
  const dispatch = useDispatch<AppDispatch>();
  const [showModal, setShowModal] = useState(false);
  const [checkTimeout, setCheckTimeout] = useState<number | null>(null);
  const [disableWatcher, setDisableWatcher] = useState(false);
  const [clientName, setClientName] = useState<string | null>(null);

  const browserApi = (window as any)?.shakewin;
  // To do a quick test, replace browserApi with:
  // {
  //   isDefaultBrowser: () => true,
  //   isDefaultBrowserSupported: () => true
  // }

  const completeMission = useCallback(async () => {
    let response;
    if (clientName === "ofa") {
      response = await xhr.postOfADefaultBrowserAction();
    } else if (clientName === "mini") {
      response = await xhr.postMiniDefaultBrowserAction();
    } else {
      return;
    }

    if (response?.message === "User already completed the mission") {
      setDisableWatcher(true);
      dispatch(fetchMissions());
    } else {
      setDisableWatcher(true);
      showSuccessModal();
      dispatch(fetchMissions());
    }
  }, [clientName, dispatch, xhr]);

  function clearTimeout() {
    if (checkTimeout) {
      window.clearTimeout(checkTimeout);
      setCheckTimeout(null);
    }
  }

  function hideSuccessModal() {
    setShowModal(false);
    document.removeEventListener("click", hideSuccessModal);
  }

  function showSuccessModal() {
    setShowModal(true);
    window.setTimeout(hideSuccessModal, modalDisplayTime);
    document.addEventListener("click", hideSuccessModal);
  }

  const checkBrowser = useCallback(async () => {
    const isDefault = browserApi?.isDefaultBrowser ? browserApi.isDefaultBrowser() : false;
    if (isDefault) {
      clearTimeout();
      await completeMission();
    } else {
      clearTimeout();
      setCheckTimeout(window.setTimeout(checkBrowser, browserCheckInterval));
    }
  }, [browserApi, completeMission]);

  useEffect(() => {
    const currentClientName = getClientName();

    if (currentClientName !== clientName) {
      setClientName(currentClientName);
    }

    let mission;
    if (currentClientName === "ofa") {
      mission = missions.find((mission: any) => mission.id === "ofa-default-browser");
    } else if (currentClientName === "mini") {
      mission = missions.find((mission: any) => mission.id === "mini-default-browser");
    } else {
      return;
    }

    if (!mission || mission?.isCompleted || disableWatcher) {
      return;
    }

    clearTimeout();
    const timeout = window.setTimeout(checkBrowser, browserCheckInterval);
    setCheckTimeout(timeout);

    return () => clearTimeout();
  }, [missions]);

  return showModal ? <BottomModal type={"Default browser mission success"} /> : null;
};
