import React from "react";
import styles from "../styles/tc.module.scss";

export const TcForBR: React.FC = () => {
  return (
    <div className={styles.br}>
      <div className={styles.br_title_area}>
        <h3>Regulamento Da Promoção </h3>
        <p>“Chacoalhe e Concorra com o Opera”</p>
        <p>CERTIFICADO DE AUTORIZAÇÃO SRE/MF Nº. {"05.031238/2023"}</p>
      </div>
      <article className={styles.tc_br_article}>
        <h3 className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>1. </span>
          <span className={styles.tc_text_br}>EMPRESA PROMOTORA:</span>
        </h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>1.1. </span>
          <span className={styles.tc_text_br}>Empresa Mandatária:</span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>1.1.1. </span>
          <span className={styles.tc_text_br}>
            Razão Social: Adclick Brasil Marketing de Performance Ltda
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>1.1.2. </span>
          <span className={styles.tc_text_br}>
            Endereço: Rua Sergipe, 475 – 5º andar – conj. 501 a 506 – Consolação – São Paulo/SP -
            CEP: 01243-001
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>1.1.3. </span>
          <span className={styles.tc_text_br}>CNPJ nº. {"14.314.669/0001-62"}</span>
        </p>
      </article>
      <article className={styles.tc_br_article}>
        <h3 className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>2. </span>
          <span className={styles.tc_text_br}>MODALIDADE DA PROMOÇÃO:</span>
        </h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>2.1. </span>
          <span className={styles.tc_text_br}>Assemelhado a vale-brinde</span>
        </p>
      </article>
      <article className={styles.tc_br_article}>
        <h3 className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>3. </span>
          <span className={styles.tc_text_br}>ÁREA DE ABRANGÊNCIA:</span>
        </h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>3.1. </span>
          <span className={styles.tc_text_br}>Território nacional</span>
        </p>
      </article>
      <article className={styles.tc_br_article}>
        <h3 className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>4. </span>
          <span className={styles.tc_text_br}>PERÍODO DA PROMOÇÃO:</span>
        </h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>4.1. </span>
          <span className={styles.tc_text_br}>{"23/11/2023 a 21/12/2023"}</span>
        </p>
      </article>
      <article className={styles.tc_br_article}>
        <h3 className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>5. </span>
          <span className={styles.tc_text_br}>PERÍODO DE PARTICIPAÇÃO:</span>
        </h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>5.1. </span>
          <span className={styles.tc_text_br}>
            {
              "23/11/2023 a 21/11/2024 ou enquanto durar o estoque de 3.711 (três mil setecentos e onze) prêmios."
            }
          </span>
        </p>
      </article>
      <article className={styles.tc_br_article}>
        <h3 className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>6. </span>
          <span className={styles.tc_text_br}>CRITÉRIO DE PARTICIPAÇÃO:</span>
        </h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>6.1. </span>
          <span className={styles.tc_text_br}>
            A promoção <b>“CHACOALHE E CONCORRA COM O OPERA”</b> é válida e destinada a todo e
            qualquer interessado, residente e domiciliado em território nacional, desde que preencha
            as demais condições de participação estabelecidas neste regulamento.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>6.2. </span>
          <span className={styles.tc_text_br}>
            Durante o período de participação da promoção, o usuário interessado poderá acessar a
            página <a href="https://shakewin.opera.com">shakewin.opera.com</a> da promoção no
            navegador Opera para Android por meio do site oficial{" "}
            <a href="https://shakewin.opera.com">shakewin.opera.com</a> e seguir os passos para
            baixar um dos navegadores participantes. Apenas a última versão do aplicativo Opera para
            Android participa da promoção. Os participantes devem tê-lo instalado para participar.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>6.3. </span>
          <span className={styles.tc_text_br}>
            Após acessar o site da promoção, o usuário será convidado a chacoalhar seus telefones
            para ter a chance de participar e ganhar os prêmios da promoção.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>6.4. </span>
          <span className={styles.tc_text_br}>
            Ao chacoalhar seu telefone o participante poderá ganhar de 1 (uma) a 6 (seis) peças para
            formar um quebra-cabeça composto por 6 (seis) peças, sem caráter de coleção. Ao
            completar o quebra cabeça o participante deverá verificar se foi ou não contemplado com
            um dos vale-compras previsto no item 7.1 deste regulamento, respeitando as regras de
            contemplação na promoção.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>6.5. </span>
          <span className={styles.tc_text_br}>
            Durante a campanha algumas missões serão oferecidas aos participantes, que ao completar
            as mesmas novos chacoalhos serão dados ao participante, aumentando suas chances de
            ganhar. Ao completar missões como tornar o Opera o seu navegador padrão, ou mudar o
            papel de parede do seu navegador Opera, o participante ganhará mais um chacoalho.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>6.6. </span>
          <span className={styles.tc_text_br}>
            Os participantes da promoção concorrerão aos prêmios instantâneos, indicados no item 7.1
            deste regulamento, de 23/11/2023 a 21/12/2023 ou enquanto durar o estoque de 3.711 (três
            mil setecentos e onze) prêmios disponíveis para distribuição em toda promoção, sendo
            distribuído 1 (um) prêmio para o 1º quebra-cabeça completo em determinado horário de
            contemplação sigiloso pré-determinado junto à SRE/MF.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>6.6.1. </span>
          <span className={styles.tc_text_br}>
            Caso nenhum participante complete um quebra-cabeça no exato horário de contemplação
            pré-determinado junto à SRE/MF, será considerado contemplado o 1o participante completar
            um quebra-cabeça após o horário definido acima através de hora, minuto, segundo e
            milésimos. Permanecendo o empate, será considerado contemplado o participante que
            acessou o site da promoção primeiro através de hora, minuto, segundo e milésimos.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>6.7. </span>
          <span className={styles.tc_text_br}>
            Todos os participantes declaram que somente fornecerão informações verdadeiras e
            corretas sobre si, passíveis de confirmação, e que não utilizarão qualquer artifício,
            tal como, mas não limitado, ao uso de informações de terceiros, informações incorretas,
            incompletas, que visem propositalmente burlar as regras de participação nesta promoção e
            que possam configurar crime de falsidade ideológica ou documental, cientes de que
            poderão responder administrativa, cível ou penalmente pelos atos praticados.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>6.8. </span>
          <span className={styles.tc_text_br}>
            Os participantes também não poderão utilizar-se de meios escusos para participar desta
            promoção e/ou de mecanismos que criem condições de cadastramento irregular, desleais ou
            que atentem contra os objetivos e condições de participação previstas neste regulamento.
            As situações descritas, quando identificadas, serão consideradas como infração aos
            termos do presente regulamento, ensejando o impedimento da participação e/ou o imediato
            cancelamento da inscrição do participante, sem prejuízo, ainda, das medidas cabíveis
            e/ou ação de regresso a ser promovida pela promotora em face do infrator.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>6.9. </span>
          <span className={styles.tc_text_br}>
            Caso todos os prêmios sejam distribuídos antes do término, a campanha será considerada
            encerrada, dando-se ampla divulgação aos usuários.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>6.10. </span>
          <span className={styles.tc_text_br}>
            Será considerado o horário de Brasília/DF para todos os períodos descritos neste
            regulamento.
          </span>
        </p>
      </article>
      <article className={styles.tc_br_article}>
        <h3 className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>7. </span>
          <span className={styles.tc_text_br}>PRÊMIO:</span>
        </h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>7.1. </span>
          <span className={styles.tc_text_br}>
            Serão distribuídos {"3.711"} (três mil novecentos e oitenta e um) prêmios em toda
            promoção, conforme descrito abaixo:
          </span>
        </p>
        {
          <table className={styles.tc_table_4}>
            <tbody>
              <tr>
                <th>Quantidade de prêmios</th>
                <th>Descrição de cada prêmio</th>
                <th>Valor unitário</th>
                <th>Valor total</th>
              </tr>
              <tr>
                <td>1</td>
                <td>
                  1 (um) Voucher (vale compras), a livre escolha, dentre as possibilidades
                  apresentadas pela empresa promotora: Americanas Card; ou Assaí; ou Carrefour; ou
                  Extra Card; ou Netshoes
                </td>
                <td>R$ 100,30</td>
                <td>R$ 100,30</td>
              </tr>
              <tr>
                <td>4</td>
                <td>
                  1 (um) Voucher (vale compras), a livre escolha, dentre as possibilidades
                  apresentadas pela empresa promotora: Uber; ou Ifood; ou Mc Donald’s; ou Razer Gold
                </td>
                <td>R$ 50,30</td>
                <td>R$ 201,20</td>
              </tr>
              <tr>
                <td>20</td>
                <td>
                  1 (um) Voucher (vale compras), a livre escolha, dentre as possibilidades
                  apresentadas pela empresa promotora: Mc Donald’s; ou Razer Gold; ou Carrefour
                </td>
                <td>R$ 30,30</td>
                <td>R$ 606,00</td>
              </tr>
              <tr>
                <td>2.501</td>
                <td>
                  1 (um) Voucher (vale compras), a livre escolha, dentre as possibilidades
                  apresentadas pela empresa promotora: PrimePass (2 ingressos de cinema); ou Bob’s
                  (Cheddar Australiano); ou Bob’s (Big Bob); ou Mobile Data (1.25GB)
                </td>
                <td>R$ 9,40</td>
                <td>R$ 23.590,40</td>
              </tr>
              <tr>
                <td>28</td>
                <td>
                  1 (um) Voucher (vale compras), a livre escolha, dentre as possibilidades
                  apresentadas pela empresa promotora: Uber; ou Rappi Card; ou Submarino Card; ou
                  Americanas Card
                </td>
                <td>R$ 23,50</td>
                <td>R$ 708,40</td>
              </tr>
              <tr>
                <td>44</td>
                <td>
                  1 (um) Voucher (vale compras), a livre escolha, dentre as possibilidades
                  apresentadas pela empresa promotora: Ifood; ou Uber; ou Carrefour
                </td>
                <td>R$ 20,30</td>
                <td>R$ 893,20</td>
              </tr>
              <tr>
                <td>504</td>
                <td>
                  1 (um) Voucher (vale compras), a livre escolha, dentre as possibilidades
                  apresentadas pela empresa promotora: Mc Donald’s; ou Uber; ou Rappi Card; ou Ifood
                </td>
                <td>R$ 15,30</td>
                <td>R$ 7.711,20</td>
              </tr>
              <tr>
                <td>609</td>
                <td>
                  1 (um) Voucher (vale compras), a livre escolha, dentre as possibilidades
                  apresentadas pela empresa promotora: Uber; ou Ifood; ou Razer Gold
                </td>
                <td>R$ 10,30</td>
                <td>R$ 6.272,70</td>
              </tr>
            </tbody>
          </table>
        }
      </article>
      <article className={styles.tc_br_article}>
        <h3 className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>8. </span>
          <span className={styles.tc_text_br}>PREMIAÇÃO TOTAL:</span>
        </h3>
        <table className={styles.tc_table_2}>
          <tbody>
            <tr>
              <th>Quantidade total de prêmios</th>
              <th>Valor total da promoção</th>
            </tr>
            <tr>
              <td>{3.711}</td>
              <td>{"R$ 40.002,40"}</td>
            </tr>
          </tbody>
        </table>
      </article>
      <article className={styles.tc_br_article}>
        <h3 className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>9. </span>
          <span className={styles.tc_text_br}>CRITÉRIOS DE DESCLASSIFICAÇÃO:</span>
        </h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>9.1. </span>
          <span className={styles.tc_text_br}>
            Os participantes poderão ser excluídos automaticamente da promoção em caso de fraude
            comprovada, de não preenchimento dos requisitos previamente determinados e/ou em
            decorrência da prestação de informações incorretas ou equivocadas, de acordo com as
            regras do regulamento da promoção.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>9.2. </span>
          <span className={styles.tc_text_br}>
            Ficara vedada a participação dos funcionários da Empresa Promotora e Aderentes, bem como
            dos funcionários, empregados, terceiros ou colaboradores que atuarem diretamente no
            planejamento, criação, execução da Promoção, da agencia de publicidade e promoção e
            outras empresas diretamente envolvidas no processo. O atendimento ao acima disposto será
            de inteira responsabilidade da Empresa Promotora e Aderentes, que desclassificarão os
            participantes impedidos no ato da inscrição ou da validação da condição de vencedor.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>9.3. </span>
          <span className={styles.tc_text_br}>
            O potencial contemplado porventura desclassificado da promoção não terá direito ao
            recebimento do prêmio e a empresa promotora irá reprogramar uma nova data e horário para
            distribuição do prêmio.
          </span>
        </p>
      </article>
      <article className={styles.tc_br_article}>
        <h3 className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>10. </span>
          <span className={styles.tc_text_br}>ENTREGA DO PRÊMIO:</span>
        </h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>10.1. </span>
          <span className={styles.tc_text_br}>
            O prêmio é pessoal e intransferível e será disponibilizado ao contemplado diretamente no
            site da promoção.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>10.2. </span>
          <span className={styles.tc_text_br}>
            Os prêmios distribuídos serão entregues livres e desembaraçados de qualquer ônus para os
            contemplados.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>10.3. </span>
          <span className={styles.tc_text_br}>
            As condições de utilização de cada prêmio poderão ser consultadas no Anexo I.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>10.4. </span>
          <span className={styles.tc_text_br}>
            Não será permitido ao participante contemplado trocar seu prêmio por qualquer outro
            item, tampouco por dinheiro.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>10.5. </span>
          <span className={styles.tc_text_br}>
            A responsabilidade da empresa promotora com os participantes ganhadores encerra-se no
            momento da entrega do prêmio, não cabendo ao contemplado discutir ou redefinir as
            condições e/ou premissas da promoção ou do prêmio.
          </span>
        </p>
      </article>
      <article className={styles.tc_br_article}>
        <h3 className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>11. </span>
          <span className={styles.tc_text_br}>DISPOSIÇÕES GERAIS:</span>
        </h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>11.1. </span>
          <span className={styles.tc_text_br}>
            O regulamento completo encontra-se disponível no site da promoção{" "}
            <a href="https://shakewin.opera.com">shakewin.opera.com</a>.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>11.2. </span>
          <span className={styles.tc_text_br}>
            Caso o prêmio ganho não seja reclamado no prazo de 180 (cento e oitenta) dias contados a
            partir da data de término da promoção, caducará o direito do respectivo titular e o
            valor correspondente será recolhido pela empresa promotora, ao Tesouro Nacional como
            Renda da União, no prazo subsequente de 10 (dez) dias.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>11.3. </span>
          <span className={styles.tc_text_br}>
            Em nenhum momento a empresa promotora ou as demais empresas envolvidas na Promoção serão
            responsabilizadas por problemas, falhas ou funcionamento técnico, de qualquer tipo, em
            redes de computadores, rede de acesso, servidores ou provedores, equipamentos de
            computadores, hardware ou software, ou erro, interrupção, defeito, atraso ou falha em
            operações ou transmissões para o correto processamento de inscrições por parte dos
            participantes, incluindo, mas não se limitando, a transmissão imprecisa de inscrições ou
            falha da empresa promotora em recebê-las, em razão de problemas técnicos,
            congestionamento na internet ou no site ligado à Promoção, vírus, falha de programação
            (bugs) ou violação por terceiros (hackers).
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>11.3.1. </span>
          <span className={styles.tc_text_br}>
            A empresa promotora reserva-se o direito de desclassificar qualquer participante que
            julgue estar manipulando a operação desta Promoção ou violando os termos e condições
            dispostos neste regulamento.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>11.3.2. </span>
          <span className={styles.tc_text_br}>
            Tais ocorrências, se identificadas, serão amparadas por meio de relatórios técnicos
            aptos a comprovarem os indícios e/ou fraudes identificadas, os quais somente serão
            submetidos às autoridades competentes, sendo mantidos sob sigilo pela empresa promotora.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>11.4. </span>
          <span className={styles.tc_text_br}>
            A empresa promotora, neste momento, assume o compromisso de proteger os dados pessoais
            cadastrados, mantendo absoluta confidencialidade sobre tais informações, garantindo que,
            excetuados os casos previstos em lei e ao fiel cumprimento da execução desta promoção,
            não serão compartilhados ou cedidos com terceiros a qualquer título.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>11.5. </span>
          <span className={styles.tc_text_br}>
            Assim, os dados serão compartilhados apenas com as empresas contratadas pela empresa
            promotora, tais como: empresas responsáveis pelo sistema do banco de dados, pela
            contabilidade, pela auditoria, pela autorização e prestação de contas da promoção junto
            à SRE/MF, pela assessoria jurídica, pela entrega dos prêmios, todas com a finalidade
            exclusiva de executar e operacionalizar a presente promoção. Os dados também serão
            compartilhados com a SRE/MF, órgão público responsável pela autorização, regulação e
            fiscalização das promoções comerciais, em atenção à legislação que rege o tema.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>11.6. </span>
          <span className={styles.tc_text_br}>
            A empresa promotora exige que todas as empresas responsáveis pela execução e
            operacionalização desta promoção utilizem referidos dados pessoais em conformidade com
            este Regulamento e como a Lei Geral de Proteção de Dados (Lei no 13.709/2018).
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>11.7. </span>
          <span className={styles.tc_text_br}>
            Internamente, os dados dos participantes serão acessados somente por colaboradores
            autorizados pela empresa promotora, respeitando os princípios inerentes ao tratamento de
            dados pessoais previstos na Lei Geral de Proteção de Dados, sempre com o objetivo de
            execução e operacionalização desta Promoção, além do compromisso de confidencialidade e
            preservação da privacidade, de acordo com este Regulamento.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>11.8. </span>
          <span className={styles.tc_text_br}>
            Os dados pessoais coletados para esta promoção ficarão armazenados para fins
            operacionais e obedecerão a padrões rígidos de confidencialidade e segurança. Nenhum
            documento, informação e/ou dado pessoal será divulgado e/ou compartilhado em nenhuma
            hipótese, salvo os casos acima previstos e/ou mediante ordem judicial ou por
            determinação regulatória ou legal.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>11.9. </span>
          <span className={styles.tc_text_br}>
            Em atenção às diretrizes legais aplicáveis, a empresa promotora possibilitará aos
            participantes que revoguem a autorização para uso de seus dados, para fins de execução
            desta promoção, concedida nos termos do regulamento, bastando que solicitem através do
            site{" "}
            <a href="https://legal.opera.com/pt-br/privacy/">
              https://legal.opera.com/pt-br/privacy/
            </a>
            .
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>11.10. </span>
          <span className={styles.tc_text_br}>
            Na hipótese de a promoção ainda estar em curso, a revogação da autorização, pelos
            participantes, acarretará na sua imediata desclassificação e na cessação do envio de
            mensagens com os fins específicos descritos neste Regulamento.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>11.11. </span>
          <span className={styles.tc_text_br}>
            Ao término da promoção, os dados pessoais de todos os participantes serão mantidos no
            banco de dados da empresa promotora pelo prazo de até 5 (cinco) anos, ou até que haja o
            cancelamento, de forma expressa, das autorizações de manutenção dos dados previstas no
            Regulamento, considerando o fato que ocorrer primeiro, sem prejuízo do disposto no item
            abaixo.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>11.11.1 </span>
          <span className={styles.tc_text_br}>
            A empresa promotora, para fins de cumprimento legal e/ou defesa em eventual processo
            administrativo e/ou judicial, manterá, obrigatoriamente, em sua base de dados, os dados
            pessoais: (i) dos participantes contemplados: pelo prazo de 5 (cinco) anos, contados do
            término da promoção; e (ii) dos demais participantes inclusive daqueles que cancelaram a
            autorização para participar desta promoção: até o recebimento, pela empresa promotora,
            do ofício de homologação da prestação de contas a ser realizada perante a SRE/MF, no
            prazo legal. Findos os prazos ora estipulados, os dados poderão ser deletados.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>11.12. </span>
          <span className={styles.tc_text_br}>
            A participação na presente Promoção caracteriza, por si, a aceitação por parte dos
            consumidores participantes de todos os termos e condições estabelecidos neste
            regulamento.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>11.13. </span>
          <span className={styles.tc_text_br}>
            As dúvidas, omissões ou controvérsias oriundas da presente Promoção serão,
            preliminarmente, dirimidas por uma comissão composta por 3 (três) representantes da
            Empresa Promotora. Na eventualidade de não se atingir um consenso após a atuação da
            comissão, a questão deverá, então, ser submetida à apreciação da SEAE/ME. No silêncio
            injustificado da empresa promotora, bem como em razão de decisão insatisfatória que está
            vier a adotar quanto a eventuais solicitações de esclarecimentos que lhe forem
            apresentadas, poderão os consumidores participantes da Promoção apresentar suas
            reclamações fundamentadas ao Procon local e/ou aos órgãos públicos integrantes do
            Sistema Nacional de Defesa do Consumidor.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>11.14. </span>
          <span className={styles.tc_text_br}>
            Fica, desde já, eleito o foro central da Comarca do participante para solução de
            quaisquer questões referentes ao Regulamento da presente promoção.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>11.15. </span>
          <span className={styles.tc_text_br}>
            {
              "Esta promoção está de acordo com a legislação vigente (Lei n.o 5.768/71, regulamentada pelo Decreto n.o 70.951/72 e Portaria MF 41/08) e obteve o Certificado de Autorização SRE No. 05.031238/2023 expedido pelo Ministério da Fazenda."
            }
          </span>
        </p>
      </article>
      <hr />
      <div className={styles.br_title_area}>
        <p>CERTIFICADO DE AUTORIZAÇÃO SRE/MF Nº. {"02.031214/2023"}</p>
      </div>
      <article className={styles.tc_br_article}>
        <h3 className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>1. </span>
          <span className={styles.tc_text_br}>EMPRESA PROMOTORA:</span>
        </h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>1.1. </span>
          <span className={styles.tc_text_br}>Empresa Mandatária:</span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>1.1.1. </span>
          <span className={styles.tc_text_br}>
            Razão Social: Adclick Brasil Marketing de Performance Ltda
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>1.1.2. </span>
          <span className={styles.tc_text_br}>
            Endereço: Rua Sergipe, 475 – 5º andar – conj. 501 a 506 – Consolação – São Paulo/SP -
            CEP: 01243-001
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>1.1.3. </span>
          <span className={styles.tc_text_br}>CNPJ nº. {"14.314.669/0001-62"}</span>
        </p>
      </article>
      <article className={styles.tc_br_article}>
        <h3 className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>2. </span>
          <span className={styles.tc_text_br}>MODALIDADE DA PROMOÇÃO:</span>
        </h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>2.1. </span>
          <span className={styles.tc_text_br}>Assemelhado a vale-brinde</span>
        </p>
      </article>
      <article className={styles.tc_br_article}>
        <h3 className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>3. </span>
          <span className={styles.tc_text_br}>ÁREA DE ABRANGÊNCIA:</span>
        </h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>3.1. </span>
          <span className={styles.tc_text_br}>Território nacional</span>
        </p>
      </article>
      <article className={styles.tc_br_article}>
        <h3 className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>4. </span>
          <span className={styles.tc_text_br}>PERÍODO DA PROMOÇÃO:</span>
        </h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>4.1. </span>
          <span className={styles.tc_text_br}>{"23/11/2023 a 21/11/2024"}</span>
        </p>
      </article>
      <article className={styles.tc_br_article}>
        <h3 className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>5. </span>
          <span className={styles.tc_text_br}>PERÍODO DE PARTICIPAÇÃO:</span>
        </h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>5.1. </span>
          <span className={styles.tc_text_br}>
            {
              "23/11/2023 a 21/11/2024 ou enquanto durar o estoque de 19.512 (dezenove mil quinhentos e doze) prêmios."
            }
          </span>
        </p>
      </article>
      <article className={styles.tc_br_article}>
        <h3 className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>6. </span>
          <span className={styles.tc_text_br}>CRITÉRIO DE PARTICIPAÇÃO:</span>
        </h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>6.1. </span>
          <span className={styles.tc_text_br}>
            A promoção <b>“CHACOALHE E CONCORRA COM O OPERA”</b> é válida e destinada a todo e
            qualquer interessado, residente e domiciliado em território nacional, desde que preencha
            as demais condições de participação estabelecidas neste regulamento.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>6.2. </span>
          <span className={styles.tc_text_br}>
            Durante o período de participação da promoção, o usuário interessado poderá acessar a
            página <a href="https://shakewin.opera.com">shakewin.opera.com</a> da promoção no
            navegador Opera Mini e Opera para Android por meio do botão flutuante na página inicial
            ou visitando o site oficial <a href="https://shakewin.opera.com">shakewin.opera.com</a>{" "}
            e seguir os passos para baixar um dos navegadores participantes. Apenas as últimas
            versões dos aplicativos Opera Mini e Opera para Android participam da promoção. Os
            participantes devem tê-los instalados para participar
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>6.3. </span>
          <span className={styles.tc_text_br}>
            A partir daí, os usuários são convidados a indicar 2 (dois) amigos a baixar o Opera,
            compartilhando seu link único. A cada 2 (dois) amigos que baixarem o Opera e se
            cadastrarem e participarem na Campanha Chacoalhe e Concorra, o usuário terá direito a
            ganhar 1 (um) prêmio da promoção de dados para navegar na internet para celulares que
            utilizem as operadoras Claro, Tim e Vivo, de acordo com os termos e condições listados
            neste regulamento, até acabarem os estoques.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>6.4. </span>
          <span className={styles.tc_text_br}>
            Cada participante terá direito de ao menos 1 (um) prêmio, a cada 2 (dois) amigos
            indicados devidamente ativos que tenham baixado o navegador Opera, se cadastrado na
            Campanha Chacoalhe e Concorra e participado do jogo.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>6.4.1. </span>
          <span className={styles.tc_text_br}>
            {
              "As indicações são ilimitadas durante todo período da promoção, sendo que a cada 2 (duas) indicações que resultarem em download do navegador Opera, cadastramento na Campanha Chacoalhe e Concorra e participação no jogo, renderá 1 (um) prêmio ao usuário participante que indicou."
            }
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>6.4.2. </span>
          <span className={styles.tc_text_br}>
            A validação de contemplação só ocorrerá caso os 2 (dois) amigos indicados tenham baixado
            o navegador Opera, se cadastrado na Campanha Chacoalhe e Concorra e participado do jogo.
            Caso contrário, as indicações não darão direito ao prêmio da promoção.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>6.4.3. </span>
          <span className={styles.tc_text_br}>
            Se somente 1 (um) amigo indicado realize a ativação baixando o navegador Opera, se
            cadastrando na Campanha Chacoalhe e Concorra e participando do jogo, o usuário
            participante que indicou não terá direito ao prêmio.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>6.4.4. </span>
          <span className={styles.tc_text_br}>
            Em hipótese alguma poderão ser indicados e/ou computados 2 (dois) amigos com mesmo CPF
            para ativação da premiação.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>6.5. </span>
          <span className={styles.tc_text_br}>
            Os participantes da promoção concorrerão aos prêmios instantâneos, indicados no item 7.1
            deste regulamento, de 23/11/2023 a 21/12/2023 ou enquanto durar o estoque de 19.512
            (dezenove mil quinhentos e doze) prêmios disponíveis para distribuição em toda promoção,
            sendo distribuído 1 (um) prêmio a cada 2 (dois) amigos indicados devidamente ativos que
            tenham baixado o navegador Opera e se cadastrado.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>6.6. </span>
          <span className={styles.tc_text_br}>
            Todos os participantes declaram que somente fornecerão informações verdadeiras e
            corretas sobre si, passíveis de confirmação, e que não utilizarão qualquer artifício,
            tal como, mas não limitado, ao uso de informações de terceiros, informações incorretas,
            incompletas, que visem propositalmente burlar as regras de participação nesta promoção e
            que possam configurar crime de falsidade ideológica ou documental, cientes de que
            poderão responder administrativa, cível ou penalmente pelos atos praticados.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>6.7. </span>
          <span className={styles.tc_text_br}>
            Os participantes também não poderão utilizar-se de meios escusos para participar desta
            promoção e/ou de mecanismos que criem condições de cadastramento irregular, desleais ou
            que atentem contra os objetivos e condições de participação previstas neste regulamento.
            As situações descritas, quando identificadas, serão consideradas como infração aos
            termos do presente regulamento, ensejando o impedimento da participação e/ou o imediato
            cancelamento da inscrição do participante, sem prejuízo, ainda, das medidas cabíveis
            e/ou ação de regresso a ser promovida pela promotora em face do infrator.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>6.8. </span>
          <span className={styles.tc_text_br}>
            Caso todos os prêmios sejam distribuídos antes do término, a campanha será considerada
            encerrada, dando-se ampla divulgação aos usuários.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>6.9. </span>
          <span className={styles.tc_text_br}>
            Será considerado o horário de Brasília/DF para todos os períodos descritos neste
            regulamento.
          </span>
        </p>
      </article>
      <article className={styles.tc_br_article}>
        <h3 className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>7. </span>
          <span className={styles.tc_text_br}>PRÊMIO:</span>
        </h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>7.1. </span>
          <span className={styles.tc_text_br}>
            Serão distribuídos {"19.512"} (dezenove mil quinhentos e doze) prêmios em toda promoção,
            conforme descrito abaixo:
          </span>
        </p>
        {
          <table className={styles.tc_table_4}>
            <tbody>
              <tr>
                <th>Quantidade de prêmios</th>
                <th>Descrição de cada prêmio</th>
                <th>Valor unitário</th>
                <th>Valor total</th>
              </tr>
              <tr>
                <td>19.512</td>
                <td>1 (um) Voucher de Mobile Data (250MB)</td>
                <td>R$ 2,05</td>
                <td>R$ 40.000,00</td>
              </tr>
            </tbody>
          </table>
        }
      </article>
      <article className={styles.tc_br_article}>
        <h3 className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>8. </span>
          <span className={styles.tc_text_br}>PREMIAÇÃO TOTAL:</span>
        </h3>
        <table className={styles.tc_table_2}>
          <tbody>
            <tr>
              <th>Quantidade total de prêmios</th>
              <th>Valor total da promoção</th>
            </tr>
            <tr>
              <td>{19.512}</td>
              <td>{"R$ 40.000,00"}</td>
            </tr>
          </tbody>
        </table>
      </article>
      <article className={styles.tc_br_article}>
        <h3 className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>9. </span>
          <span className={styles.tc_text_br}>CRITÉRIOS DE DESCLASSIFICAÇÃO:</span>
        </h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>9.1. </span>
          <span className={styles.tc_text_br}>
            Os participantes poderão ser excluídos automaticamente da promoção em caso de fraude
            comprovada, de não preenchimento dos requisitos previamente determinados e/ou em
            decorrência da prestação de informações incorretas ou equivocadas, de acordo com as
            regras do regulamento da promoção.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>9.2. </span>
          <span className={styles.tc_text_br}>
            Ficara vedada a participação dos funcionários da Empresa Promotora e Aderentes, bem como
            dos funcionários, empregados, terceiros ou colaboradores que atuarem diretamente no
            planejamento, criação, execução da Promoção, da agencia de publicidade e promoção e
            outras empresas diretamente envolvidas no processo. O atendimento ao acima disposto será
            de inteira responsabilidade da Empresa Promotora e Aderentes, que desclassificarão os
            participantes impedidos no ato da inscrição ou da validação da condição de vencedor.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>9.3. </span>
          <span className={styles.tc_text_br}>
            O potencial contemplado porventura desclassificado da promoção não terá direito ao
            recebimento do prêmio e a empresa promotora retornará o prêmio ao estoque da promoção.
          </span>
        </p>
      </article>
      <article className={styles.tc_br_article}>
        <h3 className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>10. </span>
          <span className={styles.tc_text_br}>ENTREGA DO PRÊMIO:</span>
        </h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>10.1. </span>
          <span className={styles.tc_text_br}>
            O prêmio é pessoal e intransferível e será disponibilizado ao contemplado diretamente no
            site da promoção.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>10.2. </span>
          <span className={styles.tc_text_br}>
            Os prêmios distribuídos serão entregues livres e desembaraçados de qualquer ônus para os
            contemplados.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>10.3. </span>
          <span className={styles.tc_text_br}>
            As condições de utilização de cada prêmio poderão ser consultadas no Anexo I.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br} style={{ textAlign: "center" }}>
            I.
          </span>
          <span className={styles.tc_text_br}>
            Este voucher (vale-compra) concede desconto sem valor mínimo para a aplicação do mesmo.
            (0MBS);
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br} style={{ textAlign: "center" }}>
            II.
          </span>
          <span className={styles.tc_text_br}>
            Este cupom é válido para novos usuários e usuários existentes no App/Site da Shopee;
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br} style={{ textAlign: "center" }}>
            III.
          </span>
          <span className={styles.tc_text_br}>
            Este cupom é limitado apenas para usuários selecionados.;
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br} style={{ textAlign: "center" }}>
            IV.
          </span>
          <span className={styles.tc_text_br}>
            Este cupom é válido até {"04/03/2024 às 23h59"};
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br} style={{ textAlign: "center" }}>
            V.
          </span>
          <span className={styles.tc_text_br}>
            Este cupom só pode ser utilizado uma vez, e em caso de cancelamento da compra, o valor
            descontado não será reembolsado;
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br} style={{ textAlign: "center" }}>
            VI.
          </span>
          <span className={styles.tc_text_br}>Este cupom não pode ser trocado por dinheiro;</span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br} style={{ textAlign: "center" }}>
            VII.
          </span>
          <span className={styles.tc_text_br}>
            Este cupom é válido apenas para compras no App/Site da Shopee.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>10.4. </span>
          <span className={styles.tc_text_br}>
            Não será permitido ao participante contemplado trocar seu prêmio por qualquer outro
            item, tampouco por dinheiro.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>10.5. </span>
          <span className={styles.tc_text_br}>
            A responsabilidade da empresa promotora com os participantes ganhadores encerra-se no
            momento da entrega do prêmio, não cabendo ao contemplado discutir ou redefinir as
            condições e/ou premissas da promoção ou do prêmio.
          </span>
        </p>
      </article>
      <article className={styles.tc_br_article}>
        <h3 className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>11. </span>
          <span className={styles.tc_text_br}>DISPOSIÇÕES GERAIS:</span>
        </h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>11.1. </span>
          <span className={styles.tc_text_br}>
            O regulamento completo encontra-se disponível no site da promoção{" "}
            <a href="https://shakewin.opera.com">shakewin.opera.com</a>.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>11.2. </span>
          <span className={styles.tc_text_br}>
            Caso o prêmio ganho não seja reclamado no prazo de 180 (cento e oitenta) dias contados a
            partir da data de término da promoção, caducará o direito do respectivo titular e o
            valor correspondente será recolhido pela empresa promotora, ao Tesouro Nacional como
            Renda da União, no prazo subsequente de 10 (dez) dias.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>11.3. </span>
          <span className={styles.tc_text_br}>
            Em nenhum momento a empresa promotora ou as demais empresas envolvidas na Promoção serão
            responsabilizadas por problemas, falhas ou funcionamento técnico, de qualquer tipo, em
            redes de computadores, rede de acesso, servidores ou provedores, equipamentos de
            computadores, hardware ou software, ou erro, interrupção, defeito, atraso ou falha em
            operações ou transmissões para o correto processamento de inscrições por parte dos
            participantes, incluindo, mas não se limitando, a transmissão imprecisa de inscrições ou
            falha da empresa promotora em recebê-las, em razão de problemas técnicos,
            congestionamento na internet ou no site ligado à Promoção, vírus, falha de programação
            (bugs) ou violação por terceiros (hackers).
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>11.3.1. </span>
          <span className={styles.tc_text_br}>
            A empresa promotora reserva-se o direito de desclassificar qualquer participante que
            julgue estar manipulando a operação desta Promoção ou violando os termos e condições
            dispostos neste regulamento.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>11.3.2. </span>
          <span className={styles.tc_text_br}>
            Tais ocorrências, se identificadas, serão amparadas por meio de relatórios técnicos
            aptos a comprovarem os indícios e/ou fraudes identificadas, os quais somente serão
            submetidos às autoridades competentes, sendo mantidos sob sigilo pela empresa promotora.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>11.4. </span>
          <span className={styles.tc_text_br}>
            A empresa promotora, neste momento, assume o compromisso de proteger os dados pessoais
            cadastrados, mantendo absoluta confidencialidade sobre tais informações, garantindo que,
            excetuados os casos previstos em lei e ao fiel cumprimento da execução desta promoção,
            não serão compartilhados ou cedidos com terceiros a qualquer título.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>11.5. </span>
          <span className={styles.tc_text_br}>
            Assim, os dados serão compartilhados apenas com as empresas contratadas pela empresa
            promotora, tais como: empresas responsáveis pelo sistema do banco de dados, pela
            contabilidade, pela auditoria, pela autorização e prestação de contas da promoção junto
            à SRE/MF, pela assessoria jurídica, pela entrega dos prêmios, todas com a finalidade
            exclusiva de executar e operacionalizar a presente promoção. Os dados também serão
            compartilhados com a SRE/MF, órgão público responsável pela autorização, regulação e
            fiscalização das promoções comerciais, em atenção à legislação que rege o tema.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>11.6. </span>
          <span className={styles.tc_text_br}>
            A empresa promotora exige que todas as empresas responsáveis pela execução e
            operacionalização desta promoção utilizem referidos dados pessoais em conformidade com
            este Regulamento e como a Lei Geral de Proteção de Dados (Lei no 13.709/2018).
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>11.7. </span>
          <span className={styles.tc_text_br}>
            Internamente, os dados dos participantes serão acessados somente por colaboradores
            autorizados pela empresa promotora, respeitando os princípios inerentes ao tratamento de
            dados pessoais previstos na Lei Geral de Proteção de Dados, sempre com o objetivo de
            execução e operacionalização desta Promoção, além do compromisso de confidencialidade e
            preservação da privacidade, de acordo com este Regulamento.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>11.8. </span>
          <span className={styles.tc_text_br}>
            Os dados pessoais coletados para esta promoção ficarão armazenados para fins
            operacionais e obedecerão a padrões rígidos de confidencialidade e segurança. Nenhum
            documento, informação e/ou dado pessoal será divulgado e/ou compartilhado em nenhuma
            hipótese, salvo os casos acima previstos e/ou mediante ordem judicial ou por
            determinação regulatória ou legal.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>11.9. </span>
          <span className={styles.tc_text_br}>
            Em atenção às diretrizes legais aplicáveis, a empresa promotora possibilitará aos
            participantes que revoguem a autorização para uso de seus dados, para fins de execução
            desta promoção, concedida nos termos do regulamento, bastando que solicitem através do
            site{" "}
            <a href="https://legal.opera.com/pt-br/privacy/">
              https://legal.opera.com/pt-br/privacy/
            </a>
            .
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>11.10. </span>
          <span className={styles.tc_text_br}>
            Na hipótese de a promoção ainda estar em curso, a revogação da autorização, pelos
            participantes, acarretará na sua imediata desclassificação e na cessação do envio de
            mensagens com os fins específicos descritos neste Regulamento.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>11.11. </span>
          <span className={styles.tc_text_br}>
            Ao término da promoção, os dados pessoais de todos os participantes serão mantidos no
            banco de dados da empresa promotora pelo prazo de até 5 (cinco) anos, ou até que haja o
            cancelamento, de forma expressa, das autorizações de manutenção dos dados previstas no
            Regulamento, considerando o fato que ocorrer primeiro, sem prejuízo do disposto no item
            abaixo.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>11.11.1 </span>
          <span className={styles.tc_text_br}>
            A empresa promotora, para fins de cumprimento legal e/ou defesa em eventual processo
            administrativo e/ou judicial, manterá, obrigatoriamente, em sua base de dados, os dados
            pessoais: (i) dos participantes contemplados: pelo prazo de 5 (cinco) anos, contados do
            término da promoção; e (ii) dos demais participantes inclusive daqueles que cancelaram a
            autorização para participar desta promoção: até o recebimento, pela empresa promotora,
            do ofício de homologação da prestação de contas a ser realizada perante a SRE/MF, no
            prazo legal. Findos os prazos ora estipulados, os dados poderão ser deletados.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>11.12. </span>
          <span className={styles.tc_text_br}>
            A participação na presente Promoção caracteriza, por si, a aceitação por parte dos
            consumidores participantes de todos os termos e condições estabelecidos neste
            regulamento.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>11.13. </span>
          <span className={styles.tc_text_br}>
            As dúvidas, omissões ou controvérsias oriundas da presente Promoção serão,
            preliminarmente, dirimidas por uma comissão composta por 3 (três) representantes da
            Empresa Promotora. Na eventualidade de não se atingir um consenso após a atuação da
            comissão, a questão deverá, então, ser submetida à apreciação da SEAE/ME. No silêncio
            injustificado da empresa promotora, bem como em razão de decisão insatisfatória que está
            vier a adotar quanto a eventuais solicitações de esclarecimentos que lhe forem
            apresentadas, poderão os consumidores participantes da Promoção apresentar suas
            reclamações fundamentadas ao Procon local e/ou aos órgãos públicos integrantes do
            Sistema Nacional de Defesa do Consumidor.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>11.14. </span>
          <span className={styles.tc_text_br}>
            Fica, desde já, eleito o foro central da Comarca do participante para solução de
            quaisquer questões referentes ao Regulamento da presente promoção.
          </span>
        </p>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_title_br}>11.15. </span>
          <span className={styles.tc_text_br}>
            {
              "Esta promoção está de acordo com a legislação vigente (Lei n.o 5.768/71, regulamentada pelo Decreto n.o 70.951/72 e Portaria MF 41/08) e obteve o Certificado de Autorização SRE No. 02.031214/2023 expedido pelo Ministério da Fazenda."
            }
          </span>
        </p>
      </article>
      <p className={styles.tc_br_section} style={{ margin: "32px 0 16px 0" }}>
        ANEXO I
      </p>
      <h2>Regras e Condições de Uso dos Prêmios</h2>
      <article className={styles.tc_br_article}>
        <h3>RAPPI CARD</h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            A Rappi é um aplicativo que faz entrega de praticamente tudo o que o premiado precisar,
            inclusive delivery de comida e supermercados. Após a escolha do Rappi Card, é enviada
            uma mensagem SMS e/ou e-mail com as instruções para resgate e prazo de validade.
            <br />O prazo de ativação do serviço é 30 dias, conforme orientações de resgate recebido
            no SMS e/ou e-mail e após ativação a utilização será de acordo com a validade da oferta
            recebida.
          </span>
        </p>
        <br />
        <h3>Instruções para ativação:</h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            1. Acesse o link de resgate recebido para ter acesso ao código do Vale Presente Rappi;
            <br />
            2. Em seguida, baixe o app Rappi e faça o cadastro ou login;
            <br />
            3. Clique no menu; <br />
            4. Selecione a opção “Cupons”; <br />
            5. Insira o código recebido e clique em “Adicionar”;
            <br />
            6. Em seguida, clique na opção “Comprar agora”;
            <br />
            7. Escolha os produtos desejados e clique no carrinho; <br />
            8. Selecione a opção “Confirmar”; <br />
            9. Pronto! O valor será aplicado automaticamente no pedido.
          </span>
        </p>
        <br />
        <h3>Regras e Condições de Uso </h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            Utilização exclusiva no aplicativo Rappi. O Vale Presente Rappi deve ser utilizado em
            uma única compra,não existindo saldo remanescente. Você também poderá complementar o
            pagamento com as formas disponíveis. Para utilizar, basta clicar no ícone de Meu Perfil;
            na tela inicial do aplicativo e inserir o código do Vale Presente Rappi no campo Cupons.
            O valor será creditado em sua conta - pronto para consumo. Para aplicar o seu Vale
            Presente Rappi, basta selecioná-lo na tela de pagamento clicando em usar meu cupom. O
            valor será descontado automaticamente antes de confirmar o pagamento. O crédito do Vale
            Presente Rappi não contempla taxas e frete, cobrindo apenas o valor dos produtos. Limite
            de 1 (um) Vale por compra. Seu saldo não poderá ser cancelado, trocado, devolvido ou ser
            convertido em dinheiro. O Vale Presente é ao portador e não é recarregável. Não é
            possível a transferência de saldo para outro Vale Presente.
          </span>
        </p>
        <br />
        <h3>SUBMARINO</h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            O Submarino é uma loja online pioneira em tecnologia e inovação. As melhores condições e
            entrega rápida! Após a escolha do Submarino, é enviada uma mensagem SMS e/ou e-mail com
            as instruções para resgate e prazo de validade. O prazo de ativação do serviço é 30
            dias, conforme orientações de resgate recebido no SMS e/ou e-mail e após ativação a
            utilização será de acordo com validade da oferta recebida
          </span>
        </p>
        <br />
        <h3>Instruções para ativação:</h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            1. Acessar o link de resgate recebido para ter acesso ao código do vale e clicar em cima
            do “Vale Presente”; <br />
            2. Selecionar os produtos desejados; <br />
            3. Clicar em “Comprar”;
            <br />
            {`4. Em seguida, clicar em "Continuar";`} <br />
            5. Fazer o cadastro ou login; <br />
            {`6. Selecionar a opção "possui cupom ou vale?";`} <br />
            {`7. Inserir o cupom recebido e clicar em "Aplicar";`} <br />
            8. Finalizar a compra;
          </span>
        </p>
        <br />
        <h3>Regras e Condições de Uso</h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            Utilização no site https://www.submarino.com.br e no App Submarino. O Vale Presente
            Submarino é válido apenas para compra de produtos e não serviços. Para compra de um
            produto pelo aplicativo, é necessário que o mesmo esteja totalmente atualizado. O Vale
            Presente Submarino pode ser utilizado em diversas compras até o término do saldo. Você
            também poderá complementar o pagamento com as formas disponíveis. Para utilizar, basta
            inserir o código no campo &quot;Possui cupom ou vale?&quot; na tela de pagamento. Não há
            limite de Vales por compra. Seu saldo não poderá ser cancelado, trocado, devolvido ou
            ser convertido em dinheiro. O Vale Presente Submarino é ao portador e não é
            recarregável. Não é possível a transferência de saldo para outro Vale Presente.
          </span>
        </p>
        <br />
        <h3>PRIMEPASS</h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            O Primepass é um aplicativo mobile (app) que permite que os seus usuários escolham
            ingressos de cinema em uma sessão dentro das redes credenciadas Primepass, de acordo com
            a oferta contratada. Após a escolha da Primepass, é enviada uma mensagem SMS e/ou e-mail
            com as instruções para resgate e prazo de validade. O prazo de ativação do serviço é 30
            dias, conforme orientações de resgate recebido no SMS e/ou e-mail e após ativação a
            utilização será de acordo com validade da oferta recebida
          </span>
        </p>
        <br />
        <h3>Abaixo as redes de cinema credenciadas:</h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            Espaço Itaú Unibanco, GNC, Centerplex, Cinesystem, Kinoplex, PlayArte, UCI, Cinépolis,
            Cinemark, Cine Show e CineArt. Entretanto, deverá ser considerada a rede, filmes, salas
            e horários que aparecem no App da Primepass via GPS, pois, a informação apresenta as
            redes disponíveis em tempo real.
          </span>
        </p>
        <br />
        <h3>Instruções para ativação da oferta</h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            1. Baixe o App Primepass; cadastre-se ou faça login e clique em entrar; <br />
            2. Insira seu e-mail cadastrado e clique em “Continuar”; <br />
            3. Digite o pin recebido no e-mail; <br />
            4. Clique em “Resgate”; <br />
            5. Insira o código recebido por sms e clique em “Resgatar voucher”; <br />
            6. Confira o serviço resgatado e clique em “Continuar”; <br />
            7. Clique em OK e volte para tela inicial; <br />
            8. Clique em “Créditos”; <br />
            9. Para gerar o ingresso de cinema, clique em “Utilizar créditos de cinema”; <br />
            10. Escolha o filme que gostaria de assistir; <br />
            11. Com a localização ligada, escolha o cinema desejado; <br />
            12. Escolha o horário; <br />
            13. Escolha quantidade ingressos e clique em “prosseguir”; <br />
            14. Clique em “Confirmar pagamento”; <br />
            15. No cinema, clique em “ Liberar Ingresso(s)”; <br />
            16. Em seguida clique em “OK”; <br />
            17. Na bilheteria, clique em “ Ver códigos”; <br />
            18. E apresente o código correspondente ao ingresso; Caso a sessão selecionada esteja
            lotada, você consegue usar seu ingresso digital para outra sessão ou filme no mesmo dia
            e na mesma rede.
          </span>
        </p>
        <br />
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            <b style={{ color: "#fff" }}>IMPORTANTE: Para a rede de cinema UCI,</b> após gerar o
            ingresso digital pelo aplicativo é preciso seguir um pequeno passo a passo: Entrar no
            site da ingresso.com; Selecionar o filme e a sessão do cinema da rede UCI na qual irá;
            Escolher o assento que desejar; Clicar em{" "}
            {`"tipo de ingresso"; Selecionar a opção "Cinematicket Eletrônico";`}
            Inserir o código do ingresso digital que foi gerado; Confirmar o pagamento; Após a
            realização deste passo a passo, o usuário conseguirá gerar o ingresso. Uma vez que
            estiver com o ingresso gerado, deve ir até o cinema selecionado pelo usuário junto com o
            CPF e apresentá-lo diretamente na sala do filme, sem precisar validar nada na
            bilheteria.
          </span>
        </p>
        <br />
        <h3>Regras e Condições de Uso</h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            Não é possível o resgate de vouchers no app por dispositivos IOS, neste caso o resgate
            deverá ser realizado pelo site, esta restrição é apenas para o serviço de resgate de
            voucher, os demais serviços, como a geração do ingresso digital poderá ser realizada
            normalmente pelo app IOS. O ingresso digital é gerado somente no seu celular ou tablet
            através do aplicativo PrimePass. Lembrando que a liberação pode ser iniciada de casa,
            porém, para o ingresso digital ser totalmente liberado é necessário estar no cinema -
            local onde será possível fazer o check-in. Caso o usuário tenha mais de um código
            aplicado e sua conta, o sistema irá por ordem de resgate, por exemplo, tendo um ingresso
            de terça-feira e um válido para todos os dias, o sistema irá reconhecer primeiro o de
            terça-feira, e só irá conseguir utilizar o ingresso válido para todos os dias, após o
            uso do ingresso de terça-feira. Não é possível resgatar o ingresso pelo computador. Caso
            o usuário chegue no cinema e a sessão esteja lotada e não queira assistir outro filme,
            ou mude de ideia, antes da liberação ser feita, poderá cancelar a sessão direto no app.
            O aplicativo precisa identificar a localização do usuário para conseguir listar as
            sessões: É importante verificar se a localização está ativa e com bom sinal de conexão
            com a internet, pois o GPS depende deste sinal. O aplicativo Primepass utiliza o GPS
            para ativar a sessão, é possível iniciar o desbloqueio desde casa, mas por segurança o
            ingresso só será liberado quando estiver próximo ao cinema. É aconselhável que a
            liberação seja feita da porta do cinema/shopping, pois dessa forma, o GPS e internet não
            tem interferências no sinal. É importante autorizar todas as permissões ao instalar o
            app. No caso do Android e quer maior precisão e segurança no aplicativo, é necessário
            ativar o modo de precisão. Abra as configurações do GPS do Smartphone e ative o{" "}
            {`"modo de
            precisão"`}
            . Depois reinicie o celular, ative o GPS e então abra o app. No caso do iPhone, é
            necessário instalar o app autorizando todas as permissões pro app utilizar o GPS do
            celular e manter o app sempre atualizado.
          </span>
        </p>
        <br />
        <h3>Tipo de Sala: </h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            Atualmente o aplicativo apresenta apenas o tipo de tela ( ex: 2D ou 3D) no qual o
            crédito poderá ser utilizado. Entretanto, existe uma iniciativa de evolução do app para
            que possam ser apresentados também o tipo de sala (ex: Normal, VIP e/ou IMAX), porém,
            ainda não temos expectativa de quando esta solução será concluída.
          </span>
        </p>
        <br />
        <h3>Escolha do filme errado: </h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            Caso não tenha liberado o ingresso, poderá ser cancelado a qualquer momento o filme
            direto no aplicativo (há um botão Cancelar abaixo do Botão de Fazer Check-in) e escolha
            outra sessão. Caso você já tenha liberado o filme é emitido o ingresso digital, porém,
            poderá ser utilizado o ingresso digital para qualquer outro filme e horário, na mesma
            categoria (2d, 3d,ou vip), e na mesma rede de cinema.
          </span>
        </p>
        <br />
        <h3>Caso o usuário não visualize o ingresso no aplicativo: </h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            Caso o usuário já tenha ativado o filme no aplicativo, o mesmo precisa estar no cinema
            escolhido, para confirmar sua localização. Na mesma tela onde foi confirmado o filme,
            que lhe instrui a se locomover até o cinema, será habilitado um botão de confirmação de
            sua localização. Basta confirmar para receber o ingresso na tela de seu celular. A
            Primepass poderá ser utilizada em todo Brasil, nas redes de cinemas credenciadas.
          </span>
        </p>
        <br />
        <h3>Caso o usuário tenha liberado o ingresso e não tenha utilizado: </h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            Os ingressos ficam válidos para utilização na mesma rede e na mesma categoria de filme
            liberado (se a liberação foi feita em cinema 2D, poderá ser utilizado para outra sessão
            2D ou se for 3D, poderá ser utilizado em outra sessão 3D). Mesmo que o ingresso tenha
            sido liberado para um filme e horário específico, poderá ser utilizado em outra sessão e
            horários distintos. <b style={{ color: "#fff" }}>Há algumas ressalvas</b>, alguns
            ingressos são válidos em alguns dias específicos da semana, e se forem usados em outros
            dias, não serão aceitos. Por exemplo, há ingressos liberados que valem para SEG-TER-QUA
            somente, se tentar usar na QUI, será apresentado erro, este ingresso ficará válido para
            próxima semana nos dias informados.
          </span>
        </p>
        <br />
        <h3>RAZE</h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            Razer Gold é o crédito virtual unificado para jogadores de todo o mundo. Use o Razer
            Gold para comprar jogos e conteúdo no jogo para obter mais retorno do seu investimento -
            incluindo a recompensa com o Razer Silver e ofertas de jogos exclusivas. Você pode fazer
            uma compra usando o Razer Gold em mais de 3.000 sites de conteúdo de jogos e
            entretenimento. Comece comprando Razer Gold hoje. Cada gasto no Razer Gold dá a você
            Razer Silver, o único programa de recompensas de fidelidade para jogadores. Saiba mais:
            https://gold.razer.com/gold/about Após a escolha da Raze é enviada uma mensagem SMS e/ou
            e-mail com as instruções para resgate e prazo de validade. O prazo de ativação do
            serviço é 30 dias, conforme orientações de resgate recebido no SMS e/ou e-mail e após
            ativação a utilização será de acordo com validade da oferta recebida.
          </span>
        </p>
        <br />
        <h3>Instruções para ativação da oferta</h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            1. Clica no link enviado na comunicação
            <br />
            2. Copia o código de resgate <br />
            3. Recarregue sua conta Razer Gold em gold.razer.com.
            <br />
            4. Acesse gold.razer.com e faça login na sua conta Razer Gold.
            <br />
            5. Clique em [Recarregar agora] e selecione Razer Gold como sua forma de pagamento.
            <br />
            6. Insira o código do voucher e comece a gastar seu Razer Gold.
            <br />
            7. OBS: Para ganhar Razer Silver, escolha [Razer Gold] nos jogos, conteúdo digital e
            serviços suportados pelo Razer Gold
          </span>
        </p>
        <br />
        <h3>Regras e Condições de Resgate</h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            Válido para uso somente no Brasil. O uso deste código está sujeito a aceitação pelos
            usuários dos Termos de Serviço e da Política de Privacidade Razer Gold em
            gold.razer.com. É necessário software/hardware/acesso à Internet compatíveis. O risco de
            perda para este código passa para o comprador após a compra. A Razer não se
            responsabiliza por qualquer perda ou dano resultante de cartões perdidos ou roubados,
            usado sem permissão ou uso incorreto. Não é permitida a revenda. Não pode ser resgatável
            por dinheiro. A disponibilidade de conteúdo e os preços podem mudar. Para suporte ao
            cliente, visite-nos em gold.razer.com. Copyright © 2019 Razer Inc. Todos os direitos
            reservados. *Ao resgatar esse código será descontada a taxa de resgate. O valor
            creditado na sua conta Razer Gold será o líquido após descontada a taxa.
          </span>
        </p>
        <br />
        <h3>MCDONALD’S CARD</h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            O {`McDonald's`} é uma empresa americana, considerada a maior cadeia de serviço rápido
            de alimentação, presente em 119 países e com uma rede de mais de 33 mil restaurantes. É
            uma das marcas de fast-food mais valiosas do mundo. Atuando no mercado desde 2008, a
            Giftty é uma empresa do grupo Direct Shopping especializada no incentivo de servidores
            por meio de premiações com vales presente. O cliente vai receber um link do Vale Méqui.
            Após a escolha do Mc Donalds Card, é enviada uma mensagem SMS e/ou e-mail com as
            instruções para resgate e prazo de validade. O prazo de ativação do serviço é 30 dias,
            conforme orientações de resgate recebido no SMS e/ou e-mail e após ativação a utilização
            será de acordo com validade da oferta recebida
          </span>
        </p>
        <br />
        <h3>Instruções para ativação:</h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            1- Acessar o site https://cupons.mcdonalds.com.br/valemequi e consulte a loja mais
            próxima.
            <br />
            2- Na loja, selecionar os produtos de interesse, acessar o link de resgate recebido e
            apresentar o gift card como forma de pagamento.
            <br />
            3- Apresentar o código do vale no caixa e digitar a senha contida no mesmo link.
          </span>
        </p>
        <br />
        <h3>Regras e condições de Uso</h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            Confira abaixo todas as lojas participantes https://cupons.mcdonalds.com.br/valemequi
          </span>
        </p>
        <br />
        <h3>NETSHOES</h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            Os melhores produtos e ofertas no ramo de artigos esportivos, perfeito para quem deseja
            produtos das melhores marcas e com os melhores preços. A Netshoes oferece descontos
            exclusivos em toda loja virtual. A Netshoes limita as suas entregas ao território
            nacional brasileiro.
          </span>
        </p>
        <br />
        <h3>Forma de entrega:</h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            O voucher de assinatura é enviado por SMS. Após o recebimento do voucher, o cliente tem
            o prazo de 30 dias corridos para ativar o código no ambiente da Netshoes. Prazo de
            utilização da recompensa Trata-se de uma escolha sem periodicidade, ou seja, sem prazo
            para utilização após o recebimento.
          </span>
        </p>
        <br />
        <h3>Regras de utilização:</h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            Para ativar a oferta, você precisa digitar o código do cupom no campo “digite aqui seu
            cupom ou vale-presente” na parte inferior da página de pagamento. O valor será aplicado
            sobre o valor total do pedido, exceto no valor de frete. O prêmio não é válido para
            camisas de time lançamento, suplementos e produtos com selo {`"ofertas"`}. <br />
            <br />
            Somente os produtos disponíveis no https://clube.netshoes.com.br/recompensas são
            elegíveis a promoção mencionada. Fica a critério exclusivo da Netshoes a inclusão ou
            exclusão a qualquer momento dos produtos disponíveis dos e preços praticados no clube
            netshoes. Não é válido para o aplicativo netshoes e não é acumulativa com outras
            promoções e cupons do site.
          </span>
        </p>
        <br />
        <h3>Como resgatar:</h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            Cliente acessa https://clube.netshoes.com.br/recompensas, escolhe o produto, informa o
            CEP. Faça o login ou crie uma nova conta. Confirme o endereço de entrega e a forma de
            pagamento. Informe o cupom e finalize. Confira aqui os produtos disponíveis:
            https://clube.netshoes.com.br/
          </span>
        </p>
        <br />
        <h3>UBER</h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            O Uber é uma empresa que presta serviços eletrônicos na área de transporte privado
            urbano, por meio de um aplicativo de transporte que permite a busca por motoristas
            baseada na localização, oferecendo um serviço semelhante ao tradicional táxi. Após a
            escolha do Uber, é enviada uma mensagem SMS e/ou e-mail com as instruções para resgate e
            prazo de validade. O prazo de ativação do serviço é 30 dias, conforme orientações de
            resgate recebido no SMS e/ou e-mail e após ativação a utilização será de acordo com
            validade da oferta recebida
          </span>
        </p>
        <br />
        <h3>Instruções para ativação: </h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            1 - Acessar o aplicativo e inserir o cupom no campo {`"adicionar código do voucher"`};
            <br />
            2- Neste passo o valor já estará disponível. * Para conferir o crédito, o usuário deverá
            ir no campo pagamento e clicar na opção Vouchers, neste passo o seu voucher já estará
            disponível
          </span>
        </p>
        <br />
        <h3>Regras e condições de Uso</h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            Os vouchers deverão ser utilizados em seu valor total, caso o cliente não realize uma
            viagem no valor total o mesmo não conseguirá utilizar o valor restante em outra corrida.
            Em caso de cancelamento da corrida pelo usuário o valor do crédito não será reembolsado.
            Certifique-se de o aplicativo está habilitado para abrir links compatíveis Este caminho
            poderá ser encontrado em {`Configurações > aplicativos > Uber > Abrir`} links
            compatíveis * Este último passo poderá variar o nome do campo de acordo com cada
            aparelho, mas é importante que o usuário mantenha a opção disponível para links
            compatíveis.
          </span>
        </p>
        <br />
        <h3>EXTRA CARD</h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            O Extra.com.br oferece uma grande variedade de produtos para toda a família e tudo o que
            você precisa para estar e se conectar com quem você ama. Após a escolha do Extra Card, é
            enviada uma mensagem SMS e/ou e-mail com as instruções para resgate e prazo de validade.
            O prazo de ativação do serviço é 30 dias, conforme orientações de resgate recebido no
            SMS e/ou e-mail e após ativação a utilização será de acordo com validade da oferta
            recebida.
          </span>
        </p>
        <br />
        <h3>Instruções para ativação do prêmio:</h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            1. Acessar o site https://www.extra.com.br/ pelo Desktop (não é aceito na versão
            mobile), escolha seus produtos, calcule o frete e feche seu pedido. <br />
            2. Fazer login, confirmar a opção de entrega e digitar o número do código recebido,
            juntamente com a senha, na opção “Cartão Presente” na tela de pagamento e clicar em{" "}
            {`"Validar"`}. <br />
            3. Em seguida, preencher com o código de verificação/senha. Se houver a necessidade de
            completar o valor da compra, informar a opção “Cartão de Crédito”. Não é permitido
            complementar com mais de 1 cartão de crédito, cupom de desconto ou boleto. Válido também
            para pagamento de frete.
            <br />
            4. Clicar em {`"Finalizar compra"`} e seu pedido está completo.
          </span>
        </p>
        <br />
        <h3>APP</h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            Acesse o APP Extra, escolha os produtos, calcule o frete e feche seu pedido. 1. Fazer
            seu login, confirmar a opção de entrega e digitar o número do código recebido,
            juntamente com a senha, na opção “Cartão Presente” na tela de pagamento (rode as opções
            de pagamento para a esquerda até encontrar Cartão Presente). Se houver a necessidade de
            completar o valor da compra, informe a opção “Cartão de Crédito”. Não é permitido
            complementar com mais de 1 cartão de crédito, cupom de desconto ou boleto. Válido também
            para pagamento de frete. Não é aceito para compra de alimentos ou outros itens do site
            deliveryextra.com.br. <br />
            2. Clicar em {`"Finalizar compra"`}; e seu pedido está completo. ATENÇÃO: O seu Cartão
            Presente Extra.com.br não é um cupom de desconto, portanto não use a opção “Insira seu
            cupom” na área carrinho de compra.
          </span>
        </p>
        <br />
        <h3>Regras e Condições de Uso</h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            Utilização exclusiva no site https://www.extra.com.br/, com acesso pelo Desktop e APP
            (não é aceito na versão mobile). Válido para compra de qualquer produto (exceto a área
            de mercado do site) e também para pagamento de frete. Não é aceito para compra de
            alimentos ou outros itens do site deliveryextra.com.br. O Cartão Presente Extra.com.br
            pode ser utilizado em diversas compras até o término do saldo. O uso também poderá ser
            complementar o pagamento com 1 (um) cartão de crédito.
          </span>
        </p>
        <br />
        <h3>CARREFOUR</h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            Carrefour é uma rede internacional de hipermercados fundada na França em 1960. Produtos
            para casa, de forma rápida, prática e principalmente com os melhores preços e muito mais
            conforto em um só lugar. Site: https://www.carrefour.com.br Após a escolha do Carrefour,
            é enviada uma mensagem SMS e/ou e-mail com as instruções para resgate e prazo de
            validade. <br />O prazo de ativação do serviço é 30 dias, conforme orientações de
            resgate recebido no SMS e/ou e-mail e após ativação a utilização será de acordo com
            validade da oferta recebida.
          </span>
        </p>
        <br />
        <h3>Instruções para ativação: </h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            NAS LOJAS: 1. Para utilizar, basta apresentar como forma de pagamento nos caixas das
            lojas. NO SITE: 1. Acesse o link recebido e selecione “Clique Aqui”; <br />
            2. Selecione a Opção “Shopping” e comece a comprar; <br />
            3. No carrinho clique em “Continuar”; <br />
            4. Faça seu cadastro com dados pessoais e endereço de entrega; <br />
            5. Em pagamento clique em “Adicionar um Cartão Presente” e finalize sua compra;
          </span>
        </p>
        <br />
        <h3>Regras e condições de Uso</h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            Confira a cobertura de entrega de alimentos da sua região através do site, inserindo seu
            CEP para consulta. Não poderá ser utilizado no e-commerce nas categorias Mercado e
            Drogaria. Além disso, o Cartão presente Carrefour não pode resgatar outros Cartões
            Conteúdos dentro do site. Não é aceito no Atacadão, Mercado, Drogarias, Postos e caixas
            sem operador (autoatendimento). O Cartão Presente Carrefour Virtual pode ser utilizado
            em diversas compras até o término do saldo. Você também poderá complementar o pagamento
            com as formas disponíveis. Limite de 3 (três) Cartões por compra. Não há valor mínimo de
            consumo. O saldo pode ser consultado 24h por dia, 7 dias por semana pelo WhatsApp (11)
            3004-2222 (Opção 4). O Cartão Presente Carrefour Virtual é ao portador e é recarregável.
            Não é possível a transferência de saldo para outro Cartão Presente.
          </span>
        </p>
        <br />
        <h3>ASSAÍ</h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            O Assaí Atacadista é uma empresa brasileira de atacarejo. Foi fundado em 1974 na cidade
            de São Paulo, com foco no atendimento a clientes de micro e pequenas empresas. Site:
            https://www.assai.com.br/ Após a escolha do Assaí, é enviada uma mensagem SMS e/ou
            e-mail com as instruções para resgate e prazo de validade. O prazo de ativação do
            serviço é 30 dias, conforme orientações de resgate recebido no SMS e/ou e-mail e após
            ativação a utilização será de acordo com validade da oferta recebida.
          </span>
        </p>
        <br />
        <h3>Instruções para ativação:</h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            NAS LOJAS: 1. Para utilizar, basta apresentar como forma de pagamento nos caixas das
            lojas.
          </span>
        </p>
        <br />
        <h3>Regras e Condições de Uso</h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            Utilização em todas as lojas físicas. O crédito do seu Cartão Presente Assaí poderá ser
            utilizado no valor parcial, total ou você poderá complementar o pagamento com as formas
            disponíveis. Não há limites de Cartões por compra. O Cartão Presente Assaí pode ser
            utilizado em diversas compras, até o término do saldo. O Cartão Presente é ao portador e
            pode ser recarregado. Não é possível a transferência de saldo para outro Cartão
            Presente.
          </span>
        </p>
        <br />
        <h3>AMERICANAS</h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            A Americanas é a maior empresa online da América Latina! No site ou no App você encontra
            a maior variedade e as melhores ofertas. Após a escolha das Americanas, é enviada uma
            mensagem SMS e/ou e-mail com as instruções para resgate e prazo de validade. O prazo de
            ativação do serviço é 30 dias, conforme orientações de resgate recebido no SMS e/ou
            e-mail e após ativação a utilização será de acordo com validade da oferta recebida.
          </span>
        </p>
        <br />
        <h3>Instruções para ativação:</h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            1. Acesse o link de resgate recebido para ter acesso ao código do vale e clique em cima
            do “Vale Presente”;
            <br />
            2. Selecione os produtos desejados; <br />
            3. Clique em “Comprar”;
            <br />
            4. Em seguida, clique em continuar; <br />
            5. Faça o cadastro ou login; <br />
            6. Selecione a opção ”possui cupom ou vale?”; <br />
            7. Insira o cupom recebido e clique em “aplicar”; <br />
            8. Pronto! Agora é só aproveitar o seu desconto e finalizar a compra!
          </span>
        </p>
        <br />
        <h3>Regras e Condições de Uso</h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            Utilização no site https://www.americanas.com.br e no App Americanas. Não é aceito para
            Americanas Mercado. O Vale Presente Americanas é válido apenas para compra de produtos e
            não serviços. Para compra de um produto pelo aplicativo, é necessário que o mesmo esteja
            totalmente atualizado. O Vale Presente Americanas pode ser utilizado em diversas compras
            até o término do saldo. O consumidor poderá complementar o pagamento com as formas
            disponíveis. Para utilizar no site ou Aplicativo, basta inserir o código no campo{" "}
            {`"Vale"`}
            na tela de pagamento. Não há limite de Vales por compra. Seu saldo não poderá ser
            cancelado, trocado, devolvido ou ser convertido em dinheiro. O Vale Presente Americanas
            é ao portador e não é recarregável. Não é possível a transferência de saldo para outro
            Vale Presente.
          </span>
        </p>
        <br />
        <h3>1. BÔNUS CELULAR</h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            1.1. No caso dos serviços prestados pelas operadoras de telefonia Móvel, as regras
            gerais de fruição e uso dos referidos serviços são as seguintes: o Bônus Celular é uma
            recompensa para os usuários das principais operadoras de qualquer DDD, desde que o
            número, não esteja bloqueado, desativado ou cancelado, e seja de pessoa física. O prêmio
            é oferecido em reais para os planos pré e controle, e em minutos para os planos
            pós-pagos.
            <br />
            1.2. Assim que o prêmio for entregue, o participante receberá automaticamente um SMS
            (mensagem) de confirmação no seu celular.
          </span>
        </p>
        <br />
        <h3>Regras Bônus Celular - Por plano e operadora</h3>
        <h3>Operadora Claro</h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            Bônus Celular (Bônus+) é um crédito de telefonia concedido pela operadora Claro para
            falar e navegar na internet, conforme segue:
            <br />
            <br />
            - Ligação local para números móveis e fixos da Claro: o minuto será tarifado de acordo
            com o plano ativo (pré-pago,controle ou pós-pago), contratado junto à operadora.
            <br />- De acordo com o bônus recebido, serão concedidos 1.25 gigabytes para utilização
            por 30 dias. Na operadora Claro o bônus celular é chamado de Bônus Patrocinado. Caso o
            cliente queira consultar o bônus celular recebido ou utilizado, basta ele digitar no
            telefone *1052# ou acessar o site Minha Claro. A consulta do pacote de internet estará
            disponível dentro da validade de uso do serviço. Caso o prazo de utilização tenha
            expirado, a consulta deverá ser feita no extrato detalhado buscando a informação do
            produto Bônus Patrocinado.
          </span>
        </p>
        <br />
        <h3>Importante:</h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            O bônus não é acumulativo com outras promoções da Claro, não é válido para planos de
            números fixos, planos de dados, planos corporativos, plano Claro nxt, plano Controle
            Fácil e plano Claro Flex. Para os clientes de plano (família) com internet
            compartilhada, o pacote de internet estará disponível apenas para a linha titular.
            <br />
            <br />
            Quando houver contratação de pacote (30 dias), o pacote de internet do bônus terá
            prioridade, exceto quando existir outro bônus com menor tempo de validade. O limite de
            concessão de bônus por mês, por número de celular, é de R$240, ainda que o participante
            tenha aderido a mais de uma campanha no período, independente da marca patrocinadora. O
            limite de concessão dos pacotes de internet para planos pós-pagos e controle é de 1
            pacote de internet no período de 30 dias. Ao atingir o limite, o participante receberá o
            valor correspondente para uso em ligações.
          </span>
        </p>
        <br />
        <h3>Confira abaixo as regras de utilização:</h3>
        <h3>Planos PRÉ-PAGO E CONTROLE</h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            - O valor em reais (R$) do bônus é válido para chamadas locais para Claro Móvel e Claro
            fixo de mesmo DDD;
            <br />
            - O prazo de utilização do bônus é de 30 dias após a concessão do crédito;
            <br />
            - O saldo de bônus concedido não poderá ser utilizado para adesão ou renovação de
            promoções da Claro, sejam elas pontuais ou recorrentes; <br />
            - O saldo de bônus concedido não poderá ser utilizado para aquisição de produtos ou
            serviços da operadora;
            <br />
            - O pré-pago deverá ter saldo de recarga ativo dentro da validade, para usar o bônus,
            seja em valor de recarga disponível acima de R$0,01 ou com valor de recarga disponível
            em pacote acima de R$0,01;
            <br />
            - O bônus será consumido antes do saldo principal, para celulares pré-pago;
            <br />
            - O bônus entrará como um valor extra, e será utilizado somente caso o participante
            ultrapasse o limite da sua franquia, para celulares controle;
            <br />
            - Quando houver contratação de internet diária, primeiro será consumido o pacote de
            internet do bônus e depois a internet diária;
            <br />- O cliente que não conseguir ativar o bônus decorrente de alguma restrição do seu
            plano na Operadora, receberá uma comunicação com o link de acesso ao cupom permitindo o
            direito de ativar o bônus em outro número de celular válido e elegível, de acordo com as
            regras de negócios da OPERADORA. Assim que o bônus celular/bônus patrocinado é
            disponibilizado, é enviado um SMS (mensagem) avisando a entrega do bônus para utilizar
            com ligações e outro avisando para utilizar o pacote de internet, conforme segue:
            (cliente): Chegou seu Bonus+ Claro: R$ XX p/ ligações locais Claro fixo e móvel. Val. 30
            dias. Logo sua internet será liberada. Saldo? *1052#.
          </span>
        </p>
        <br />
        <h3>Planos PÓS-PAGO:</h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            - O valor em reais (R$) do bônus é válido para chamadas locais para Claro Móvel e Claro
            fixo de mesmo DDD;
            <br />
            - O valor em reais (R$) do bônus para ligações é convertido em minutos, exemplo: R$10,00
            = 10 Minutos.
            <br />
            - O consumo do bônus para ligações começará após a utilização total da franquia mensal
            contratada e sua duração vale até o último dia do mês.
            <br />
            - O cliente que não conseguir ativar o bônus decorrente de alguma restrição do seu plano
            na Operadora, receberá uma comunicação com o link de acesso ao cupom permitindo o
            direito de ativar o bônus em outro número de celular válido e elegível, de acordo com as
            regras de negócios da OPERADORA. Assim que o bônus celular/bônus patrocinado é
            disponibilizado, é enviado um SMS (mensagem) avisando a entrega do bônus para utilizar
            com ligações e outro avisando para utilizar o pacote de internet, conforme segue:
            <br />
            (cliente): Chegou seu Bonus+ Claro! XX min p/ lig. locais Claro fixo e móvel val. neste
            mês. Logo sua internet será liberada. Saldo?*1052# O cliente que não estiver elegível ao
            bônus em decorrência de alguma restrição do seu plano em sua operadora receberá um
            voucher com instruções para transferência do Bônus para outro número de celular válido e
            elegível, de acordo com as regras de negócios de sua operadora. Após o recebimento do
            voucher, o participante terá o prazo de até 30 (trinta) dias corridos, contados da data
            de envio do e-mail e/ou SMS para ativar o voucher de bônus.
            <br />A entrega do voucher é comunicada por mensagem SMS com a seguinte redação:
            (cliente): Seu Bonus+ Claro já está disponível para ativação. Acesse
            https://seu.bz/XXXXXXX consulte as regras e informe o numero Claro desejado.
          </span>
        </p>
        <br />
        <h3>Operadora TIM</h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            Bônus Celular (Bônus+) é um crédito de telefonia concedido pela operadora TIM para
            falar, enviar SMS e navegar na internet, conforme segue:
            <br />
            - Ligação local ilimitada para números móveis e fixos da TIM durante o período de
            validade do saldo concedido;
            <br />
            - Envio de SMS ilimitado para números móveis locais da TIM durante o período de validade
            do saldo concedido;
            <br />- De acordo com o bônus recebido, será concedido 1.25 gigabytes para utilização em
            4 dias.
          </span>
        </p>
        <br />
        <h3>Na operadora TIM o bônus celular é chamado de Bônus Mais.</h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            Caso o cliente queira consultar o bônus recebido ou utilizado, basta ele acessar o Meu
            TIM. A consulta do pacote de internet estará disponível dentro da validade de uso do
            serviço. Caso o prazo de utilização tenha expirado, a consulta deverá ser feita no
            extrato detalhado buscando a informação do produto Bônus Mais.
          </span>
        </p>
        <br />
        <h3>Importante: </h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            O bônus não é acumulativo com outras promoções da TIM, não é válido para planos de
            números fixos, planos de dados, planos corporativos, plano pós-pago e Controle Express.
            <br />O cliente Pós-pago e Controle Express que for contemplado, receberá uma
            comunicação com o link de acesso ao cupom permitindo o direito de ativar o Bônus Mais em
            outro número de celular válido e elegível, sendo Pré-pago ou Plano Controle, de acordo
            com as regras de negócios da OPERADORA.
          </span>
        </p>
        <br />
        <h3>Confira abaixo as regras de utilização:</h3>
        <h3>Planos PRÉ-PAGO e CONTROLE FATURA:</h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            - Válido para internet, ligações locais para números TIM fixos e móveis, envio de SMS
            local de TIM para TIM;
            <br />
            - O cliente com plano/oferta ativa assim que receber o bônus, o mesmo já começará a ser
            utilizado antes de qualquer outro bônus ou benefícios da sua oferta;
            <br />
            - O bônus terá prioridade de uso diante de outras ofertas/bônus que o cliente tenha
            recebido;
            <br />
            - O saldo de bônus concedido não poderá ser utilizado para adesão ou renovação de
            promoções da TIM, sejam elas pontuais ou recorrentes; O prazo de utilização do bônus é
            de 1 dia;
            <br />
            - O saldo de bônus concedido não poderá ser utilizado para aquisição de produtos ou
            serviços da operadora.
            <br />
            - Caso o usuário não utilize o volume total de MB concedidos, dentro da validade
            estipulada, o bônus restante deste pacote será expirado.
            <br />
            - Não há limite ou restrição de concessão de mais de 1 pacote com a mesma quantidade de
            MB, durante 30 dias;
            <br />
            - O cliente que não conseguir ativar o bônus decorrente de alguma restrição do seu plano
            na Operadora, receberá uma comunicação com o link de acesso ao cupom permitindo o
            direito de ativar o Bônus Mais em outro número de celular válido e elegível, de acordo
            com as regras de negócios da OPERADORA. Assim que o bônus celular é disponibilizado, é
            enviado um SMS (mensagem) avisando a entrega do bônus para utilizar, conforme segue:
            (cliente): Você ganhou R$ XX em bônus para Internet, ligações TIM e SMS. Acesse
            https://meuplano.tim.com.br e acompanhe seu consumo. O cliente que não estiver elegível
            ao bônus em decorrência de alguma restrição do seu plano em sua operadora receberá um
            voucher com instruções para transferência do Bônus para outro número de celular válido e
            elegível, de acordo com as regras de negócios de sua operadora. Após o recebimento do
            voucher, o participante terá o prazo de até 30 (trinta) dias corridos, contados da data
            de envio do e-mail e/ou SMS para ativar o voucher de bônus.
            <br />A entrega do voucher é comunicada por mensagem SMS com a seguinte redação:
            (cliente): Seu Bonus+ TIM já está disponível para ativação. Acesse
            https://seu.bz/XXXXXXX consulte as regras e informe o numero TIM desejado.
          </span>
        </p>
        <br />
        <h3>Operadora Vivo</h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            Bônus Celular é um crédito de telefonia concedido pela operadora Vivo para falar, enviar
            SMS e navegar na internet e acesso à Caixa Postal e Vivo Avisa, conforme segue:
            <br />
            - Ligação local Vivo para Vivo móvel: o minuto será tarifado de acordo com o plano ativo
            (pré- pago, controle ou pós-pago), contratado junto à operadora.
            <br />
            - De acordo com o bônus recebido, será concedido 1.25 gigabytes para utilização por 30
            dias. <br />
            - Envio de SMS: o envio de SMS é tarifado de acordo com o valor do plano ativo (pré-pago
            e controle), contratado junto à operadora. <br />- Acesso à Caixa Postal e Vivo Avisa: o
            serviço é tarifado de acordo com o valor do plano ativo (pré-pago e controle),
            contratado junto à operadora. Na operadora Vivo o bônus celular é chamado de Bônus Gold.
            Caso o cliente pré-pago ou controle queira consultar o bônus celular recebido ou
            utilizado, basta ele ligar para *8000. Para o plano pós-pago ligar para *8012.
          </span>
        </p>
        <br />
        <h3>Importante:</h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            O bônus não é acumulativo com outras promoções da Vivo. Não é válido para planos de
            números fixos, planos de dados, planos corporativos e plano Vivo Easy.
          </span>
        </p>
        <br />
        <h3>Confira abaixo as regras de utilização: Planos PRÉ-PAGO E CONTROLE </h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            - Bônus válido para uso em chamadas locais de Vivo Móvel para Vivo Móvel, SMS de Vivo
            para Vivo, SMS Vivo para Outras Operadoras móveis, acesso ao WAP, Internet, envio de
            MMS, acesso à Caixa Postal e Vivo Avisa.
            <br />
            - Para contagem da utilização da bonificação serão considerados os mesmos valores
            vigentes para o Plano de Serviço contratado pelo Cliente junto à Vivo.
            <br />
            - O pré-pago deverá ter saldo de recarga ativo dentro da validade para usar o bônus,
            seja em valor de recarga disponível acima de R$0,01 ou com valor de recarga disponível
            em pacote acima de R$0,01.
            <br />
            - O bônus será consumido antes do saldo principal (recarga), para celulares pré-pago;
            <br />
            - O bônus será consumido após a franquia para planos Controle;
            <br />
            - O bônus concedido não poderá ser utilizado para adesão a promoções da Vivo e/ou
            aquisição de produtos/serviços de terceiros, bem como para transferência de créditos de
            recarga entre clientes Vivo.
            <br />
            - O bônus é válido por 30 (trinta) dias, a partir da data de concessão, não sendo
            prorrogado em nenhuma hipótese. Créditos eventualmente não utilizados em um mês serão
            automaticamente cancelados e não poderão ser utilizados nos meses subsequentes.
            <br />- O cliente que não conseguir ativar o bônus decorrente de alguma restrição do seu
            plano na Operadora, receberá uma comunicação com o link de acesso ao cupom permitindo o
            direito de ativar o bônus em outro número de celular válido e elegível, de acordo com as
            regras de negócios da OPERADORA. Assim que o bônus celular é disponibilizado, é enviado
            um SMS (mensagem) avisando a entrega do bônus para utilizar, conforme segue: (cliente):
            Vc ganhou R$XX,00 em bônus celular. Válidos por 30 dias em ligações locais p/ Vivo
            Móvel, SMS e Internet. Saldo*8000
          </span>
        </p>
        <br />
        <h3>Planos PÓS-PAGO</h3>
        <p className={styles.tc_br_section}>
          <span className={styles.tc_text_br}>
            - O valor em reais (R$) é válido para uso em chamadas locais de Vivo para Vivo e para
            fixo.
            <br />
            - O valor em reais (R$) para ligações é convertido em minutos, exemplo: R$10,00 = 10
            Minutos.
            <br />
            - O bônus será consumido após a franquia mensal contratada; - O bônus é válido por 30
            (trinta) dias, a partir da data de concessão, não sendo prorrogado em nenhuma hipótese.
            Créditos eventualmente não utilizados em um mês serão automaticamente cancelados e não
            poderão ser utilizados nos meses subsequentes.
            <br />
            - O bônus concedido não poderá ser utilizado para adesão a promoções da Vivo e/ou
            aquisição de produtos/serviços de terceiros, bem como para transferência de créditos de
            recarga entre clientes Vivo.
            <br />
            - O cliente que não conseguir ativar o bônus decorrente de alguma restrição do seu plano
            na Operadora, receberá uma comunicação com o link de acesso ao cupom permitindo o
            direito de ativar o bônus em outro número de celular válido e elegível, de acordo com as
            regras de negócios da OPERADORA. Assim que o bônus celular é disponibilizado, é enviado
            um SMS (mensagem) avisando a entrega do bônus para utilizar, conforme segue: (cliente):
            Você ganhou XX minutos em bônus celular para ligações locais Vivo Móvel e Fixo.
            Validade: 30 dias. Consulte seu saldo no app Vivo! O cliente que não estiver elegível ao
            bônus em decorrência de alguma restrição do seu plano em sua operadora receberá um
            voucher com instruções para transferência do Bônus para outro número de celular válido e
            elegível, de acordo com as regras de negócios de sua operadora. Após o recebimento do
            voucher, o participante terá o prazo de até 30 (trinta) dias corridos, contados da data
            de envio do e-mail e/ou SMS para ativar o voucher de bônus. A entrega do voucher é
            comunicada por mensagem SMS com a seguinte redação: (cliente): Seu Bônus Celular Vivo já
            está disponível para ativação. Acesse https://seu.bz/XXXXXXX consulte as regras e
            informe o numero Vivo desejado.
            <br />
          </span>
        </p>
      </article>
    </div>
  );
};
