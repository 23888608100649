import { useEffect, useState } from "react";
import store from "store2";
import createXhr from "../api";
import jwtDecode from "jwt-decode";
import { useDispatch } from "react-redux";
import {
  initialize,
  logout,
  setCampaign,
  setPhase,
  setPrizeImages,
  setSponsorImageUrl,
  setToken,
  setUserCount,
  setUserInfo,
  updateAuth,
} from "../store/state";
import { availableCountries, getPhase, production, usableCountries } from "../config";
import { errorHandler } from "../api/errorHandler";
import { useTranslation } from "react-i18next";
import { AppDispatch, useSelector } from "../store";

type UserStatus = "unknown" | "new" | "exist";

export const Init = (access: string) => {
  const dispatch = useDispatch<AppDispatch>();
  const { i18n } = useTranslation();
  const [accessToken, setAccessToken] = useState<string>(access || "");
  const [refreshToken, setRefreshToken] = useState<string>(store("refresh_token"));
  const [authorization, setAuthorization] = useState<string>("");
  const [userStatus, setUserStatus] = useState<UserStatus>("unknown");
  const [uid, setUid] = useState<string>(store("user_id"));
  const [country, setCountry] = useState<string>("");
  const [createdUser, createUser] = useState(false);
  const [fetchedUserInfo, setFetchedUserInfo] = useState(false);
  const lang = useSelector((state) => state.data.lang);

  const setAuthInfo = (tokens: Tokens) => {
    const { accessToken, refreshToken } = tokens;
    store("access_token", accessToken);
    store("refresh_token", refreshToken);
    setAccessToken(accessToken);
    setRefreshToken(refreshToken);
    setAuthorization(`Bearer ${accessToken}`);
  };

  useEffect(() => {
    i18n.changeLanguage(lang).then();
    if (createdUser) {
      return;
    }
    createXhr({
      headers: {
        "X-CountryCode": country || availableCountries[0],
      },
    })
      .getInfo()
      .then((res: BasicInfo) => {
        const country = usableCountries.includes(res.clientCountryCode)
          ? !availableCountries.includes(res.clientCountryCode)
            ? availableCountries[0]
            : res.clientCountryCode
          : res.clientCountryCode;
        setCountry(country);
        dispatch(
          setSponsorImageUrl({
            sponsorImageUrl: res.sponsorImageUrl || "",
          }),
        );
        if (production && !(window as any)?.news_data_h5 && !store("debug")) {
          setPhase({ phase: "NOT_OPR" });
          dispatch(
            setUserInfo({
              country: country,
              verified: false,
            }),
          );
          dispatch(
            setPhase({
              phase: "NOT_OPR",
            }),
          );
          dispatch(initialize());
          return;
        }
        const phase = getPhase(country || "");
        dispatch(
          setPhase({
            phase,
          }),
        );
        if (phase === "NOT_START" || phase === "NOT_IN_COUNTRY" || phase === "FINISHED") {
          dispatch(
            setUserInfo({
              country: country,
              verified: false,
            }),
          );
          dispatch(initialize());
          return;
        }
        if (!accessToken || !refreshToken) {
          store.remove("access_token");
          store.remove("refresh_token");
          createXhr({
            headers: {
              "X-CountryCode": store("debug_mode")
                ? availableCountries[0]
                : country || store("country") || availableCountries[0],
            },
          })
            .getAnonUser()
            // .getNewUser()
            .then((res: Tokens) => {
              createUser(true);
              setUserStatus("new");
              setAuthInfo(res);
            })
            .catch((reason: any) => {
              errorHandler(reason, dispatch);
            });
          return;
        }
        setAuthorization(`Bearer ${accessToken}`);
        setUserStatus("exist");
      })
      .catch((err: { message: string | string[] }) => {
        if (err.message?.includes("no campaign for")) {
          setFetchedUserInfo(true);
          dispatch(
            setPhase({
              phase: "NOT_IN_COUNTRY",
            }),
          );
          dispatch(initialize());
          return;
        }
      });

    return;
  }, [createdUser]);

  useEffect(() => {
    if (userStatus === "new") {
      store.remove("chosen_country");
    }
  }, [userStatus]);

  useEffect(() => {
    if (!authorization || !accessToken || !refreshToken) {
      return;
    }
    const decoded: { exp: number; sub: string } = jwtDecode(accessToken);
    const expireTime = decoded.exp;
    store("user_id", decoded.sub);
    setUid(decoded.sub);
    // if nearly expired
    if (expireTime - Date.now() / 1000 < 60 * 60) {
      createXhr({
        headers: {
          "X-CountryCode": store("debug_mode") ? availableCountries[0] : country,
        },
      })
        // .postRefreshToken({ refreshToken: refreshToken })
        .postRefreshTokenV2({ refreshToken: refreshToken })
        .then((res: Tokens) => {
          setAuthInfo(res);
          window.location.reload();
        })
        .catch((reason: any) => {
          errorHandler(reason, dispatch);
          dispatch(logout());
        });
      return;
    }
    // if still valid
    createXhr({
      headers: {
        "X-CountryCode": store("debug_mode")
          ? availableCountries[0]
          : country || usableCountries[0],
        Authorization: authorization,
      },
    })
      .getUserInfo()
      .then((res: UserInfo) => {
        setCountry(
          store("debug_mode") ? availableCountries[0] : res.countryCode || usableCountries[0],
        );
        dispatch(
          setUserInfo({
            userId: uid,
            country: res.countryCode || usableCountries[0],
            verified: res.isPhoneVerified,
            avatarUrl: res.avatarUrl,
            canChangeCountryCode: res.canChangeCountryCode,
          }),
        );
        store("campaign", res.campaign);
        dispatch(
          setCampaign({
            campaign: res.campaign,
          }),
        );
        dispatch(
          updateAuth({
            authAccessToken: store("auth_accessToken") || "",
            authRefreshToken: store("auth_refreshToken") || "",
          }),
        );
        setFetchedUserInfo(true);
      })
      .catch((reason: any) => {
        if (reason.message?.includes("not permitted for country")) {
          setFetchedUserInfo(true);
          dispatch(
            setPhase({
              phase: "NOT_IN_COUNTRY",
            }),
          );
          dispatch(initialize());
          return;
        }
        errorHandler(reason, dispatch);
        dispatch(logout());
        window.location.reload();
      });
  }, [authorization, accessToken, refreshToken, country]);

  useEffect(() => {
    if (country && authorization && fetchedUserInfo) {
      if (userStatus === "exist") {
        if (!usableCountries.includes(country)) {
          return;
        }
      }
      const xhr = createXhr({
        headers: {
          Authorization: authorization,
          "X-CountryCode": store("debug_mode") ? availableCountries[0] : country,
        },
      });
      xhr
        .getInfo()
        .then((res: BasicInfo) => {
          const images = res.prizes.map((item) => item.imgUrl);
          const userCount = res.usersCount || 0;
          dispatch(
            setUserCount({
              userCount,
            }),
          );
          dispatch(
            setToken({
              accessToken: accessToken,
              refreshToken: refreshToken,
            }),
          );
          dispatch(
            setPrizeImages({
              prizeImages: images,
            }),
          );
          dispatch(
            setSponsorImageUrl({
              sponsorImageUrl: res.sponsorImageUrl || "",
            }),
          );
          dispatch(initialize());
        })
        .catch((reason: any) => {
          console.error(reason);
          dispatch(logout());
          errorHandler(reason, dispatch);
        });
      return;
    }
  }, [country, authorization, userStatus, fetchedUserInfo]);
};
