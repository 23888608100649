import React from "react";
import { brandLogo, couponOptionsBR } from "../../constants/couponOptions";
import { useSelector } from "../../store";
import { useSearchParams } from "react-router-dom";
import { Footer } from "../../components/Footer";

export const PrizeOptions: React.FC = () => {
  const theme = useSelector((state) => state.data.theme);
  const [params] = useSearchParams();
  const gift = params.get("name") || "";
  const options = couponOptionsBR[gift] || [];

  return (
    <div style={{ paddingTop: "72px" }}>
      <section
        style={{
          margin: "0 16px",
          padding: "12px",
          borderRadius: "12px",
          border: `#5966AD 1px solid`,
          display: "flex",
          alignItems: "center",
          background: theme.brandBackgroundColor,
        }}
      >
        <img
          style={{
            borderRadius: "8px",
            width: "44px",
            height: "44px",
          }}
          src={require("../../assets/images/brand_logos/Opera.png")}
          alt={""}
        />
        <div style={{ marginLeft: "16px" }}>
          <h2 style={{ fontSize: "12px", fontWeight: 700, lineHeight: "18px" }}>{gift}</h2>
          <p style={{ fontSize: "12px", fontWeight: 400, lineHeight: "16px", color: "#FFFFFFBF" }}>
            Opera
          </p>
        </div>
      </section>
      <section
        style={{
          margin: "16px",
          borderRadius: "8px",
          overflow: "hidden",
          border: `${theme.menuColor} 1px solid`,
        }}
      >
        {options.map((brand, index) => (
          <article
            key={`${brand}_${index}`}
            style={{
              display: "flex",
              alignItems: "center",
              background: theme.secondaryBackgroundColor,
              padding: "12px",
              borderTop: index ? `${theme.menuColor} 1px solid` : "",
            }}
          >
            <img
              style={{
                borderRadius: "8px",
                width: "44px",
                height: "44px",
              }}
              src={brandLogo[brand] || ""}
              alt={brand}
            />
            <div style={{ marginLeft: "16px" }}>
              <h2 style={{ fontSize: "12px", fontWeight: 700, lineHeight: "18px" }}>{`${gift}`}</h2>
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "16px",
                  color: "#FFFFFFBF",
                }}
              >
                {brand}
              </p>
            </div>
          </article>
        ))}
      </section>
      <Footer />
    </div>
  );
};
