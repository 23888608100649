import axios from "axios";

// import { useNavigate } from "react-router-dom";
// import { useLocation } from "react-router-dom";

const ajax = axios.create({
  timeout: 20000,
  headers: {
    Accept: "application/json",
    // AuthoriZation:localStorage.getItem("token")||'',
    // AuthoriZation:'',
    // "X-ClientId":123
  },
  // baseURL: "https://staging-football-api.ams.op-mobile.opera.com",
});

ajax.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

const controller = new AbortController();

export { ajax, controller };
