import React, { FC, useMemo } from "react";
import styles from "./index.module.scss";
import { openOuterTab } from "utils/schema";
import { SWButton } from "components/SWButton";
import { useSelector } from "store";
import { oprVersion, getClientName, production } from "config";

interface PromMiniProps {
  status?: "live" | "end";
  className?: string;
}

const PromMini: FC<PromMiniProps> = ({ className, status = "live" }) => {
  const country = useSelector((state) => state.data.country);
  const canShowProm = useMemo(() => {
    const clientName = getClientName();
    return (
      clientName === "mini" && oprVersion >= "84" && ["KE", "GH", "NG", "ZA"].includes(country)
    );
  }, [country]);
  const promDomain = useMemo(
    () => (production ? "https://rewards.opera.com/" : "https://missions.op-test.net/"),
    [production],
  );

  const handleClick = () => openOuterTab(`${promDomain}?utm_content=shake_and_win`);

  if (!canShowProm) return null;

  if (status === "live") {
    return (
      <div onClick={handleClick} className={styles.box + (className ? ` ${className}` : "")}>
        <div>
          <img
            className={styles.logo}
            src={require("../../assets/images/prom/rewards.png")}
            alt=""
          />
          <span className={styles.highlight}>NEW!</span>
          <span>Earn daily rewards with Mini</span>
        </div>
        <img className={styles.arrow} src={require("../../assets/images/prom/arrow.png")} alt="" />
      </div>
    );
  }
  return (
    <div className={styles.boxEnd + (className ? ` ${className}` : "")}>
      <img src={require("../../assets/images/prom/coin.png")} alt="" />
      <p>
        Earn daily rewards with <span>Opera Mini</span>
      </p>
      <p>Browse, read articles and play games to earn rewards every day!</p>
      <SWButton text="Get started" callback={handleClick} />
    </div>
  );
};

export default PromMini;
