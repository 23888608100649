import React from "react";
// import ReactECharts from "echarts-for-react";
import { toDMY } from "../utils/time";
import { useUtmParamNavigate } from "../utils/utmParamNavigate";
import { useTranslation } from "react-i18next"; // or var ReactECharts = require('echarts-for-react');

interface PrizePops {
  prize: Reward;
}

export const PrizeCard: React.FC<PrizePops> = ({ prize }) => {
  const {
    id,
    brandLogo,
    progress,
    prizeWonTime,
    won,
    type,
    inProgress,
    prizeTypeId,
    name,
    brandName,
  } = prize;
  const { t, i18n } = useTranslation();
  const navigate = useUtmParamNavigate();
  return (
    <div
      style={{
        boxSizing: "border-box",
        padding: "12px",
      }}
      onClick={() => {
        if (!won) {
          navigate(`/winnable?prize_type_id=${prizeTypeId}`);
        } else {
          navigate(`/won?prize_id=${id}`);
        }
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <img
          src={brandLogo}
          alt={name}
          style={{
            width: "44px",
            height: "44px",
            borderRadius: "4px",
          }}
        />
        <div
          style={{
            flex: 1,
            height: "44px",
            textAlign: "left",
            margin: "0 12px",
            boxSizing: "border-box",
            padding: "2px 0",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              height: "18px",
              fontWeight: "700",
              color: "#ffffff",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {name}
          </div>
          <div
            style={{
              marginTop: "4px",
              height: "18px",
              fontWeight: "400",
              fontSize: "12px",
              color: "rgba(255, 255, 255, 0.75)",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {brandName}
          </div>
        </div>
        <div
          style={{
            display: id && type === "puzzle" && progress && progress.current > 0 ? "" : "none",
            padding: "5px 8px",
            color: "#ffffff",
            fontWeight: 600,
            fontSize: "12px",
            background: "rgba(255, 255, 255, 0.1)",
            borderRadius: "12px",
          }}
        >{`${progress?.current}/${progress?.total}`}</div>
        <div
          style={{
            display: !id && type === "puzzle" && inProgress ? "" : "none",
            padding: "5px 8px",
            color: "#000000",
            fontWeight: 600,
            fontSize: "12px",
            background: "#ffffff",
            borderRadius: "8px",
            width: "64px",
          }}
        >
          {t("inProgress", { count: inProgress })}
        </div>
        <div
          style={{
            marginLeft: "12px",
            display: won ? "" : "none",
          }}
        >
          <img
            src={require("../assets/images/shake_win_br/white_tick.png")}
            style={{
              height: "22px",
              width: "22px",
            }}
            alt=""
          />
        </div>
      </div>
      <div
        style={{
          paddingTop: "12px",
          display: won ? "flex" : "none",
          justifyContent: "space-between",
          color: "#ffffff",
        }}
      >
        <span
          style={{
            fontSize: "12px",
            color: "rgba(255, 255, 255, 0.75)",
          }}
        >
          {prizeWonTime ? `${t("won")}: ${toDMY(prizeWonTime, i18n.language)}` : " "}
        </span>
        <span
          style={{
            fontSize: "12px",
            fontWeight: 700,
          }}
        >
          {prize.isClaimed
            ? t("claimed") || "Claimed"
            : prize.type === "immediate"
            ? t("howToClaim") || "How to claim >"
            : t("claimPrize") || "Claim prize >"}
        </span>
      </div>
    </div>
  );
};
