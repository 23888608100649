import { useEffect, useState } from "react";
import store from "store2";
import createXhr from "../api";
import jwtDecode from "jwt-decode";
import { useDispatch } from "react-redux";
import {
  initialize,
  logout,
  setCampaign,
  setCountry,
  setPhase,
  setPrizeImages,
  setSponsorImageUrl,
  setToken,
  setUserCount,
  setUserInfo,
  updateAuth,
} from "../store/state";
import { errorHandler } from "../api/errorHandler";
import { useTranslation } from "react-i18next";
import { availableCountries, getPhase, usableCountries } from "../config";
import { useSelector } from "../store";

export const ClientInit = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const [uid, setUid] = useState<string>(store("user_id"));
  const lang = useSelector((state) => state.data.lang);
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    let clientCountry = "";
    const defaultCountry = usableCountries.includes(store("country"))
      ? store("country")
      : availableCountries[0];
    let tries = 0;
    while (!clientCountry && tries < 5) {
      // @ts-ignore
      clientCountry = (
        (window as Client).shakewin?.getClientLocalization
          ? // @ts-ignore
            (window as Client).shakewin?.getClientLocalization()
          : ""
      )?.toUpperCase();
      ++tries;
    }
    if (tries >= 5 || !clientCountry) {
      clientCountry = defaultCountry;
    }
    clientCountry = clientCountry?.toUpperCase();
    i18n.changeLanguage(lang).then();
    const phase = getPhase(clientCountry);
    dispatch(
      setPhase({
        phase,
      }),
    );
    // @ts-ignore
    window.shakewinweb = {
      onNewAccessToken: (access?: string) => {
        if (!access) {
          if ((window as any)?.showServerError) {
            (window as any)?.showServerError({
              title: `Error:`,
              url: "Mini client",
              text: "No token available by Client. Please check the network status or update to the newest version.",
            });
          }
          store("force", 1);
          return;
        }
        const decoded: { exp: number; sub: string } = jwtDecode(access);
        const expireTime = decoded.exp;
        store("user_id", decoded.sub);
        setUid(decoded.sub);
        // if nearly expired
        if (expireTime - Date.now() / 1000 < 60 * 60) {
          store("force", 1);
          window.location.reload();
          return;
        }
        store.remove("force");
        const authorization = "Bearer " + access;

        store("access_token", access);
        createXhr({
          headers: {
            "X-CountryCode": clientCountry || availableCountries[0],
          },
        })
          .getInfo()
          .then((res: BasicInfo) => {
            const detectedCountry = clientCountry || res.clientCountryCode;
            const country = usableCountries.includes(detectedCountry)
              ? availableCountries.includes(detectedCountry)
                ? detectedCountry
                : availableCountries[0]
              : detectedCountry;
            dispatch(
              setCountry({
                country: country,
              }),
            );
            const images = res.prizes.map((item) => item.imgUrl);
            dispatch(
              setPrizeImages({
                prizeImages: images,
              }),
            );
            dispatch(
              setSponsorImageUrl({
                sponsorImageUrl: res.sponsorImageUrl || "",
              }),
            );
            if (phase === "NOT_START" || phase === "NOT_IN_COUNTRY" || phase === "FINISHED") {
              dispatch(
                setPhase({
                  phase: getPhase(country),
                }),
              );
              dispatch(initialize());

              return;
            }
            createXhr({
              headers: {
                Authorization: authorization,
                "X-CountryCode": country,
              },
            })
              .getUserInfo()
              .then((res: UserInfo) => {
                if (
                  res.isPhoneVerified &&
                  !(store("auth_accessToken") && store("auth_refreshToken")) &&
                  !initialized
                ) {
                  dispatch(logout());
                  (window as Client)?.shakewin?.onLogout();
                  window.location.reload();
                }
                store("campaign", res.campaign);
                dispatch(
                  setCampaign({
                    campaign: res.campaign,
                  }),
                );
                dispatch(
                  setUserInfo({
                    userId: uid,
                    country: res.countryCode || availableCountries[0],
                    verified: res.isPhoneVerified,
                    avatarUrl: res.avatarUrl,
                    canChangeCountryCode: res.canChangeCountryCode,
                  }),
                );
                dispatch(
                  updateAuth({
                    authAccessToken: store("auth_accessToken") || "",
                    authRefreshToken: store("auth_refreshToken") || "",
                  }),
                );
                const xhr = createXhr({
                  headers: {
                    Authorization: authorization,
                    "X-CountryCode": store("debug_mode")
                      ? availableCountries[0]
                      : res.countryCode || availableCountries[0],
                  },
                });
                xhr
                  .getInfo()
                  .then((res: BasicInfo) => {
                    const images = res.prizes.map((item) => item.imgUrl);
                    const userCount = res.usersCount || 0;
                    dispatch(
                      setUserCount({
                        userCount,
                      }),
                    );
                    dispatch(
                      setToken({
                        accessToken: access,
                        refreshToken: "",
                      }),
                    );
                    dispatch(
                      setPrizeImages({
                        prizeImages: images,
                      }),
                    );
                    dispatch(
                      setSponsorImageUrl({
                        sponsorImageUrl: res.sponsorImageUrl || "",
                      }),
                    );
                    dispatch(initialize());
                  })
                  .catch((reason: any) => {
                    if (reason.message?.includes("no campaign for")) {
                      dispatch(
                        setPhase({
                          phase: "NOT_IN_COUNTRY",
                        }),
                      );
                      dispatch(initialize());
                      return;
                    }
                    errorHandler(reason, dispatch);
                  });
              })
              .catch((reason: any) => {
                if (reason.message?.includes("not permitted for country")) {
                  if (
                    reason.message?.includes("not permitted for country BR") &&
                    availableCountries.includes("BR")
                  ) {
                    dispatch(logout());
                    window.location.reload();
                    return;
                  }
                  (window as any).showToast(`/api/v1/user/me: \n ${reason?.message}`);
                  dispatch(
                    setPhase({
                      phase: "NOT_IN_COUNTRY",
                    }),
                  );
                  dispatch(initialize());
                  return;
                }
                errorHandler(reason, dispatch);
                dispatch(logout());
                window.location.reload();
              });
          })
          .catch((reason: any) => {
            (window as any).showToast(`/api/v1/info: \n ${reason?.message}`);
            if (reason.message?.includes("no campaign for")) {
              dispatch(
                setPhase({
                  phase: "NOT_IN_COUNTRY",
                }),
              );
              setInitialized(true);
              dispatch(initialize());
              return;
            }
            errorHandler(reason, dispatch);
          });
      },
    };
    (window as Client).shakewin?.requestAccessToken(!!store("force"));
  }, []);
};
