import { useCallback } from "react";
import { useSelector } from "../store";

export const useShakes = () => {
  const xhr = useSelector((state) => state.data.xhr);
  return useCallback(
    (param: {
      callback?: (res?: any) => any;
      onError?: (error: any) => any;
      recaptcha?: string;
    }) => {
      const { recaptcha, callback, onError } = param;
      xhr
        .postShakes({ recaptcha })
        .then(callback)
        .catch((e: any) => {
          if (typeof onError === "function") {
            onError(e);
          }
          console.error(e);
        });
    },
    [xhr],
  );
};
