type ClientType = "H5" | "ofa" | "mini" | "news";

const production = process.env.REACT_APP_MODE === "production";
export const browserName = (window as Client).shakewin?.getClientName
  ? (window as Client).shakewin?.getClientName()?.includes("mini")
    ? "Opera Mini"
    : "Opera"
  : "Opera";
// const clientCountry = (window as Client).shakewin?.getClientLocalization() || "";
const getClientName: () => ClientType = () => {
  if (!(window as Client).shakewin) {
    if ((window as Client).news_data_h5) {
      return "news";
    }
    return "H5";
  }
  const name = (window as Client).shakewin?.getClientName() + "";
  if (name?.includes("mini")) {
    return "mini";
  }
  return "ofa";
};

const availableCountiesMap: { [key: string]: string[] } = {
  H5: ["NG", "KE", "ZA", "BR"],
  ofa: ["BR"],
  mini: ["NG", "KE", "ZA", "GH"],
  news: ["NG", "KE", "ZA"],
};

const clientName = getClientName();
const availableCountries: string[] = production
  ? availableCountiesMap[clientName] || ["NG"]
  : ["NG", "GH", "KE", "ZA", "US"];
const usableCountries: string[] = production
  ? [...availableCountries, "CN", "HK", "PL", "NO", "SE", "GB"]
  : [...availableCountries, "CN", "HK", "PL", "NO", "SE", "GB"];
const PENDING_TIME = 999999999999;

export const isBeta = Date.now() / 1000 < 1698361200;

// const browser = ((window as Client).shakewin?.getClientName() + "").toLowerCase();
const getCampaignTime = (country: string) => {
  let countryCode: string = country;
  const AF_START = new Date("2024-08-14T08:00:00Z").getTime() / 1000;
  const AF_CLAIM = new Date("2024-09-11T08:00:00Z").getTime() / 1000; // 2024-9-11
  const BR_START = 1700733600;
  const AF_END = new Date("2024-09-25T08:00:00Z").getTime() / 1000; // 2024-09-20
  const timePointData: {
    [key: string]: {
      start: number;
      claimOnly: number;
      end: number;
    };
  } = {
    NG: {
      start: production ? AF_START : 0,
      claimOnly: AF_CLAIM,
      end: production ? AF_END : AF_END,
    },
    KE: {
      start: production ? AF_START : 0,
      claimOnly: AF_CLAIM,
      end: production ? AF_END : AF_END,
    },
    ZA: {
      start: production ? AF_START : 0,
      claimOnly: AF_CLAIM,
      end: production ? AF_END : AF_END,
    },
    GH: {
      start: production ? AF_START : 0,
      claimOnly: AF_CLAIM,
      end: production ? AF_END : AF_END,
    },
    US: {
      start: production ? AF_START : 0,
      claimOnly: AF_CLAIM,
      end: production ? AF_END : AF_END,
    },
    BR: {
      start: production ? BR_START : 0,
      claimOnly: 1,
      end: PENDING_TIME,
    },
    DEFAULT: {
      start: production ? BR_START : 0,
      claimOnly: PENDING_TIME,
      end: PENDING_TIME,
    },
  };

  if (!availableCountries.includes(countryCode) || !timePointData[countryCode]) {
    countryCode = "DEFAULT";
  }

  return timePointData[countryCode];
};

const getTheme: (param: string) => ThemeData = (country: string) => {
  const countryCode: string = country;
  const themeIdMap: {
    [key: string]: string;
  } = {
    NG: "default",
    ZA: "default",
    GH: "default",
    KE: "default",
    BR: "default",
  };
  const key = themeIdMap[countryCode] || "default";
  const themeData: {
    [key: string]: ThemeData;
  } = {
    af: {
      name: "af",
      afterLoginUrl: "/prizes",
      shakingUrl: "/shaking",
      backgroundColor: `linear-gradient(162.44deg, #1F0353 0%, #3D0354 100%)`,
      backgroundImage: `url(${require("../assets/images/theme/br2_bg.jpg")})`,
      buttonColor: "rgb(255, 185, 6)",
      buttonFontColor: "#191919",
      menuColor: "#623D90",
      secondaryBackgroundColor: "#431F70",
      inputBackgroundColor: "rgb(67, 31, 112)",
      inputFontColor: "#ffffff",
      placeholder: "light",
      brandBackgroundColor: "#FFFFFF33",
      formBackground: "linear-gradient(180deg, #9E1942 0%, #5B001C 100%)",
      shakingIcon: require("../assets/images/afcon/shaking.png"),
      borderColor: "#284D3D",
      highlightBackground: "#007744",
    },
    giveaway: {
      name: "giveaway",
      afterLoginUrl: "/giveaway",
      shakingUrl: "/giveaway",
      backgroundColor: `linear-gradient(180deg, #9E1942 0%, #5B001C 100%)`,
      backgroundImage: `url(${require("../assets/images/theme/bg_giveaway.jpg")})`,
      buttonColor: "#0E823F",
      buttonFontColor: "#fff",
      menuColor: "#6A0020",
      secondaryBackgroundColor: "#6A0020",
      inputBackgroundColor: "#6A0020",
      inputFontColor: "#ffffff",
      placeholder: "light",
      brandBackgroundColor: "#C3245480",
      formBackground: "linear-gradient(180deg, #9E1942 0%, #5B001C 100%)",
      shakingIcon: require("../assets/images/giveaway/gift_box.png"),
      borderColor: "#eee",
      highlightBackground: "#007744",
    },
    default: {
      name: "default",
      afterLoginUrl: "/prizes",
      shakingUrl: "/shaking",
      backgroundColor: `linear-gradient(162.44deg, #1F0353 0%, #3D0354 100%)`,
      backgroundImage: `url(${require("../assets/images/theme/br2_bg.jpg")})`,
      buttonColor: "rgb(255, 185, 6)",
      buttonFontColor: "#191919",
      secondaryBackgroundColor: "#431F70",
      inputBackgroundColor: "rgb(67, 31, 112)",
      inputFontColor: "#ffffff",
      menuColor: "#623D90",
      placeholder: "light",
      brandBackgroundColor:
        "linear-gradient(0deg, rgba(89, 102, 173, 0.5), rgba(89, 102, 173, 0.5))",
      shakingIcon: require("../assets/images/shake_win_br2/shaking_phone.png"),
      borderColor: "#623D91",
      highlightBackground: "#007744",
    },
  };
  return (
    themeData[key] ||
    ({
      name: "default",
      afterLoginUrl: "/prizes",
      shakingUrl: "/shaking",
      backgroundColor: `linear-gradient(162.44deg, #1F0353 0%, #3D0354 100%)`,
      backgroundImage: `url(${require("../assets/images/theme/bg_giveaway.jpg")})`,
      buttonColor: "rgb(255, 185, 6)",
      buttonFontColor: "#191919",
      secondaryBackgroundColor: "#431F70",
      inputBackgroundColor: "rgb(67, 31, 112)",
      inputFontColor: "#ffffff",
      menuColor: "#623D90",
      placeholder: "light",
      brandBackgroundColor:
        "linear-gradient(0deg, rgba(89, 102, 173, 0.5), rgba(89, 102, 173, 0.5))",
      shakingIcon: require("../assets/images/shake_win_br2/shaking_phone.png"),
      borderColor: "#623D91",
      highlightBackground: "#007744",
    } as ThemeData)
  );
};

const selectableLanguageItems: Map<Languages, { name: string }> = new Map([
  ["en", { name: "English" }],
  ["pt-BR", { name: "Português - BR" }],
]);

const getDefaultLanguage: (lang?: string) => Languages = (cachedLang?: string) => {
  if (["pt", "pt-BR", "en"].includes(cachedLang || "")) {
    return cachedLang as Languages;
  }
  if (["pt", "pt-BR", "en"].includes(window.navigator.language)) {
    return window.navigator.language as Languages;
  }
  return "en";
};

const getPhase: (code: string) => Phase = (country: string) => {
  const { start, claimOnly, end } = getCampaignTime(country);
  const now = Date.now() / 1000;
  let phase: Phase = "PENDING";
  if (start === PENDING_TIME) {
    return phase;
  }
  if (start - now >= 0) {
    phase = "NOT_START";
  }
  if (now - start > 0) {
    phase = "IN_PROGRESS";
  }
  if (now - claimOnly > 0) {
    phase = "CLAIM_ONLY";
  }
  if (now - end > 0) {
    phase = "FINISHED";
  }
  return phase;
};

const oprVersion = (window as Client)?.shakewin?.getClientVersion
  ? (window as Client)?.shakewin?.getClientVersion() || ""
  : window?.navigator.userAgent
      .split(" ")
      .find((el) => el.includes("OPR/"))
      ?.split("/")[1] || "0";

export {
  availableCountries,
  usableCountries,
  getTheme,
  getPhase,
  getCampaignTime,
  getClientName,
  getDefaultLanguage,
  production,
  selectableLanguageItems,
  oprVersion,
  clientName,
};
