import React, { useEffect, useState } from "react";
import styles from "./styles/prizes.module.scss";
import { PrizeCard } from "../components/PrizeCard";
import { clearNewGift } from "../store/state";
import { useDispatch } from "react-redux";
import { useSelector } from "../store";
// import { Warning } from "../components/Warning";
import { Footer } from "../components/Footer";
import { useTranslation } from "react-i18next";
import { setDot } from "../utils/dot";
import { setGaDot } from "../utils/gaDot";
import PromMini from "components/promotion/Mini";
// import { useUtmParamNavigate } from "../utils/utmParamNavigate";

export const Prizes: React.FC = () => {
  const dispatch = useDispatch();
  // const navigate = useUtmParamNavigate();
  // const shakes = useSelector((state) => state.data.shakes);
  const winnable: Reward[] = useSelector((state) => state.data.winnablePrizes);
  const won: Reward[] = useSelector((state) => state.data.wonPrizes);
  // const loggedIn: boolean = useSelector((state) => state.data.loggedIn);
  const theme = useSelector((state) => state.data.theme);
  const [tab, setTab] = useState("winnable");
  const [prizeDom, setPrizeDom] = useState(<div></div>);
  const { t } = useTranslation();
  // const navigate = useUtmParamNavigate();

  // set the gift number to zero when going to gifts page
  useEffect(() => {
    setDot("pv", "pv_reward_list_page", "", "");
    setGaDot({ action: "pv_reward_list_page", value: {} });
    dispatch(clearNewGift());
  }, []);

  // handle the tab switch
  useEffect(() => {
    if (tab === "winnable") {
      setPrizeDom(
        <>
          {Array.isArray(winnable)
            ? winnable.map((prize: Reward, index) => {
                return (
                  <div
                    key={prize?.id + "_rewards_" + index}
                    style={{
                      borderTop: index ? "1px solid rgba(255,255,255,0.15)" : "",
                    }}
                  >
                    <PrizeCard prize={prize} />
                  </div>
                );
              })
            : []}
        </>,
      );
    } else {
      setPrizeDom(
        won.length ? (
          <>
            {won.map((prize, index) => {
              return (
                <div
                  key={prize?.id + "_rewards_" + index}
                  style={{
                    borderTop: index ? "1px solid rgba(255,255,255,0.15)" : "",
                  }}
                >
                  <PrizeCard prize={prize} />
                </div>
              );
            })}
          </>
        ) : (
          <div
            style={{
              textAlign: "center",
              margin: "24px",
            }}
          >
            <img
              style={{
                width: "44px",
                height: "44px",
              }}
              src={require("../assets/images/error_white.png")}
              alt=""
            />
            <div
              style={{
                marginTop: "8px",
                color: "#ffffff",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "20px",
              }}
            >
              {t("noPrizeYet")}
            </div>
          </div>
        ),
      );
    }
  }, [winnable, won, tab]);

  return (
    <div
      className={styles["main"]}
      style={{ background: theme.backgroundImage, backgroundPosition: "center" }}
    >
      <div className={styles["empty-header"]}></div>
      {/* <div
        style={{
          display: theme.name === "af" ? "block" : "none",
          textAlign: "center",
          marginTop: "16px",
        }}
      >
        <img
          src={require("../assets/images/afcon/challenge_header.png")}
          style={{ maxWidth: "328px" }}
          alt=""
          onClick={() => {
            navigate("/stats");
          }}
        />
      </div> */}
      <div className={styles["inventory"]}>
        {/* {loggedIn ? null : <Warning noBg={true} margin={"0 auto 8px"} />} */}
        <PromMini className={styles.prom} />
        <div className={styles["tabs"]}>
          <div
            className={styles["tab"] + " " + (tab === "winnable" ? styles["chosen-tab"] : "")}
            onClick={() => {
              setTab("winnable");
            }}
          >
            {t("availablePrizes")}
          </div>
          <div
            className={styles["tab"] + " " + (tab === "won" ? styles["chosen-tab"] : "")}
            onClick={() => {
              setTab("won");
            }}
          >
            {t("won")}
          </div>
        </div>
        <div
          className={styles["container"]}
          style={{
            background:
              (tab === "won" && won.length) || (tab === "winnable" && winnable.length)
                ? theme.secondaryBackgroundColor
                : "",
            border:
              (tab === "won" && won.length) || (tab === "winnable" && winnable.length)
                ? `1px solid ${theme.menuColor}`
                : "",
            borderRadius:
              (tab === "won" && won.length) || (tab === "winnable" && winnable.length)
                ? "12px"
                : "",
          }}
        >
          {prizeDom}
        </div>
      </div>
      <Footer />
    </div>
  );
};
