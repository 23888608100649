const pendingReward: Reward = {
  id: "",
  type: "pending",
  title: "",
  prizeTypeId: "",
  subtitle: "",
  brandLogo: "",
  height: 1,
  width: 1,
  wonPuzzlePieces: [],
  giftPicture: "",
  brandName: "",
  name: "",
};
const noneReward: Reward = {
  id: "",
  type: "none",
  title: "",
  prizeTypeId: "",
  subtitle: "",
  brandLogo: "",
  height: 1,
  width: 1,
  wonPuzzlePieces: [],
  giftPicture: "",
  brandName: "",
  name: "",
};

const noneReward3: Reward = {
  id: "",
  type: "none",
  title: "You didn’t get anything this time!",
  prizeTypeId: "",
  subtitle: "Try again tomorrow!",
  brandLogo: "",
  height: 1,
  width: 1,
  wonPuzzlePieces: [],
  giftPicture: require("../assets/images/giveaway/no_prize_3.png"),
  brandName: "",
  name: "",
};

const noneReward1: Reward = {
  id: "",
  type: "none",
  title: "The reindeer ate your gift!",
  prizeTypeId: "",
  subtitle: "Try again tomorrow!",
  brandLogo: "",
  height: 1,
  width: 1,
  wonPuzzlePieces: [],
  giftPicture: require("../assets/images/giveaway/no_prize_1.png"),
  brandName: "",
  name: "",
};

const noneReward2: Reward = {
  id: "",
  type: "none",
  title: "Father Christmas lost your gift!",
  prizeTypeId: "",
  subtitle: "Try again tomorrow!",
  brandLogo: "",
  height: 1,
  width: 1,
  wonPuzzlePieces: [],
  giftPicture: require("../assets/images/giveaway/no_prize_2.png"),
  brandName: "",
  name: "",
};

export { pendingReward, noneReward, noneReward1, noneReward2, noneReward3 };
