import React, { useCallback, useEffect, useState } from "react";
import styles from "./styles/rules.module.scss";
import { SWCheckbox } from "../components/SWCheckbox";
import { SWButton } from "../components/SWButton";
import { Link } from "react-router-dom";
import { useUtmParamNavigate } from "../utils/utmParamNavigate";
import store from "store2";
import { setDot } from "../utils/dot";
import { setGaDot } from "../utils/gaDot";
import { Trans, useTranslation } from "react-i18next";

/**
 * @description The page to display rules.
 * @author ziwenw@opera.com
 * @since 31/08/2022
 */
export const Rules: React.FC = () => {
  const navigate = useUtmParamNavigate();
  const { t } = useTranslation();
  const [confirmedAge, setConfirmedAge] = useState<boolean>(false);
  const [confirmedTerm, setConfirmedTerm] = useState<boolean>(false);
  const [confirmButton, setConfirmButton] = useState(<div></div>);
  const goToLogin = useCallback(() => {
    const enabled = confirmedAge && confirmedTerm;

    const tmp = ((window as Client).shakewin ? store("shake") : store("shake")) || 0;
    if ((window as Client).shakewin) {
      store("shake", tmp + 1);
    } else {
      store("shake", tmp + 1);
    }

    if (enabled) {
      navigate("/login/verify");
    }
  }, [confirmedAge, confirmedTerm]);

  useEffect(() => {
    setDot("pv", "pv_age_gate_page", "", "");
    setGaDot({ action: "pv_age_gate_page", value: {} });
  }, []);

  useEffect(() => {
    const enabled = confirmedAge && confirmedTerm;
    setConfirmButton(
      <div
        style={{
          margin: "32px",
        }}
      >
        <SWButton
          text={t("next")}
          disabled={!enabled}
          background={"#FFB906"}
          color={"#000000"}
          disabledBackground={"rgba(255, 255, 255, 0.1)"}
          disabledColor={"rgba(255, 255, 255, 0.5)"}
          size={"medium"}
          fontSize={"14px"}
          fontWeight={600}
          callback={goToLogin}
        />
      </div>,
    );
  }, [confirmedAge, confirmedTerm]);
  return (
    <div className={styles.main}>
      <div className={styles.age_restriction}>
        <span>18+</span>
      </div>
      <h2>{t("18ToContinue")}</h2>
      <ul className={styles.statements}>
        <li>
          <div
            onClick={() => {
              setConfirmedAge((state) => !state);
            }}
          >
            <SWCheckbox checked={confirmedAge} />
          </div>
          <span>{t("confirmAge")}</span>
        </li>
        <li>
          <div
            onClick={() => {
              setConfirmedTerm((state) => !state);
            }}
          >
            <SWCheckbox checked={confirmedTerm} />
          </div>

          <span>
            <Trans
              components={{
                1: <Link to={"/tc"} />,
              }}
            >
              confirmCondition
            </Trans>
          </span>
        </li>
      </ul>
      {confirmButton}
    </div>
  );
};
