const fullScreenPath = [
  "/",
  "/shaking",
  "/tc",
  "/qa",
  "/verify",
  "/won",
  "/winnable",
  "/result",
  "/prizes",
  "/missions",
  "/rules",
  "/not-in-opera",
  "/prize/claim",
  "/tc/",
  "/qa/",
  "/verify/",
  "/won/",
  "/winnable/",
  "/result/",
  "/prizes/",
  "/missions/",
  "/rules/",
  "/not-in-opera/",
  "/prize/claim/",
  "/feedback",
  "/feedback/",
];
const noFooterPath = [
  "/tc",
  "/qa",
  "/prizes",
  "/missions",
  "/tc/",
  "/qa/",
  "/prizes/",
  "/missions/",
  "/",
  "/shaking",
  "/not-in-opera",
  "/login",
  "/login/",
  "/login/onetime",
  "/login/onetime/",
  "/profile",
  "/profile/",
  "/profile/password",
  "/profile/password/",
  "/select",
  "/select/",
  "/shakewin/callback",
  "/shakewin/callback/",
  "/winnable/options",
  "/winnable/options/",
  "/prize/demo",
  "/prize/demo/",
  "/welcome",
  "/welcome/",
  "/prize/claim/verify",
  "/prize/claim/verify/",
  "/stats",
  "/stats/",
];
const noHeaderPath = [
  "/welcome",
  "/welcome/",
  "/tc",
  "/qa",
  "/verify",
  "/rules",
  "/tc/",
  "/qa/",
  "/verify/",
  "/rules/",
  "/not-in-opera",
  "/not-in-opera/",
  "/login",
  "/login/",
  "/login/onetime",
  "/login/onetime/",
  "/profile/password",
  "/profile/password/",
  "/login/verify",
  "/login/verify/",
  "/select",
  "/select/",
  "/shakewin/callback",
  "/shakewin/callback/",
  "/prize/claim/verify",
  "/prize/claim/verify/",
  "/prize/claim",
  "/prize/claim/",
];
const listPath = ["/missions", "/prizes", "/missions/", "/prizes/"];

export { fullScreenPath, noHeaderPath, noFooterPath, listPath };
