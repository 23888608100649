import store from "store2";

export const setGaDot = (ga_param: any) => {
  const campaign = store("campaign");
  const countryCode = store("country") || store("country") || "not_detected";
  if (ga_param.action === "pv_reward_list_page" && !location.href.includes("gifts")) {
    return;
  }
  // @ts-ignore
  let clientName = "h5";
  // @ts-ignore
  if (typeof (window as any)?.shakewin?.getClientName === "function") {
    // @ts-ignore
    clientName = (window as any)?.shakewin?.getClientName();
    if ((clientName + "").toLowerCase().includes("mini")) {
      clientName = "mini-native";
    } else {
      clientName = "ofa-native";
    }
  }
  if ((window as any)?.news_data_h5) {
    clientName = "news";
  }
  const productObj = {
    // @ts-ignore
    product: clientName,
    campaign,
    country_code: countryCode,
  };
  const obj = Object.assign({}, ga_param.value, productObj);

  // @ts-ignore
  (window as any)?.ga4?.gtag("event", ga_param.action, {
    event_category: "shakeWin",
    ...obj,
  });
};
