import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setUserCountry, stopShaking } from "../store/state";
import { AppDispatch, useSelector } from "../store";
import store from "store2";
import { availableCountries } from "../config";

const onRouteUpdate = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const dispatchFunc = useDispatch<AppDispatch>();
  const isShaking = useSelector((state) => state.data.isShaking);
  const verified = useSelector((state) => state.data.verified);
  const xhr = useSelector((state) => state.data.xhr);
  const canChangeCountryCode = useSelector((state) => state.data.canChangeCountryCode);
  useEffect(() => {
    if (isShaking && location.pathname !== "/shaking" && location.pathname !== "/giveaway") {
      dispatch(stopShaking());
    }
  }, [location.pathname, isShaking]);

  useEffect(() => {
    if (
      !((window as Client).shakewin ? store("chosen_country") : store("chosen_country")) &&
      availableCountries.includes(store("selected_country")) &&
      canChangeCountryCode &&
      !verified &&
      xhr.headers?.Authorization
    ) {
      dispatchFunc(setUserCountry({ country: store("selected_country") }));
    }
  }, [canChangeCountryCode, verified, xhr]);
};

export default onRouteUpdate;
