import React from "react";
import { useSelector } from "../../store";

export const FailedShakeImage: React.FC = () => {
  // const theme = useSelector((state) => state.data.theme);
  const language = useSelector((state) => state.data.lang);
  const defaultFailureEn = (
    <img
      src={require("../../assets/images/shake_win_br2/no_prize_en.png")}
      alt=""
      style={{
        position: "relative",
        width: "100%",
        height: "auto",
      }}
    />
  );
  const defaultFailurePt = (
    <img
      src={require("../../assets/images/shake_win_br2/no_prize_pt.png")}
      alt=""
      style={{
        position: "relative",
        width: "100%",
        height: "auto",
      }}
    />
  );
  return language === "pt-BR" ? defaultFailurePt : defaultFailureEn;
  // return theme.name === "af" ? (
  //   <div
  //     style={{
  //       position: "relative",
  //       width: "100%",
  //       height: "auto",
  //     }}
  //   >
  //     <img
  //       style={{
  //         width: "100%",
  //         height: "auto",
  //       }}
  //       src={require("../../assets/images/afcon/failed_shake.png")}
  //       alt={""}
  //     />
  //     <img
  //       style={{
  //         width: "100%",
  //         height: "auto",
  //         position: "absolute",
  //         left: 0,
  //         top: 0,
  //       }}
  //       src={require("../../assets/images/afcon/failed_phone.png")}
  //       alt={""}
  //     />
  //   </div>
  // ) : theme.name === "default" ? (
  //   language === "pt-BR" ? (
  //     defaultFailurePt
  //   ) : (
  //     defaultFailureEn
  //   )
  // ) : (
  //   defaultFailureEn
  // );
};
