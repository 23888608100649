export const countries: { [key: string]: any } = {
  HK: {
    name: "Hong Kong",
    native: "Hong Kong",
    phone: "852",
    continent: "AF",
    capital: "Abuja",
    currency: "NGN",
    languages: ["en"],
    emoji: "🇳🇬",
    emojiU: "U+1F1F3 U+1F1EC",
    code: "HK",
    defaultAccountProvider: "Opay",
  },
  PL: {
    name: "Poland",
    native: "Poland",
    phone: "48",
    continent: "EU",
    capital: "Warsaw",
    currency: "PLN",
    languages: ["pl"],
    emoji: "🇳🇬",
    emojiU: "U+1F1F3 U+1F1EC",
    code: "PL",
    defaultAccountProvider: "Opay",
  },
  NL: {
    name: "Netherlands",
    native: "Netherlands",
    phone: "31",
    continent: "EU",
    capital: "Abuja",
    currency: "NGN",
    languages: ["en"],
    emoji: "🇳🇬",
    emojiU: "U+1F1F3 U+1F1EC",
    code: "NL",
    defaultAccountProvider: "Opay",
  },
  NO: {
    name: "Norway",
    native: "Norway",
    phone: "47",
    continent: "AF",
    capital: "Abuja",
    currency: "NGN",
    languages: ["en"],
    emoji: "🇳🇬",
    emojiU: "U+1F1F3 U+1F1EC",
    code: "NO",
    defaultAccountProvider: "Opay",
  },
  JP: {
    name: "Japan",
    native: "Japan",
    phone: "81",
    continent: "AF",
    capital: "Abuja",
    currency: "NGN",
    languages: ["en"],
    emoji: "🇳🇬",
    emojiU: "U+1F1F3 U+1F1EC",
    code: "JP",
    defaultAccountProvider: "Opay",
  },
  CN: {
    name: "China mainland",
    native: "China mainland",
    phone: "86",
    continent: "AS",
    capital: "Beijing",
    currency: "RMB",
    languages: ["zh"],
    emoji: "🇳🇬",
    emojiU: "U+1F1F3 U+1F1EC",
    code: "CN",
    defaultAccountProvider: "Opay",
  },
  SE: {
    name: "Sweden",
    native: "Sweden",
    phone: "46",
    continent: "AF",
    capital: "Abuja",
    currency: "NGN",
    languages: ["en"],
    emoji: "🇳🇬",
    emojiU: "U+1F1F3 U+1F1EC",
    code: "SE",
    defaultAccountProvider: "Opay",
  },
  GB: {
    name: "England",
    native: "England",
    phone: "44",
    continent: "AF",
    capital: "Abuja",
    currency: "NGN",
    languages: ["en"],
    emoji: "🇳🇬",
    emojiU: "U+1F1F3 U+1F1EC",
    code: "GB",
    defaultAccountProvider: "Opay",
  },
  NG: {
    name: "Nigeria",
    native: "Nigeria",
    phone: "234",
    continent: "AF",
    capital: "Abuja",
    currency: "NGN",
    languages: ["en"],
    emoji: "🇳🇬",
    emojiU: "U+1F1F3 U+1F1EC",
    code: "NG",
    defaultAccountProvider: "Opay",
  },
  KE: {
    name: "Kenya",
    native: "Kenya",
    phone: "254",
    continent: "AF",
    capital: "Nairobi",
    currency: "KES",
    languages: ["en", "sw"],
    emoji: "🇰🇪",
    emojiU: "U+1F1F0 U+1F1EA",
    code: "KE",
    defaultAccountProvider: "M-Pesa",
  },
  ZA: {
    name: "South Africa",
    native: "South Africa",
    phone: "27",
    continent: "AF",
    capital: "Pretoria",
    currency: "ZAR",
    languages: ["af", "en", "nr", "st", "ss", "tn", "ts", "ve", "xh", "zu"],
    emoji: "🇿🇦",
    emojiU: "U+1F1FF U+1F1E6",
    code: "ZA",
    defaultAccountProvider: "Paypal",
  },
  GH: {
    name: "Ghana",
    native: "Ghana",
    phone: "233",
    continent: "AF",
    capital: "Accra",
    currency: "GHS",
    languages: ["en"],
    emoji: "🇬🇭",
    emojiU: "U+1F1EC U+1F1ED",
    code: "GH",
    defaultAccountProvider: "MTN Money Mobile",
  },
  US: {
    name: "U.S.A",
    native: "U.S.A",
    phone: "1",
    continent: "NA",
    capital: "Accra",
    currency: "USD",
    languages: ["en"],
    emoji: "🇬🇭",
    emojiU: "U+1F1EC U+1F1ED",
    code: "US",
    defaultAccountProvider: "PayPal",
  },
  BR: {
    name: "Brasil",
    native: "Brasília",
    phone: "55",
    continent: "SA",
    capital: "Brasília",
    currency: "USD",
    languages: ["en", "pt"],
    emoji: "🇬🇭",
    emojiU: "U+1F1EC U+1F1ED",
    code: "BR",
    defaultAccountProvider: "PayPal",
  },
};

export const getCountriesList = (countriesCodeList: string[]) => {
  if (!countriesCodeList.length) {
    return countries;
  }
  let res = {};
  countriesCodeList.forEach((code) => {
    const country = {};
    // @ts-ignore
    country[code] = countries[code];
    // @ts-ignore
    country[code].code = code;
    // @ts-ignore
    res = { ...res, ...country };
  });
  return Object.keys(res).length ? res : countries;
};

export const getCountryPaymentProviders = (code: string) => {
  let providers = ["Opay"];
  switch (code) {
    case "GH":
      providers = ["MTN Money Mobile"];
      break;
    case "ZA":
      providers = ["PayPal", "Ozow", "PayU", "Prepaid24"];
      break;
    case "KE":
      providers = ["M-Pesa"];
      break;
    case "BR":
      providers = ["PayPal"];
      break;
  }

  return providers;
};
