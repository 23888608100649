import React, { useEffect, useState } from "react";
import { getCountriesList } from "../../utils/countries";
import { availableCountries } from "../../config";
import { SelectList } from "../SelectList";
import { useSelector } from "../../store";

interface CountryListProps {
  callback?: (key?: any) => unknown;
  title?: string;
  disabled?: boolean;
  background?: string;
  currentCountry?: string;
}

export const CountryList: React.FC<CountryListProps> = ({
  callback,
  title,
  disabled,
  background,
  currentCountry,
}) => {
  const countries = getCountriesList(availableCountries);
  const country = availableCountries.includes(useSelector((state) => state.data.country))
    ? useSelector((state) => state.data.country)
    : availableCountries[0];
  const [countryItem, setCountryItem] = useState(countries[country]);
  const [list, setList] = useState(<></>);
  const theme = useSelector((state) => state.data.theme);

  useEffect(() => {
    if (currentCountry) {
      setCountryItem(countries[currentCountry]);
      return;
    }
    setCountryItem(countries[country]);
  }, [country, currentCountry]);

  useEffect(() => {
    setList(
      <SelectList
        title={title}
        currentItem={countryItem}
        items={countries}
        type={"countries"}
        selectCb={(data) => {
          callback?.(data);
        }}
        background={background || theme.menuColor}
        color={"#ffffff"}
        placeholderColor={"rgba(255,255,255,0.5)"}
        theme={"dark"}
        disabled={!!disabled}
        key={country}
      />,
    );
  }, [countryItem, country]);

  return <>{list}</>;
};
