const en = {
  worldCup: {
    OverNPrizesToWinBR: "Win mobile data, Uber rides, iFood vouchers, movie tickets and more!",
    OverNPrizesToWin: "Win Mobile Phones, Cash Prizes and Airtime Vouchers!",
    enter: "Enter",
    continue: "Continue",
    selectYourCountry: "Select your country of residence",
    continueWithAccount: "Continue with your account",
    participating: "<1>{{amount}}</1> people are participating",
    betaInfo: "<1>Beta</1> Help us test - Extra prizes to win until 23/10/23!",
    logIn: "Log in",
    logOut: "Log out",
    shakingYourPhone: "<4><0><6>Shake</6><5/><2>your phone!</2></0></ 4>",
    shake: "Shake",
    earnMoreShakes: "Earn more shakes",
    useNextShake: "Use next shake",
    ranOutShakes: "You've run out of shakes.",
    hintToLogin: "You will lose your progress if you uninstall this app!",
    "18ToContinue": "You must be over 18 to continue",
    confirmAge: "I confirm I am over the age of 18",
    confirmCondition: "By verifying my account I agree to the <1>terms and conditions</1>",
    next: "Next",
    verifyYourDetails: "Verify your details",
    sendSMSToClaimYourPrize: "To claim your prize, we need to send a verification sms",
    enterPhoneNumber: "Enter phone number",
    sendSMS: "Send SMS",
    resendSMS: "Resend SMS",
    verificationSMS: "Verification SMS sent to:",
    enterSMSCode: "Enter code from SMS",
    verifyingCode: "Verifying code...",
    availablePrizes: "Available prizes",
    won: "Won",
    inProgress: "{{count}} in progress",
    howToClaim: "How to claim >",
    claimed: "Claimed",
    claimPrize: "Claim prize >",
    noPrizeYet: "You haven’t won any prizes yet",
    saveYourProgress: "Save your progress",
    findRemainingPieces: `Find the remaining {{count}} piece(s) to win!`,
    youFoundPiece: `<1>You found  <2>1 puzzle piece</2> of {{name}}</1>`,
    congratulations: "Congratulations!",
    keepShaking: "Keep shaking to find this prize!",
    verifyToClaim: "Verify to claim it",
    claimYourWonPrize: "Claim your won prize",
    claimStep: "How to claim your prize:",
    claimYourPrize: "Claim your prize",
    verifyToClaimIt: "Verify to claim it",
    takeDaysProcess: "Your claim may take 5 - 7 days to process",
    notWin: "You didn't win anything this time.",
    sorry: "Sorry!",
    duplicatedPiece: `Duplicate pieces count towards additional rewards`,
    footer: `<1><2>One account per user. Only valid claims will be rewarded. Promotion is valid only for individuals over 18 years old in the period from {{from}} to {{to}}. See participation conditions and complete regulations <3>here</3>. </2><4><5>Frequently asked questions</5> </4><6 /> <7>Need help? <8>Send feedback</8></7><10>Privacy policy</10></1>`,
    footerBR: `<1><2>One account per user. Only valid claims will be rewarded. Promotion certificate of Authorization {{certification}} is valid only for individuals over 18 years old from {{from}} to {{to}}. Promotion certificate of Authorization SRE/MF no. 02.031214/2023 is valid only for individuals over 18 years old from 23.11.2023 to 21.11.2024. See participation conditions and complete regulations <3>here</3>. Images for illustration purposes only.  </2><4><5>Frequently asked questions</5> </4><6 /> <7>Need help? <8>Send feedback</8></7><10>Privacy policy</10></1>`,
    oneAccountOnly:
      "One account per user. Only valid claims will be rewarded. {{countries}}. 18+. ",
    claimBefore: "Vouchers can only be used until {{expired}}.",
    unLocksIn: "Next shake unlocks in: ",
    availablePrizesCount:
      "<1><2/><3>Over <4>{{instantCount}}</4> prizes won by participants!</3></1>",
    referToAFriend: "Refer {{browser}} to a friend",
    referAFriend: "Refer a friend  >",
    referToAFriendText:
      "Get a free shake for every friend who plays Shake & Win using a verified phone number in {{browser}}.",
    makeDefault: "Make {{browser}} default",
    makeDefaultText:
      "Get a free shake when you set {{browser}} as your default browser. Your free shake will be awarded in 24 hours.",
    makeDefaultButton: "Make {{browser}} default  >",
    playOperaPredictor: "Play Opera Predictor",
    playOperaPredictorText:
      "Earn one free shake for playing Opera’s football predictor game. Predict the outcomes correctly to win $10,000!",
    playOperaPredictorButton: "Play Opera Predictor  >",
    with: "with",
    dailyFreeShake: "Daily free shake",
    referralMissionText: `<1>Share your unique referral link with your friends and you’ll earn a free shake each time one of them successfully installs <2>{{browser}}</2> and plays <3>Shake & Win</3> and verifies their phone number for the first time.</1>`,
    uniqueReferralLink: "Your unique referral link:",
    youEarnedShakes: `<1>You’ve earned <2>{{count}}</2> shake(s) so far!</1>`,
    copy: "Copy",
    copied: "Copied",
    "fetching...": "fetching...",
    setDefaultStep: "{{product}}-> ⚙️ Settings -> Set as default browser",
    setDefaultStepResult:
      "Free shake from Set {{product}} Default mission will be granted in less than 24h after {{product}} is set as the default browser.",
    earnedAShake: "You've earned a shake!",
    tapToUseIt: "Tap here to use it",
    orTapHereToShake: "or tap here to shake",
    followTheseSteps: "Follow these steps",
    seeAvailablePrizes: "See available prizes",
    dataPlan: "You'll need an active data plan to complete verification",
    completeVerificationToClaim: "Complete your verification to claim your prize.",
    wonPrizes: "Won Prizes",
    endNotification: `<1>Thank you for playing <2>Shake & Win!</2>We've reached the end of this year's campaign. All completed prizes can still be claimed before {{ endDate }}. Stand by for news on <3>Shake & Win’s</3> return. Watch this space!</1>`,
    "You're currently logged out. Log In to see your prizes.":
      "You're currently logged out. Log In to see your prizes.",
    "Browser unsupported": "Browser unsupported",
    "Opera Browser is required to play Shake and Win":
      "Opera Mini or Opera News is required to play Shake and Win",
    "Get Opera": "Get Opera",
    "How do I take part": "How do I take part",
    "Go to shopee.com.br": "Go to shopee.com.br",
    "How do I claim my prize?": "How do I claim my prize?",
    STEP: "STEP {{x}}",
    "Download the Opera Mini browser": "Download the {{x}} browser",
    "Tap on the green 'Shake' button": "Tap on the green 'Shake' button",
    "Start shaking!": "Start shaking!",
    "You will lose your progress if you uninstall this app!":
      "You will lose your progress if you uninstall this app!",
    shoppingBefore: "Vouchers can only be used until {{date}}.",
    notInCountry: "Sorry, Shake & Win is not available in your country!",
    startIn: "Start in:",
    minutes: "Minutes",
    hours: "Hours",
    days: "Days",
    seconds: "Seconds",
    defaultBrowserMissionSuccess:
      "{{product}} is now your default browser. You have received a free shake",
    // form
    name: "Name",
    typeYourName: "Type your name",
    cpf: "CPF",
    email: "email",
    phone: "phone",
    "Enter your CPF": "Enter your CPF",
    "First and last name": "First and last name",
    "Country of residence": "Country of residence",
    "Phone Number": "Phone Number",
    "brand Phone Number": "{{brand}} Phone Number",
    "Enter phone number": "Enter phone number",
    "Enter brand phone number": "Enter {{brand}} phone number",
    "Email address": "Email address",
    "Enter your email": "Enter your email",
    "Account Provider": "Account Provider",
    accountName: "{{provider}} Account Name",
    accountNumber: "{{provider}} Account Number",
    "Alternative Phone Number": "Alternative Phone Number",
    "Enter alternative phone number": "Enter alternative phone number",
    "Delivery address": "Delivery address",
    "Enter your delivery address": "Enter your delivery address",
    state: "State",
    "Enter your state": "Enter your state",
    "Prize type": "Prize type",
    "Prize amount": "Prize amount",
    "Enter your account number or email": "Enter your account number or email",
    "Enter your account number": "Enter your account number",
    tryAgain: "Try again",
    "Are you sure?": "Are you sure?",
    "Please check that all your information is correct before submitting":
      "Please check that all your information is correct before submitting",
    "Edit details": "Edit details",
    "Submission failed": "Submission failed",
    "You need to verify your phone number to claim this reward":
      "You need to verify your phone number to claim this reward",
    "Get next shake": "Get next shake",
    "There was a problem submitting your details. Please try again.":
      "There was a problem submitting your details. Please try again.",
    cancel: "Cancel",
    submit: "Submit",
    // error
    "Already selected {{country}} as the default country, reinstall the app if you selected the wrong country":
      "Already selected {{country}} as the default country, reinstall the app if you selected the wrong country",
    "Failed to get CAPTCHA. Please check the network or try again.":
      "Failed to get CAPTCHA. Please check the network or try again.",
    "Quota exceeded, try later.": "Quota exceeded, try later.",
    "Too many requests, try later.": "Too many requests, try later.",
    "Phone number might invalid. Please double check.":
      "Phone number might invalid. Please double check.",
    "Session expired. Please retry.": "Session expired. Please retry.",
    "Sorry! The campaign is too popular at the moment. We're working to increase capacity, Previously-claimed prizes have been saved. Please try again later.":
      "Sorry! The campaign is too popular at the moment. We're working to increase capacity, Previously-claimed prizes have been saved. Please try again later.",
    "Please remove any leading zeros from your phone number. Example 0803****** should be 803*****":
      "Please remove any leading zeros from your phone number. Example 0803****** should be 803*****",
    "Invalid code.": "Invalid code.",
    "Server internal error or bad network. Please try later.":
      "Server internal error or bad network. Please try later.",
    "Invalid phone.": "Invalid phone.",
    "Please enter your {{var}}": `Please enter your {{var}}`,
    "Too many attempts, please try again later.": "Too many attempts, please try again later.",
    // end
    participantsWon: `<1/><2>Over <3>{{n}}</3> prizes won by users!</2>`,
    thanksMessage: `<1>Thank you for playing <2>Shake & Win</2>, we have reached the end of our campaign. All completed prizes can be claimed no later than 25/09/24.</1>`,
    thanksMessageBR: `<1>We have reached the end of our campaign. All prizes won must be claimed by 26/1/24. Stay alert for news about <2>Chacoalhe & Concorra</2>. Meanwhile, you can refer two friends to download Opera and get free internet!
        </1>`,
    "Vouchers can only be used until 5/1/23": "Vouchers can only be used until 5/1/23",
    screenshot: "Screenshot",
    country: "Country",
    telephone: "Telephone",
    emailAddress: "Email address",
    problemArea: "Problem area",
    summaryOfIssue: "Summary of issue",
    optional: "optional",
    "Enter your email so we can contact you": "Enter your email so we can contact you",
    "Select a category ": "Select a category ",
    "Upload a screenshot": "Upload a screenshot",
    "Describe the issue you are experiencing": "Describe the issue you are experiencing",
    sendFeedback: "Send feedback",
    "For quick help, please check our FAQ page before submitting feedback":
      "For quick help, please check our <1>FAQ page</1> before submitting feedback",
    "Account Verification": "Account Verification",
    Missions: "Missions",
    Prizes: "Prizes",
    Others: "Others",
    feedbackFormAcknowledgement:
      "By submitting this form you agree that we may contact you via the email address provided, only for issue resolution. We will not use your data for any other purpose. ",
    createAccount: "Create Account",
    signup: "Sign up",
    login: "Log in",
    rememberMe: "Remember me",
    forgotPassword: "Forgot password?",
    orLoginWith: "Or log in with",
    orSignupWith: "Or sign up with",
    Phone: "Phone",
    Email: "Email",
    confirmPassword: "Confirm password",
    Password: "Password",
    phoneNumber: "Phone number",
    forgotPasswordBtn: "Forgot password",
    newPassword: "New password",
    enterNewPassword: "Enter new password",
    enterPassword: "Enter password",
    updatePassword: "Update password",
    connectAccounts: "Connect Accounts",
    dateOfBirth: "Date of Birth",
    idNumber: "Id Number",
    completed: "Completed",
    goToNews: "Go to news >",
    findHiddenShakes: "Find hidden shakes",
    hiddenShakesDescription: "Read news stories to find up to {{count}} shakes every day",
    hiddenShakesHint:
      "Earn shakes by reading articles in the Opera news feed. There are {{count}} hidden shakes to find every day. You may have to read the entire article before you find the hidden shake - Good luck!",
    setDefaultHint:
      "Earn shakes by setting {{client}} as your default browser. Your free shake will be awarded in 24 hours.",
    shareWithYourFriends: "Share with your friends",
    chooseYourCountryOfResidence: "Choose your country of residence",
    selectCountry: "Select country",
    wonSharingTitle: "I won a prize from {{brandName}} on Shake&Win!",
    wonSharingMessage:
      "I just won {{rewardName}} on Shake&Win! You can play on https://shakewin.opera.com for a chance to win Mobile Phones, Cash Prizes and Airtime Vouchers!",
    enterYourPasswordFor: "Enter your password for:",
    enterYourPhoneNumberToContinue: "Enter your phone number to continue.",
    connectYourGoogleAccount: "Connect your Google account!",
    connectYourOperaAccount: "Connect your Opera account!",
    connectToGoogle: "Connect to Google",
    connectToOpera: "Connect to Opera",
    connectGoogleDescription: "Add your Google account to enable missions and earn more shakes!",
    youHaveAvailableShakes: "You have available shakes!",
    accountIsConnected: "This account has connected to phone number {{phoneNumber}}. Logging in...",
    wrongPassword: "Account or password is not invalid. Please check again.",
    redirectingToThirdPartyLogin: "Redirecting to third-party login...",
    requestingCredential: "Requesting the credential, please wait and do not reload the page...",
    "To continue, we need to send you a verification SMS.":
      "To continue, we need to send you a verification SMS.",
    share: "Share",
    countryOfResidence: "Country of residence",
    participantsWonBR: `<1/><2>Over <3>{{n}},000</3> in prizes won by users!</2>`,
    updateApp: "Update Opera {{client}}",
    updateAppText:
      "Update Opera {{client}} to the latest version in the Google Play Store and get a free shake",
    updateAppBtn: "Update {{client}}  >",
    bet9jAccountId: "Bet9ja account Id",
    walletAddress: "Wallet Address",
    updateRequired: "Update required",
    updateNow: "Update now",
    later: "Later",
    "Please ensure you have the latest version of our app to log in successfully. Update now for uninterrupted access and enhanced features!":
      "Please ensure you have the latest version of our app to log in successfully. Update now for uninterrupted access and enhanced features!",
    enterYourEmail: "Enter your email",
    "We'll send a one-time password to your email ":
      "We'll send a one-time password to your email ",
    loginViaGoogle: "Log in via Google",
    loginViaEmail: "Log in via Email",
    "earn data": "EARN DATA",
    referToAFriendHint: "Get 250MB free data for referring Opera Browser",
    referToAFriendDescription: "Refer 2 friends to Opera Browser and earn 250mb of free data",
    seeRewardOptions: "See reward options",
    setAWallpaper: "Set a Wallpaper in {{name}}",
    setAWallpaperDescription: "Change your wallpaper in {{browserName}}, earn a free shake.",
    setAWallpaperHint:
      "This free shake will only be awarded to changing your wallpaper. Use the button below to get started.",
    setAWallpaperBtn: "Set a wallpaper  >",
    claimYourFreeData: "Claim your free data  >",
    claimYourReward: "Claim your reward",
    "Get free data for referring your friends": "Get free data for referring your friends",
    "Refer 2 friends to Opera Browser and earn 250mb of free data!":
      "Refer 2 friends to Opera Browser and earn 250mb of free data!",
    gotIt: "Got it",
    makeDefaultHint:
      "Earn shakes by setting Opera as your default browser. Your free shake will be awarded in 24 hours.",
    updateAppHint:
      "This free shake will only be awarded to updates that happen through the Google Play Store. Use the button below to get started.",
    operaUpdateRequired: "{{client}} update required",
    "Get 250MB free data for referring Opera Browser":
      "Get 250MB free data for referring Opera Browser",
    openAGift: "Open a gift",
    playMoreGames: "Play more games",
    earn250Mb: "Earn 250mb Free Data",
    pleaseLogIn: "Please log in",
    "Log in to claim your rewards or earn free data":
      "Log in to claim your rewards or earn free data",
    close: "Close",
    phaseEnd: "This phase of the contest has ended. Your next free shake will be available in:",
    winFreeData: "Meanwhile you can still win {{amount}} free internet data",
  },
};

export default en;
