const pt = {
  worldCup: {
    OverNPrizesToWin: "Até R$70,000 em vale-compras para você concorrer",
    OverNPrizesToWinBR:
      "Ganhe internet grátis, corridas de Uber, iFood cards, ingressos de cinema e muito mais!",
    enter: "Começar",
    continue: "Continue",
    selectYourCountry: "Selecione seu país de residência",
    continueWithAccount: "Continue com sua conta",
    participating: "<1>{{amount}}</1> pessoas participando",
    logIn: "Entrar",
    shakingYourPhone: "<4><0><6>Chacoalhe</6><5/><2>seu telefone!</2></0></ 4>",
    shake: "Chacoalhe",
    earnMoreShakes: "Ganhe mais chacoalhos",
    useNextShake: "Use o seu próximo chacoalho",
    ranOutShakes: "Você já gastou todos os seus chacoalhos.",
    hintToLogin: "Você irá perder seus ganhos caso desinstale este app!",
    "18ToContinue": "Você precisa ter mais de 18 anos para continuar",
    confirmAge: "Confirmo que sou maior de 18 anos",
    confirmCondition: "Ao verificar minha conta eu concordo com os <1>termos e condições</1>",
    next: "Próximo",
    verifyYourDetails: "Verifique seus detalhes",
    sendSMSToClaimYourPrize: "Para resgatar seu prêmio, precisamos enviar uma sms para verificação",
    enterPhoneNumber: "Digite seu número de telefone",
    sendSMS: "Enviar SMS",
    resendSMS: "Reenviar sms",
    verificationSMS: "Verificação por SMS enviada para:",
    enterSMSCode: "Digitar código por SMS",
    verifyingCode: "Verificando código...",
    availablePrizes: "Prêmios disponíveis",
    won: "Ganho",
    inProgress: "{{count}} em andamento",
    howToClaim: "Requisitar prêmio >",
    claimed: "Reivindicada",
    claimPrize: "requisitar prêmio >",
    noPrizeYet: "Você ainda não ganhou nenhum prêmio",
    saveYourProgress: "Salve seu progresso",
    findRemainingPieces: `encontre os {{count}} pedaços remanescentes para ganhar!`,
    youFoundPiece: `<1>você encontrou  <2>1 (um) pedaço</2> de {{name}}</1>`,
    congratulations: "Parabéns!",
    keepShaking: "Continue chacoalhando para encontrar este prêmio!",
    verifyToClaim: "Verifique seus detalhes",
    verifyToClaimIt: "Verifique seus detalhes",
    claimYourWonPrize: "Resgate seu prêmio",
    claimStep: "Como requisitar seu prêmio:",
    claimYourPrize: "Resgate seu prêmio",
    takeDaysProcess: "Sua solicitação pode levar entre 5 a 7 dias para ser processada",
    notWin: "Você não ganhou nada desta vez.",
    sorry: "Desculpe!",
    duplicatedPiece: `Dobre a quantia de peças para prêmios extras`,
    footer: `<1><2>Apenas uma conta por usuário.</2><4><5>Perguntas Frequentes</5> </4><6 /> <7>Precisa de ajuda? <8>Enviar feedback</8></7><10>Política de privacidade</10></1>`,
    footerBR: `<1><2>Apenas uma conta por usuário. Somente pedidos válidos serão recompensados. Promoção Certificado de Autorização {{certification}} é válida apenas para pessoas físicas maiores de 18 anos de {{from}} a {{to}}. Promoção Certificado de Autorização SRE/MF nº. 02.031214/2023 é válida desde 23.11.2023 a 21.11.2024. Consulte condições de participação e regulamento completo <3>aqui</3>. Imagens meramente ilustrativas.</2><4><5>Perguntas Frequentes</5> </4><6 /> <7>Precisa de ajuda? <8>Enviar feedback</8></7><10>Política de privacidade</10></1>`,
    oneAccountOnly: `Apenas uma conta por usuário. Somente pedidos válidos serão recompensados. `,
    claimBefore: "Os prêmios podem ser resgatados até o dia {{expired}}.",
    unLocksIn: "Próximo chacoalho será desbloqueado em: ",
    availablePrizesCount:
      "<1><2/><3>Mais de <4>{{instantCount}}</4> ganhos por participantes!</3></1>",
    referToAFriend: "Indique {{browser}} a um amigo",
    referAFriend: "Indique um amigo  >",
    referToAFriendText:
      "Ganhe um chacoalho grátis para cada amigo que jogar Chacoalhe e Concorra usando um número de telefone verificado no Navegador {{browser}}.",
    makeDefault: "Tornar {{browser}} padrão",
    makeDefaultText:
      "Ganhe um chacoalho grátis quando configurar {{browser}} como seu navegador padrão. O próximo chacoalho será dado em 24 horas.",
    makeDefaultButton: "Tornar {{browser}} padrão  >",
    with: "com",
    playOperaPredictor: "Play Opera Predictor",
    playOperaPredictorText:
      "Earn one free shake for playing Opera’s football predictor game. Predict the outcomes correctly to win $10,000!",
    playOperaPredictorButton: "Play Opera Predictor  >",
    dailyFreeShake: "Chacoalho grátis diário",
    referralMissionText: `<1>Compartilhe seu link único de referência com amigos e você receberá um chacoalho grátis toda vez que um deles instalar com sucesso <2>{{browser}}</2> e verificar o número de telefone depois de jogar <3>Chacoalhe e Concorra</3> pela primeira vez.</1>`,
    uniqueReferralLink: "Seu link de referência único:",
    youEarnedShakes: `<1>Você ganhou <2>{{count}}</2> chacoalho (s) até agora!</1>`,
    copy: "Copy",
    copied: "Copied",
    setDefaultStep: "{{product}}-> ⚙️ Configurações -> Navegador padrão",
    setDefaultStepResult:
      "O shake grátis da missão padrão Set {{product}} será concedido em menos de 24h após {{product}} ser definido como o navegador padrão.",
    "fetching...": "buscando...",
    followTheseSteps: "Siga esses passos",
    seeAvailablePrizes: "Veja os prêmios disponíveis",
    dataPlan: "You'll need an active data plan to complete verification",
    completeVerificationToClaim: "Conclua sua verificação para reivindicar seu prêmio.",
    wonPrizes: "Ganho",
    endNotification: `<1>Obrigado por jogar <2>Chacoalhe e Concorra!</2>chegamos ao final da nossa campanha. Todos os prêmios completos podem ser requisitados até antes de {{ endDate }}. Fique alerta para novidades sobre <3>Chacoalhe e Concorra</3>. Acompanhe por aqui!</1>`,
    "You're currently logged out. Log In to see your prizes.": "Entre para conferir seus prêmios.",
    "Browser unsupported": "Navegador não suportado",
    "Opera Browser is required to play Shake and Win":
      "Um navegador Opera é necessário para participar do Chacoalhe & Concorra",
    "Get Opera": "Baixe o Opera",
    "How do I take part": "Como eu participo",
    "How do I claim my prize?": "Para resgatar seu prêmio:",
    "Go to shopee.com.br": "Vá até shopee.com.br",
    STEP: "PASSO {{x}}",
    "Download the Opera Mini browser": "Baixe o Navegador {{x}}",
    "Tap on the green 'Shake' button": "Clique no botão chacolhar",
    "Start shaking!": "Comece a chacolhar!",
    "You will lose your progress if you uninstall this app!":
      "Você perderá seu progresso se desinstalar este aplicativo!",
    earnedAShake: "Você ganhou um chacoalho!",
    tapToUseIt: "Tap here to use it",
    orTapHereToShake: "ou clique aqui para chacoalhar",
    shoppingBefore:
      "Os vale-compras podem ser utilizados apenas até o dia {{date}} e somente uma vez",
    notInCountry: "Desculpe, Chachoalhe & Concorra não está disponível no seu país",
    startIn: "Começa em:",
    minutes: "Minutos",
    hours: "Horas",
    days: "Dias",
    seconds: "Segundos",
    defaultBrowserMissionSuccess:
      "{{product}} agora é seu navegador padrão. Você recebeu um shake grátis",
    // form
    "First and last name": "First and last name",
    name: "Nome",
    email: "email",
    "Email Address": "Endereço de email",
    phone: "telefone",
    typeYourName: "Digite seu nome",
    "Country of residence": "Country of residence",
    "Phone Number": "Telefone",
    "brand Phone Number": "{{brand}} Telefone",
    "Enter phone number": "Digite telefone",
    "Enter brand phone number": "Digite {{brand}} telefone",
    "e.g. john@company.com": "Digite seu email",
    "Account Provider": "Account Provider",
    accountName: "{{provider}} Account Name",
    accountNumber: "{{provider}} Account Number",
    "Enter your CPF": "Digite seu CPF",
    "Alternative Phone Number": "Alternative Phone Number",
    "Enter alternative phone number": "Enter alternative phone number",
    "Delivery address": "Delivery address",
    "Enter your delivery address": "Enter your delivery address",
    state: "State",
    "Enter your state": "Enter your state",
    "Prize type": "Prize type",
    "Prize amount": "Prize amount",
    submit: "Enviar",
    tryAgain: "Tente novamente",
    "Are you sure?": "Tem certeza?",
    "Please check that all your information is correct before submitting":
      "Verifique se todas as suas informações estão corretas antes de enviar",
    "Edit details": "Edite seus dados",
    "Submission failed": "Falha ao enviar",
    "You need to verify your phone number to claim this reward":
      "You need to verify your phone number to claim this reward",
    "There was a problem submitting your details. Please try again.":
      "Ocorreu um problema ao enviar seus dados. Por favor, tente novamente.",
    "Get next shake": "Get next shake",
    cancel: "Cancel",
    "Submitted successfully": "Enviado com sucesso!",
    "Order date:": "Data do pedido:",
    "Thank you for submitting your details. Your claim may take 5 - 7 days to process.":
      "Obrigado por enviar seus dados. Sua reivindicação pode levar de 5 a 7 dias para ser processada.",
    // error
    "Already selected {{country}} as the default country, reinstall the app if you selected the wrong country":
      "Já selecionado {{country }} como país padrão, reinstale o app caso tenha selecionado o país errado",
    "Failed to get CAPTCHA. Please check the network or try again.":
      "Erro ao capturar CAPTCHA. Por favor, verifique sua conexão ou tente novamente.",
    "Quota exceeded, try later.": "Cota excedida, tente novamente.",
    "Too many requests, try later.": "Muitos requerimentos, tente novamente.",
    "Phone number might invalid. Please double check.":
      "O número do telefone pode ser inválido. Por favor, verifique novamente.",
    "Session expired. Please retry.": "Sessão expirada. Tente novamente.",
    "Sorry! The campaign is too popular at the moment. We're working to increase capacity, Previously-claimed prizes have been saved. Please try again later.":
      "Desculpe! A campanha está muito popular neste momento. Estamos trabalhando para aumentar a capacidade, os prêmios já requeridos estão salvos. Por favor, tente novamente.",
    "Please remove any leading zeros from your phone number. Example 0803****** should be 803*****":
      "Por favor, remova quaisquer zeros do seu número de telefone. Por exemplo, 0803****** deve ser 803*****",
    "Invalid code.": "Código inválido.",
    "Server internal error or bad network. Please try later.":
      "Erro do servidor interno ou má conexão. Tente novamente, por favor.",
    "Invalid phone.": "Telefone inválido.",
    "Please enter your {{var}}": `Por favor, insira seu {{var}}`,
    "Too many attempts, please try again later.":
      "Muitas tentativas, por favor tente novamente mais tarde.",
    //end
    participantsWon: `<1/><2>Mais de <3>{{n}}</3> prêmios ganhos por!</2>`,
    thanksMessage: `<1>Obrigado por jogar <2>Chacoalhe & Concorra,</2> chegamos ao final da nossa campanha. Todos os prêmios completos podem ser requisitados até antes de 21/11/24. Fique alerta para novidades sobre <2>Chacoalhe & Concorra.</2> Acompanhe por aqui!
        </p>`,
    thanksMessageBR: `<1>Chegamos ao final da nossa campanha.Todos os prêmios ganhos devem ser resgatados até 26/01/24. Fique alerta para novidades sobre <2>Chacoalhe & Concorra.</2> Enquanto isso, você pode indicar dois amigos para baixar o Opera e ganhar internet grátis!
        </p>`,
    "Vouchers can only be used until 5/1/23":
      "Os vale-compras podem ser utilizados apenas até o dia 01/05/23",
    screenshot: "Imagem da tela reportada",
    country: "Country",
    telephone: "Telefone",
    emailAddress: "Endereço de email",
    problemArea: "Área do problema",
    summaryOfIssue: "Resumo do problema",
    optional: "opcional",
    "Enter your email so we can contact you": "Digite seu email",
    "Select a category ": "Selecione uma categoria ",
    "Upload a screenshot": "Upload da imagem da tela",
    "Describe the issue you are experiencing": "Descreva o problema que você está tendo",
    sendFeedback: "Enviar feedback",
    "For quick help, please check our FAQ page before submitting feedback":
      "Para uma ajuda rápida, por favor cheque nossa página de <1>Perguntas frequentes</1> antes de submeter sua dúvida.",
    "Account Verification": "Problemas de verificação de conta",
    Missions: "Missões",
    Prizes: "Prêmios",
    Others: "Outros",
    feedbackFormAcknowledgement:
      "Ao enviar este formulário, você concorda que possamos entrar em contato com você por meio do endereço de e-mail fornecido, apenas para resolução do problema relatado. Não usaremos seus dados para qualquer outra finalidade.",
    createAccount: "Criar conta",
    signup: "Cadastre-se",
    login: "Entrar",
    logOut: "Sair",
    rememberMe: "Lembrar-me",
    forgotPassword: "Esqueceu a senha?",
    orLoginWith: "Ou entre com",
    orSignupWith: "Ou cadastre-se com",
    Phone: "Telefone",
    Email: "Email",
    confirmPassword: "Confirmar senha",
    Password: "Senha",
    phoneNumber: "Telefone",
    forgotPasswordBtn: "Esqueceu a senha",
    newPassword: "Nova Senha",
    enterNewPassword: "Insira a nova senha",
    enterPassword: "Digite a senha",
    updatePassword: "Atualizar senha",
    connectAccounts: "Conectar conta",
    dateOfBirth: "Data de nascimento",
    idNumber: "Id Number",
    completed: "Concluído",
    goToNews: "Ir para notícias >",
    findHiddenShakes: "Encontre chacoalhos escondidos",
    hiddenShakesDescription: "Encontre {{count}} chacoalhos escondidos em artigos todos os dias",
    hiddenShakesHint:
      "Ganhe chacoalhos lendo artigos no feed de notícias do Opera. Existem {{count}} chacoalhos escondidos para encontrar todos os dias. Você terá que ler o artigo inteiro antes de encontrar o chacoalho escondido - Boa sorte!",
    setDefaultHint:
      "Ganhe chacoalhos definindo o {{client}} como seu navegador padrão. Seu chacoalho grátis será concedido em 24 horas.",
    shareWithYourFriends: "Compartilhe com seus amigos",
    chooseYourCountryOfResidence: "Escolha o seu país de residência",
    selectCountry: "Selecionar país",
    wonSharingTitle: "Ganhei um prêmio da {{brandName}} no Chacoalhe&Concorra!",
    wonSharingMessage:
      "Eu acabei de ganhar {{rewardName}} no Chacoalhe & Concorra. Você pode jogar aqui insert link e ter a chance de ganhar prêmios!",
    enterYourPasswordFor: "Digite sua senha para:",
    enterYourPhoneNumberToContinue: "Digite seu número de telefone para continuar.",
    connectYourGoogleAccount: "Conecte sua conta do Google!",
    connectYourOperaAccount: "Conecte sua conta do Opera!",
    connectToGoogle: "Conecte-se ao Google",
    connectToOpera: "Conecte-se ao Opera",
    connectGoogleDescription:
      "Adicione sua conta do Google para habilitar missões e ganhar mais shakes!",
    youHaveAvailableShakes: "Você tem Chacoalhos disponíveis!",
    accountIsConnected:
      "Esta conta foi conectada ao número de telefone {{phoneNumber}}. Fazendo login...",
    wrongPassword: "A conta ou senha é inválida. Por favor cheque novamente.",
    redirectingToThirdPartyLogin: "Redirecionando para login de terceiros...",
    requestingCredential: "Solicitando a credencial, aguarde e não recarregue a página...",
    "To continue, we need to send you a verification SMS.":
      "Para continuar, precisamos enviar uma sms para verificação",
    share: "Compartilhar",
    countryOfResidence: "País de residência",
    participantsWonBR: "<1/><2>Mais de <3>{{n}}</3> mil em prêmios ganhos!</2>",
    bet9jAccountId: "Bet9ja account Id",
    walletAddress: "Wallet Address",
    updateRequired: "Atualização necessária",
    updateNow: "Atualizar agora",
    later: "Mais tarde",
    "Please ensure you have the latest version of our app to log in successfully. Update now for uninterrupted access and enhanced features!":
      "Certifique-se de ter a versão mais recente do nosso aplicativo para fazer login com sucesso. Atualize agora para acesso ininterrupto e recursos aprimorados!",
    enterYourEmail: "Digite seu e-mail",
    "We'll send a one-time password to your email ":
      "Enviaremos uma senha de uso único para seu e-mail",
    loginViaGoogle: "Faça login por Google",
    loginViaEmail: "Faça login por e-mail",
    "earn data": "Ganhar INTERNET",
    referToAFriendHint: "Ganhe 250MB de internet grátis ao indicar o navegador Opera",
    referToAFriendDescription:
      "Indique 2 amigos para baixar o navegador Opera e ganhe 250MB de internet grátis",
    seeRewardOptions: "Veja opções de recompensa",
    setAWallpaper: "Defina um papel de parede no navegador {{name}}",
    setAWallpaperDescription:
      "Ganhe um chacoalho quando você colocar um papel de parede no navegador {{browserName}} pela primeira vez",
    setAWallpaperHint:
      "Esta chance gratuita só será concedida para alterar seu papel de parede. Use o botão abaixo para começar.",
    setAWallpaperBtn: "Defina um papel de parede  >",
    claimYourFreeData: "Resgate sua internet grátis  >",
    claimYourReward: "Resgate seu prêmio",
    "Get free data for referring your friends": "Ganhe internet grátis indicando seus amigos",
    "Refer 2 friends to Opera Browser and earn 250mb of free data!":
      "Indique 2 amigos para baixar o navegador Opera e ganhe 250MB de internet grátis!",
    gotIt: "Entendi",
    makeDefaultHint:
      "Ganhe chacoalhos definindo o Opera como seu navegador padrão. Seu chacoalho grátis será concedido em 24 horas.",
    updateAppHint:
      "Este um chacoalho grátis só será concedido às atualizações que acontecerem através da Google Play Store. Use o botão abaixo para começar.",
    operaUpdateRequired: "Atualização do {{client}} necessária",
    "Get 250MB free data for referring Opera Browser":
      "Ganhe 250MB de internet grátis ao indicar o navegador Opera",
    updateAppBtn: "Atualizar {{client}} >",
    earn250Mb: "Ganhe 250mb de internet grátis",
    pleaseLogIn: "Faça Login",
    "Log in to claim your rewards or earn free data":
      "Faça login para resgatar suas recompensas ou para ganhar internet grátis",
    close: "Fechar",
    phaseEnd: "Esta fase da promoção terminou. Seu próximo chacoalho grátis estará disponível em:",
    winFreeData: "Enquanto isso ganhe internet gratis",
  },
};

export default pt;
