import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./languages/en";
import pt from "./languages/pt";

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en,
      pt,
      "pr-BR": pt,
    },
    interpolation: {
      escapeValue: false,
    },
    fallbackLng: "en",
    debug: false,
    ns: ["worldCup"],
    defaultNS: "worldCup",
  })
  .then();

export default i18n;
