import React, { useEffect, useState } from "react";
import { SWInputBar } from "../components/SWInputBar";
import style from "./styles/feedback.module.scss";
import { SelectList } from "../components/SelectList";
import { useSelector } from "../store";
import { countries } from "../utils/countries";
import { FileUploader } from "../components/FileUploader";
import { SWTextArea } from "../components/SWTextArea";
import { SWButton } from "../components/SWButton";
import { SWFormResult } from "../components/SWFormResult";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const getClientName = () => {
  if (!(window as Client).shakewin) {
    return "WEB";
  }
  const name = (window as Client).shakewin?.getClientName() + "";
  if (name?.includes("mini")) {
    return "MINI_NATIVE";
  }
  return "OFA_NATIVE";
};

export const Feedback: React.FC = () => {
  const { t } = useTranslation();
  const FEEDBACK_AREAS = {
    accountVerification: {
      code: "accountVerification",
      name: t("Account Verification"),
    },
    missions: {
      code: "missions",
      name: t("Missions"),
    },
    prizes: {
      code: "prizes",
      name: t("Prizes"),
    },
    others: {
      code: "others",
      name: t("Others"),
    },
  };
  const xhr = useSelector((state) => state.data.xhr);
  const country = useSelector((state) => state.data.country);
  const theme = useSelector((state) => state.data.theme);
  const [userProvidedCountry, setUserProvidedCountry] = useState(country);
  const [userProvidedEmail, setUserProvidedEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [success, setSuccess] = useState(false);
  const [screenshot, setScreenshot] = useState<File | undefined>();
  const [summary, setSummary] = useState<string>("");
  const [phonePrefix, setPhonePrefix] = useState("");
  const [phoneBody, setPhoneBody] = useState("");
  const [userProvidedPhoneNumber, setUserProvidedPhoneNumber] = useState("");
  // @ts-ignore
  const [issueArea, setIssueArea] = useState(FEEDBACK_AREAS[Object.keys(FEEDBACK_AREAS)[0]]);
  const [enabled, setEnabled] = useState(false);

  const [form] = useState(new FormData());

  const chooseIssueArea = (code: string) => {
    // @ts-ignore
    setIssueArea(FEEDBACK_AREAS[code]);
  };

  useEffect(() => {
    setPhonePrefix(
      countries[userProvidedCountry]?.phone ? `+${countries[userProvidedCountry]?.phone}` : "",
    );
  }, [userProvidedCountry]);

  useEffect(() => {
    setUserProvidedPhoneNumber(`${phonePrefix} ${phoneBody}`);
  }, [phonePrefix, phoneBody]);

  useEffect(() => {
    if (userProvidedPhoneNumber) form.set("userProvidedPhoneNumber", userProvidedPhoneNumber);
    if (!userProvidedPhoneNumber) form.delete("userProvidedPhoneNumber");
    if (userProvidedCountry) form.set("userProvidedCountry", userProvidedCountry);
    if (!userProvidedCountry) form.delete("userProvidedCountry");
    if (summary) form.set("summary", summary);
    if (!summary) form.delete("summary");
    if (userProvidedEmail) form.set("userProvidedEmail", userProvidedEmail);
    if (!userProvidedEmail) form.delete("userProvidedEmail");
    form.set("problemArea", issueArea?.code);
    form.set("country", country);
    form.set("clientType", getClientName());
    if (screenshot) form.set("screenshot", screenshot || "");
    if (!screenshot) form.delete("screenshot");
    setEnabled(userProvidedCountry && phoneBody && issueArea && summary && country);
  }, [
    userProvidedPhoneNumber,
    country,
    summary,
    userProvidedEmail,
    issueArea,
    screenshot,
    userProvidedCountry,
    phoneBody,
  ]);

  const submit = () => {
    setEnabled(false);
    xhr
      .postFeedback(form)
      .then(() => {
        setSuccess(true);
      })
      .catch((err: any) => {
        console.error(err);
        setSuccess(false);
        setEnabled(true);
      })
      .finally(() => {
        setSubmitted(true);
      });
  };

  return (
    <>
      {submitted ? (
        <div>
          <SWFormResult
            success={success}
            successTitle={"Feedback submitted"}
            successText={
              "Thank you for submitting your feedback. The solution to this issue may already be available in our FAQ page."
            }
            failTitle={"Feedback failed"}
            failText={"Thank you for submitting your feedback though failed. Please try again."}
            toggleFunction={() => {
              setSuccess(false);
            }}
          />
        </div>
      ) : (
        <div className={style.feedback}>
          <div
            style={{
              background: theme.secondaryBackgroundColor,
              border: `1px solid ${theme.brandBackgroundColor}`,
              borderRadius: "8px",
              padding: "8px",
              fontSize: "14px",
              textAlign: "center",
              lineHeight: "22px",
              color: "#fff",
            }}
          >
            <Trans
              components={{
                1: <Link style={{ color: "#fff" }} to={"/qa"} />,
              }}
            >
              For quick help, please check our FAQ page before submitting feedback
            </Trans>
          </div>
          <div className={style.field}>
            <p>{t("country")}</p>
            <SelectList
              type={"countries"}
              selectCb={setUserProvidedCountry}
              placeholder={t("Select your country ") || "Select your country "}
              currentItem={countries[userProvidedCountry]}
              items={countries}
              background={theme.inputBackgroundColor}
              color={theme.inputFontColor}
              placeholderColor={theme.placeholder}
              theme={"dark"}
            />
          </div>
          <div className={style.field}>
            <p>{t("telephone")}</p>
            <SWInputBar
              type={"phone"}
              placeholder={t("Enter phone number")}
              inputTitle={phonePrefix}
              passDataFunction={setPhoneBody}
              background={theme.inputBackgroundColor}
              color={theme.inputFontColor}
              placeholderColor={theme.placeholder}
              keyword={t("phone") || "phone"}
              necessary={true}
            />
          </div>
          <div className={style.field}>
            <p>{`${t("emailAddress")} (${t("optional")})`}</p>
            <SWInputBar
              type={"email"}
              placeholder={t("Enter your email so we can contact you")}
              necessary={false}
              passDataFunction={setUserProvidedEmail}
              background={theme.inputBackgroundColor}
              color={theme.inputFontColor}
              placeholderColor={theme.placeholder}
              onError={
                !!userProvidedEmail &&
                (!userProvidedEmail.includes("@") ||
                  (userProvidedEmail.includes("@") &&
                    !userProvidedEmail.split("@")[1].includes(".")))
              }
              errorMessage={"Invalid email"}
            />
          </div>
          <div className={style.field}>
            <p>{t("problemArea")}</p>
            <SelectList
              type={"issues"}
              selectCb={chooseIssueArea}
              placeholder={t("Select a category ") || "Select a category "}
              items={FEEDBACK_AREAS}
              currentItem={issueArea}
              background={theme.inputBackgroundColor}
              color={"#fff"}
              placeholderColor={"rgba(255,255,255,0.5)"}
              theme={"dark"}
            />
          </div>
          <div className={style.field}>
            <p>{`${t("screenshot")} (${t("optional")})`}</p>
            <FileUploader
              type={"image/*"}
              setValueCb={setScreenshot}
              background={theme.inputBackgroundColor}
            />
          </div>
          <div className={style.field}>
            <p>{t("summaryOfIssue")}</p>
            <SWTextArea
              setValue={setSummary}
              background={theme.inputBackgroundColor}
              color={"#fff"}
              theme={"dark"}
              necessary
            />
          </div>
          <div
            className={style.field}
            style={{ fontSize: "12px", lineHeight: "16px", fontWeight: 400 }}
          >
            {t("feedbackFormAcknowledgement")}
          </div>
          <div className={style.btn_field}>
            <SWButton
              text={t("submit")}
              background={theme.buttonColor}
              disabledBackground={"rgba(255, 255, 255, 0.2)"}
              color={theme.buttonFontColor}
              disabledColor={"rgba(255, 255, 255, 0.5)"}
              size={"medium"}
              callback={submit}
              disabled={!enabled}
            />
          </div>
        </div>
      )}
    </>
  );
};
