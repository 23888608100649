import React, { FC } from "react";
import styles from "./index.module.scss";

const EndClaim: FC = () => {
  return (
    <div className={styles.box}>
      <p>
        Thank you for playing <span>Shake & Win</span>, we have reached the end of our campaign.{" "}
      </p>
      <p>
        <img src={require("../../assets/images/info.png")} alt="" />
        <span>Completed prizes must be claimed by 25/09/24</span>
      </p>
    </div>
  );
};

export default EndClaim;
