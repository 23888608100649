import React, { useEffect } from "react";
import { SWButton } from "../../components/SWButton";
import { useSelector } from "../../store";
import { useUtmParamNavigate } from "../../utils/utmParamNavigate";
import store from "store2";
import { useTranslation } from "react-i18next";

export const WelcomePage: React.FC = () => {
  const theme = useSelector((state) => state.data.theme);
  const navigate = useUtmParamNavigate();
  const { t, i18n } = useTranslation();
  const country = useSelector((state) => state.data.country);
  const welcomeImage: { [key: string]: string } = {
    NG: require("../../assets/images/afcon/welcome.png"),
    KE: require("../../assets/images/afcon/welcome.png"),
    ZA: require("../../assets/images/afcon/welcome.png"),
    BR:
      i18n.language === "pt-BR"
        ? require("../../assets/images/br_2023_q4/welcome_puzzle_pt.png")
        : require("../../assets/images/br_2023_q4/welcome_puzzle.png"),
  };
  const welcomeText: {
    [key: string]: {
      title: string;
      text: string;
    };
  } = {
    NG: {
      title: "Opera Cup Complete!",
      text: "Congratulations Nigeria, you completed the most missions and have unlocked extra prizes for your nation",
    },
    KE: {
      title: "Opera Cup Complete!",
      text: "Congratulations Kenya, you completed the most missions and have unlocked extra prizes for your nation",
    },
    ZA: {
      title: "Opera Cup Complete!",
      text: "Congratulations South Africa, you completed the most missions and have unlocked extra prizes for your nation",
    },
    BR: {
      title: t("Get free data for referring your friends"),
      text: t("Refer 2 friends to Opera Browser and earn 250mb of free data!"),
    },
  };
  useEffect(() => {
    store("welcomed", true);
  }, []);
  return (
    <div
      style={{ minHeight: "100vh", textAlign: "center", overflow: "auto", position: "relative" }}
    >
      <img
        src={welcomeImage[country] || ""}
        style={{
          display: "block",
          margin: "125px 16px 16px 16px",
          width: "calc(100% - 32px)",
          height: "auto",
          boxSizing: "content-box",
        }}
        alt=""
      />

      <h2 style={{ margin: "8px 16px", fontSize: "16px", lineHeight: "22px" }}>
        {welcomeText[country]?.title || "Activity complete!"}
      </h2>
      <p style={{ margin: "8px 16px", fontSize: "12px", lineHeight: "16px", color: "#FFFFFFBF" }}>
        {welcomeText[country]?.text || "Thanks for playing Shake&Win!"}
      </p>
      <div
        style={{
          position: "absolute",
          width: "calc(100vw - 32px)",
          height: "48px",
          bottom: "16px",
          left: "16px",
        }}
      >
        <SWButton
          text={t("gotIt")}
          background={theme.buttonColor}
          color={theme.buttonFontColor}
          callback={() => {
            navigate("/shaking");
          }}
        />
      </div>
    </div>
  );
};
