import React from "react";
import { useSelector } from "../store";

interface LogoProp {
  size?: "BIG" | "MEDIUM" | "SMALL";
}

export const SWLogo: React.FC<LogoProp> = ({ size }) => {
  const lang = useSelector((state) => state.data.lang);
  const SIZE: "BIG" | "MEDIUM" | "SMALL" = size || "BIG";
  const logos: { [lang: string]: string } = {
    en: require("../assets/images/shake_win_br2/shake_win_logo.png"),
    br: require("../assets/images/shake_win_br2/shake_win_logo_br.png"),
    "pt-BR": require("../assets/images/shake_win_br2/shake_win_logo_br.png"),
    pt: require("../assets/images/shake_win_br2/shake_win_logo_br.png"),
    giveaway: require("../assets/images/giveaway/logo.png"),
  };
  const theme = useSelector((state) => state.data.theme);
  const index = theme.name?.toLowerCase() || lang;
  return (
    <img
      style={{
        width: "auto",
        height: SIZE === "BIG" ? "96px" : SIZE === "MEDIUM" ? "72px" : "50px",
        display: "block",
        margin: "0 auto",
      }}
      src={logos[index] || require("../assets/images/shake_win_br2/shake_win_logo.png")}
      alt=""
    />
  );
};
