import React, { useEffect, useState } from "react";
import styles from "./styles/claim_form.module.scss";
import { SWButton } from "../components/SWButton";
import { useUtmParamNavigate } from "../utils/utmParamNavigate";
import { useTranslation } from "react-i18next";

type FormStatus = "unknown" | "success" | "fail";

export const SWFormResult: React.FC<{
  success: boolean;
  prize?: Reward;
  claimedDate?: string;
  toggleFunction?: () => void;
  successTitle: string;
  successText: string;
  failTitle: string;
  failText: string;
}> = (props) => {
  const navigate = useUtmParamNavigate();
  const { t } = useTranslation();
  const [status, setStatus] = useState<FormStatus>("unknown");
  const [dom, setDom] = useState(<div></div>);
  const [success] = useState(props.success);
  const [prize] = useState(props.prize);
  const toggleFunction = props.toggleFunction;
  useEffect(() => {
    setStatus(success ? "success" : "fail");
  }, []);

  useEffect(() => {
    switch (status) {
      case "fail":
        setDom(
          <div className={styles.result}>
            <img src={require("../assets/images/fail.png")} alt="" className={styles.result_icon} />
            <br />
            <h3 className={styles.result_title_fail}>{props.failTitle}</h3>
            <p className={styles.result_text_fail}>{props.failText}</p>
            <SWButton
              text={t("tryAgain") || "Try again"}
              disabled={false}
              background={"#FFB906"}
              disabledBackground={""}
              color={"#000000"}
              disabledColor={""}
              size={"medium"}
              callback={() => {
                if (toggleFunction) toggleFunction();
              }}
            />
          </div>,
        );
        break;
      case "success":
        setDom(
          <div className={styles.result}>
            <img
              src={require("../assets/images/success.png")}
              alt=""
              className={styles.result_icon}
            />
            <br />
            <h3 className={styles.result_title_success}>{props.successTitle}</h3>
            <p className={styles.result_text_fail}>{props.successText}</p>
            {prize?.id ? (
              <SWButton
                text={"OK"}
                disabled={false}
                background={"#FFB906"}
                disabledBackground={""}
                color={"#000000"}
                disabledColor={""}
                size={"medium"}
                callback={() => {
                  navigate(prize ? `/won?prize_id=${prize.id}` : "/gifts");
                }}
              />
            ) : null}
          </div>,
        );
        break;
      default:
        return;
    }
  }, [status]);
  return <div>{dom}</div>;
};
