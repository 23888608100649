import React from "react";

export const QaEnBR: React.FC = () => {
  const client = window as Client;
  const goToMail = () => {
    if (client.OperaFootballJsApi?.sendEmail) {
      client.OperaFootballJsApi.sendEmail(
        "sw-help@opera.com",
        "Requests and feedbacks for Opera S&W",
      );
      return;
    }
    window.location.href = "mailto:sw-help@opera.com";
  };
  return (
    <div
      style={{
        margin: "0 16px 0 16px",
        padding: "70px 0",
      }}
    >
      <h2
        style={{
          color: "#FFB906",
          fontWeight: 900,
          fontSize: "18px",
        }}
      >
        Frequently Asked Questions
      </h2>
      <section style={{ padding: "16px 0" }}>
        <h2
          style={{
            color: "#ffffff",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "18px",
          }}
        >
          {"I referred my friends, but I still haven't received the free internet bonus."}
        </h2>
        <p
          style={{
            color: "rgba(255,255,255,0.75)",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            marginTop: "16px",
          }}
        >
          You will receive your bonus when they play Shake and Win and verify their phone number.
          Once you have your bonus unlocked, {`don't`} forget to follow the steps to claim it. When
          you complete the steps, you will receive a confirmation SMS and email.
          <br />
          Note that each telco has specific limitations, including restrictions on accumulation with
          other promotions, plan types and a monthly bonus limit. The bonus is not valid for certain
          plans or services. See the detailed rules for each operator in the annex to the terms and
          conditions.
        </p>
      </section>
      <section style={{ padding: "16px 0" }}>
        <h2
          style={{
            color: "#ffffff",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "18px",
          }}
        >
          {"I'm not a Claro, Vivo or Tim customer, can I get the bonus?"}
        </h2>
        <p
          style={{
            color: "rgba(255,255,255,0.75)",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            marginTop: "16px",
          }}
        >
          No, only customers of these operators can receive the bonus.
        </p>
      </section>
      <section style={{ padding: "16px 0" }}>
        <h2
          style={{
            color: "#ffffff",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "18px",
          }}
        >
          {"How can I win my internet bonus?"}
        </h2>
        <p
          style={{
            color: "rgba(255,255,255,0.75)",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            marginTop: "16px",
          }}
        >
          You must nominate two friends to download Opera, they should participate in Shake and Win
          and verify their phones. Upon completing the mission, follow the steps to claim your
          bonus.
          <br />
          In the case of services provided by telcos, the general rules for the use of services
          include that the bonus is a reward for users of the main operators of any area code (Vivo,
          TIM and Claro), as long as the number is not blocked, deactivated or canceled, and is
          owned by an individual, not a company.
          <br /> As soon as the prize is claimed, the participant will automatically receive a
          confirmation SMS (message) on their cell phone. Each telco has Rules and Conditions for
          Using Rewards, which apply to each type of plan. If in doubt, consult the Annex to the
          terms and conditions and look for your telco and plan.
        </p>
      </section>
      <section style={{ padding: "16px 0" }}>
        <h2
          style={{
            color: "#ffffff",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "18px",
          }}
        >
          {"Are there limitations or restrictions for redeeming the internet bonus?"}
        </h2>
        <p
          style={{
            color: "rgba(255,255,255,0.75)",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            marginTop: "16px",
          }}
        >
          Each operator has specific limitations, including restrictions on accumulation with other
          promotions, plan types and a monthly bonus limit. The bonus is not valid for certain plans
          or services. See the detailed rules for each telco in the annex to the terms and
          conditions.
        </p>
      </section>
      <section style={{ padding: "16px 0" }}>
        <h2
          style={{
            color: "#ffffff",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "18px",
          }}
        >
          {"I won a prize and chose my reward, how can I use it?"}
        </h2>
        <p
          style={{
            color: "rgba(255,255,255,0.75)",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            marginTop: "16px",
          }}
        >
          The Rules and Conditions for Using Prizes are available in the annex to the terms and
          conditions, please consult the document if you have any questions about how to redeem your
          reward.
        </p>
      </section>
      <section style={{ padding: "16px 0" }}>
        <h2
          style={{
            color: "#ffffff",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "18px",
          }}
        >
          {`I have a verified account, but I've lost my progress!`}
        </h2>
        <p
          style={{
            color: "rgba(255,255,255,0.75)",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            marginTop: "16px",
          }}
        >
          {`It is possible that your account has been disconnected. Don't worry, your progress is
          still there, just use the login button on the homepage to verify your account again and
          see your old rewards. Alternatively, you can follow these steps:`}
        </p>
        <ul
          style={{
            color: "rgba(255,255,255,0.75)",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            marginTop: "16px",
            marginLeft: "16px",
          }}
        >
          <li>Using an Opera browser on an Android device, go to shakewin.opera.com/verify</li>
          <li>Select your country</li>
          <li>Tap the Shake button</li>
          <li>Tap the save progress button after you see your reward.</li>
          <li>Complete the verification process using the same phone number</li>
          <li>Check your available and “earned” prizes!</li>
        </ul>
      </section>
      <section style={{ padding: "16px 0" }}>
        <h2
          style={{
            color: "#ffffff",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "18px",
          }}
        >
          {`What does “in progress” mean?`}
        </h2>
        <p
          style={{
            color: "rgba(255,255,255,0.75)",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            marginTop: "16px",
          }}
        >
          {`This means you have not yet collected all the pieces required to claim a specific prize. You can see the number of pieces left to claim for that prize by tapping the name of the prize in the Available Prizes section.`}
        </p>
      </section>
      <section style={{ padding: "16px 0" }}>
        <h2
          style={{
            color: "#ffffff",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "18px",
          }}
        >
          {`What does “duplicate pieces count towards additional rewards” mean?`}
        </h2>
        <p
          style={{
            color: "rgba(255,255,255,0.75)",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            marginTop: "16px",
          }}
        >
          This means you can win more than one unit of that prize if you are able to collect more
          than one complete set of the required pieces. The more times you collect all the pieces,
          the more units you can claim.
        </p>
      </section>
      <section style={{ padding: "16px 0" }}>
        <h2
          style={{
            color: "#ffffff",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "18px",
          }}
        >
          I referred my friends but I did not use my referral link, will I still get the reward?
        </h2>
        <p
          style={{
            color: "rgba(255,255,255,0.75)",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            marginTop: "16px",
          }}
        >
          No. The only way to get the referral mission reward is to use your unique referral link.
          To get your unique link, simply visit the referral mission page and tap copy.
        </p>
      </section>
      <section style={{ padding: "16px 0" }}>
        <h2
          style={{
            color: "#ffffff",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "18px",
          }}
        >
          {"Why is my phone number invalid? Why didn't I receive my code?"}
        </h2>
        <p
          style={{
            color: "rgba(255,255,255,0.75)",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            marginTop: "16px",
          }}
        >
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          Why is my phone number invalid? Why didn't I receive my code? This usually happens if
          there is another Chacoalhe & Concorra account associated with your number. Please check
          that you have entered the correct phone number, that your phone line is active with your
          telco and that your network signal is live. This could also happen when too many people
          are trying to access Chacoalhe & Concorra at the same time. If the problem persists,
          please send us an email at{" "}
          <span
            style={{
              textDecoration: "underline",
              color: "#ffffff",
              fontWeight: "700",
            }}
            onClick={goToMail}
          >
            sw-help@opera.com
          </span>{" "}
          with the affected phone number for further investigation and possible resolution.
        </p>
      </section>
      <section style={{ padding: "16px 0" }}>
        <h2
          style={{
            color: "#ffffff",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "18px",
          }}
        >
          {"Why can't I find my prizes?"}
        </h2>
        <p
          style={{
            color: "rgba(255,255,255,0.75)",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            marginTop: "16px",
          }}
        >
          You’ll lose your progress and saved prizes if you uninstall your Opera browser before
          verifying your Chacoalhe & Concorra account.
        </p>
      </section>
    </div>
  );
};
