import { ajax } from "./axios";
import store from "store2";

export class Ajax {
  private readonly ajax;

  constructor() {
    this.ajax = ajax;
  }

  // 埋点
  // eslint-disable-next-line @typescript-eslint/ban-types
  setDot(params: {}): Promise<any> {
    const production = process.env.REACT_APP_MODE === "production";
    const test = process.env.REACT_APP_MODE !== "production";
    let url = "";
    if (production) {
      url = "https://api.operafootball.com/";
    }
    if (test) {
      url = "https://staging-football-api.ams.op-mobile.opera.com/";
    }

    return this.ajax.post(
      `${url}${store("goe") ? store("goe").toLowerCase() : "ng"}/en/v1/sports/user/dot`,
      {
        ...params,
      },
    );
  }
}
