import { Ajax } from "../api/axios-dot";
import store from "store2";

export const setDot = (action: string, event: string, param_name: string, param_value: string) => {
  if (event === "pv_reward_list_page" && !location.href.includes("gifts")) {
    return;
  }

  const ajax = new Ajax();

  const getUuid = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };

  if (!localStorage.getItem("uid")) {
    const uid = getUuid();
    localStorage.setItem("uid", uid);
  }

  // const country = localStorage.getItem("country") || "";

  let product = "others";

  // @ts-ignore
  if (window?.OperaFootballJsApi !== undefined) {
    // @ts-ignore
    product = "apex_football";
  } else {
    // @ts-ignore
    if (typeof window?.shakewin?.getClientName === "function") {
      // @ts-ignore
      // const clientName = window.shakewin?.getClientName();
      product = window?.shakewin?.getClientName();

      if ((String(product) || "").toLowerCase().includes("mini")) {
        product = "mini";
      } else {
        product = "ofa";
      }
      // alert(clientName);
    }
  }
  if (localStorage.getItem("productByTest")) {
    product = localStorage.getItem("productByTest") || "others";
  }

  let params = {
    country: store("chosen_country") || store("country") || "",
    language: "en",
    // @ts-ignore
    product: product,
    action: action,
    event: event,
    param_name: param_name || "product",
    param_value: param_value || product,
    user_id: localStorage.getItem("uid") || "",
  };

  if (product === "app") {
    // @ts-ignore
    let version = /\b(SPO\/)[\d|\.]+$/g
      .exec(window.navigator.userAgent)[0]
      .replace(/^(SPO\/)\b/g, "");
    version = version.replace(/[^\w+' ']/g, "");
    params = Object.assign({}, params, { version: version });
  }

  return ajax.setDot(params);
};
