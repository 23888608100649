import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "../store";
import styles from "./styles/shaking.module.scss";
import ShakeDetector from "shake-detector";
import { useUtmParamNavigate } from "../utils/utmParamNavigate";
import store from "store2";
import { addNewGift, shaking } from "../store/state";
import { setDot } from "../utils/dot";
import { setGaDot } from "../utils/gaDot";
import { Footer } from "../components/Footer";
import { Trans, useTranslation } from "react-i18next";
import { ShakingIcon } from "../components/ShakingIcon";
import { SWButton } from "../components/SWButton";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import PromMini from "components/promotion/Mini";

interface ShakingProps {
  name: string;
}

export const Shaking: React.FC<ShakingProps> = (props: ShakingProps) => {
  const navigate = useUtmParamNavigate();
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { t } = useTranslation();
  const client = window as Client;
  const shakes = useSelector((state: any) => state.data.shakes);
  const giftsAmount = useSelector((state: any) => state.data.shakeGifts);
  const xhr = useSelector((state) => state.data.xhr);
  const verified = useSelector((state) => state.data.verified);
  const loggedIn = useSelector((state) => state.data.loggedIn);
  const recaptchaRequired = useSelector((state) => state.data.recaptchaRequired);
  const isShaking = useSelector((state) => state.data.isShaking);
  const theme = useSelector((state) => state.data.theme);
  const [disabled, disable] = useState<boolean>(false);
  const shakeDetector = new ShakeDetector({
    threshold: 4,
    debounceDelay: 500,
  });

  const id = useState(props.name + "_rewarding_shaking")[0];

  useEffect(() => {
    if (store("autoShake")) {
      store("autoShake", false);
      onShake().then();
      return;
    }
  }, [store("autoShake")]);

  /*
   * Process the reward animation and post a shake to the server.
   * */
  const rewarding = useCallback(
    (body?: { recaptcha: string }) => {
      if (!loggedIn && store("shake") >= 1) {
        (window as any)?.showToast(
          "You've won a prize piece before as an anonymous user. Please log in to earn more!",
        );
        navigate("/login");
        return;
      }
      let user_status = "anno_user";
      if (verified) {
        user_status = "phone_verify";
      }
      // when shaking, some element in the background will be hidden
      const bg = document.getElementById(id);
      if (client.shakewin_report?.reportInteraction && loggedIn) {
        if (verified) {
          client.shakewin_report?.reportInteraction("shake_logged_in");
        } else {
          client.shakewin_report?.reportInteraction("shake_logged_in_social_only");
        }
      }
      dispatch(shaking());
      disable(true);
      if (bg) {
        xhr
          .postShakes(body?.recaptcha ? body : { recaptcha: "" })
          .then((res: any) => {
            const currentShakes = store("shake") || 0;
            const prize = res.prizeWon || {
              type: "none",
            };
            const resultId = res.shakeResultId;
            setDot("click", "shake_completed", "prize_won", res.prizeWon ? "1" : "0");
            setDot(
              "click",
              "shake_completed",
              "prize_type",
              res.prizeWon
                ? res.prizeWon.type === "puzzle" && !!res.prizeWon.prizeId
                  ? "puzzle prize"
                  : res.prizeWon.type
                : "null",
            );
            setDot(
              "click",
              "shake_completed",
              "puzzle_piece_id",
              res.prizeWon?.wonPuzzlePiece ? String(res.prizeWon?.wonPuzzlePiece) : "null",
            );
            setDot("click", "shake_completed", "user_status", user_status);
            setDot("click", "shake_completed", "", "");
            setGaDot({
              action: "shake_completed",
              value: {
                prize_won: res.prizeWon ? "1" : "0",
                prize_type: res.prizeWon
                  ? res.prizeWon.type === "puzzle" && !!res.prizeWon.prizeId
                    ? "puzzle prize"
                    : res.prizeWon.type
                  : "null",
                puzzle_piece_id: res.prizeWon?.wonPuzzlePiece || "null",
                user_status: user_status,
              },
            });
            // animation delay
            setTimeout(() => {
              if (prize.type !== "none") {
                dispatch(addNewGift());
              }
              bg.setAttribute("style", "bottom: 0;");
              setTimeout(() => {
                store("shake", currentShakes + 1);
                navigate("/result?result_id=" + resultId || "none");
              }, 600);
            }, 1500);
          })
          .catch((e: any) => {
            console.error(e);
            dispatch(shaking());
            setDot("click", "shake_completed", "prize_won", "0");
            setDot("click", "shake_completed", "prize_type", "null");
            setDot("click", "shake_completed", "puzzle_piece_id", "null");
            setDot("click", "shake_completed", "user_status", user_status);
            setDot("click", "shake_completed", "", "");
            setGaDot({
              action: "shake_completed",
              value: {
                prize_won: "0",
                prize_type: "null",
                puzzle_piece_id: "null",
                user_status: user_status,
              },
            });
            bg.setAttribute("style", "bottom: 0;");
            setTimeout(() => {
              navigate("/result?result_id=none");
            }, 600);
          })
          .finally();
      }
    },
    [giftsAmount, id, navigate, xhr, verified, loggedIn],
  );

  const onShake = useCallback(async () => {
    if (recaptchaRequired && executeRecaptcha) {
      const recaptcha = await executeRecaptcha("USER_SHAKE");
      if (shakes > 0 && !disabled && !isShaking) {
        rewarding({ recaptcha });
      }
      return;
    }
    if (shakes > 0 && !disabled && !isShaking) {
      rewarding();
    }
  }, [disabled, shakes, isShaking, executeRecaptcha, recaptchaRequired]);

  useEffect(() => {
    let user_status = "anno_user";
    if (verified) {
      user_status = "phone_verify";
    }
    setDot("pv", "pv_shake_page", "", "");
    setDot("pv", "pv_shake_page", "user_status", user_status);
    setGaDot({ action: "pv_shake_page", value: { user_status: user_status } });
  }, []);

  // The set a listener on shakes
  useEffect(() => {
    window.removeEventListener(ShakeDetector.SHAKE_EVENT, onShake);

    if (shakeDetector.requestPermission) {
      shakeDetector.requestPermission(document.documentElement).then(() => {
        shakeDetector.start();
        window.addEventListener(ShakeDetector.SHAKE_EVENT, onShake);
      });
    } else {
      shakeDetector.confirmPermissionGranted();
      shakeDetector.start();
      window.addEventListener(ShakeDetector.SHAKE_EVENT, onShake);
    }

    return function clear() {
      window.removeEventListener(ShakeDetector.SHAKE_EVENT, onShake);
      shakeDetector?.stop();
    };
  }, [dispatch, id, rewarding, shakeDetector, shakes, isShaking]);

  // @ts-ignore
  return (
    <div
      className={styles.main}
      style={{
        backgroundImage: theme.backgroundImage,
      }}
    >
      {shakes > 0 || disabled ? (
        <Trans
          components={{
            0: <div className={styles.header} />,
            1: <span />,
            2: <span style={{ fontWeight: "700", fontSize: "18px" }} />,
            3: <p />,
            4: (
              <div
                className={styles.shaking_title}
                style={{ visibility: disabled ? "hidden" : "visible" }}
              />
            ),
            5: <br />,
            6: <span style={{ fontWeight: "900", fontSize: "34px" }} />,
          }}
        >
          shakingYourPhone
        </Trans>
      ) : (
        <div className={styles.shaking_title}>
          <div className={styles.header}>
            <span>{t("ranOutShakes")}</span>
          </div>
          <div
            style={{
              margin: "16px",
            }}
          >
            <SWButton
              text={t("earnMoreShakes")}
              background={theme.buttonColor}
              color={theme.buttonFontColor}
              callback={() => {
                if (shakes === 0) {
                  navigate("/missions");
                  return;
                }
              }}
            />
          </div>
        </div>
      )}
      <div
        style={{
          display: shakes > 0 || isShaking ? "flex" : "none",
          justifyContent: "center",
        }}
      >
        <ShakingIcon />
      </div>
      <div className={styles.button}>
        <div
          style={{
            textAlign: "center",
            display: shakes ? "" : "none",
          }}
          onClick={() => {
            if (shakes === 0) {
              navigate("/missions");
              return;
            }
            onShake();
            return;
          }}
        >
          <span
            style={{
              background: theme.secondaryBackgroundColor,
              padding: "12px",
              display: "inline-block",
              borderRadius: "8px",
              color: "rgba(255, 255, 255, 0.75) ",
            }}
          >
            {t("orTapHereToShake")}
          </span>
        </div>
      </div>
      <PromMini />
      <div id={"recaptch-shaking"}></div>
      <div className={styles["transition-bg"]} id={id}>
        <div className={styles["purple-bg"]} style={{ background: theme.backgroundColor }}></div>
        <div className={styles["dark-bg"]}></div>
      </div>
      <Footer />
    </div>
  );
};
