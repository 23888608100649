import { useSelector } from "../store";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { setPuzzles, setWinnable, setWon } from "../store/state";
import { noHeaderPath } from "../constants/paths";
import { errorHandler } from "../api/errorHandler";
import { useTranslation } from "react-i18next";

export const getPrizes = () => {
  const IMG = require("../assets/images/transparent.png");
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const xhr = useSelector((state) => state.data.xhr);
  const initialized = useSelector((state) => state.data.initialized);
  const country = useSelector((state) => state.data.country);
  const puzzles: Reward[] = useSelector((state) => state.data.puzzles);
  const phase = useSelector((state) => state.data.phase);
  const verified = useSelector((state) => state.data.verified);
  const [winnable, setWinnablePrizes] = useState<Reward[]>([]);
  const [wonPrizes, setWonPrizes] = useState<Reward[]>([]);
  const [fetchedWonPrizes, setFetchedWonPrizes] = useState<boolean>(false);
  const [fetchedWinnablePrizes, setFetchedWinnablePrizes] = useState<boolean>(false);
  const [fetchedPuzzles, setFetchedPuzzles] = useState<boolean>(false);

  const getPrizeWinnable = useCallback(() => {
    if (phase !== "IN_PROGRESS") return;
    return xhr
      .getPrizesWinnable()
      .then((res: any) => {
        const winnablePrizes: Reward[] = Array.isArray(res?.prizesWinnable)
          ? res?.prizesWinnable?.map((prize: Prize) => {
              const reward: Reward = {
                id: prize.prizeId || "",
                prizeTypeId: prize.prizeTypeId || "",
                brandLogo: prize.thumbnailUrl,
                brandName: prize.brandName,
                name: prize.name,
                type: prize.type,
                title: prize.title,
                subtitle: prize.subtitle,
                giftPicture: prize.imageUrl || IMG,
                height: 0,
                width: 0,
                wonPuzzlePieces: prize.puzzlePieces || [],
                won: false,
                redirectButtonTitle: prize.redirectButtonTitle,
                redirectUrl: prize.redirectUrl,
                isClaimFormRequired: prize.isClaimFormRequired,
                isClaimed: prize.isClaimed,
                couponCode: prize.couponCode,
                expiresAt: prize.expiresAt
                  ? new Date(prize.expiresAt).toLocaleDateString("en")
                  : "05/01/24",
              };
              return reward;
            })
          : [];
        setWinnablePrizes(winnablePrizes);
      })
      .finally(() => {
        setFetchedWinnablePrizes(true);
      });
  }, [xhr, location.pathname, phase]);

  const getPrizeWon = useCallback(() => {
    // if (!verified) return;
    return xhr
      .getPrizesWon()
      .then((res: any) => {
        const wonPrizes: Reward[] = Array.isArray(res?.prizesWon)
          ? res?.prizesWon?.map((prize: Prize) => {
              const reward: Reward = {
                id: prize.prizeId || "",
                prizeId: prize.prizeId || "",
                brandName: prize.brandName,
                name: prize.name,
                prizeTypeId: prize.prizeTypeId || "",
                brandLogo: prize.thumbnailUrl,
                type: prize.type,
                title: prize.title,
                subtitle: prize.subtitle,
                giftPicture: prize.imageUrl || IMG,
                height: prize.type === "puzzle" ? 2 : 0,
                width: prize.type === "puzzle" ? 3 : 0,
                wonPuzzlePieces: prize.puzzlePieces || [],
                claimSteps: prize.claimSteps || [],
                won: true,
                prizeWonTime: prize.prizeWonTime || "",
                redirectButtonTitle: prize.redirectButtonTitle,
                redirectUrl: prize.redirectUrl,
                isClaimFormRequired: prize.isClaimFormRequired,
                isClaimed: prize.isClaimed,
                isVerifiedPhoneRequired: prize.isVerifiedPhoneRequired,
                couponCode: prize.couponCode,
                expiresAt: prize.expiresAt
                  ? new Date(prize.expiresAt).toLocaleDateString(i18n.language)
                  : i18n.language === "pt-BR"
                  ? "01/05/23"
                  : "05/01/23",
              };
              return reward;
            })
          : [];
        dispatch(
          setWon({
            wonPrizes,
          }),
        );
        setWonPrizes(wonPrizes);
      })
      .finally(() => {
        setFetchedWonPrizes(true);
      });
  }, [xhr, location.pathname, verified]);

  const getPuzzles = useCallback(() => {
    if (phase !== "IN_PROGRESS") return;
    return xhr
      .getPuzzles()
      .then((res: any) => {
        const userPuzzles: Reward[] = Array.isArray(res?.userPuzzles)
          ? res?.userPuzzles?.map((prize: Prize) => {
              const reward: Reward = {
                id: prize.prizeId || "",
                prizeTypeId: prize.prizeTypeId || "",
                brandLogo: prize.thumbnailUrl,
                brandName: prize.brandName,
                name: prize.name,
                type: prize.type || "puzzle",
                title: prize.title,
                subtitle: prize.subtitle,
                giftPicture: prize.imageUrl || IMG,
                height: prize.height || 2,
                width: prize.width || 3,
                wonPuzzlePieces: prize.puzzlePieces || [],
                progress: prize.progress || {
                  current: prize.puzzlePieces?.length || 0,
                  total: 6,
                },
                claimSteps: prize.claimSteps || [],
                redirectButtonTitle: prize.redirectButtonTitle,
                redirectUrl: prize.redirectUrl,
                isClaimFormRequired: prize.isClaimFormRequired,
                isClaimed: prize.isClaimed,
                couponCode: prize.couponCode,
                won: (prize.progress?.current || 0) >= (prize.progress?.total || 0),
                expiresAt: prize.expiresAt
                  ? new Date(prize.expiresAt).toLocaleDateString(i18n.language)
                  : "",
              };
              return reward;
            })
          : [];
        dispatch(
          setPuzzles({
            puzzles: userPuzzles,
          }),
        );
      })
      .finally(() => {
        setFetchedPuzzles(true);
      });
  }, [xhr, location.pathname, phase]);

  const connectPrizeData = useCallback(() => {
    const newPuzzles: Reward[] = puzzles.map((reward: Reward) => {
      const wonPuzzlesLength = wonPrizes.filter((el: Reward) => {
        return el.prizeTypeId === reward.prizeTypeId && el.isClaimed;
      }).length;
      const wonPuzzlePieces: PuzzlePiece[] = [];
      reward.wonPuzzlePieces.forEach((el) => {
        const piece = {
          count: el.count - wonPuzzlesLength,
          id: el.id,
        };
        wonPuzzlePieces.push(piece);
      });
      const puzzlePieces = wonPuzzlePieces.filter((el) => {
        return el.count > 0;
      });
      const progress: Progress = {
        total: reward.progress?.total || 6,
        current: puzzlePieces.length || 0,
      };

      return {
        ...reward,
        wonPuzzlePieces: puzzlePieces,
        progress,
      };
    });

    const tmpWinnable: Reward[] = winnable.map((reward: Reward) => {
      const puzzle = newPuzzles.find((el: Reward) => {
        return el.prizeTypeId === reward.prizeTypeId;
      });
      const tmp: Reward | undefined = puzzle ? { ...puzzle } : undefined;
      if (puzzle && tmp) {
        const length = puzzle?.progress?.total || 0;
        if (length >= 9) {
          tmp.width = 3;
          tmp.height = 3;
        } else if (length >= 6) {
          tmp.width = 3;
          tmp.height = 2;
        } else if (length >= 4) {
          tmp.width = 2;
          tmp.height = 2;
        } else if (length >= 2) {
          tmp.width = 2;
          tmp.height = 1;
        } else {
          tmp.width = length;
          tmp.height = 1;
        }
      }

      return tmp?.wonPuzzlePieces?.length && tmp?.progress
        ? {
            ...reward,
            wonPuzzlePieces: puzzle?.wonPuzzlePieces || [],
            inProgress: Math.max(...tmp?.wonPuzzlePieces.map((el) => el.count)),
            width: tmp?.width || 1,
            height: tmp?.height || 1,
            progress: tmp?.progress,
          }
        : {
            ...reward,
            width: reward.type === "puzzle" ? 3 : 0,
            height: reward.type === "puzzle" ? 2 : 0,
            progress: {
              total: reward.type === "puzzle" ? 6 : 0,
              current: 0,
            },
          };
    });
    dispatch(
      setWinnable({
        winnablePrizes: tmpWinnable,
      }),
    );
  }, [xhr, puzzles, wonPrizes, location.pathname, verified]);

  useEffect(() => {
    if (noHeaderPath.includes(location.pathname)) {
      return;
    }
    if (phase === "NOT_START" || phase === "NOT_IN_COUNTRY") {
      return;
    }
    if (!initialized) {
      return;
    }
    if (!xhr.headers.Authorization || !xhr.headers["X-CountryCode"]) {
      return;
    }
    if (
      ["/rules", "/tc", "/qa", "/not-in-opera", "/login", "/login/verify"].includes(
        location.pathname,
      )
    )
      return;
    Promise.allSettled([getPrizeWinnable(), getPuzzles(), getPrizeWon()])
      .then()
      .catch((e: any) => {
        errorHandler(e, dispatch);
      });
  }, [xhr, initialized, country, location.pathname, phase]);

  useEffect(() => {
    if (fetchedWinnablePrizes && fetchedWonPrizes && fetchedPuzzles) {
      setFetchedPuzzles(false);
      setFetchedWinnablePrizes(false);
      setFetchedWonPrizes(false);
      connectPrizeData();
    }
  }, [
    fetchedWinnablePrizes,
    fetchedWonPrizes,
    fetchedPuzzles,
    puzzles,
    location.pathname,
    verified,
  ]);
};
