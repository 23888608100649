import React from "react";
import { useUtmParamNavigate } from "../utils/utmParamNavigate";

export const NotFound: React.FC = () => {
  const navigate = useUtmParamNavigate();
  return (
    <div
      style={{
        padding: "70px 16px",
        height: "calc(100vh - 200px)",
        textAlign: "center",
      }}
      onClick={() => {
        navigate("/");
      }}
    >
      <h3>404</h3>
      <p>{`${window.location.pathname} is not available. Tap the screen to go to homepage!`}</p>
    </div>
  );
};
