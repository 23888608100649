import React, { useEffect, useState } from "react";
import { useSelector } from "../store";
import { getCampaignTime } from "../config";
import { useTranslation } from "react-i18next";

export const StartCupCountDown: React.FC = () => {
  const country = useSelector((state) => state.data.country);
  const theme = useSelector((state) => state.data.theme);
  const { t } = useTranslation();
  const [startTime, setStartTime] = useState(getCampaignTime(country).start);
  const [secondsLeft, setSecondsLeft] = useState<number>(
    Math.round(startTime - new Date().getTime() / 1000),
  );
  const [dateLeft, setDateLeft] = useState<{
    seconds: number;
    minutes: number;
    hours: number;
    days: number;
    [key: string]: number;
  }>({
    seconds: 0,
    days: 0,
    minutes: 0,
    hours: 0,
  });

  useEffect(() => {
    setStartTime(getCampaignTime(country).start);
  }, [country]);
  useEffect(() => {
    const timer = setInterval(() => {
      setSecondsLeft(Math.round(startTime - new Date().getTime() / 1000));
      if (startTime - new Date().getTime() / 1000 < 0) {
        window.location.reload();
      }
    }, 500);
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (secondsLeft >= 0) {
      setDateLeft({
        days: Math.floor(secondsLeft / (3600 * 24)),
        hours: Math.floor((secondsLeft % (3600 * 24)) / 3600),
        minutes: Math.floor((secondsLeft % 3600) / 60),
        seconds: Math.floor(secondsLeft % 60),
      });
    }
  }, [secondsLeft]);

  return (
    <div
      style={{
        textAlign: "center",
        margin: "24px 16px",
      }}
    >
      <h2
        style={{
          fontSize: "16px",
          marginBottom: "16px",
        }}
      >
        {t("startIn")}
      </h2>
      <div
        style={{
          padding: "8px 12px 12px 12px",
          background: theme.menuColor,
          borderRadius: "0px 0px 8px 8px",
        }}
      >
        <div
          style={{
            height: "52px",
            marginTop: "12px",
          }}
        >
          {Object.keys(dateLeft).map((key) => (
            // eslint-disable-next-line react/jsx-key
            <div style={{ float: "left", textAlign: "center", width: "25%" }} key={key}>
              <div
                style={{
                  padding: "2px",
                  fontSize: "22px",
                  height: "22px",
                  lineHeight: "22px",
                  fontWeight: "900",
                }}
              >
                {dateLeft[key] < 10 ? `0${dateLeft[key]}` : `${dateLeft[key]}`}
              </div>
              <div
                style={{
                  fontSize: "12px",
                  padding: "2px",
                  marginTop: "4px",
                  fontWeight: "400",
                  color: "rgba(255, 255, 255, 0.75)",
                }}
              >
                {t(key)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
