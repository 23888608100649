import React from "react";
import { useTranslation } from "react-i18next";
import { QaPtBr } from "./Qa_pt_br";
import { QaEnBR } from "./Qa_en_br";
import { useSelector } from "../store";
import { QaEnAf } from "./Qa_en_af";

export const Qa: React.FC = () => {
  const { i18n } = useTranslation();
  const country = useSelector((state) => state.data.country);
  return (
    <>
      {country === "BR" ? (
        ["pt", "pt-BR"].includes(i18n.language) ? (
          <QaPtBr />
        ) : (
          <QaEnBR />
        )
      ) : (
        <QaEnAf />
      )}
    </>
  );
};
