import { useCallback } from "react";
import store from "store2";
import { useSelector } from "../store";
import { useUtmParamNavigate } from "./utmParamNavigate";
import { setDot } from "./dot";
import { setGaDot } from "./gaDot";
import accountProviders from "../constants/accountProviders";

export default function usePostClaim() {
  const verified = useSelector((state) => state.data.verified);
  const navigate = useUtmParamNavigate();
  const country = useSelector((state) => state.data.country);
  const xhr = useSelector((state) => state.data.xhr);
  const theme = useSelector((state) => state.data.theme);
  return useCallback(
    async (
      params: { prizeId: string; prizeType: string; data: { [key: string]: string } },
      isVerified = false,
    ) => {
      const { prizeId, prizeType, data } = params;
      (window as any).loadingLogin?.open("Claiming");
      let validProviders: string[] = [];
      let requiredKeys: string[] = [];
      try {
        const r: {
          schema: {
            properties: any;
            required: any;
          };
          claimedDate?: string;
          name?:
            | ""
            | "Other Claim form"
            | "Airtime Claim form"
            | undefined
            | "Cash Claim form ZA"
            | "Cash Claim form NG"
            | "Cash Claim form GH"
            | "Cash Claim form KE"
            | "MiniPay"
            | "bet9j";
        } = await xhr.getClaimForm({
          prize_type: prizeType,
          prize_id: prizeId,
        });
        const { schema } = r;
        setDot("pv", "claim_prize_start", "", "");

        setGaDot({
          action: "claim_prize_start",
          value: {},
        });
        requiredKeys = schema?.required || [];

        if (
          Array.isArray(schema?.properties?.accountProvider?.enum) &&
          schema?.properties?.accountProvider?.enum[0]
        ) {
          const tmp: {
            [key: string]: any;
          } = {};
          schema?.properties?.accountProvider?.enum.forEach((code: string) => {
            tmp[code] = accountProviders[code] || {};
          });
          validProviders = schema?.properties?.accountProvider?.enum;
        }
        if (country === "NG") {
          validProviders = ["Opay"];
        }

        if (country === "GH") {
          validProviders = ["MTN Mo Mo"];
        }

        if (country === "KE") {
          validProviders = ["MPesa"];
        }
        if (!isVerified) {
          if (!verified && country === "BR") {
            store("formCache", data);
            store("prizeType", prizeType);
            store("prizeId", prizeId);
            navigate("/login/verify");
            return;
          }
        }

        const validatedForm =
          !requiredKeys.find((key) => !Object.keys(data).includes(key)) &&
          (data.accountProvider ? validProviders.includes(data.accountProvider) : true);

        if (!validatedForm) {
          store("formCache", null);
          navigate(`/prize/claim?prize_id=${prizeId}&prize_type=${prizeType}`);
          return;
        }
        try {
          await xhr.postClaimForm(
            {
              prize_type: prizeType,
              prize_id: prizeId,
            },
            { data: data },
          );
          setDot("click", "claim_form_submitted", "status", "success");
          setDot("click", "claim_form_submitted", "", "");
          setGaDot({
            action: "claim_form_submitted",
            value: {
              status: "success",
            },
          });
          store("prizeType", "");
          store("prizeId", "");
          (window as any).loadingLogin?.close();
          navigate(params.prizeId ? `/won?prize_id=${params.prizeId}` : theme.afterLoginUrl);
        } catch (err: any) {
          setDot("click", "claim_form_submitted", "status", "failed");
          setDot("click", "claim_form_submitted", "", "");
          setGaDot({
            action: "claim_form_submitted",
            value: {
              status: "failed",
            },
          });
          store("formCache", null);
          console.error(err);
          (window as any).loadingLogin?.close();
          if (err?.message) {
            (window as any)?.showToast(err?.message);
          }
          navigate(`/prize/claim?prize_id=${prizeId}&prize_type=${prizeType}`);
          return false;
        }
      } catch (e) {
        (window as any).loadingLogin?.close();
        navigate(`/prize/claim?prize_id=${prizeId}&prize_type=${prizeType}`);
      }
    },
    [country, verified, xhr],
  );
}
