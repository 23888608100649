import React, { useEffect, useState } from "react";
import styles from "./styles/prizes.module.scss";
import { PrizeCard } from "../components/PrizeCard";
import { clearNewGift } from "../store/state";
import { useDispatch } from "react-redux";
import { useSelector } from "../store";
// import { Warning } from "../components/Warning";
import { Footer } from "../components/Footer";
import { useTranslation } from "react-i18next";
import { SWLogo } from "../components/SWLogo";
import { setDot } from "../utils/dot";
import { setGaDot } from "../utils/gaDot";
import { SWButton } from "../components/SWButton";
import { useUtmParamNavigate } from "../utils/utmParamNavigate";
import EndClaim from "components/EndClaim";
import PromMini from "components/promotion/Mini";

export const PrizesEnd: React.FC = () => {
  const dispatch = useDispatch();
  const won: Reward[] = useSelector((state) => state.data.wonPrizes);
  const navigate = useUtmParamNavigate();
  const [prizeDom, setPrizeDom] = useState(<div></div>);
  const { t } = useTranslation();
  const loggedIn = useSelector((state) => state.data.loggedIn);
  const theme = useSelector((state) => state.data.theme);
  // const country = useSelector((state) => state.data.country);

  // set the gift number to zero when going to gifts page
  useEffect(() => {
    setDot("pv", "pv_reward_list_page", "", "");
    setGaDot({ action: "pv_reward_list_page", value: {} });
    dispatch(clearNewGift());
  }, []);

  // handle the tab switch
  useEffect(() => {
    setPrizeDom(
      won.length ? (
        <div style={{ marginTop: "8px" }}>
          {won.map((prize, index) => {
            return <PrizeCard key={prize.id + "_rewards_" + index} prize={prize} />;
          })}
        </div>
      ) : (
        <div
          style={{
            textAlign: "center",
            margin: "24px",
          }}
        >
          <img
            style={{
              width: "44px",
              height: "44px",
            }}
            src={require("../assets/images/error_white.png")}
            alt=""
          />
          <div
            style={{
              marginTop: "8px",
              color: "#ffffff",
              fontSize: "16px",
              fontWeight: 700,
              lineHeight: "20px",
            }}
          >
            {t("noPrizeYet")}
          </div>
        </div>
      ),
    );
  }, [won]);

  return (
    <div className={styles.main_end}>
      <div className={styles["title-area"]}>
        <img
          src={require("../assets/images/opera_logo.png")}
          alt=""
          style={{
            width: "88px",
            height: "32px",
            margin: "8px 0",
          }}
        />
        <SWLogo />
        <EndClaim />
        <PromMini status="end" />
        {/* <div
          className={styles.end_message}
          style={{ background: theme.brandBackgroundColor || "" }}
        >
          <Trans
            components={{
              1: (
                <p
                  style={{
                    fontSize: "14px",
                  }}
                />
              ),
              2: (
                <span
                  style={{
                    fontWeight: 700,
                    color: "#FFB906",
                  }}
                />
              ),
            }}
          >
            {country !== "BR" ? "thanksMessage" : "thanksMessageBR"}
          </Trans>
        </div> */}
        <div
          style={{
            margin: "16px",
            display: loggedIn ? "none" : "",
          }}
        >
          <p
            style={{
              fontSize: "16px",
              fontWeight: 700,
              margin: "12px",
            }}
          >
            {t("You're currently logged out. Log In to see your prizes.")}
          </p>
          <SWButton
            text={t("logIn")}
            background={"#FFB906"}
            color={"#191919"}
            callback={() => {
              navigate("/login");
            }}
          />
        </div>
      </div>
      <div
        style={{
          display: loggedIn ? "none" : "",
          background: "#040F46",
          minHeight: "calc(100vh - 300px)",
        }}
      >
        <Footer />
      </div>

      <div className={styles["inventory"]} style={{ display: loggedIn ? "" : "none" }}>
        <div className={styles.end_title}>{t("wonPrizes")}</div>
        <div className={styles.end_hint}>
          <img src={require("../assets/images/info.png")} alt={""} />
          <span>{t("shoppingBefore", { date: "25/09/24" })}</span>
        </div>

        {/* <Warning noBg={true} margin={"0 auto 8px"} /> */}
        <div
          className={styles["container"]}
          style={{
            background: won.length ? theme.secondaryBackgroundColor : "",
            border: won.length ? "1px solid #623D90" : "",
            borderRadius: won.length ? "12px" : "",
            marginTop: "16px",
          }}
        >
          {prizeDom}
        </div>
        <Footer />
      </div>
    </div>
  );
};
