import { getClientName } from "config";

const openOuterTab = (url: string) => {
  if (getClientName() === "mini") {
    (window as any)?.shakewin.openOperaMiniDeepLink("opera-mini://open?url=" + url, true);
  }
  if (getClientName() === "ofa") {
    window.open(url, "_blank");
  }
};

export { openOuterTab };
