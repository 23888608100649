import { createSearchParams, useNavigate } from "react-router-dom";
import { useSelector } from "../store";
import { useCallback } from "react";
import store from "store2";

export const useUtmParamNavigate = () => {
  const navigate = useNavigate();
  const utmQuery = useSelector((state) => state.data.utm);
  const verified = useSelector((state) => state.data.loggedIn);
  const initialized = useSelector((state) => state.data.initialized);
  return useCallback(
    (path: string | number | { pathname: string; search?: string }) => {
      if (typeof path === "string") {
        let pathStr = path;
        store("next_path", path);
        if (initialized) {
          if (!["/rules", "/qa", "/tc"].includes(path) && !path.includes("/result")) {
            if (
              (!(window as Client).shakewin ? store("shake") >= 1 : store("shake") >= 1) &&
              !verified &&
              location.pathname !== "/login" &&
              path === "/shaking"
            ) {
              pathStr = "/login";
            }
          }
        }
        if (pathStr.includes("?")) {
          const [pathName, search] =
            pathStr.split("?").length === 2
              ? pathStr.split("?")
              : [
                  pathStr.split("?")[0],
                  pathStr
                    .split("?")
                    .slice(-1 * pathStr.split("?")[0].length)
                    .join("?"),
                ];
          navigate({
            pathname: pathName,
            search: createSearchParams(utmQuery).toString()
              ? `?${createSearchParams(utmQuery)}&${search}`
              : search
              ? `?${search}`
              : "",
          });
          return;
        }
        navigate({
          pathname: pathStr,
          search: `?${createSearchParams(utmQuery)}`,
        });
        return;
      }
      if (typeof path === "number") {
        navigate(path);
        return;
      }
      store("next_path", path.pathname + (path.search || "?"));
      let pathStr = path.pathname;
      if (initialized) {
        if (!["/rules", "/qa", "/tc"].includes(pathStr) && !pathStr.includes("/result")) {
          if (
            (!(window as Client).shakewin ? store("shake") >= 1 : store("shake") >= 1) &&
            !verified &&
            location.pathname !== "/login" &&
            path.pathname === "/shaking"
          ) {
            pathStr = "/login";
          }
        }
      }

      navigate({
        pathname: pathStr,
        search: createSearchParams(utmQuery).toString()
          ? (path.search || "?") + `&${createSearchParams(utmQuery)}`
          : path.search,
      });
    },
    [utmQuery, location.pathname],
  );
};
