import React, { useCallback, useEffect, useState } from "react";
import { getCountriesList } from "../../utils/countries";
import { SWInputBar } from "../SWInputBar";
import { useSelector } from "../../store";
import { SelectList } from "../SelectList";
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import store from "store2";
import { SWButton } from "../SWButton";
import { availableCountries } from "../../config";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import {
  logout,
  setAuthXhr,
  setTmpPassword,
  setToken,
  setVerified,
  setXhr,
} from "../../store/state";
import { setDot } from "../../utils/dot";
import { setGaDot } from "../../utils/gaDot";
import createXhr from "../../api";
import { useDispatch } from "react-redux";
import { useUtmParamNavigate } from "../../utils/utmParamNavigate";
import { useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";

interface VerifyProps {
  // eslint-disable-next-line @typescript-eslint/ban-types
  dataTube?: Function;
}

export const PhoneVerifyV2: React.FC<VerifyProps> = (props) => {
  const { t } = useTranslation();
  const app = useSelector((state) => state.data.firebase);
  const tmpPhone = store("phoneBody");
  const dispatch = useDispatch();
  const navigate = useUtmParamNavigate();
  const [searchParams] = useSearchParams();
  const verifyType = searchParams.get("verifyType");
  const xhr = useSelector((state) => state.data.xhr);
  const country = useSelector((state) => state.data.country);
  const language = useSelector((state) => state.data.lang);
  const password = useSelector((state) => state.data.tmpPassword);
  const dataTube =
    props.dataTube ||
    function pass() {
      return;
    };
  const [botCheck, setBotCheck] = useState(false);
  const countries = getCountriesList(availableCountries);
  const [currentCode, setCurrentCode] = useState<string>(
    availableCountries.includes(country)
      ? country
      : availableCountries.includes(
          (window as Client).shakewin ? store("chosen_country") : store("chosen_country"),
        )
      ? (window as Client).shakewin
        ? store("chosen_country")
        : store("chosen_country")
      : availableCountries[0] || "NG",
  );
  const [currentCountry, setCurrentCountry] = useState(countries[currentCode]);
  const [phoneNumber, setPhoneNumber] = useState(tmpPhone || "");
  const [isPhoneError, setIsPhoneError] = useState(false);
  const [isCodeError, setIsCodeError] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const theme = useSelector((state) => state.data.theme);
  const [error, setError] = useState<{
    phone: string;
    dob: string;
    code: string;
  }>({
    phone: t("Invalid phone."),
    dob: "Invalid date of birth. Please check.",
    code: t("Invalid code."),
  });
  const [disabled, setDisabled] = useState(!phoneNumber);
  const [sentPhone, setSentPhone] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [testingBot, setTestingBot] = useState(false);
  const [session, setSession] = useState("");
  const authAccessToken = useSelector((state) => state.data.authAccessToken);

  const [cookies, setCookie] = useCookies([
    "code",
    "state",
    "codeVerifier",
    "behaviour",
    "providerId",
    "auth_refreshToken",
    "auth_accessToken",
  ]);

  useEffect(() => {
    if (!password && cookies.behaviour === "signup") {
      navigate("/login");
    }
  }, [password]);

  const changePhoneNumber = useCallback(
    (phoneNum: React.SetStateAction<string>) => {
      setPhoneNumber(phoneNum);
      if (testingBot) {
        // @ts-ignore
        window.recaptchaVerifier?.clear();
      }
      setTestingBot(false);
    },
    [testingBot, phoneNumber],
  );

  useEffect(() => {
    if (tmpPhone) {
      sendSMS("recaptcha-container", tmpPhone);
    }
  }, [tmpPhone]);

  const setSWTokens = useCallback(
    (mobileAuthAccessToken: string) => {
      xhr
        .postMobileAuthLogin({ mobileAuthAccessToken })
        .then((r: { accessToken: any; refreshToken: any }) => {
          if (r.accessToken && r.refreshToken) {
            if ((window as any)?.shakewin) {
              (window as any)?.shakewin?.onPhoneVerification(r.accessToken, r.refreshToken);
            }
            store("access_token", r.accessToken);
            store("refresh_token", r.refreshToken);
            store("next_send_time", 0);
            dispatch(
              setToken({
                accessToken: r.accessToken,
                refreshToken: r.refreshToken,
              }),
            );
            dispatch(
              setVerified({
                verified: true,
              }),
            );
            setDot("click", "phone_verify_finished", "phone_num", `+${currentCountry.phone}`);
            setGaDot({
              action: "phone_verify_finished",
              value: {
                phone_num: `+${currentCountry.phone}`,
              },
            });
            dispatch(
              setTmpPassword({
                phoneNum: "",
                email: "",
                signupMethod: "",
                tmpPassword: "",
              }),
            );
            if (store("formCache") && store("prizeType") && store("prizeId")) {
              const data = store("formCache");
              const prizeType = store("prizeType");
              const prizeId = store("prizeId");
              createXhr({
                headers: {
                  Authorization: `Bearer ${r.accessToken}`,
                },
              })
                .postClaimForm(
                  {
                    prize_type: prizeType,
                    prize_id: prizeId,
                  },
                  { data },
                )
                .then(() => {
                  setDot("click", "claim_form_submitted", "status", "success");
                  setDot("click", "claim_form_submitted", "", "");
                  setGaDot({
                    action: "claim_form_submitted",
                    value: {
                      status: "success",
                    },
                  });
                  store("prizeType", "");
                  store("prizeId", "");
                  store("phoneBody", "");
                  window.location.href = prizeId ? `/won?prize_id=${prizeId}` : theme.afterLoginUrl;
                })
                .catch((e: any) => {
                  setDot("click", "claim_form_submitted", "status", "failed");
                  setDot("click", "claim_form_submitted", "", "");
                  setGaDot({
                    action: "claim_form_submitted",
                    value: {
                      status: "failed",
                    },
                  });
                  console.error(e);
                  if (e?.message) {
                    (window as any)?.showToast(e?.message);
                  }
                  setTimeout(() => {
                    window.location.href = prizeId ? `/won?prize_id=${prizeId}` : "/prizes";
                  }, 500);
                });
              return;
            }

            if ((window as any)?.shakewin) {
              (window as any)?.shakewin?.requestAccessToken(true);
              setTimeout(() => {
                dispatch(
                  setTmpPassword({
                    phoneNum: "",
                    email: "",
                    signupMethod: "",
                    tmpPassword: "",
                  }),
                );
                setCookie("providerId", "", { path: "/", maxAge: 900 });
                setCookie("code", "", { path: "/", maxAge: 900 });
                setCookie("codeVerifier", "", { path: "/", maxAge: 900 });
                setCookie("behaviour", "", { path: "/", maxAge: 900 });
                setCookie("state", "", { path: "/", maxAge: 900 });
                window.location.href = "/prizes";
              }, 250);
              return;
            }
            dispatch(
              setXhr({
                xhr: createXhr({
                  headers: {
                    Authorization: "Bearer " + r.accessToken,
                    "X-CountryCode": country,
                    "Accept-Language": language,
                  },
                }),
              }),
            );
          }
        })
        .then(() => {
          setTimeout(() => {
            dispatch(
              setTmpPassword({
                phoneNum: "",
                email: "",
                signupMethod: "",
                tmpPassword: "",
              }),
            );
            setCookie("providerId", "", { path: "/", maxAge: 900 });
            setCookie("code", "", { path: "/", maxAge: 900 });
            setCookie("codeVerifier", "", { path: "/", maxAge: 900 });
            setCookie("behaviour", "", { path: "/", maxAge: 900 });
            setCookie("state", "", { path: "/", maxAge: 900 });
            if (verifyType === "otp") {
              navigate("/profile/password");
            } else {
              window.location.href = "/prizes";
            }
          }, 250);
        })
        .catch((err: { message: any }) => {
          (window as any)?.showToast(err.message);
          dispatch(logout());
        });
    },
    [xhr, dispatch, country, language],
  );

  useEffect(() => {
    if (phoneNumber?.startsWith("0")) {
      setError({
        ...error,
        phone: t(
          "Please remove any leading zeros from your phone number. Example 0803****** should be 803*****",
        ),
      });
      setIsPhoneError(true);
      return;
    }
    setIsPhoneError(false);
  }, [phoneNumber]);

  useEffect(() => {
    setInterval(() => {
      setTimeLeft(
        Math.round(Math.max(0, ((store("next_send_time") || 0) - new Date().getTime()) / 1000)),
      );
    }, 1000);
  }, []);

  useEffect(() => {
    setPhoneNumber(tmpPhone);
  }, [tmpPhone]);

  const sendSMS = useCallback(
    (id: string, body = "") => {
      if (phoneNumber?.startsWith("0")) {
        setError({
          ...error,
          phone: t(
            "Please remove any leading zeros from your phone number. Example 0803****** should be 803*****",
          ),
        });
        setIsPhoneError(true);
        return;
      }
      if (testingBot) {
        return;
      }
      setTestingBot(true);
      if (botCheck) {
        // @ts-ignore
        window.recaptchaVerifier?.clear();
        setBotCheck(false);
      }
      if (app) {
        const auth = getAuth(app);
        // @ts-ignore
        window.recaptchaVerifier = new RecaptchaVerifier(id, {}, auth);
        // @ts-ignore
        const appVerifier = (window as any).recaptchaVerifier;
        signInWithPhoneNumber(auth, `+${currentCountry.phone}${body || phoneNumber}`, appVerifier)
          .then((confirmationResult) => {
            // SMS sent. Prompt user to type the code from the message, then sign the
            // user in with confirmationResult.confirm(code).

            (window as any).confirmationResult = confirmationResult;
            setBotCheck(true);
            setTestingBot(false);
            setSentPhone(true);
            setDot("click", "phone_verify_request_send", "phone_num", `+${currentCountry.phone}`);
            setGaDot({
              action: "phone_verify_request_send",
              value: {
                phone_num: `+${currentCountry.phone}`,
              },
            });
            store("next_send_time", new Date().getTime() + 60000);
            // ...
          })
          .catch((err) => {
            console.error(err);
            setIsPhoneError(true);
            if (err.code.includes("internal-error")) {
              setError({
                ...error,
                phone: t("Server internal error or bad network. Please try later."),
              });
              (window as any)?.showServerError({
                title: "Internal Error",
                text: t("Failed to get CAPTCHA. Please check the network or try again."),
                url: "Firebase api",
              });
            } else if (err.code.includes("quota-exceeded")) {
              setError({
                ...error,
                phone: t("Quota exceeded, try later."),
              });
            } else if (err.code.includes("too-many-requests")) {
              setError({
                ...error,
                phone: t("Too many requests, try later."),
              });
            } else if (err.code.includes("invalid-phone-number")) {
              setError({
                ...error,
                phone: t("Phone number might invalid. Please double check."),
              });
            } else if (err.code.includes("session-expired")) {
              setError({
                ...error,
                phone: t("Session expired. Please retry."),
              });
            } else if (
              JSON.stringify(err)?.includes("attempts") ||
              JSON.stringify(err)?.includes("ATTEMPTS") ||
              JSON.stringify(err)?.includes("39")
            ) {
              setError({
                ...error,
                phone: t("Too many attempts, please try again later."),
              });
            } else {
              setError({
                ...error,
                phone: t(
                  `Sorry! The campaign is too popular at the moment. We're working to increase capacity, Previously-claimed prizes have been saved. Please try again later.`,
                ),
              });
            }
            if (!err.code.includes("invalid-phone-number")) {
              Sentry.captureException({
                phone_number: `+${currentCountry.phone}${phoneNumber}`,
                country: country,
                error: JSON.stringify(err),
              });
            }
          })
          .finally(() => {
            appVerifier?.clear();
            setTestingBot(false);
            setBotCheck(false);
          });
      }
    },
    [testingBot, botCheck, session, phoneNumber, currentCode, verifying, sentPhone, app],
  );

  const verifyCode = useCallback(
    (code: string) => {
      setIsCodeError(false);
      setVerifying(true);
      setSession("");
      if (/^\d{6}$/.test(code)) {
        (window as any).confirmationResult
          .confirm(code)
          .then(
            (res: {
              _tokenResponse: {
                idToken: string;
                refreshToken: string;
                expiresIn: string;
                localId: string;
                isNewUser: boolean;
                phoneNumber: string;
              };
            }) => {
              setVerifying(true);
              dataTube({
                inputting: false,
                phone: `+${currentCountry.phone} ${phoneNumber}`,
                verifying: true,
              });
              const idToken = res._tokenResponse.idToken;

              (authAccessToken
                ? createXhr({
                    headers: {
                      Authorization: `Bearer ${authAccessToken}`,
                    },
                  }).postConnectPhoneNumber({
                    idToken,
                    phoneNumber: `+${currentCountry.phone}${phoneNumber}`,
                  })
                : createXhr({}).postPhoneVerifyV2({
                    idToken,
                    phoneNumber: `+${currentCountry.phone}${phoneNumber}`,
                  })
              )
                .then((res: { accessToken: any; refreshToken: any }) => {
                  if (res.accessToken) {
                    const mobileAuthAccessToken = res.accessToken;
                    const mobileAuthXhr = createXhr({
                      headers: {
                        Authorization: "Bearer " + mobileAuthAccessToken,
                        "X-CountryCode": country,
                        "Accept-Language": language,
                      },
                    });
                    store("auth_accessToken", res.accessToken);
                    store("auth_refreshToken", res.refreshToken);

                    dispatch(
                      setAuthXhr({
                        mobileAuthXhr,
                      }),
                    );
                    setDot(
                      "click",
                      "phone_verify_success",
                      "phone_num",
                      `+${currentCountry.phone}`,
                    );
                    setGaDot({
                      action: "phone_verify_success",
                      value: {
                        phone_num: `+${currentCountry.phone}`,
                      },
                    });
                    if (verifyType === "otp") {
                      setDot("click", "find_password", "", "");
                      setGaDot({ action: "find_password", value: {} });
                    }
                    if (verifyType !== "otp" && password && searchParams.get("has_password")) {
                      mobileAuthXhr.putUserPassword({ password }).then(() => {
                        setSWTokens(mobileAuthAccessToken);
                      });
                    } else {
                      setSWTokens(mobileAuthAccessToken);
                    }
                  } else {
                    alert(
                      `Invalid value from mobilePhoneLogin Api: \n idToken: ${idToken} \n accessToken: ${res.accessToken} \n refreshToken: ${res.refreshToken}`,
                    );
                    window.location.reload();
                  }

                  // setIsError(true);
                })
                .catch((e: { status: number; message: string }) => {
                  console.error(e);
                  if (JSON.stringify(e)?.includes("too-many-requests")) {
                    setError({
                      ...error,
                      phone: t("Too many requests, try later."),
                    });
                  } else if (
                    JSON.stringify(e)?.includes("attempts") ||
                    JSON.stringify(e)?.includes("ATTEMPTS")
                  ) {
                    setError({
                      ...error,
                      phone: t("Too many attempts, please try again later."),
                    });
                  } else {
                    setError({
                      ...error,
                      code: `${e.status}: ${
                        e.message || t("Server internal error or bad network. Please try later.")
                      }`,
                    });
                  }

                  setIsCodeError(true);
                });
            },
          )
          .catch((err: { code: string | string[] }) => {
            console.error(err);
            setIsCodeError(true);

            if (
              err.code.includes("internal-error") ||
              err.code.includes("network-request-failed")
            ) {
              setError({
                ...error,
                code:
                  t("Server internal error or bad network. Please try later.") ||
                  "Server internal error or bad network. Please try later.",
              });

              (window as any)?.showServerError({
                title: "Internal Error",
                text: "Network request failed. Please check the network or try again.",
                url: "firebase",
              });
            } else if (err.code.includes("expired")) {
              setError({
                ...error,
                phone: t("Session expired. Please retry."),
              });
            } else if (err.code.includes("too-many-requests")) {
              setError({
                ...error,
                phone: t("Too many requests, try later."),
              });
            } else if (err.code?.includes("attempts") || err.code?.includes("ATTEMPTS")) {
              setError({
                ...error,
                phone: t("Too many attempts, please try again later."),
              });
            } else {
              setError({
                ...error,
                code: t("Invalid code.") + " - " + err.code,
              });
            }
          });
      } else {
        setVerifying(false);
        setError({ ...error });
        dataTube({
          inputting: true,
          phone: `+${currentCountry.phone} ${phoneNumber}`,
          verifying: false,
        });
      }
    },
    [session, phoneNumber, app, verifyType, authAccessToken],
  );

  useEffect(() => {
    setIsCodeError(false);
  }, [currentCode]);

  useEffect(() => {
    return () => {
      // @ts-ignore
      window.recaptchaVerifier = undefined;
    };
  }, []);

  useEffect(() => {
    if (phoneNumber) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    return;
  }, [phoneNumber]);

  return (
    <div
      style={{
        position: "relative",
        margin: "0 16px",
        boxSizing: "border-box",
        minHeight: "560px",
      }}
    >
      <div
        style={{
          display: !sentPhone ? "" : "none",
        }}
      >
        <SelectList
          title={""}
          currentItem={currentCountry}
          items={countries}
          type={"countries"}
          selectCb={(code: React.SetStateAction<string>) => {
            setCurrentCode(code);
            // @ts-ignore
            setCurrentCountry(countries[code]);
            if (botCheck) {
              // @ts-ignore
              window.recaptchaVerifier?.clear();
              setBotCheck(false);
            }
            setIsPhoneError(false);
          }}
          disabled
          background={theme.inputBackgroundColor}
          color={theme.inputFontColor}
          placeholderColor={"rgba(255,255,255,0.5)"}
          theme={"dark"}
        />
        <div
          style={{
            marginTop: "16px",
          }}
        >
          <SWInputBar
            key={"phone"}
            type={"phone"}
            onError={isPhoneError}
            errorMessage={error.phone}
            inputTitle={`+${currentCountry.phone}`}
            placeholder={t("enterPhoneNumber") || "Enter phone number"}
            passDataFunction={changePhoneNumber}
            keyword={"Phone number"}
            autofocus={true}
            background={theme.inputBackgroundColor}
            color={theme.inputFontColor}
            placeholderColor={theme.placeholder}
            defaultVal={tmpPhone}
          />
          <div id={"recaptcha-container"}></div>
        </div>
        <div
          style={{
            marginTop: "24px",
          }}
        >
          <SWButton
            text={t("continue") || "Continue"}
            // locked={testingBot || disabled}
            disabled={testingBot || disabled}
            background={theme.buttonColor}
            disabledBackground={"rgba(255, 255, 255, 0.2)"}
            color={theme.buttonFontColor}
            disabledColor={"rgba(255, 255, 255, 0.5)"}
            callback={() => {
              store("next_send_time", 0);
              sendSMS("recaptcha-container");
            }}
          />
        </div>
      </div>
      <div
        style={{
          display: sentPhone ? "" : "none",
        }}
      >
        <SWInputBar
          type={"number"}
          bigTitle={t("enterSMSCode") || "Enter code from SMS"}
          placeholder={""}
          passDataFunction={verifyCode}
          onError={isCodeError}
          keyword={"code"}
          key={"code"}
          errorMessage={error.code}
          autofocus={true}
          background={theme.inputBackgroundColor}
          color={theme.inputFontColor}
          placeholderColor={"rgba(255,255,255,0.5)"}
        />
        <div
          style={{
            color: "#ffffff",
            textAlign: "center",
            fontSize: "12px",
            marginTop: "16px",
            fontWeight: 600,
            textDecoration: "underline",
          }}
          onClick={() => {
            if (store("next_send_time") - new Date().getTime() <= 0 && !testingBot) {
              sendSMS("recaptcha-container-1");
            }
          }}
        >
          <span
            style={{
              color: store("next_send_time") - new Date().getTime() <= 0 ? "#ffffff" : "#dddddd",
            }}
          >
            {t("resendSMS") || "Resend SMS"}
          </span>{" "}
          <span
            style={{
              display: timeLeft <= 0 ? "none" : "",
            }}
          >
            {" "}
            ({timeLeft}s){" "}
          </span>
        </div>
        <div id="recaptcha-container-1"></div>
      </div>
    </div>
  );
};
