import store from "store2";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import createXhr from "../api";
import ShakeDetector from "shake-detector";
import { RootState } from "./";
import { getClientName, getDefaultLanguage, getTheme, oprVersion } from "../config";

interface UtmState {
  utm: { [key: string]: string };
}

interface UtmAction {
  payload: UtmState;
}

interface UserState {
  userId?: string;
  country: string;
  verified: boolean;
  avatarUrl?: string;
  canChangeCountryCode?: boolean;
}

interface UserAction {
  payload: UserState;
}

interface XhrState {
  xhr: XHR;
}

interface XhrAction {
  payload: XhrState;
}

interface InitialState {
  initialized: boolean;
}

interface PrizeImagesState {
  prizeImages: string[];
}

interface PrizeImagesAction {
  payload: PrizeImagesState;
}

interface SponsorImageUrlAction {
  payload: {
    sponsorImageUrl: string;
  };
}

interface PhaseState {
  phase: Phase;
}

interface PhaseAction {
  payload: PhaseState;
}

interface CountryState {
  country: string;
}

interface UserCountryState {
  tmpXhr?: XHR;
  country: string;
}

interface CountryAction {
  payload: CountryState;
}

interface NewGiftState {
  newGift: boolean;
}

interface ShakeState {
  shakes: number;
  recaptchaRequired: boolean;
}

interface ShakeAction {
  payload: ShakeState;
}

interface FirebaseState {
  firebase: any;
}

interface FirebaseAction {
  payload: FirebaseState;
}

interface VerifyState {
  verified: boolean;
  loggedIn?: boolean;
}

interface VerifyAction {
  payload: VerifyState;
}

interface WinnablePrizesState {
  winnablePrizes: Reward[];
}

interface WonPrizesState {
  wonPrizes: Reward[];
}

interface PuzzleState {
  puzzles: Reward[];
}

interface WinnablePrizesAction {
  payload: WinnablePrizesState;
}

interface WonPrizesAction {
  payload: WonPrizesState;
}

interface PuzzleAction {
  payload: PuzzleState;
}

interface UnlockTimeState {
  unlockedTime: number;
}

interface UnlockTimeAction {
  payload: UnlockTimeState;
}

interface ShakingState {
  isShaking: boolean;
}

interface TokenState {
  accessToken: string;
  refreshToken: string;
}

interface TokenAction {
  payload: TokenState;
}

interface ErrorState {
  error: string;
}

interface ErrorAction {
  payload: ErrorState;
}

interface RefreshState extends TokenState, CountryState {
  xhr: XHR;
}

interface RefreshAction {
  payload: TokenState;
}

interface LangState {
  lang: string;
}

interface LangAction {
  payload: LangState;
}

interface MissionState {
  missions: {
    id: string;
  }[];
  fetchedMissions: boolean;
  fetchingMissions: boolean;
}

interface SetMissionsAction {
  // only the missions field
  payload: {
    missions: MissionState["missions"];
  };
}

interface UserCountState {
  userCount: number;
}

interface UserCountAction {
  payload: UserCountState;
}

interface ChosenCountryState {
  chosenCountry?: string;
}

interface ThemeState {
  theme: ThemeData;
}

interface ThemeAction {
  payload: CountryState;
}

interface TmpPasswordState {
  phoneNum: string;
  email: string;
  tmpPassword: string;
  signupMethod: string;
}

interface TmpPasswordAction {
  payload: TmpPasswordState;
}

interface MobileAuthXhrState {
  mobileAuthXhr: XHR;
}

interface MobileAuthXhrAction {
  payload: MobileAuthXhrState;
}

interface AuthState {
  authAccessToken: string;
  authRefreshToken: string;
  loggedIn?: boolean;
  verified?: boolean;
}

interface AuthAction {
  payload: AuthState;
}

interface AuthUserState {
  authUserInfo: {
    phoneNumber: string;
    email: string;
    registeredOAuth2Providers: string[];
    emailVerified: boolean;
  };
}

interface AuthUserAction {
  payload: AuthUserState;
}

interface ModalState {
  modalData: {
    title: string | undefined;
    text: string | undefined;
    background: string | undefined;
    assets?:
      | {
          image?: any | undefined;
          text?: string | undefined;
          url?: string | undefined;
        }[]
      | undefined;
    options: {
      text: string;
      color: string;
      background: string;
      cb: (param?: any) => void;
    }[];
  };
}

interface ModalAction {
  payload: ModalState;
}

interface ShowModalState {
  showFullscreenModal: boolean;
  modalData?: {
    title: string | undefined;
    text: string | undefined;
    background: string | undefined;
    assets?:
      | {
          image?: any | undefined;
          text?: string | undefined;
          url?: string | undefined;
        }[]
      | undefined;
    options: {
      text: string;
      color: string;
      background: string;
      cb: (param?: any) => void;
    }[];
  };
}

interface ChoosingCountryState {
  choosingCountry: boolean;
}

interface ChoosingCountryAction {
  payload: ChoosingCountryState;
}

interface LoggingInState {
  loggingIn: boolean;
  verified?: boolean;
}

interface LoggingInAction {
  payload: LoggingInState;
}

interface CampaignState {
  campaign: string;
}

interface CampaignAction {
  payload: CampaignState;
}

interface AuthIdState {
  authId: string;
}

interface AuthIdAction {
  payload: AuthIdState;
}

interface MissionCountState {
  stats: { campaign: string; count: number }[];
}

interface MissionCountAction {
  payload: MissionCountState;
}

// @ts-ignore
export const stateSlice = createSlice({
  name: "data",
  initialState: {
    utm: {},
    userId: store("user_id") || "",
    avatarUrl: require("../assets/images/profile.png"),
    avatarDarkUrl: require("../assets/images/profile_dark.png"),
    country: "",
    verified: false,
    xhr: createXhr({}),
    mobileAuthXhr: createXhr({}),
    initialized: false,
    prizeImages: [],
    sponsorImageUrl: "",
    phase: "PENDING" as Phase,
    error: "",
    newGift: false,
    shakes: 0,
    firebase: undefined,
    shakeDetector: new ShakeDetector({
      threshold: 50,
      debounceDelay: 2500,
    }),
    winnablePrizes: [],
    wonPrizes: [],
    puzzles: [],
    missions: [],
    fetchedMissions: false,
    fetchingMissions: false,
    isShaking: false,
    unlockedTime: 0,
    accessToken: "",
    refreshToken: "",
    authAccessToken: store("auth_accessToken") || "",
    authRefreshToken: store("auth_refreshToken") || "",
    canChangeCountryCode: true,
    userCount: 0,
    lang: getDefaultLanguage(store("lang")) || "en",
    chosenCountry: store("chosen_country"),
    theme: {
      name: "",
      afterLoginUrl: "/prizes",
      shakingUrl: "/shaking",
      backgroundColor: ``,
      backgroundImage: ``,
      buttonColor: "",
      buttonFontColor: "",
      secondaryBackgroundColor: "",
      inputBackgroundColor: "",
      inputFontColor: "",
      menuColor: "",
      placeholder: "dark" as "dark" | "light",
      brandBackgroundColor: "",
      formBackground: "",
      shakingIcon: "",
      borderColor: "",
      highlightBackground: "",
    },
    phoneNum: "",
    tmpPassword: "",
    signupMethod: "",
    email: "",
    authUserInfo: {
      phoneNumber: "",
      email: "",
      registeredOAuth2Providers: [],
      emailVerified: false,
    },
    modalData: {
      title: "",
      text: "",
      background: "",
      assets: [],
      options: [],
    },
    showFullscreenModal: false,
    choosingCountry: false,
    loggingIn: false,
    loggedIn: false,
    campaign: "shakewin",
    recaptchaRequired: false,
    authId: store("authId") || "",
    hasMiniPay: (window as Client).shakewin?.getMiniPayStatus
      ? !!(window as Client).shakewin?.getMiniPayStatus()
      : false,
    stats: [],
  },
  reducers: {
    setUserInfo: (state: UserState, action: UserAction) => {
      if (action.payload.userId) {
        state.userId = action.payload.userId;
      }
      state.country = action.payload.country;
      store("country", state.country);
      state.verified = action.payload.verified;
      if (typeof action.payload.canChangeCountryCode === "boolean") {
        state.canChangeCountryCode = action.payload.canChangeCountryCode;
      }
    },
    setAuthUser: (state: AuthUserState, action: AuthUserAction) => {
      state.authUserInfo = action.payload.authUserInfo;
    },
    setXhr: (state: XhrState, action: XhrAction) => {
      state.xhr = action.payload.xhr;
    },
    setAuthXhr: (state: MobileAuthXhrState, action: MobileAuthXhrAction) => {
      state.mobileAuthXhr = action.payload.mobileAuthXhr;
    },
    updateAuth: (state: AuthState, action: AuthAction) => {
      state.authAccessToken = action.payload.authAccessToken;
      state.authRefreshToken = action.payload.authRefreshToken;
      state.loggedIn = !!action.payload.authAccessToken || state.verified;

      store("auth_accessToken", action.payload.authAccessToken);
      store("auth_refreshToken", action.payload.authRefreshToken);
    },
    setTheme: (state: ThemeState, action: ThemeAction) => {
      state.theme = getTheme(action.payload.country);
    },
    setUtmSearch: (state: UtmState, action: UtmAction) => {
      state.utm = action.payload.utm;
    },
    initialize: (state: InitialState) => {
      state.initialized = true;
    },
    setPrizeImages: (state: PrizeImagesState, action: PrizeImagesAction) => {
      state.prizeImages = action.payload.prizeImages;
    },
    setSponsorImageUrl: (state, action: SponsorImageUrlAction) => {
      state.sponsorImageUrl = action.payload.sponsorImageUrl;
    },
    setPhase: (state: PhaseState, action: PhaseAction) => {
      state.phase = action.payload.phase;
    },
    setCountry: (state: CountryState, action: CountryAction) => {
      state.country = action.payload.country;
      store("country", state.country);
    },
    setShakes: (state: ShakeState, action: ShakeAction) => {
      state.recaptchaRequired = action.payload.recaptchaRequired;
      state.shakes = action.payload.shakes;
    },
    addNewGift: (state: NewGiftState) => {
      state.newGift = true;
    },
    clearNewGift: (state: NewGiftState) => {
      state.newGift = false;
    },
    setFirebase: (state: FirebaseState, action: FirebaseAction) => {
      state.firebase = action.payload.firebase;
    },
    setVerified: (state: VerifyState, action: VerifyAction) => {
      state.verified = action.payload.verified;
      if (state.verified) state.loggedIn = true;
    },
    setWinnable: (state: WinnablePrizesState, action: WinnablePrizesAction) => {
      state.winnablePrizes = action.payload.winnablePrizes;
    },
    setWon: (state: WonPrizesState, action: WonPrizesAction) => {
      state.wonPrizes = action.payload.wonPrizes;
    },
    setPuzzles: (state: PuzzleState, action: PuzzleAction) => {
      state.puzzles = action.payload.puzzles;
    },
    setUnlockTime: (state: UnlockTimeState, action: UnlockTimeAction) => {
      state.unlockedTime = action.payload.unlockedTime;
    },
    shaking: (state: ShakingState) => {
      state.isShaking = true;
    },
    stopShaking: (state: ShakingState) => {
      state.isShaking = false;
    },
    setMissions: (state: MissionState, action: SetMissionsAction) => {
      state.missions = action.payload.missions;
      state.fetchedMissions = true;
      state.fetchingMissions = false;
    },
    setFetchingMissions: (state: MissionState) => {
      state.fetchingMissions = true;
    },
    setToken: (state: TokenState, action: TokenAction) => {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      if ((window as Client).shakewin) {
        store("refresh_token", state.refreshToken);
        store("access_token", state.accessToken);
      } else {
        store("refresh_token", state.refreshToken);
        store("access_token", state.accessToken);
      }
    },
    setServerError: (state: ErrorState, action: ErrorAction) => {
      state.error = action.payload.error;
    },
    refresh: (state: RefreshState, action: RefreshAction) => {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      store("refresh_token", state.refreshToken);
      store("access_token", state.accessToken);

      state.xhr = createXhr({
        headers: {
          "X-CountryCode": state.country,
          Authorization: "Bearer " + state.accessToken,
        },
      });
    },
    setLanguage: (state: LangState, action: LangAction) => {
      state.lang = action.payload.lang;
      if ((window as Client).shakewin) {
        store("lang", state.lang);
      } else {
        store("lang", state.lang);
      }
    },
    setUserCount: (state: UserCountState, action: UserCountAction) => {
      state.userCount = action.payload.userCount;
    },
    updateChosenCountry: (state: ChosenCountryState) => {
      state.chosenCountry = store("chosen_country") || "";
    },
    setTmpPassword: (state: TmpPasswordState, action: TmpPasswordAction) => {
      state.phoneNum = action.payload.phoneNum;
      state.tmpPassword = action.payload.tmpPassword;
      state.signupMethod = action.payload.signupMethod;
      state.email = action.payload.email;
    },
    logout() {
      store.remove("user_id");
      store.remove("refresh_token");
      store.remove("access_token");
      store.remove("auth_accessToken");
      store.remove("auth_refreshToken");
      store.remove("beforeLoginUrl");
      store.remove("chosen_country");
      store.remove("country");
      store.remove("uid");
      store.remove("shake");
      store.remove("authId");
      store.remove("googleAccount");
      store.remove("selected_country");
      store.remove("formCache");
      store.remove("prizeType");
      store.remove("prizeId");
      store.remove("phoneToVerify");
      store.remove("phoneBody");
      store.remove("autoShake");
      store.remove("currentPhone");
      store.remove("shakeStatus");
      if ((window as Client).shakewin) {
        (window as Client).shakewin?.onLogout();
      }
    },
    setModalData: (state: ModalState, action: ModalAction) => {
      state.modalData = action.payload.modalData;
    },
    showModal: (state: ShowModalState) => {
      state.showFullscreenModal = true;
    },
    closeModal: (state: ShowModalState) => {
      state.showFullscreenModal = false;
      state.modalData = {
        title: "",
        text: "",
        background: "",
        assets: [],
        options: [],
      };
    },
    setChoosingCountry: (state: ChoosingCountryState, action: ChoosingCountryAction) => {
      state.choosingCountry = action.payload.choosingCountry;
    },
    setLoggingIn: (state: LoggingInState, action: LoggingInAction) => {
      state.loggingIn = action.payload.loggingIn || !!state.verified;
    },
    setCampaign: (state: CampaignState, action: CampaignAction) => {
      state.campaign = action.payload.campaign;
    },
    setAuthId: (state: AuthIdState, action: AuthIdAction) => {
      state.authId = action.payload.authId;
      if ((window as Client).shakewin) {
        store("authId", action.payload.authId);
      } else {
        store("authId", action.payload.authId);
      }
    },
    setMissionCounts: (state: MissionCountState, action: MissionCountAction) => {
      state.stats = action.payload.stats;
    },
  },
});

export const {
  setUserInfo,
  setXhr,
  setAuthXhr,
  updateAuth,
  setUtmSearch,
  initialize,
  setSponsorImageUrl,
  setPrizeImages,
  setPhase,
  setCountry,
  addNewGift,
  clearNewGift,
  setShakes,
  setFirebase,
  setVerified,
  setWon,
  setPuzzles,
  setWinnable,
  setUnlockTime,
  shaking,
  setMissions,
  stopShaking,
  setToken,
  setServerError,
  refresh,
  setLanguage,
  setFetchingMissions,
  setUserCount,
  updateChosenCountry,
  setTheme,
  setTmpPassword,
  setAuthUser,
  logout,
  setModalData,
  showModal,
  closeModal,
  setChoosingCountry,
  setLoggingIn,
  setCampaign,
  setAuthId,
  setMissionCounts,
} = stateSlice.actions;

export const fetchMissions = createAsyncThunk<void, void, { state: RootState }>(
  "data/fetchMissions",
  async (_, { getState, dispatch }) => {
    dispatch(setFetchingMissions());
    const state = getState();
    try {
      const res = await state.data.xhr.getMissionIds();
      let missions = res?.missions || [];
      // set the initial list to speed up the first render
      if (!state.data.missions.length) {
        missions = missions.map(
          (mission: { id: string; isCompleted: boolean; minimalMajor: number | null }) => ({
            id: mission.id,
            isCompleted: false,
            minimalMajor: mission.minimalMajor,
          }),
        );
        dispatch(setMissions({ missions: missions }));
      }
      // fetch mission details
      const missionDetails = await Promise.all(
        missions.map(
          async (mission: {
            id: string;
            isCompleted: boolean;
            minimalMajor: number | null;
            status?: string;
          }) => {
            const params = {
              missionId: mission.id,
              version: "v1",
            };
            if (["hidden-shake", "update-app"].includes(mission.id)) {
              params.version = "v2";
            }
            if ((mission.minimalMajor || 0) + "" > oprVersion) {
              return {
                id: mission.id,
                isCompleted: false,
                needUpdate: true,
              };
            } else {
              try {
                const res = await state.data.xhr.getMissionDetails(params);
                let completed = res?.isCompleted;
                const name = getClientName();
                if (mission.id === "update-app") {
                  if (name === "mini") {
                    completed = res.mini?.isCompleted;
                  }
                  if (name === "ofa") {
                    completed = res.ofa?.isCompleted;
                  }
                }
                if (mission.id === "hidden-shake") {
                  completed = res.availableShakes === res.grantedShakes;
                }

                if (mission.id === "ofa-referral") {
                  completed = res.numCompleted >= 2;
                }

                if (mission.id === "mini-referral") {
                  // do not show the progress bar
                  completed = res.numCompleted > 0;
                  res.availableShakes = 0;
                }

                if (mission.id === "minipay-wallet") {
                  completed = res.isCompleted;
                  if ((window as Client)?.shakewin?.getMiniPayStatus && !completed) {
                    let walletId = "",
                      createdAt = "",
                      source = "New";
                    const res = (window as Client)?.shakewin?.getMiniPayStatus() || null;
                    try {
                      walletId =
                        typeof res === "string" ? JSON.parse(res).walletId : res?.walletId || "";
                      createdAt =
                        typeof res === "string" ? JSON.parse(res).createdAt : res?.createdAt || "";
                      source = typeof res === "string" ? JSON.parse(res).source : res?.source || "";
                    } catch (e) {
                      console.error(e);
                    }

                    if (walletId) {
                      store("walletId", walletId);
                      state.data.xhr
                        .postMiniPayStatus({
                          walletId,
                          createdAt,
                          // @ts-ignore
                          source,
                        })

                        .catch((e: any) => {
                          console.error(e);
                          (window as any)?.showToast(e.message);
                          store("walletId", "");
                        });
                    }
                  }
                }

                return {
                  ...res,
                  id: mission.id,
                  isCompleted: completed,
                  needUpdate: (mission.minimalMajor || 0) + "" > oprVersion,
                  status: res.status || "",
                };
              } catch (e) {
                console.error(e);
                return {
                  ...res,
                  id: mission.id,
                  isCompleted: false,
                  needUpdate: (mission.minimalMajor || 0) + "" > oprVersion,
                  status: mission.status || "",
                };
              }
            }
          },
        ),
      );
      // if not available, remove mission from list
      missions = missionDetails.filter((mission: any) => mission !== null);
      dispatch(setMissions({ missions: missions }));
    } catch (e) {
      dispatch(setMissions({ missions: [] }));
    }
  },
);

export const setUserCountry = createAsyncThunk<void, UserCountryState, { state: RootState }>(
  "data/setUserCountry",
  async (data: UserCountryState, { getState, dispatch }) => {
    const state = getState();
    const xhr = data.tmpXhr || state.data.xhr;
    if (state.data.choosingCountry || !xhr.headers?.Authorization || !state.data.initialized) {
      return;
    }
    try {
      dispatch(setChoosingCountry({ choosingCountry: true }));
      await xhr.postUserCountry({ countryCode: data.country });
      dispatch(setCountry({ country: data.country }));
      if ((window as Client).shakewin) {
        store("chosen_country", data.country);
      } else {
        store("chosen_country", data.country);
      }
    } catch (e: any) {
      if (JSON.stringify(e).includes("alter")) {
        if ((window as any).showToast) {
          (window as any).showToast(
            `Already selected a default country, reinstall the app if you selected the wrong country`,
          );
        }
      }
      (window as any)?.showToast(e?.message || "Failed on updating user country");
    }
    dispatch(setChoosingCountry({ choosingCountry: false }));
  },
);

export const getMissionStats = createAsyncThunk<void, void, { state: RootState }>(
  "data/getMissionStats",
  async (_, { getState, dispatch }) => {
    const state = getState();
    const xhr = state.data.xhr;
    if (!xhr.headers?.Authorization || !state.data.initialized) {
      return;
    }

    try {
      const { missionCounts } = (await xhr.getStats()) || { missionCounts: [] };
      dispatch(
        setMissionCounts({
          stats: missionCounts,
        }),
      );
    } catch (e: any) {
      console.error(e);
      dispatch(
        setMissionCounts({
          stats: [
            { campaign: "ng-dec-2023", count: 0 },
            { campaign: "ng-dec-2023-ng", count: 0 },
            { campaign: "ng-dec-2023-za", count: 0 },
            { campaign: "ng-dec-2023-ke", count: 0 },
          ],
        }),
      );
    }
  },
);
export default stateSlice.reducer;
