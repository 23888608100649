import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { availableCountries } from "../config";
import { Trans, useTranslation } from "react-i18next";
import { LanguageSelect } from "./LanguageSelect";
import { useSelector } from "../store";
import ReactCountryFlag from "react-country-flag";
import { getCountriesList } from "../utils/countries";
import { useUtmParamNavigate } from "../utils/utmParamNavigate";

export const Footer: React.FC = () => {
  const { t } = useTranslation();
  const openCountries = availableCountries;
  const [countries, setCountries] = useState<string>("");
  const country = useSelector((state) => state.data.country);
  const [countryName, setCountryName] = useState("");
  const navigate = useUtmParamNavigate();

  useEffect(() => {
    const name = getCountriesList(availableCountries)[country]?.name;
    setCountryName(name);
  }, [country]);

  useEffect(() => {
    if (!openCountries.length) {
      return;
    }
    if (openCountries.length === 1) {
      setCountries(`${openCountries[0]} only.`);
      return;
    }
    let str = "";
    openCountries.forEach((country, index) => {
      if (index === 0) {
        str = str + country;
        return;
      }
      if (index === openCountries.length - 1) {
        str = str + ` and ${country} only.`;
        return;
      }
      str = str + ", " + country;
    });
    setCountries(str);
  }, [openCountries]);
  return (
    <div style={{ paddingBottom: "16px" }}>
      {country === "BR" ? (
        <Trans
          values={{
            countries,
            from: "23.11.2023",
            to: "21.12.2023",
            certification: "SRE/MF no. 05.031238/2023 and 02.031214/2023",
            firstLine:
              t("oneAccountOnly", {
                countries,
              }) || "",
          }}
          components={{
            1: (
              <div
                style={{
                  minHeight: "160px",
                  width: "100%",
                  color: "rgba(255,255,255,.75)",
                  fontSize: "12px",
                  padding: "16px",
                  boxSizing: "border-box",
                  textAlign: "center",
                }}
              />
            ),
            2: <div />,
            3: (
              <Link
                style={{
                  color: "#ffffff",
                  fontWeight: "600",
                }}
                to={"/tc"}
              />
            ),
            4: (
              <div
                style={{
                  margin: "16px 0",
                }}
              />
            ),
            5: (
              <Link
                style={{
                  color: "#ffffff",
                  fontWeight: "600",
                }}
                to={"/qa"}
              />
            ),
            6: <div />,
            7: (
              <div
                style={{
                  marginTop: "16px",
                  textDecoration: "none !important",
                  fontWeight: "400",
                }}
              />
            ),
            8: (
              <Link
                style={{
                  marginBottom: "16px",
                  color: "#fff",
                  display: "inline-block",
                  fontSize: "12px",
                  fontWeight: 700,
                  textAlign: "center",
                }}
                to="/feedback"
              />
            ),
            9: <br />,
            10: (
              <a
                style={{
                  marginBottom: "16px",
                  color: "#fff",
                  display: "inline-block",
                  fontSize: "12px",
                  fontWeight: 700,
                  textAlign: "center",
                }}
                href="https://legal.opera.com/pt-br/privacy/"
              />
            ),
            11: (
              <div
                style={{
                  marginBottom: "16px",
                  color: "#fff",
                  display: "inline-block",
                  fontSize: "12px",
                  fontWeight: 700,
                  textAlign: "center",
                  textDecoration: "underline",
                }}
              />
            ),
          }}
        >
          footerBR
        </Trans>
      ) : (
        <Trans
          values={{
            countries,
            from: "14/08/2024",
            to: "11/09/2024",
            firstLine:
              t("oneAccountOnly", {
                countries: ["NG", "KE", "ZA", "GH"],
              }) || "",
          }}
          components={{
            1: (
              <div
                style={{
                  minHeight: "160px",
                  width: "100%",
                  color: "rgba(255,255,255,.75)",
                  fontSize: "12px",
                  padding: "16px",
                  boxSizing: "border-box",
                  textAlign: "center",
                }}
              />
            ),
            2: <div />,
            3: (
              <Link
                style={{
                  color: "#ffffff",
                  fontWeight: "600",
                }}
                to={"/tc"}
              />
            ),
            4: (
              <div
                style={{
                  margin: "16px 0",
                }}
              />
            ),
            5: (
              <Link
                style={{
                  color: "#ffffff",
                  fontWeight: "600",
                }}
                to={"/qa"}
              />
            ),
            6: <div />,
            7: (
              <div
                style={{
                  marginTop: "16px",
                  textDecoration: "none !important",
                  fontWeight: "400",
                }}
              />
            ),
            8: (
              <Link
                style={{
                  marginBottom: "16px",
                  color: "#fff",
                  display: "inline-block",
                  fontSize: "12px",
                  fontWeight: 700,
                  textAlign: "center",
                }}
                to="/feedback"
              />
            ),
            9: <br />,
            10: (
              <a
                style={{
                  marginBottom: "16px",
                  color: "#fff",
                  display: "inline-block",
                  fontSize: "12px",
                  fontWeight: 700,
                  textAlign: "center",
                }}
                href="https://legal.opera.com/privacy/"
              />
            ),
            11: (
              <div
                style={{
                  marginBottom: "16px",
                  color: "#fff",
                  display: "inline-block",
                  fontSize: "12px",
                  fontWeight: 700,
                  textAlign: "center",
                  textDecoration: "underline",
                }}
              />
            ),
          }}
        >
          footer
        </Trans>
      )}
      <div
        style={{
          display: location.pathname !== "/" && availableCountries.length > 1 ? "flex" : "none",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "16px",
        }}
        onClick={() => {
          navigate("/select");
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "16px",
            height: "16px",
            borderRadius: "16px",
            overflow: "hidden",
          }}
        >
          <ReactCountryFlag
            countryCode={country}
            svg
            style={{
              width: "22px",
              height: "22px",
            }}
          />
        </div>
        <span
          style={{
            fontSize: "12px",
            lineHeight: "16px",
            marginLeft: "8px",
          }}
        >
          {countryName}
        </span>
      </div>
      <LanguageSelect />
    </div>
  );
};
