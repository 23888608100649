import React, { FC } from "react"
import styles from './global.module.scss'

interface ModalGlobalProps {
  title?: string
  text?: string
  background?: string
  assets?: {
    image?: any | undefined;
    text?: string | undefined;
    url?: string | undefined;
  }[]
  options: {
    text: string;
    color: string;
    background: string;
    cb: (param?: any) => void;
  }[]
}

const ModalGlobal: FC<ModalGlobalProps> = ({
  title,
  text,
  background,
  assets,
  options,
}) => {
  return <div className={styles.shadow}>
      <div
        className={styles.modal}
        style={{
          background,
        }}
      >
        <h3 style={{display: title ? '' : 'none'}}>{title}</h3>
        <p className={styles.text} style={{ display: text ? '' : 'none' }}>{text}</p>
        <div style={{ display: assets?.length ? '' : 'none' }}>
          {
            assets?.map((asset, index) => <div
              className={styles.asset}
              key={index}
            >
              <img src={asset.image} alt="" />
              <div className={styles.description}>{asset.text}</div>
            </div>)
          }
        </div>
        <div style={{display: options.length ? '' : 'none'}} className={styles.buttons}>
          {
            options?.map((option, index) => (
              <div
                key={index}
                className={styles.button}
                style={{
                  background: option.background,
                  color: option.color,
                }}
                onClick={option.cb}
              >
                <span>{option.text}</span>
              </div>
            ))
          }
        </div>
      </div>
  </div>
}

export default ModalGlobal
