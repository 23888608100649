import React, { useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import store from "store2";
import { useSelector } from "../../store";
import createXhr from "../../api";
import { logout, setAuthId, setAuthXhr, setToken, setXhr, updateAuth } from "../../store/state";
import { setDot } from "../../utils/dot";
import { setGaDot } from "../../utils/gaDot";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { SpinnerCircular } from "spinners-react";
import * as Sentry from "@sentry/react";

export const Oauth: React.FC = () => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const code = searchParams.get("code");
  const state = searchParams.get("state");
  const xhr = useSelector((state) => state.data.xhr);
  const mobileAuthXhr = useSelector((state) => state.data.mobileAuthXhr);
  const country = useSelector((state) => state.data.country);
  const language = useSelector((state) => state.data.lang);
  const verified = useSelector((state) => state.data.verified);
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies([
    "email",
    "phone",
    "password",
    "codeVerifier",
    "code",
    "state",
    "providerId",
    "behaviour",
    "auth_accessToken",
    "auth_refreshToken",
  ]);

  const generateSWToken = useCallback(
    (credential: string) => {
      xhr
        .postMobileAuthLogin({ mobileAuthAccessToken: credential })
        .then((r: { accessToken: any; refreshToken: any }) => {
          if (r.accessToken && r.refreshToken) {
            if ((window as any)?.shakewin) {
              store("access_token", r.accessToken);
              store("refresh_token", r.refreshToken);
            } else {
              store("access_token", r.accessToken);
              store("refresh_token", r.refreshToken);
            }
            store("next_send_time", 0);
            dispatch(
              setToken({
                accessToken: r.accessToken,
                refreshToken: r.refreshToken,
              }),
            );
            dispatch(
              setXhr({
                xhr: createXhr({
                  headers: {
                    Authorization: "Bearer " + r.accessToken,
                    "X-CountryCode": country,
                    "Accept-Language": language,
                  },
                }),
              }),
            );
            setDot("click", "third_party_login_success", "", "");
            setGaDot({ action: "third_party_login_success", value: {} });
            setTimeout(() => {
              setCookie("providerId", "", { path: "/", maxAge: 900 });
              setCookie("code", "", { path: "/", maxAge: 900 });
              setCookie("codeVerifier", "", { path: "/", maxAge: 900 });
              setCookie("behaviour", "", { path: "/", maxAge: 900 });
              setCookie("state", "", { path: "/", maxAge: 900 });
              if ((window as any)?.shakewin) {
                (window as Client).shakewin?.onPhoneVerification(r.accessToken, r.refreshToken);
              }
              window.location.href = "/prizes";
            }, 250);
          }
        })
        .catch((err: { message: any }) => {
          (window as any).showToast(err.message);
          store.remove("user_id");
          store.remove("refresh_token");
          store.remove("access_token");
          store.remove("auth_accessToken");
          store.remove("auth_refreshToken");
          store.remove("beforeLoginUrl");
          store.remove("chosen_country");
          store.remove("chosen_country");
          store.remove("country");
          store.remove("uid");
          store.remove("country");
          store.remove("access_token");
          store.remove("refresh_token");
          store.remove("auth_accessToken");
          store.remove("auth_refreshToken");
          store.remove("shake");
          store.remove("shake");
          // Allow user to select country
          store.remove("selected_country");
          dispatch(logout());
          window.location.href = "/";
        });
    },
    [xhr, language, country],
  );

  useEffect(() => {
    const behaviour = cookies.behaviour;
    const codeVerifier = cookies.codeVerifier;
    const providerId = cookies.providerId;
    setCookie("code", code, { path: "/", maxAge: 900 });
    setCookie("state", state, { path: "/", maxAge: 900 });
    if (behaviour && codeVerifier && code && state) {
      if (behaviour === "signup") {
        window.location.href = `/login?auth_type=signup`;
      }

      if (behaviour === "connect" && mobileAuthXhr.headers.Authorization) {
        mobileAuthXhr
          .postConnectAccount({
            code: code,
            codeVerifier: codeVerifier,
            providerId: providerId,
          })
          .then(() => {
            window.location.href = "/prizes";
          })
          .catch((e: { message: any }) => {
            (window as any).showToast(e.message || "Failed on connecting account");
            dispatch(logout());
            setTimeout(() => {
              window.location.href = "/";
            }, 500);
          })
          .finally(() => {
            setTimeout(() => {
              setCookie("providerId", "", { path: "/", maxAge: 900 });
              setCookie("code", "", { path: "/", maxAge: 900 });
              setCookie("codeVerifier", "", { path: "/", maxAge: 900 });
              setCookie("behaviour", "", { path: "/", maxAge: 900 });
              setCookie("state", "", { path: "/", maxAge: 900 });
            }, 150);
          });
      }
    } else {
      (window as any).showToast(
        "PKCE values are missing. Please clear cache and storage and then login/signup again!",
      );
      window.location.href = "/";
    }
  }, [code, state, xhr, verified, mobileAuthXhr.headers.Authorization]);

  useEffect(() => {
    const behaviour = cookies.behaviour;
    const codeVerifier = cookies.codeVerifier;
    const providerId = cookies.providerId;
    setCookie("code", code, { path: "/", maxAge: 900 });
    setCookie("state", state, { path: "/", maxAge: 900 });
    if (code && codeVerifier && providerId && behaviour === "login") {
      const anonXhr = createXhr({});
      anonXhr
        .postOauth2Login({
          code,
          providerId,
          codeVerifier,
          projectId: "shakewin",
        })
        .then((res: Tokens) => {
          const { accessToken, refreshToken } = res;
          createXhr({
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "X-CountryCode": country,
            },
          })
            .getUser()
            .then((res: { phoneNumber: any; id: string }) => {
              if (res.phoneNumber !== store("currentPhone") && res.phoneNumber) {
                (window as any)?.showToast(
                  t("accountIsConnected", { phoneNumber: res.phoneNumber }),
                );
              }
              const mobileAuthXhr = createXhr({
                headers: {
                  Authorization: "Bearer " + accessToken,
                  "X-CountryCode": country,
                  "Accept-Language": language,
                },
              });
              dispatch(
                setAuthXhr({
                  mobileAuthXhr,
                }),
              );
              dispatch(
                updateAuth({
                  authRefreshToken: refreshToken,
                  authAccessToken: accessToken,
                }),
              );
              if ((window as Client).shakewin) {
                store("auth_accessToken", accessToken);
                store("auth_refreshToken", refreshToken);
              } else {
                store("auth_accessToken", accessToken);
                store("auth_refreshToken", refreshToken);
              }
              dispatch(
                setAuthId({
                  authId: res.id,
                }),
              );
              generateSWToken(accessToken);
            })
            .catch((err: { message: any }) => {
              console.error(err);
              (window as any)?.showToast(err.message);
              dispatch(logout());
              window.location.href = "/";
            });
        })
        .catch((err: { message: string }) => {
          console.error(err);
          if (err?.message.includes("is not connected to any user")) {
            (window as any)?.showToast(
              `This account is not connected to any ${
                providerId || ""
              } user, redirecting to register page...`,
            );
            setCookie("behaviour", "signup", { path: "/" });
            return;
          }
          Sentry.captureException(err);
          (window as any)?.showToast(err.message);
          window.location.href = "/";
        });
    }
  }, []);

  return (
    <div
      style={{
        padding: "64px 16px",
        textAlign: "center",
        color: "#fff",
        fontSize: "14px",
        fontWeight: 500,
      }}
    >
      {t("requestingCredential")}
      <div
        style={{
          marginTop: "32px",
        }}
      >
        <SpinnerCircular
          size={44}
          thickness={180}
          speed={100}
          color="#FFB906"
          secondaryColor="rgba(0, 0, 0, 0)"
        />
      </div>
    </div>
  );
};
