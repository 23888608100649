import React, { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./styles/result.module.scss";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "../store";
import {
  noneReward,
  noneReward1,
  noneReward2,
  noneReward3,
  pendingReward,
} from "../constants/prizeTemplate";
import { ApexReward } from "../components/Reward";
import { useUtmParamNavigate } from "../utils/utmParamNavigate";
import { ClaimSteps } from "../components/ClaimSteps";
import { SWButton } from "../components/SWButton";
import { SWOrange } from "../constants/colors";
import confetti from "canvas-confetti";
import { Trans, useTranslation } from "react-i18next";
import { clientName, getClientName } from "../config";
import store from "store2";
import usePostClaim from "../utils/postClaim";

export const Result: React.FC = () => {
  const [params] = useSearchParams();
  const navigate = useUtmParamNavigate();
  const postClaim = usePostClaim();
  const { t } = useTranslation();
  const xhr = useSelector((state) => state.data.xhr);
  const verified = useSelector((state) => state.data.verified);
  const loggedIn = useSelector((state) => state.data.loggedIn);
  const shakes = useSelector((state) => state.data.shakes);
  const puzzles: Reward[] = useSelector((state) => state.data.puzzles);
  const theme = useSelector((state) => state.data.theme);
  const resultId = params.get("result_id");
  const [rawRewardInfo, setRawRewardinfo] = useState<any>(null);
  const [rewardInfo, setRewardInfo] = useState<Reward>(pendingReward);
  const [claimSteps, setClaimSteps] = useState(<div></div>);
  const [stepInfo, setStepInfo] = useState<string[]>([]);
  const wonPrizes: Reward[] = useSelector((state) => state.data.wonPrizes);
  const [code, setCode] = useState("");
  const country = useSelector((state) => state.data.country);
  const [reward, setReward] = useState(
    <ApexReward reward={rewardInfo} rewardedPiece={rewardInfo.wonPuzzlePiece} />,
  );
  const [win, setWin] = useState(false);
  const [intro, setIntro] = useState<{
    logo: string;
    title: string;
    brandName: string;
    name: string;
    subtitle: string;
    description: string;
    styleName: string;
  }>({
    logo: "",
    title: "",
    subtitle: "",
    description: "",
    styleName: "",
    name: "",
    brandName: "",
  });
  const giveawaysNoneRewards = [noneReward2, noneReward1, noneReward3];
  const needToVerifyPhone = useMemo(
    () => !verified && rewardInfo.isVerifiedPhoneRequired,
    [verified, rewardInfo],
  );

  const verify = () => {
    if (loggedIn) {
      if (!verified && country !== "BR") {
        navigate(`/login/verify?prize_id=${rewardInfo.id}&prize_type=${rewardInfo.type}`);
        return;
      }
      navigate(`/prize/claim?prize_id=${rewardInfo.id}&prize_type=${rewardInfo.type}`);
      return;
    } else {
      navigate("/login");
    }
  };

  const useNextShake = () => {
    navigate(theme.shakingUrl);
  };

  const earnMoreShakes = () => {
    if (theme.name === "giveaway") {
      (window as Client).shakewin?.openOperaMiniDeepLink("opera-mini://newsfeed/Games", true);
      return;
    }
    navigate("/missions");
  };

  const claim = useCallback(
    (params: { url?: string; id: string; type: string }) => {
      if (needToVerifyPhone) {
        return navigate(`/login/verify?prize_id=${params.id}&prize_type=${params.type}`);
      }
      if (!rewardInfo.isClaimFormRequired || rewardInfo.isClaimed) {
        if (rewardInfo.isClaimed) {
          navigate(`/prize/claim?prize_id=${params.id}&prize_type=${params.type}`);
          return;
        }
        window.open(params.url);
        return;
      }
      if (rewardInfo.isClaimFormRequired) {
        if (country === "BR" && store("formCache")) {
          postClaim({
            prizeId: rewardInfo.id,
            prizeType: rewardInfo.type,
            data: store("formCache"),
          }).then(() => {
            getDetails();
          });
          return;
        }
        navigate(`/prize/claim?prize_id=${params.id}&prize_type=${params.type}`);
      }
    },
    [rewardInfo, needToVerifyPhone],
  );

  const [buttons, setButtons] = useState(
    <div className={styles.button}>
      <SWButton text={t("saveYourProgress")} callback={verify} />
    </div>,
  );

  // 获取认领的步骤
  useEffect(() => {
    if (rewardInfo.type === "immediate" || rewardInfo.type === "puzzle") {
      if (
        ((rewardInfo.isClaimFormRequired && rewardInfo.isClaimed) ||
          !rewardInfo.isClaimFormRequired) &&
        country &&
        win &&
        rewardInfo.claimSteps?.length
      ) {
        xhr
          .getPrizeCouponCode({
            prize_id: rewardInfo.id,
            prize_type: rewardInfo.type,
          })
          .then((res: { couponCode: string }) => {
            setCode(res.couponCode || "");
            const stepInfo = rewardInfo.claimSteps
              ? rewardInfo.claimSteps.map((el: string, index) => {
                  if (rewardInfo.redirectUrl && getClientName() === "mini") {
                    if (index === 0 && country === "BR") {
                      return t("Go to shopee.com.br");
                    }
                  }
                  if (el.includes("#code##")) {
                    return el.replace("#code##", res.couponCode || "");
                  }
                  return el;
                })
              : [];
            setClaimSteps(
              <div>
                <ClaimSteps steps={stepInfo} />
              </div>,
            );
          })
          .catch(() => {
            setCode("");
          });
      }
    }
  }, [rewardInfo, country, win, rewardInfo.claimSteps]);

  // 设置按钮
  useEffect(() => {
    if (loggedIn) {
      if (!needToVerifyPhone && !rewardInfo.isClaimFormRequired) {
        return setButtons(<></>);
      }
      if (win) {
        setButtons(
          <div>
            <SWButton
              text={rewardInfo.redirectButtonTitle || t("claimYourPrize") || "Claim your prize"}
              callback={() => {
                claim({
                  url: rewardInfo.redirectUrl,
                  id: rewardInfo.id,
                  type: rewardInfo.type,
                });
              }}
            />
          </div>,
        );
        return;
      }
      return;
    }
    setButtons(
      <div>
        <SWButton
          text={
            rewardInfo.type === "puzzle"
              ? t("saveYourProgress")
              : rewardInfo.type === "none"
              ? t("tryAgain")
              : t("verifyToClaimIt")
          }
          callback={
            rewardInfo.type === "none"
              ? () => {
                  navigate(theme.shakingUrl);
                }
              : verify
          }
        />
      </div>,
    );
  }, [rewardInfo.type, verified, win]);

  useEffect(() => {
    setReward(<ApexReward reward={rewardInfo} rewardedPiece={rewardInfo.wonPuzzlePiece} />);
    setWin(!!rewardInfo.id);
  }, [rewardInfo]);

  const getDetails = useCallback(() => {
    if (!xhr.headers.Authorization || !xhr.headers["X-CountryCode"]) {
      return;
    }
    if (resultId && resultId !== "none" && resultId !== "undefined") {
      xhr.getShakeResult({ result_id: resultId }).then((res: { prizeWon: Prize }) => {
        const prize = res.prizeWon;
        if (!prize) {
          if (theme.name === "giveaway") {
            setRewardInfo(giveawaysNoneRewards[Math.floor(2.5 * Math.random())]);
          } else {
            setRewardInfo(noneReward);
          }

          return;
        }
        setRawRewardinfo(prize);
      });
      return;
    }
    if (theme.name === "giveaway") {
      setRewardInfo(giveawaysNoneRewards[Math.floor(2.5 * Math.random())]);
    } else {
      setRewardInfo(noneReward);
    }
  }, [xhr]);

  useEffect(() => {
    getDetails();
  }, [xhr]);

  useEffect(() => {
    if (!resultId) {
      if (theme.name === "giveaway") {
        setRewardInfo(giveawaysNoneRewards[Math.floor(2.5 * Math.random())]);
      } else {
        setRewardInfo(noneReward);
      }
      return;
    }
    if (rawRewardInfo) {
      let [height, width] = [0, 0];
      const size: number = rawRewardInfo.progress?.total || 0;
      if (size >= 9) {
        height = 3;
        width = 3;
      } else if (size === 6) {
        height = 2;
        width = 3;
      } else if (size === 4) {
        height = 2;
        width = 2;
      } else {
        height = 1;
        width = size;
      }
      const wonCount =
        rawRewardInfo.type === "puzzle" &&
        (rawRewardInfo.progress?.current || 0) >= (rawRewardInfo.progress?.total || 0)
          ? !rawRewardInfo.prizeId
            ? wonPrizes.filter((el) => {
                return el.prizeTypeId === rawRewardInfo.prizeTypeId;
              }).length
            : wonPrizes.filter((el) => {
                return el.prizeTypeId === rawRewardInfo.prizeTypeId;
              }).length - 1
          : 0;
      const pieces = rawRewardInfo.puzzlePieces
        ? rawRewardInfo.puzzlePieces.map((piece: { count: number }) => {
            return {
              ...piece,
              count: piece.count - wonCount,
            };
          })
        : [];
      const progress = {
        total: rawRewardInfo.progress.total || 1,
        current: pieces.filter((el: { count: number }) => el.count > 0).length,
      };
      const tmp: Reward = {
        id: rawRewardInfo.prizeId || "",
        prizeTypeId: rawRewardInfo.prizeTypeId || "",
        brandLogo: rawRewardInfo.thumbnailUrl || require("../assets/images/transparent.png"),
        type: rawRewardInfo.type,
        title: rawRewardInfo.title,
        subtitle: rawRewardInfo.subtitle,
        brandName: rawRewardInfo.brandName,
        name: rawRewardInfo.name,
        giftPicture: rawRewardInfo.imageUrl || require("../assets/images/transparent.png"),
        height: height,
        width: width,
        wonPuzzlePieces: pieces || [],
        wonPuzzlePiece: rawRewardInfo.wonPuzzlePiece || 0,
        won: (rawRewardInfo.progress?.current || 0) >= (rawRewardInfo.progress?.total || 0),
        progress: progress || {
          total: 1,
          current: 0,
        },
        claimSteps: rawRewardInfo.claimSteps || [],
        redirectButtonTitle: rawRewardInfo.redirectButtonTitle,
        redirectUrl: rawRewardInfo.redirectUrl,
        isClaimFormRequired: rawRewardInfo.isClaimFormRequired,
        isVerifiedPhoneRequired: rawRewardInfo.isVerifiedPhoneRequired,
        isClaimed: rawRewardInfo.isClaimed,
        couponCode: rawRewardInfo.couponCode,
      };
      setRewardInfo(tmp);
      setStepInfo(
        tmp.claimSteps
          ? tmp.claimSteps.map((el: string) => {
              if (el.includes("#code##")) {
                return el.replace("#code##", code || "");
              }
              return el;
            })
          : [],
      );
    }
  }, [rawRewardInfo, puzzles, code]);

  // Handle the steps of claiming a prize
  useEffect(() => {
    setClaimSteps(
      stepInfo.length && win ? (
        <div>
          <ClaimSteps steps={stepInfo} />
        </div>
      ) : (
        <div></div>
      ),
    );
  }, [stepInfo, win]);

  // Handle the introduction
  useEffect(() => {
    const introObj = {
      title: "",
      subtitle: "",
      brandName: "",
      name: "",
      logo: "",
      description: "",
      styleName: styles.title_text,
    };
    if (rewardInfo.type === "puzzle") {
      introObj.title = !rewardInfo.id ? "" : rewardInfo.title || t("congratulations");
      introObj.subtitle =
        (rewardInfo.progress?.total || 0) - (rewardInfo.progress?.current || 0) !== 0
          ? t("findRemainingPieces", {
              count: (rewardInfo.progress?.total || 0) - (rewardInfo.progress?.current || 0),
            })
          : "";
      introObj.description =
        (rewardInfo.progress?.total || 0) - (rewardInfo.progress?.current || 0) !== 0
          ? t("findRemainingPieces", {
              count: (rewardInfo.progress?.total || 0) - (rewardInfo.progress?.current || 0),
            })
          : "";
      introObj.logo = rewardInfo.brandLogo;
      introObj.brandName = rewardInfo.brandName;
      introObj.name = rewardInfo.name;
    } else if (rewardInfo.type === "immediate") {
      introObj.title = rewardInfo.title || t("congratulations");
      introObj.subtitle = rewardInfo.subtitle || "";
      introObj.description = rewardInfo.subtitle || "";
      introObj.logo = rewardInfo.brandLogo;
      introObj.styleName = styles.title_text_immediate;
      introObj.brandName = rewardInfo.brandName;
      introObj.name = rewardInfo.name;
    }
    setIntro(introObj);
  }, [rewardInfo]);

  // 礼花
  useEffect(() => {
    if (win && resultId && resultId !== "none" && resultId !== "undefined") {
      confetti({
        particleCount: 100,
        startVelocity: 30,
        spread: 320,
        origin: {
          x: 1.1,
          y: 0.1,
        },
      });
    }
  }, [resultId, win]);

  return rewardInfo.type === "pending" ? (
    <div
      style={{
        height: "400px",
      }}
    ></div>
  ) : (
    <div>
      <div className={styles.main}>
        <div
          className={styles.title}
          style={{
            display: rewardInfo.type === "none" ? "none" : "",
          }}
        >
          <img className={styles.logo} src={rewardInfo.brandLogo} alt={rewardInfo.brandName} />
          <div className={styles.title_name}>{intro.brandName}</div>
        </div>
        <div
          className={styles.intro}
          style={{
            display: rewardInfo.type !== "none" ? "" : "none",
          }}
        >
          <Trans
            components={{
              1: (
                <div
                  style={{
                    display:
                      rewardInfo.type === "puzzle" &&
                      (rewardInfo.progress?.total || 0) > (rewardInfo.progress?.current || 0)
                        ? ""
                        : "none",
                  }}
                  className={intro.styleName}
                />
              ),
              2: (
                <span
                  style={{
                    color: SWOrange,
                    fontSize: "18px",
                    fontWeight: 900,
                  }}
                />
              ),
            }}
            values={{
              name: intro.name,
            }}
          >
            youFoundPiece
          </Trans>
          <div className={intro.styleName}>{intro.title}</div>
          <div className={styles.intro_guide}>{intro.subtitle}</div>
        </div>
        {reward}
        {!needToVerifyPhone &&
        ((rewardInfo.isClaimFormRequired && rewardInfo.isClaimed) ||
          !rewardInfo.isClaimFormRequired)
          ? claimSteps
          : null}
        {buttons}
        <div
          style={{
            display: loggedIn && clientName === "mini" ? "" : "none",
            marginTop: "12px",
          }}
        >
          <SWButton
            text={
              shakes > 0
                ? theme.name === "giveaway"
                  ? t("openAGift")
                  : t("useNextShake")
                : theme.name === "giveaway"
                ? t("playMoreGames")
                : t("earnMoreShakes")
            }
            background={"rgba(255, 255, 255, 0.2)"}
            color={"#ffffff"}
            callback={shakes > 0 ? useNextShake : earnMoreShakes}
          />
        </div>
      </div>
    </div>
  );
};
