import React, { useEffect, useState } from "react";
// import { MissionsBR } from "../MissionsBR";
import { AppDispatch, useSelector } from "../../store";
import { useUtmParamNavigate } from "../../utils/utmParamNavigate";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { setGaDot } from "../../utils/gaDot";
import { setDot } from "../../utils/dot";
import { browserName } from "../../config";
import { fetchMissions, setShakes, setUnlockTime } from "../../store/state";
import { BottomModal } from "../../components/BottomModal";
import { countDown } from "../../utils/time";
import { Missions } from "../Missions";
import { SWButton } from "../../components/SWButton";
import { Footer } from "../../components/Footer";
import store from "store2";
import PromMini from "components/promotion/Mini";

export const MissionList: React.FC = () => {
  const country = useSelector((state) => state.data.country);
  const navigate = useUtmParamNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const xhr = useSelector((state) => state.data.xhr);
  const phase = useSelector((state) => state.data.phase);
  const shakes = useSelector((state) => state.data.shakes);
  const unlockTime = useSelector((state: any) => state.data.unlockedTime);
  const missionIds = useSelector((state: any) => state.data.missions);
  const theme = useSelector((state) => state.data.theme);
  const timeCountDown = countDown();
  const client = !(window as Client).shakewin?.getClientName
    ? (window as any).news_data_h5
      ? "news"
      : "H5"
    : (window as Client).shakewin?.getClientName().includes("mini")
    ? "mini"
    : "ofa";
  const [diff, setDiff] = useState(unlockTime - new Date().getTime() / 1000);
  const verified = useSelector((state) => state.data.verified);
  const campaign = useSelector((state) => state.data.campaign);
  const [completedBrowserMissionOfa, setCompletedBrowserMissionOfa] = useState(false);
  const [completedBrowserMissionMini, setCompletedBrowserMissionMini] = useState(false);
  const [completedOfaReferral, setCompletedOfaReferral] = useState(false);
  const [foundShakes, setFoundShakes] = useState(false);
  const [updatedApp, setUpdatedApp] = useState(false);
  const [wallpaperMission, setWallpaperMission] = useState(false);
  const [miniPayMission, setMiniPayMission] = useState(false);
  const [predictorCompleted, setPredictorCompleted] = useState(false);
  const fetchingMissions = useSelector((state: any) => state.data.fetchingMissions);

  const [showSteps, setShowSteps] = useState(false);
  const [showGrantTime, setShowGrantTime] = useState(false);
  const [missionToken, setMissionToken] = useState("");
  const { t } = useTranslation();

  const goToNews = () => {
    setGaDot({
      action: "earn_more_shakes",
      value: {
        button: "Go to news",
      },
    });
    setDot("event", "earn_more_shakes", "button", "Go to news");
    if ((window as Client)?.shakewin?.openOperaMiniDeepLink) {
      // @ts-ignore
      (window as Client)?.shakewin?.openOperaMiniDeepLink("opera-mini://newsfeed/", true);
    }

    return;
  };
  const missionDetails: {
    [key: string]: MissionDetail;
  } = {
    "mini-default-browser": {
      title: t("makeDefault", { browser: "Opera Mini" }) || "Make Opera Mini default",
      description:
        t("makeDefaultText", { browser: "Opera Mini" }) ||
        "Earn one free shake for setting Opera Mini as your default browser. The new shake will be granted within 24 hours.",
      hint: "Earn shakes by setting Opera Mini as your default browser. Your free shake will be awarded in 24 hours.",
      showHint: false,
      finishedCount: 0,
      allCount: 0,
      showHintFunc: () => {
        if (this) {
          // @ts-ignore
          this.showHint = true;
          setTimeout(() => {
            // @ts-ignore
            this.showHint = false;
          }, 300);
        }
      },
      button: [
        {
          name: completedBrowserMissionMini
            ? t("completed")
            : t("makeDefaultButton", { browser: "Opera Mini" }) || `Make Opera Mini default  >`,
          done: completedBrowserMissionMini,
          callback: () => {
            if (completedBrowserMissionMini) {
              return;
            }
            setGaDot({
              action: "earn_more_shakes",
              value: {
                button: "Make Opera Mini default",
              },
            });

            setDot("event", "earn_more_shakes", "button", "Make Opera Mini default");
            if ((window as any)?.shakewin?.openOperaMiniDeepLink) {
              (window as any)?.shakewin.openOperaMiniDeepLink(
                "opera-mini://set_default_browser",
                false,
              );
            } else {
              setShowGrantTime(false);
              setShowSteps(true);
            }
            return;
          },
        },
      ],
    },
    "hidden-shake": {
      title: t("findHiddenShakes") || "Find hidden Shakes",
      description: "hiddenShakesDescription",
      hint: "hiddenShakesHint",
      showHint: true,
      finishedCount: 0,
      allCount: 0,
      showHintFunc: () => {
        if (this) {
          // @ts-ignore
          this.showHint = true;
          setTimeout(() => {
            // @ts-ignore
            this.showHint = false;
          }, 300);
        }
      },
      button: [
        {
          name: foundShakes ? t("completed") : t("goToNews") || `Go to news  >`,
          done: foundShakes,
          callback: () => {
            if (foundShakes) {
              return;
            }
            goToNews();
          },
        },
      ],
    },
    "ofa-default-browser": {
      title: t("makeDefault", { browser: "Opera" }) || "Make Opera default",
      description:
        t("makeDefaultText", { browser: "Opera" }) ||
        "Earn one free shake for setting Opera as your default browser. The new shake will be granted within 24 hours.",
      hint:
        t("makeDefaultHint") ||
        "Earn shakes by setting Opera as your default browser. Your free shake will be awarded in 24 hours.",
      showHint: false,
      showHintFunc() {
        if (this) {
          // @ts-ignore
          this.showHint = true;
          setTimeout(() => {
            // @ts-ignore
            this.showHint = false;
          }, 300);
        }
      },
      button: [
        {
          name: completedBrowserMissionOfa
            ? t("completed")
            : t("makeDefaultButton", { browser: "Opera" }) || `Make Opera default  >`,
          done: completedBrowserMissionOfa,
          callback: () => {
            if (completedBrowserMissionOfa) {
              return;
            }
            setGaDot({
              action: "earn_more_shakes",
              value: {
                button: "Make Opera default",
              },
            });
            setDot("event", "earn_more_shakes", "button", "Make Opera default");
            if (
              (window as any)?.shakewin?.isDefaultBrowserSupported &&
              (window as any)?.shakewin?.isDefaultBrowserSupported()
            ) {
              window.location.href = "ofa://system/default_browser";
            } else {
              setShowGrantTime(false);
              setShowSteps(true);
            }
          },
        },
      ],
    },
    "mini-referral": {
      title: t("referToAFriend", { browser: "Opera Mini" }) || "Refer Opera Mini to a friend",
      description:
        t("referToAFriendText", { browser: "Opera Mini" }) ||
        "Earn one free shake for each friend that plays Shake & Win using a verified phone number with Opera Mini. It will be granted in at most 24h after you finish the mission.",
      hint: "You will receive your free shake when the person you referred verifies their phone number inside Shake & Win.",
      showHint: false,
      showHintFunc() {
        if (this) {
          // @ts-ignore
          this.showHint = true;
          setTimeout(() => {
            // @ts-ignore
            this.showHint = false;
          }, 300);
        }
      },
      button: [
        {
          name: t("referAFriend") || `Refer a friend  >`,
          done: false,
          callback: () => {
            setGaDot({
              action: "earn_more_shakes",
              value: {
                button: "Refer Opera Mini to a friend",
              },
            });
            setDot("event", "earn_more_shakes", "button", "Refer Opera Mini to a friend");
            navigate("/missions/mini-referral");
          },
        },
      ],
    },
    "ofa-referral": {
      title: t("referToAFriend", { browser: "Opera" }) || "Refer Opera to a friend",
      hint: t("referToAFriendHint") || "",
      description:
        t("referToAFriendDescription") ||
        "Refer 2 friends to Opera Browser and earn 250mb of free data.",
      showHint: false,
      finishedCount: 0,
      allCount: 2,
      showHintFunc: () => {
        if (this) {
          // @ts-ignore
          this.showHint = true;
          setTimeout(() => {
            // @ts-ignore
            this.showHint = false;
          }, 300);
        }
      },
      button: [
        {
          name: !completedOfaReferral ? t("referAFriend") : t("claimYourFreeData"),
          done: false,
          callback: async () => {
            if (completedOfaReferral && shakes) {
              store("autoShake", true);
              navigate("/shaking");
              return;
            }
            setGaDot({
              action: "earn_more_shakes",
              value: {
                button: "Refer Opera to a friend",
              },
            });
            setDot("event", "earn_more_shakes", "button", "Refer Opera to a friend");
            navigate("/missions/ofa-referral");
          },
        },
      ],
    },
    predictor: {
      title: t("playOperaPredictor") || "Play Opera Predictor",
      description:
        t("playOperaPredictorText") ||
        "Earn one free shake for playing Opera’s football predictor game. Predict the outcomes correctly to win $10,000!",
      showHint: false,
      showHintFunc: () => {
        if (this) {
          // @ts-ignore
          this.showHint = true;
          setTimeout(() => {
            // @ts-ignore
            this.showHint = false;
          }, 300);
        }
      },
      button: [
        {
          name: predictorCompleted
            ? t("completed")
            : t("playOperaPredictorButton") || "Play Opera Predictor  >",
          done: predictorCompleted,
          callback: () => {
            if (!missionToken) {
              return;
            }
            setDot("event", "earn_more_shakes", "button", "Play Opera Predictor");
            setGaDot({
              action: "earn_more_shakes",
              value: {
                button: "Play Opera Predictor",
              },
            });
            const url = `${
              process.env.REACT_APP_MODE === "production"
                ? "https://predictor.apex-football.com"
                : "https://testpredictor.apex-football.com"
            }?mission=${missionToken}`;
            window.open(
              url,
              "_blank", // <- This is what makes it open in a new window.
            );
          },
        },
      ],
    },
    "update-app": {
      title:
        t("updateApp", { client: client === "mini" ? "Mini" : "Opera" }) || "Update Opera Mini",
      description:
        t("updateAppText", { client: client === "mini" ? "Mini" : "Opera" }) ||
        "Update Opera Mini to the latest version in the Google Play Store and get a free shake.",
      hint:
        t("updateAppHint") ||
        "This free shake will only be awarded to updates that happen through the Google Play Store. Use the button below to get started.",
      showHint: false,
      showHintFunc: () => {
        if (this) {
          // @ts-ignore
          this.showHint = true;
          setTimeout(() => {
            // @ts-ignore
            this.showHint = false;
          }, 300);
        }
      },
      button: [
        {
          name: updatedApp
            ? t("completed")
            : t("updateAppBtn", { client: browserName }) || "Update Mini  >",
          done: updatedApp,
          callback: () => {
            setDot("event", "earn_more_shakes", "button", "Update App");
            setGaDot({
              action: "earn_more_shakes",
              value: {
                button: "Update App",
              },
            });
            if (client === "mini") {
              if ((window as Client).shakewin?.getClientName()?.includes("beta")) {
                (window as Client).shakewin?.openOperaMiniDeepLink(
                  "opera-mini://open?url=https://play.google.com/store/apps/details?id=com.opera.mini.native.beta",
                  true,
                );
              } else {
                (window as Client).shakewin?.openOperaMiniDeepLink(
                  "opera-mini://open?url=https://play.google.com/store/apps/details?id=com.opera.mini.native",
                  true,
                );
              }
            }
            if (client === "ofa") {
              if ((window as Client).shakewin?.getClientName()?.includes("beta")) {
                (window as Window).location.href =
                  "https://play.google.com/store/apps/details?id=com.opera.browser.beta";
              } else {
                (window as Window).location.href =
                  "https://play.google.com/store/apps/details?id=com.opera.browser";
              }
            }
            return;
          },
        },
      ],
    },
    "set-wallpaper": {
      title: t("setAWallpaper", { name: browserName }) || `Set a Wallpaper in ${browserName}`,
      description:
        t("setAWallpaperDescription", { browserName }) ||
        `Change your wallpaper in ${browserName}, earn a free shake.`,
      hint:
        t("setAWallpaperHint") ||
        "This free shake will only be awarded to changing your wallpaper. Use the button below to get started.",
      showHint: false,
      showHintFunc: () => {
        if (this) {
          // @ts-ignore
          this.showHint = true;
          setTimeout(() => {
            // @ts-ignore
            this.showHint = false;
          }, 300);
        }
      },
      button: [
        {
          name: wallpaperMission ? t("completed") : t("setAWallpaperBtn") || "Set a wallpaper  >",
          done: wallpaperMission,
          callback: () => {
            setDot("event", "earn_more_shakes", "button", "Set wallpaper");
            setGaDot({
              action: "earn_more_shakes",
              value: {
                button: "Set wallpaper",
              },
            });
            if (client === "mini") {
              (window as Client).shakewin?.openOperaMiniDeepLink(
                "opera-mini://wallpapers/wallpaper_picker",
                true,
              );
            }
            if (client === "ofa") {
              (
                window as Window
              ).location.href = `ofa://ui/wallpaper/gallery?source=shake_win&campaign=${campaign}`;
            }
            return;
          },
        },
      ],
    },
    "minipay-wallet": {
      title: `Activate MiniPay Wallet`,
      description: "Get a free shake when you activate MiniPay Wallet in Opera Mini",
      hint: "This free shake will only be awarded to activate MiniPay Wallet in Opera Mini. Use the button below to get started.",
      showHint: false,
      showHintFunc: () => {
        if (this) {
          // @ts-ignore
          this.showHint = true;
          setTimeout(() => {
            // @ts-ignore
            this.showHint = false;
          }, 300);
        }
      },
      button: [
        {
          name: miniPayMission ? t("completed") : "Activate MiniPay  >",
          done: miniPayMission,
          callback: () => {
            setDot("event", "earn_more_shakes", "button", "Activate MiniPay");
            setGaDot({
              action: "earn_more_shakes",
              value: {
                button: "Activate MiniPay",
              },
            });
            (window as Client).shakewin?.openOperaMiniDeepLink("opera-mini://minipay", true);
            return;
          },
        },
      ],
    },
  };

  useEffect(() => {
    let user_status = "anno_user";
    if (verified) {
      user_status = "phone_verify";
    }

    setDot("pv", "pv_earn_more_shake_page", "user_status", user_status);
    setDot("pv", "pv_earn_more_shake_page", "", "");
    setGaDot({
      action: "pv_earn_more_shake_page",
      value: { user_status: user_status },
    });
  }, []);

  useEffect(() => {
    if (!fetchingMissions) {
      dispatch(fetchMissions());
    }
  }, []);

  // Check if the next shake is coming
  useEffect(() => {
    const timer = setInterval(() => {
      setDiff(unlockTime - new Date().getTime() / 1000);
      if (unlockTime - new Date().getTime() / 1000 <= 0) {
        if (shakes === 0) {
          xhr
            .getShakes()
            .then((res: ShakesInfo) => {
              const shakes = res?.shakesCount || 0;
              const recaptchaRequired = res.recaptchaRequired || false;
              dispatch(
                setShakes({
                  shakes: shakes,
                  recaptchaRequired,
                }),
              );
              dispatch(
                setUnlockTime({
                  unlockedTime: new Date(res.timeOfFreeShake).getTime() / 1000,
                }),
              );
            })
            .catch((e: { message: string }) => {
              console.error(e);
            });
        }
      }
    }, 500);
    return function () {
      clearInterval(timer);
    };
  }, [unlockTime, shakes, xhr]);

  useEffect(() => {
    missionIds?.forEach((el: { id: string; isCompleted: boolean; availableShakes?: number }) => {
      if (el.id.includes("ofa-default-browser")) {
        setCompletedBrowserMissionOfa(el.isCompleted);
      }
      if (el.id.includes("ofa-referral")) {
        // setCompletedOfaReferral(el.isCompleted);
        setCompletedOfaReferral(!!el.availableShakes);
      }
      if (el.id.includes("mini-default-browser")) {
        setCompletedBrowserMissionMini(el.isCompleted);
      }
      if (el?.id?.includes("hidden-shake")) {
        setFoundShakes(el.isCompleted);
        return;
      }
      if (el?.id?.includes("update-app")) {
        setUpdatedApp(el.isCompleted);
        return;
      }
      if (el?.id?.includes("set-wallpaper")) {
        setWallpaperMission(el.isCompleted);
        return;
      }
      if (el?.id?.includes("minipay-wallet")) {
        setMiniPayMission(el.isCompleted);
        return;
      }
      if (el.id.includes("predictor")) {
        if (el.isCompleted) {
          setPredictorCompleted(true);
          return;
        }
        xhr
          .getMissionsPredictorToken()
          .then((res: { missionToken: string }) => {
            const { missionToken } = res;
            setMissionToken(missionToken);
          })
          .catch((e: any) => {
            alert(JSON.stringify(e));
          });
      }
    });
  }, [missionIds]);
  return (
    <div style={{ paddingTop: "64px" }}>
      <div style={{ display: shakes === 0 ? "" : "none", margin: "8px 16px", textAlign: "center" }}>
        <div style={{ lineHeight: "20px", fontWeight: 700, fontSize: "16px" }}>
          {shakes === 0
            ? phase === "CLAIM_ONLY"
              ? t("phaseEnd")
              : t("ranOutShakes")
            : t("earnedAShake")}
        </div>
        <div style={{ lineHeight: "20px", fontWeight: 400, fontSize: "14px" }}>
          {phase === "CLAIM_ONLY" && country === "BR"
            ? t("winFreeData", { amount: "250mb" })
            : t("unLocksIn")}
        </div>
        <div
          style={{
            marginTop: "8px",
            padding: "8px 16px",
            fontWeight: 900,
            fontSize: "22px",
            background: theme.secondaryBackgroundColor,
            display: "inline-block",
            borderRadius: "8px",
          }}
        >
          {timeCountDown(diff)}
        </div>
      </div>
      <div
        style={{
          display: shakes === 0 || phase !== "IN_PROGRESS" ? "none" : "",
          margin: "8px 16px",
          textAlign: "center",
        }}
      >
        <SWButton
          text={t("useNextShake")}
          background={theme.buttonColor}
          color={theme.buttonFontColor}
          callback={() => {
            navigate("/shaking");
          }}
        />
      </div>
      <div
        style={{
          display: "none",
          padding: "12px",
          textAlign: "center",
          fontSize: "12px",
        }}
      >
        <p>
          Can’t wait? - Find hidden shakes in your{" "}
          <u
            onClick={() => {
              goToNews();
            }}
          >
            news feed
          </u>
          !
        </p>
      </div>
      {/* <div style={{ padding: "8px 16px" }}>
        <section
          style={{
            height: "108px",
            position: "relative",
          }}
        >
          <h3>
            <span
              style={{
                padding: "4px",
                background: theme.highlightBackground,
                fontSize: "12px",
                borderRadius: "4px",
                fontWeight: 700,
              }}
            >
              OPERA CUP
            </span>
          </h3>
          <h3
            style={{
              width: "180px",
              fontSize: "14px",
              fontWeight: 600,
              padding: "12px 0",
              lineHeight: "22px",
            }}
          >
            Unlock extra prizes for your nation by completing missions!
          </h3>
          <img
            src={require("../../assets/images/afcon/lady.png")}
            style={{
              position: "absolute",
              width: "110px",
              height: "110px",
              right: "8px",
              bottom: 0,
            }}
            alt={""}
          />
        </section>
        <div
          style={{
            padding: "12px",
            borderRadius: "12px",
            background: theme.secondaryBackgroundColor,
          }}
        >
          <h3
            style={{
              fontSize: "14px",
              fontWeight: 600,
              lineHeight: "22px",
            }}
          >
            Compete for the most missions completed!
          </h3>
          <p
            style={{
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "16px",
              color: "#FFFFFFBF",
              padding: "8px 0",
            }}
          >
            The nation with the most completed missions by 12/02/24 will receive an extra prizes,
            greatly increasing your chances of winning!
          </p>
          <SWButton
            text={"View leaderboard >"}
            callback={() => {
              navigate("/stats");
            }}
            background={"#FFFFFF33"}
            color={"#fff"}
            size={"medium"}
            fontWeight={600}
            fontSize={"14px"}
          />
        </div>
      </div> */}
      <PromMini />
      <Missions missionDetails={missionDetails} />
      <div
        style={{
          display: showSteps ? "" : "none",
        }}
        onClick={() => {
          setShowSteps(false);
          setShowGrantTime(true);
        }}
      >
        <BottomModal title={t("followTheseSteps") || "Follow these steps"} type={"Steps to set"} />
      </div>
      <div
        style={{
          display: showGrantTime ? "" : "none",
        }}
        onClick={() => {
          setShowGrantTime(false);
        }}
      >
        <BottomModal type={"Grant time"} />
      </div>
      <Footer />
    </div>
  );
};
