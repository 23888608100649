import React from "react";
import styles from "../styles/tc.module.scss";

export const Tc: React.FC = () => {
  return (
    <div>
      <div className={[styles["rules"], styles["tc"]].join(" ")}>
        <div className={styles["head-title"]}>Opera Shake and Win Contest Special Rules</div>
        <div className={styles["head-content"]}>
          <p>
            These Special Rules supplement and amend the Opera general rules for contests (the
            “General Rules”). In the event of a conflict between between the Special Rules and{" "}
            <a href="https://www.opera.com/portal/contestrules">the General Rules</a>, the Special
            Rules will control.
          </p>
          <p className={styles["content-tc"]}>
            <span className={styles["head-content-num"]}>1.</span>
            The Shake and Win contest is only open to residents of Nigeria, Kenya and South Africa.
          </p>
          <p className={styles["content-tc"]}>
            <span className={styles["head-content-num"]}>2.</span>
            The Shake and Win contest will commence on 14 August 2024 and end on 11 September 2024
            (“Contest Period”).
          </p>
          <p className={styles["content-tc"]}>
            <span className={styles["head-content-num"]}>3.</span>
            By participating in the contest, You must:
            <ul>
              <li>be over 18 years old;</li>
              <li>
                own the Android device and be an Opera Mini (latest versions), Opera News user;
              </li>
              <li>Accepting these Special Rules and the General Rules;</li>
              <li>Register to participate in the Shake and Win Contest;</li>
              <li>follow the specific game rules;</li>
            </ul>
            By fulfilling the above you become the “Participant”.
          </p>
          <p className={styles["content-tc"]}>
            <span className={styles["head-content-num"]}>4.</span>
            Only one registration will be accepted per person and/or per registered cellular number.
            Multiple registrations from the same person and/or cellular number will result in the
            contestant being disqualified from participating in the Shake and Win contest as well as
            result in the forfeiture of any prizes awarded pursuant to participation in the Shake
            and Win Contest.
          </p>
          <p className={styles["content-tc"]}>
            <span className={styles["head-content-num"]}>5.</span>
            During the Contest Period the Participant will be able to access the Shake and Win
            contest from the App.
          </p>
          <p className={styles["content-tc"]}>
            <span className={styles["head-content-num"]}>6.</span>
            Participants will be allocated a single daily chance to win a reward. Once the
            Participant uses this chance the Participant may get an immediate price, or no price
            with the opportunity to try again with the next available chance after 24 hours. A
            randomization algorithm will be used to determine winners.
          </p>
          <p className={styles["content-tc"]}>
            <span className={styles["head-content-num"]}>7.</span>
            Depends on the App the Participants may get the following prizes:
            <ul>
              <li>
                Immediate prizes: cash prize, airtime prize, Bet9ja bonus prize (only available in
                Nigeria), MiniPay prize (only available in Nigeria).
              </li>
              <li>
                Puzzle prices: cash prize, airtime prize, smartphone, Bet9ja bonus prize (only
                available in Nigeria), MiniPay prize (only available in Nigeria)
              </li>
            </ul>
          </p>
          <p className={styles["content-tc"]}>
            <span className={styles["head-content-num"]}>8.</span>
            Where the Participant has won airtime prize, a recharge PIN will be provided to redeem
            the airtime.
          </p>
          <p className={styles["content-tc"]}>
            <span className={styles["head-content-num"]}>9.</span>
            Where a Participant has won a cash prize the Participant needs to fill out the Claim
            reward ford and provide Opera with the required details.
          </p>
          <p className={styles["content-tc"]}>
            <span className={styles["head-content-num"]}>10.</span>
            Where a Participant has won a smartphone the following shall be applicable:
            <ul>
              <li>
                Participant will be liable for their costs incurred in collecting the prizes from
                the designated fulfilment centres;
              </li>
              <li>
                {
                  "Participant must produce a legally accepted form of identification (ID or Passport) when attending a fulfilment center to collect their prize/s;"
                }
              </li>
              <li>
                {`In the event of a Participant’s registered details not aligning with the details contained in their form of identification Opera reserves the right to withhold the prize/s until such time as the contestant has satisfied Opera that s/he is the correct recipient of the prize/s.`}
              </li>
            </ul>
          </p>
          <p className={styles["content-tc"]}>
            <span className={styles["head-content-num"]}>11.</span>
            Where a Participant wins a Bet9ja bonus, the following shall be applicable:
            <ul>
              <li>
                Participant are expected to provide their Bet9ja account ID for prize verification;
              </li>
              <li>
                {
                  "The fulfilment of the Bet9ja bonus will be added directly to the contestant's Bet9ja wallet by the Bet9ja team within ten days of submission;"
                }
              </li>
              <li>
                {`Neither Opera nor Bet9ja will be liable for any discrepancies or issues arising from the provision of an incorrect Bet9ja account ID by the Participant;`}
              </li>
              <li>
                {`Participant must ensure that the account ID they provide is accurate before submitting their claim.`}
              </li>
              <li>
                {`Bet9ja’s specific bonus terms and conditions apply once the bonus has been awarded.`}
              </li>
            </ul>
          </p>
          <p className={styles["content-tc"]}>
            <span className={styles["head-content-num"]}>12.</span>
            Where a Participant wins a MiniPay prize, the following shall be applicable:
            <ul>
              <li>The Participant must have a MiniPay to get a MiniPay prize;</li>
              <li>
                {
                  "The value of the MiniPay prize will be credited directly to the Participant’s MiniPay wallet within ten days of submission; "
                }
              </li>
              <li>
                {`Participant who do not possess a MiniPay wallet must create one to claim their prize. Instructions for creating a MiniPay Wallet can be accessed on both the MiniPay prize website and the Mini Pay FAQ;`}
              </li>
            </ul>
          </p>
          <p className={styles["content-tc"]}>
            <span className={styles["head-content-num"]}>13.</span>
            Participant acknowledge and agree that risk and ownership in and to any prizes won will
            transfer to the Participant upon Opera making the prizes available to the Participants.
            Opera shall not be liable for the loss of or damage to any prizes once risk and
            ownership has transferred to the Participant.
          </p>
          <p className={styles["content-tc"]}>
            <span className={styles["head-content-num"]}>14.</span>
            Prizes may vary depending on the app within which the Participant is engaged.
            Participants agree and acknowledge that certain prizes will not be applicable to their
            respective apps.
          </p>
          <p className={styles["content-tc"]}>
            <span className={styles["head-content-num"]}>15.</span>
            All prizes awarded must be claimed on or before 25 September 2024, failing which the
            prizes/s will be deemed to have expired and will no longer be capable of redemption.
          </p>
          <p className={styles["content-tc"]}>
            <span className={styles["head-content-num"]}>16.</span>
            By entering the contest, you agree to allow Opera and its Affiliates the right to use,
            copy, quote, adapt, and publish details pertaining to your winning/s, including on our
            social media channels and websites, without any payment.
          </p>
        </div>
      </div>
    </div>
  );
};
