import React, { useCallback, useEffect, useState } from "react";
import { SWInputBar } from "../../components/SWInputBar";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../store";
import { SWButton } from "../../components/SWButton";
import { Footer } from "../../components/Footer";
import { setDot } from "../../utils/dot";
import { setGaDot } from "../../utils/gaDot";

export const ChangePassword: React.FC = () => {
  const { t } = useTranslation();
  const theme = useSelector((state) => state.data.theme);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validPassword, setValidPassword] = useState(true);
  const [validConfirmPassword, setValidConfirmPassword] = useState(true);
  const mobileAuthXhr = useSelector((state) => state.data.mobileAuthXhr);
  const [disabled, setDisabled] = useState(true);
  const upperCaseLetters = /[A-Z]/g;
  const numbers = /[0-9]/g;
  const passwordErrorText =
    "Invalid password. A valid password must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters.";
  const confirmPasswordErrorText = "The two passwords entered are inconsistent.";
  const updatePassword = useCallback(() => {
    setDisabled(true);
    mobileAuthXhr
      .putUserPassword({
        password,
      })
      .then(() => {
        setDot("click", "password_change_success", "", "");
        setGaDot({ action: "password_change_success", value: {} });
        window.location.href = "/profile";
      })
      .catch((e: any) => {
        console.error(e);
        setDisabled(false);
        (window as any).showToast?.(e?.message || "Failed on updating password");
      });
  }, [password, mobileAuthXhr]);

  const checkPassword = useCallback(() => {
    setValidPassword(
      !Boolean(
        !password ||
          !(password.match(upperCaseLetters) && password.match(numbers) && password.length >= 8),
      ),
    );
  }, [password]);

  const checkConfirmPassword = useCallback(() => {
    setValidConfirmPassword(confirmPassword === password);
  }, [password, confirmPassword]);

  useEffect(() => {
    setDisabled(
      !password ||
        !(password.match(upperCaseLetters) && password.match(numbers) && password.length >= 8) ||
        password !== confirmPassword,
    );
  }, [password, confirmPassword]);

  return (
    <div
      style={{
        minHeight: "100vh",
        overflow: "auto",
      }}
    >
      <section
        style={{
          padding: "16px",
          textAlign: "center",
        }}
      >
        <img
          style={{
            height: "44px",
            width: "auto",
            margin: "0 auto",
          }}
          src={require("../../assets/images/opera_logo.png")}
          alt={"opera"}
        />
        <h2
          style={{
            marginTop: "16px",
            fontSize: "16px",
          }}
        >
          {t("enterNewPassword")}
        </h2>
      </section>
      <section
        style={{
          margin: "0 16px",
        }}
      >
        <div
          style={{
            margin: "16px 0",
          }}
        >
          <div
            style={{
              padding: "8px 2px",
              fontSize: "14px",
            }}
          >
            {t("newPassword")}
          </div>
          <SWInputBar
            type={"password"}
            placeholder={t("enterPassword")}
            background={theme.inputBackgroundColor}
            color={theme.inputFontColor}
            placeholderColor={theme.placeholder}
            passDataFunction={setPassword}
            blurCallback={checkPassword}
            onError={!validPassword}
            errorMessage={passwordErrorText}
            focusCallback={() => {
              setValidPassword(true);
            }}
          />
        </div>
        <div
          style={{
            margin: "16px 0",
          }}
        >
          <div
            style={{
              padding: "8px 2px",
              fontSize: "14px",
            }}
          >
            {t("confirmPassword")}
          </div>
          <SWInputBar
            type={"password"}
            placeholder={t("confirmPassword")}
            background={theme.inputBackgroundColor}
            color={theme.inputFontColor}
            placeholderColor={theme.placeholder}
            passDataFunction={setConfirmPassword}
            errorMessage={confirmPasswordErrorText}
            onError={!validConfirmPassword}
            blurCallback={checkConfirmPassword}
            focusCallback={() => {
              setValidPassword(true);
            }}
          />
        </div>
      </section>
      <section
        style={{
          margin: "24px 16px",
        }}
      >
        <SWButton
          text={t("updatePassword")}
          background={theme.buttonColor}
          color={theme.buttonFontColor}
          callback={updatePassword}
          disabled={disabled}
          disabledBackground={"rgba(255, 255, 255, 0.1)"}
          disabledColor={"rgba(255, 255, 255, 0.5)"}
        />
      </section>
      <Footer />
    </div>
  );
};
