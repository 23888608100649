import React from "react";
import styles from "./styles/input.module.scss";

interface InputProp {
  checked: boolean;
}

export const SWCheckbox: React.FC<InputProp> = (props: InputProp) => {
  const { checked } = props;
  return !checked ? (
    <div className={styles["checkbox"]}>
      <div className={styles["unchecked"]}></div>
    </div>
  ) : (
    <div className={styles["checkbox"]}>
      <div className={styles["checked"]}></div>
    </div>
  );
};
