import React, { useCallback, useEffect, useState } from "react";
import styles from "./styles/items.module.scss";
import { useSelector } from "../store";
import { useLocation } from "react-router-dom";
import store from "store2";
import { useUtmParamNavigate } from "../utils/utmParamNavigate";
import { useTranslation } from "react-i18next";

interface ItemProps {
  type: "login" | "shaking" | "aviator" | "prizes" | "endPrizes" | "missions" | "giveaway";
  amount: number;
}

export const FunctionItem: React.FC<ItemProps> = (props) => {
  const location = useLocation();
  const navigate = useUtmParamNavigate();
  const userAvatar = useSelector((state) => state.data.avatarUrl);
  const userAvatarChosen = useSelector((state) => state.data.avatarDarkUrl);
  const verified = useSelector((state) => state.data.loggedIn);
  const shakes = useSelector((state) => state.data.shakes);
  const phase = useSelector((state) => state.data.phase);
  const theme = useSelector((state) => state.data.theme);

  const { type, amount } = props;
  const chosenStyles = {
    login: {
      display: "none",
      background: location.pathname === "/login" ? "#ffffff" : theme.secondaryBackgroundColor,
    },
    shaking: {
      display: "none",
      background: location.pathname === "/shaking" ? "#ffffff" : theme.secondaryBackgroundColor,
      color: location.pathname === "/shaking" ? "#191919" : "#ffffff",
    },
    aviator: {
      display: "none",
      background: location.pathname === "/login" ? "#ffffff" : theme.secondaryBackgroundColor,
      color: location.pathname === "/login" ? "#191919" : "#ffffff",
    },
    prizes: {
      display: "none",
      background: location.pathname === "/prizes" ? "#ffffff" : theme.secondaryBackgroundColor,
      color: location.pathname === "/prizes" ? "#191919" : "#ffffff",
    },
    missions: {
      display: "none",
      background: location.pathname === "/missions" ? "#ffffff" : theme.secondaryBackgroundColor,
      color: location.pathname === "/missions" ? "#191919" : "#ffffff",
    },
    giveaway: {
      display: "none",
      background: location.pathname === "/shaking" ? "#ffffff" : theme.secondaryBackgroundColor,
      color: location.pathname === "/shaking" ? "#191919" : "#ffffff",
    },
    endPrizes: {
      display: "none",
    },
  };
  const { t } = useTranslation();
  const [itemShow, setItemShow] = useState(chosenStyles);
  const hasChosenCountry = !!useSelector((state) => state.data.chosenCountry);
  const [chosen, setChosen] = useState(`/${type}` === location.pathname);
  const [rewardImg, setRewardImg] = useState(
    <img src={require("../assets/images/rewards.png")} alt="" />,
  );
  const [missionImg, setMissionImg] = useState(
    <img src={require("../assets/images/missions.png")} alt="" />,
  );

  const goTo = useCallback(() => {
    store("beforeLoginUrl", window.location.href);
    if (
      phase === "IN_PROGRESS" &&
      ["/", ""].includes(location.pathname) &&
      !hasChosenCountry &&
      !verified &&
      !store("selected_country")
    ) {
      return;
    }
    if (type === "shaking") {
      if (!verified) {
        navigate(shakes > 0 ? "/shaking" : "/login");
        return;
      }
      navigate(shakes > 0 ? "/shaking" : "/missions");
    }

    if (type === "giveaway") {
      navigate("/giveaway");
    }

    if (type === "missions") {
      navigate("/missions");
    }

    if (type === "aviator") {
      if (verified) {
        navigate("/profile");
        return;
      }
      navigate("/login");
    }
    if (type === "prizes") {
      if (phase === "CLAIM_ONLY") {
        navigate("/");
      }
      navigate("/prizes");
    }
  }, [amount, hasChosenCountry, location.pathname, verified, phase]);

  useEffect(() => {
    setChosen(
      `/${type}` === location.pathname ||
        (type === "endPrizes" && location.pathname === "/end/prizes"),
    );
  }, [location.pathname, type, verified]);

  useEffect(() => {
    const chosenStyles = {
      login: {
        display: "none",
        background: location.pathname === "/login" ? "#ffffff" : theme.secondaryBackgroundColor,
        color: location.pathname === "/login" ? "#191919" : "#ffffff",
      },
      shaking: {
        display: "none",
        background: location.pathname === "/shaking" ? "#ffffff" : theme.secondaryBackgroundColor,
        color: location.pathname === "/shaking" ? "#191919" : "#ffffff",
      },
      aviator: {
        display: "none",
        background: location.pathname === "/profile" ? "#ffffff" : theme.secondaryBackgroundColor,
        color: location.pathname === "/profile" ? "#191919" : "#ffffff",
      },
      prizes: {
        display: "none",
        background: location.pathname === "/prizes" ? "#ffffff" : theme.secondaryBackgroundColor,
        color: location.pathname === "/prizes" ? "#191919" : "#ffffff",
      },
      missions: {
        display: "none",
        background: location.pathname === "/missions" ? "#ffffff" : theme.secondaryBackgroundColor,
        color: location.pathname === "/missions" ? "#191919" : "#ffffff",
      },
      giveaway: {
        display: "none",
        background: location.pathname === "/giveaway" ? "#ffffff" : theme.secondaryBackgroundColor,
        color: location.pathname === "/giveaway" ? "#191919" : "#ffffff",
      },
      endPrizes: {
        display: "none",
      },
    };
    chosenStyles[type].display = "";
    setItemShow(chosenStyles);
  }, [type, location.pathname]);

  useEffect(() => {
    if (!chosen) {
      setRewardImg(<img src={require("../assets/images/rewards.png")} alt="" />);
      setMissionImg(<img src={require("../assets/images/missions.png")} alt="" />);
      return;
    }
    setRewardImg(<img src={require("../assets/images/rewards_black.png")} alt="" />);
    setMissionImg(<img src={require("../assets/images/missions_black.png")} alt="" />);
    return;
  }, [chosen]);

  return (
    <div className={styles["item"]} onClick={goTo}>
      <div style={itemShow.shaking} className={styles["shake"]}>
        <img
          src={
            location.pathname === "/shaking"
              ? require("../assets/images/shake_chosen.png")
              : require("../assets/images/shake.png")
          }
          alt=""
        />
        <div className={styles["amount"]}>
          <span
            style={{
              color: location.pathname === "/shaking" ? "#000" : "",
            }}
          >
            {amount}
          </span>
        </div>
      </div>
      <div style={itemShow.giveaway} className={styles["giveaway"]}>
        <img
          src={
            location.pathname === "/giveaway"
              ? require("../assets/images/rewards_black.png")
              : require("../assets/images/rewards.png")
          }
          alt=""
        />
        <div className={styles["amount"]}>
          <span
            style={{
              color: location.pathname === "/shaking" ? "#000" : "",
            }}
          >
            {amount}
          </span>
        </div>
      </div>
      <div
        style={itemShow.prizes}
        className={chosen ? `${styles.prizes} ${styles.chosen}` : styles.prizes}
      >
        {rewardImg}
        <div className={styles.amount} style={{ display: amount > 0 ? "" : "none" }}></div>
      </div>
      <div
        style={itemShow.missions}
        className={chosen ? `${styles.prizes} ${styles.chosen}` : styles.prizes}
      >
        {missionImg}
      </div>
      <div style={itemShow.aviator} className={styles[verified ? "aviator" : "login"]}>
        {verified ? (
          <img
            style={{
              borderRadius: "50%",
            }}
            src={location.pathname === "/profile" ? userAvatarChosen : userAvatar}
            alt="user"
          />
        ) : (
          <div>{t("login")}</div>
        )}
      </div>
    </div>
  );
};
