import React from "react";
import styles from "./styles/home.module.scss";
import { Footer } from "../components/Footer";
import { useSelector } from "../store";
import { SWLogo } from "components/SWLogo";
import PromMini from "components/promotion/Mini";
import EndClaim from "components/EndClaim";

export const HomeEnd: React.FC = () => {
  const sponsorImageUrl = useSelector((state) => state.data.sponsorImageUrl);

  // const shakeWinLogo = (
  //   <div className={styles.logo}>
  //     <img className={styles.logo_opera} src={require("../assets/images/opera_logo.png")} alt="" />
  //     <br />
  //     <img
  //       className={styles.logo_img}
  //       src={require("../assets/images/shake_win_br/shake_win_logo.png")}
  //       alt=""
  //     />
  //   </div>
  // );

  return (
    <div className={styles.home_end}>
      <img
        src={sponsorImageUrl || require("../assets/images/opera_logo.png")}
        alt=""
        style={{
          display: "block",
          margin: "0 auto",
          height: "30px",
          width: "auto",
        }}
      />
      <SWLogo size={"BIG"} />
      <EndClaim />
      {/* <div
        style={{
          margin: "16px",
          marginTop: "40px",
          background:
            "linear-gradient(0deg, rgba(172, 120, 252, 0.3), rgba(172, 120, 252, 0.3)), rgba(9, 12, 46, 0.7)",
          border: "1px solid #AC78FC",
          borderRadius: "16px",
          boxSizing: "border-box",
          padding: "12px 8px",
          textAlign: "center",
        }}
      >
        <div
          style={{
            padding: "8px",
            margin: "4px 8px",
            boxSizing: "border-box",
            background: "rgba(2, 4, 30, 0.6)",
            borderRadius: "8px",
            minHeight: "38px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Trans
            components={{
              1: (
                <img
                  src={require("../assets/images/shake_win/spectators.png")}
                  alt=""
                  style={{
                    width: "22px",
                    height: "22px",
                  }}
                />
              ),
              2: (
                <p
                  style={{
                    lineHeight: "22px",
                    marginLeft: "8px",
                  }}
                />
              ),
              3: (
                <span
                  style={{
                    fontWeight: 700,
                    color: "#FFB906",
                  }}
                />
              ),
            }}
            values={{ n: "3,052" }}
          >
            participantsWon
          </Trans>
        </div>
        <Trans
          components={{
            1: (
              <p
                style={{
                  marginTop: "8px",
                }}
              />
            ),
            2: (
              <span
                style={{
                  fontWeight: 700,
                  color: "#FFB906",
                }}
              />
            ),
          }}
        >
          thanksMessage
        </Trans>
      </div> */}
      <PromMini status="end" />
      <Footer />
    </div>
  );
};
