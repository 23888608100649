import { useSelector } from "../store";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setMissions } from "../store/state";
import { useEffect } from "react";
import { errorHandler } from "../api/errorHandler";
import store from "store2";

export const getMissionIds = () => {
  const xhr = useSelector((state) => state.data.xhr);
  const initialized = useSelector((state) => state.data.initialized);
  const loggedIn = useSelector((state) => state.data.loggedIn);
  const phase = useSelector((state) => state.data.phase);
  const dispatch = useDispatch();
  const location = useLocation();
  let setWallpaper: NodeJS.Timer;

  useEffect(() => {
    if ((window as Client)?.shakewin?.getMiniPayStatus) {
      let walletId = "";
      const res = (window as Client)?.shakewin?.getMiniPayStatus() || null;
      try {
        walletId = typeof res === "string" ? JSON.parse(res).walletId : res?.walletId || "";
        store("walletId", walletId);
      } catch (e) {
        console.error(e);
      }
    }
  }, []);

  useEffect(() => {
    if (window.location.search?.includes("mode=log_debug")) {
      (window as any)?.showToast("Requesting missionIds");
    }
  }, []);

  useEffect(() => {
    if (!xhr.headers.Authorization || !xhr.headers["X-CountryCode"] || phase !== "IN_PROGRESS") {
      return;
    }
    if (initialized) {
      let firstOccurrence = "";
      let status: any = null;
      if ((window as Client)?.shakewin?.getWallpaperStatus) {
        status = (window as Client)?.shakewin?.getWallpaperStatus() || null;
      }

      if (window.location.search?.includes("mode=log_debug")) {
        (window as any)?.showToast(
          `The value of getWallPaperStatus is ${status} \nfirstOccurrence is ${firstOccurrence}`,
        );
      }
      xhr
        .getMissionIds()
        .then((res: { missions: any[] }) => {
          const missions = res?.missions || [];
          if (
            !!missions.find((el) => el.id === "set-wallpaper") &&
            (window as Client)?.shakewin?.getWallpaperStatus
          ) {
            try {
              firstOccurrence =
                typeof status === "string"
                  ? JSON.parse(status).firstOccurrence
                  : status?.firstOccurrence || "";
            } catch (e) {
              console.error(e);
            }

            if (firstOccurrence) {
              xhr.postWallpaperMission({ firstOccurrence }).catch((e: any) => {
                console.error(e);
              });
            } else {
              let count = 0;
              setWallpaper = setInterval(() => {
                if ((window as Client)?.shakewin?.getWallpaperStatus) {
                  const res = (window as Client)?.shakewin?.getWallpaperStatus() || null;
                  try {
                    firstOccurrence =
                      typeof res === "string"
                        ? JSON.parse(res).firstOccurrence
                        : res?.firstOccurrence || "";
                  } catch (e) {
                    console.error(e);
                  }
                  if (firstOccurrence) {
                    xhr
                      .postWallpaperMission({ firstOccurrence })
                      .then(() => {
                        if (setWallpaper) {
                          window.clearInterval(setWallpaper);
                        }
                      })
                      .catch((e: any) => {
                        if (count > 2) window.clearInterval(setWallpaper);
                        count++;
                        console.error(e);
                      });
                  }
                }
              }, 5000);
            }
          }
          dispatch(setMissions({ missions: res?.missions || [] }));
        })
        .catch((e: any) => {
          errorHandler(e, dispatch);
          dispatch(setMissions({ missions: [] }));
        });
    }
    return function () {
      window.clearInterval(setWallpaper);
    };
  }, [initialized, location.pathname, xhr, loggedIn, phase]);
};
