import jwtDecode from "jwt-decode";
import store from "store2";
import { useSelector } from "../store";
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { logout, setToken, updateAuth } from "../store/state";

const tokenUpdate = () => {
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.data.accessToken);
  const refreshToken = useSelector((state) => state.data.refreshToken);
  const authRefreshToken = useSelector((state) => state.data.authRefreshToken);
  const authAccessToken = useSelector((state) => state.data.authAccessToken);
  const authXhr = useSelector((state) => state.data.mobileAuthXhr);
  const initialized = useSelector((state) => state.data.initialized);
  const xhr = useSelector((state) => state.data.xhr);
  const loggedIn = useSelector((state) => state.data.loggedIn);

  const update = useCallback(() => {
    if (!initialized || !accessToken) {
      return;
    }

    const decoded: { exp: number; sub: string; user_type: string } = jwtDecode(accessToken);
    const expireTime = decoded.exp;

    store("user_id", decoded.sub);
    if (expireTime - Date.now() / 1000 <= 30 * 60) {
      if ((window as Client).shakewin) {
        store("force", 1);
        (window as Client)?.shakewin?.requestAccessToken(true);
        return;
      }
      if (!refreshToken) {
        alert("Missing refreshToken!");
        return;
      }

      xhr
        .postRefreshToken({ refreshToken: refreshToken })
        .then((res: Tokens) => {
          dispatch(
            setToken({
              accessToken: res.accessToken,
              refreshToken: res.refreshToken,
            }),
          );
        })
        .catch((err: any) => {
          console.error(err);
          if (JSON.stringify(err)?.includes("refresh token")) {
            (window as any)?.showToast("Refresh token issue, please log in again...");
            setTimeout(() => {
              dispatch(logout());
              window.location.href = "/";
            }, 200);
          }
        });
    }
  }, [refreshToken, initialized, xhr, loggedIn, location.pathname]);

  const updateAuthTokens = useCallback(() => {
    if (!authAccessToken || !authRefreshToken) {
      return;
    }
    const decoded: { exp: number } = jwtDecode(authAccessToken);
    const expireTime = decoded.exp;
    if (expireTime - Date.now() / 1000 <= 30 * 60) {
      if (authRefreshToken) {
        authXhr
          .postRefreshAuthToken({ refreshToken: authRefreshToken })
          .then((res: Tokens) => {
            dispatch(
              updateAuth({
                authAccessToken: res.accessToken,
                authRefreshToken: res.refreshToken,
              }),
            );
          })
          .catch((err: any) => {
            console.error(err);
            if (JSON.stringify(err)?.includes("refresh token")) {
              (window as any)?.showToast("Refresh token issue, please log in again...");
              setTimeout(() => {
                dispatch(logout());
                window.location.href = "/";
              }, 200);
            }
          });
      }
    }
  }, [authXhr, authRefreshToken]);

  useEffect(() => {
    const interval = setInterval(() => {
      update();
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, [refreshToken, accessToken]);

  useEffect(() => {
    if (!initialized) return;
    update();
  }, [initialized, accessToken, refreshToken]);
  useEffect(() => {
    if (!initialized) return;
    updateAuthTokens();
  }, [initialized, authRefreshToken, authAccessToken]);
  useEffect(() => {
    const interval = setInterval(() => {
      updateAuthTokens();
      return () => {
        clearInterval(interval);
      };
    }, 60000);
  }, [authRefreshToken]);
  return;
};

export default tokenUpdate;
