import React, { useEffect, useState } from "react";
import { AppDispatch, useSelector } from "../../store";
import { useDispatch } from "react-redux";
import { getMissionStats } from "../../store/state";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";
import { SWButton } from "../../components/SWButton";
import { useUtmParamNavigate } from "../../utils/utmParamNavigate";
import { Footer } from "../../components/Footer";

export const MissionStats: React.FC = () => {
  const resultTs = 1707778800;
  const theme = useSelector((state) => state.data.theme);
  const dispatch = useDispatch<AppDispatch>();
  const stats: { campaign: string; count: number }[] = useSelector((state) => state.data.stats);
  const [endTime] = useState(resultTs);
  const { t } = useTranslation();
  const highlightRank = Date.now() / 1000 > resultTs;
  const [secondsLeft, setSecondsLeft] = useState<number>(
    Math.round(endTime - new Date().getTime() / 1000),
  );
  const [dateLeft, setDateLeft] = useState<{
    seconds: number;
    minutes: number;
    hours: number;
    days: number;
    [key: string]: number;
  }>({
    seconds: 0,
    days: 0,
    minutes: 0,
    hours: 0,
  });
  const navigate = useUtmParamNavigate();

  useEffect(() => {
    const timer = setInterval(() => {
      setSecondsLeft(Math.round(endTime - new Date().getTime() / 1000));
      if (endTime - new Date().getTime() / 1000 < 0) {
        clearInterval(timer);
      }
    }, 500);
    return () => {
      clearInterval(timer);
    };
  }, [endTime]);

  useEffect(() => {
    if (secondsLeft >= 0) {
      setDateLeft({
        days: Math.floor(secondsLeft / (3600 * 24)),
        hours: Math.floor((secondsLeft % (3600 * 24)) / 3600),
        minutes: Math.floor((secondsLeft % 3600) / 60),
        seconds: Math.floor(secondsLeft % 60),
      });
    }
  }, [secondsLeft]);
  const [missionCounts, setMissionCounts] = useState<
    { country: string; count: number; code: string }[]
  >([]);
  const countryMap: { [key: string]: string } = {
    ng: "Nigeria",
    za: "South Africa",
    ke: "Kenya",
  };
  useEffect(() => {
    dispatch(getMissionStats());
    const timer = setInterval(() => {
      dispatch(getMissionStats());
    }, 30000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    setMissionCounts(
      stats
        .filter((el) => {
          const country = el.campaign?.split("-")?.pop()?.toLowerCase();
          return country && countryMap[country];
        })
        .map((el) => {
          const country = el.campaign?.split("-")?.pop()?.toLowerCase();
          return { country: countryMap[country as string], count: el.count, code: country || "" };
        })
        .sort((e1, e2) => {
          return e2.count - e1.count;
        }),
    );
  }, [stats]);

  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundImage: theme.backgroundImage,
        backgroundPosition: "center",
      }}
    >
      <section
        style={{
          padding: "0 16px",
          height: "108px",
          paddingTop: "72px",
          position: "relative",
        }}
      >
        <h3>
          <span
            style={{
              padding: "4px",
              background: theme.highlightBackground,
              fontSize: "12px",
              borderRadius: "4px",
              fontWeight: 700,
            }}
          >
            OPERA CUP
          </span>
        </h3>
        <h3
          style={{
            width: "180px",
            fontSize: "14px",
            fontWeight: 600,
            padding: "12px 0",
            lineHeight: "22px",
          }}
        >
          Unlock extra prizes for your nation by completing missions!
        </h3>
        <img
          src={require("../../assets/images/afcon/lady.png")}
          style={{
            position: "absolute",
            width: "110px",
            height: "110px",
            right: "18px",
            bottom: 0,
          }}
          alt={""}
        />
      </section>
      <section
        style={{
          minHeight: "100px",
          background: theme.secondaryBackgroundColor,
          borderRadius: "16px",
          padding: "12px",
          margin: "0 16px",
        }}
      >
        <div style={{ display: missionCounts.length ? "" : "none" }}>
          <h3
            style={{
              fontSize: "14px",
              fontWeight: 600,
              lineHeight: "22px",
            }}
          >
            Compete for the most missions completed!
          </h3>
          <p
            style={{
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "16px",
              color: "#FFFFFFBF",
              padding: "8px 0",
            }}
          >
            The nation with the most completed missions by 12/02/24 will receive an extra prizes,
            greatly increasing your chances of winning!
          </p>
          <div
            style={{
              borderRadius: "8px",
              overflow: "hidden",
            }}
          >
            {missionCounts.map((el, index) => (
              <div
                key={el.country}
                style={{
                  margin: `0 ${index && highlightRank ? 8 : 0}px`,
                  marginTop: index ? "4px" : "",
                  background: theme.backgroundColor,
                  padding: "8px",
                  fontSize: "14px",
                  lineHeight: "18px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  border: !index && highlightRank ? "1px solid #FFB906" : "",
                  borderRadius: highlightRank ? "8px" : "",
                }}
              >
                <div style={{ display: "flex", width: "180px", alignItems: "center" }}>
                  <span style={{ width: "24px" }}>{index + 1}</span>
                  <div
                    style={{
                      borderRadius: "12px",
                      width: "16px",
                      height: "16px",
                      textAlign: "center",
                      overflow: "hidden",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ReactCountryFlag
                      countryCode={el.code}
                      svg
                      style={{
                        width: "24px",
                        height: "36px",
                      }}
                    />
                  </div>

                  <span style={{ width: "100px", margin: "0 0 0 12px", fontWeight: 600 }}>
                    {el.country}{" "}
                  </span>
                  <img
                    src={require("../../assets/images/afcon/trophy.png")}
                    alt=""
                    style={{
                      width: "22px",
                      height: "22px",
                      position: "relative",
                      top: "-4px",
                      display: !index && highlightRank ? "" : "none",
                    }}
                  />
                </div>
                <span style={{ fontSize: "12px", color: "#FFFFFFBF" }}>
                  {(Number(el.count) || 0)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                  completed
                </span>
              </div>
            ))}
          </div>
        </div>

        <h3
          style={{
            fontSize: "14px",
            fontWeight: 600,
            lineHeight: "22px",
            margin: "12px 0",
          }}
        >
          {!highlightRank ? "Time left to win:" : "Thanks for taking part!"}
        </h3>
        <div
          style={{
            padding: "8px",
            background: theme.menuColor,
            borderRadius: "8px",
            display: highlightRank ? "none" : "",
          }}
        >
          <div style={{ overflow: "hidden" }}>
            {Object.keys(dateLeft).map((key) => (
              // eslint-disable-next-line react/jsx-key
              <div style={{ float: "left", textAlign: "center", width: "25%" }} key={key}>
                <div
                  style={{
                    padding: "2px",
                    fontSize: "14px",
                    height: "18px",
                    lineHeight: "18px",
                    fontWeight: "700",
                  }}
                >
                  {dateLeft[key] < 10 ? `0${dateLeft[key]}` : `${dateLeft[key]}`}
                </div>
                <div
                  style={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "rgba(255, 255, 255, 0.75)",
                  }}
                >
                  {t(key)}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div style={{ marginTop: "16px", display: highlightRank ? "none" : "" }}>
          <SWButton
            text={t("earnMoreShakes")}
            callback={() => {
              navigate("/missions");
            }}
          />
        </div>
      </section>
      <Footer />
    </div>
  );
};
