import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import { index } from "./store";
import { Provider } from "react-redux";
// import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import ReactGA from "react-ga4";
import "./i18n";
import { CookiesProvider } from "react-cookie";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import "./utils/debug";

Promise.allSettled = Promise.allSettled || ((promises: Promise<any>[]) => Promise.all(
  promises.map(p => p
      .then(value => ({
          status: "fulfilled",
          value
      }))
      .catch(reason => ({
          status: "rejected",
          reason
      }))
  )
));

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

if (process.env.REACT_APP_MODE !== "development") {
  Sentry.init({
    dsn: "https://38062ba9290c4e55b92845db15898228@sentry-relay.opera-api.com/359",
    integrations: [new BrowserTracing()],
    environment: process.env.REACT_APP_MODE, // put here "prod", "staging" etc based on env
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.001,
    beforeSend(event, hint) {
      /* tslint:disable:no-string-literal only-arrow-functions */
      if (JSON.stringify(event?.exception?.values).includes("Non-Error")) {
        return null;
      }

      if (JSON.stringify(hint?.originalException)?.includes("Non-Error")) {
        return null;
      }

      return event;
    },
  });
}

ReactGA.initialize(process.env.REACT_APP_MODE === "production" ? "G-FSE96J79YT" : "G-8XM2PRE2VD");
if (window) {
  // @ts-ignore
  window.ga4 = ReactGA;
}

const siteKey =
  process.env.REACT_APP_MODE === "production"
    ? "6LejWj8nAAAAAKV8GcP39q2li7wSfzgiMdqohXYL"
    : "6LcPcjEnAAAAAC5_VkGgsU6fcKPE_VnU7QfFL10Y";
try {
  root.render(
    <Provider store={index}>
      {/* eslint-disable-next-line react/jsx-no-undef */}
      <GoogleReCaptchaProvider reCaptchaKey={siteKey} useRecaptchaNet={true} useEnterprise={true}>
        <CookiesProvider>
          <Router>
            <App />
          </Router>
        </CookiesProvider>
      </GoogleReCaptchaProvider>
    </Provider>,
  );
} catch (error) {
  console.log(error);
  (window as any)?.showToast(
    "GoogleReCaptchaProvider is not supported. Please update your browser.",
  );
  root.render(
    <Provider store={index}>
      <CookiesProvider>
        <Router>
          <App />
        </Router>
      </CookiesProvider>
    </Provider>,
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
