import React, { useMemo } from "react";
import styles from "./styles/share.module.scss";
import { SWButton } from "../components/SWButton";
import { Footer } from "../components/Footer";
import { SWLogo } from "../components/SWLogo";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { STORE_MINI, STORE_OFA } from "constants/store";

export const NotOpera: React.FC = () => {
  const { t } = useTranslation();
  const country = useSelector((state) => state.data.country);
  const title = useMemo(
    () =>
      country === "BR"
        ? {
            get: t("Get Opera"),
            browser: "Opera",
          }
        : {
            get: "Get Opera Mini",
            browser: "Opera Mini",
          },
    [country],
  );
  const storeUrl = useMemo(() => (country === "BR" ? STORE_OFA : STORE_MINI), [country]);

  return (
    <div className={styles["quiz-box"]}>
      <div className={styles["quiz-box-head"]}>
        <img
          src={require("../assets/images/opera_logo.png")}
          className={styles["quiz-box-head-opera"]}
          alt={"opera"}
        />
        <div className={styles["quiz-box-head-img"]}>
          <SWLogo />
        </div>

        <div className={styles["quiz-box-error"]}>
          <img
            src={require("../assets/images/head_error.png")}
            className={styles["quiz-box-img"]}
            alt=""
          />
          <div className={styles["quiz-box-error-line1"]}>{t("Browser unsupported")}</div>
          <div className={styles["quiz-box-error-line2"]}>
            {t("Opera Browser is required to play Shake and Win")}
          </div>
        </div>
      </div>
      <div className={styles["quiz-box-play-now"]}>
        <SWButton
          text={title.get}
          disabled={false}
          background={"#FFB906"}
          disabledBackground={"rgba(255, 255, 255, 0.2)"}
          color={"#000000"}
          disabledColor={"rgba(255, 255, 255, 0.5)"}
          size={"big"}
          callback={() => {
            window.location.href = storeUrl;
          }}
        />
      </div>
      <div className={styles["quiz-box-arrow-green"]}>
        <img src={require("../assets/images/arrows_green.png")} alt="" />
      </div>
      <div className={styles["quiz-box-line"]}>{t("How do I take part")}</div>
      <div className={styles["quiz-box-step-item"]}>
        <div>{t("STEP", { x: 1 })}</div>
        <div>{t("Download the Opera Mini browser", { x: title.browser })}</div>
      </div>
      <div className={styles["quiz-box-step-item"]}>
        <div>{t("STEP", { x: 2 })}</div>
        <div>{t("Tap on the green 'Shake' button")}</div>
      </div>
      <div className={styles["quiz-box-step-item"]}>
        <div>{t("STEP", { x: 3 })}</div>
        <div>{t("Start shaking!")}</div>
      </div>
      <div className={styles["quiz-box-play-now"]}>
        <SWButton
          text={title.get}
          disabled={false}
          background={"#FFB906"}
          disabledBackground={"rgba(255, 255, 255, 0.2)"}
          color={"#000000"}
          disabledColor={"rgba(255, 255, 255, 0.5)"}
          size={"big"}
          callback={() => {
            window.location.href = storeUrl;
          }}
        />
      </div>
      <Footer />
    </div>
  );
};
