import { useSelector } from "../store";
import { useEffect } from "react";
import { setShakes, setUnlockTime } from "../store/state";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { errorHandler } from "../api/errorHandler";

const getShakes = () => {
  const xhr = useSelector((state) => state.data.xhr);
  const initialized = useSelector((state) => state.data.initialized);
  const country = useSelector((state) => state.data.country);
  const unlockTime = useSelector((state: any) => state.data.unlockedTime);
  const dispatch = useDispatch();
  const location = useLocation();
  const phase = useSelector((state) => state.data.phase);
  useEffect(() => {
    if (phase !== "IN_PROGRESS") return;
    if (!xhr.headers.Authorization || !xhr.headers["X-CountryCode"]) {
      return;
    }
    if (initialized) {
      xhr
        .getShakes()
        .then((res: ShakesInfo) => {
          const shakes = res.shakesCount || 0;
          const recaptchaRequired = res.recaptchaRequired || false;
          dispatch(
            setShakes({
              shakes,
              recaptchaRequired,
            }),
          );
          dispatch(
            setUnlockTime({
              unlockedTime: res.timeOfFreeShake
                ? new Date(res.timeOfFreeShake).getTime() / 1000
                : 0,
            }),
          );
        })
        .catch((e: any) => {
          errorHandler(e, dispatch);
        });
    }
  }, [initialized, country, location.pathname, xhr.headers.Authorization, phase]);

  useEffect(() => {
    if (phase !== "IN_PROGRESS") return;
    if (unlockTime - new Date().getTime() / 1000 > 0) return;
    if (!xhr.headers.Authorization || !xhr.headers["X-CountryCode"]) {
      return;
    }
    if (initialized) {
      xhr
        .getShakes()
        .then((res: ShakesInfo) => {
          const shakes = res.shakesCount || 0;
          const recaptchaRequired = res.recaptchaRequired || false;
          dispatch(
            setShakes({
              shakes,
              recaptchaRequired,
            }),
          );
          dispatch(
            setUnlockTime({
              unlockedTime: res.timeOfFreeShake
                ? new Date(res.timeOfFreeShake).getTime() / 1000
                : 0,
            }),
          );
        })
        .catch((e: any) => {
          errorHandler(e, dispatch);
        });
    }
  }, [unlockTime, initialized, xhr.headers.Authorization, phase]);

  useEffect(() => {
    if (phase !== "IN_PROGRESS") return;
    let timer1: NodeJS.Timer = setInterval(() => {
      return;
    }, 999999);
    clearInterval(timer1);
    timer1 = setInterval(() => {
      xhr
        .getShakes()
        .then((res: ShakesInfo) => {
          const shakes = res.shakesCount || 0;
          const recaptchaRequired = res.recaptchaRequired || false;
          dispatch(
            setShakes({
              shakes,
              recaptchaRequired,
            }),
          );
          dispatch(
            setUnlockTime({
              unlockedTime: res.timeOfFreeShake
                ? new Date(res.timeOfFreeShake).getTime() / 1000
                : 0,
            }),
          );
        })
        .catch((e: any) => {
          errorHandler(e, dispatch);
        });
    }, 60000);

    return () => {
      clearInterval(timer1);
    };
  }, [unlockTime, phase]);
};

export default getShakes;
