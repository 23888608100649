import React, { useCallback, useEffect, useState } from "react";
import { availableCountries, usableCountries } from "../../config";
import { getCountriesList } from "../../utils/countries";
import ReactCountryFlag from "react-country-flag";
import { useDispatch } from "react-redux";
import { AppDispatch, useSelector } from "../../store";
import { logout, setUserCountry, setUserInfo } from "../../store/state";
import { useCookies } from "react-cookie";
import store from "store2";
import createXhr from "../../api";
import jwtDecode from "jwt-decode";
import { setDot } from "../../utils/dot";
import { setGaDot } from "../../utils/gaDot";
import { useTranslation } from "react-i18next";

export const LanguageCountrySetting: React.FC = () => {
  const [countries] = useState(getCountriesList(availableCountries));
  const dispatch = useDispatch<AppDispatch>();
  const currentCountry = useSelector((state) => state.data.country);
  const accessToken = useSelector((state) => state.data.accessToken);
  const initialized = useSelector((state) => state.data.initialized);
  const canChangeCountry = useSelector((state) => state.data.canChangeCountryCode);
  const { t } = useTranslation();
  const [_, __, removeCookie] = useCookies();
  const xhr = useSelector((state) => state.data.xhr);
  const [dataFetched, setDataFetched] = useState(false);
  useEffect(() => {
    if (initialized && xhr.headers.Authorization) {
      xhr.getUserInfo().then((res: UserInfo) => {
        const decoded: { exp: number; sub: string } = jwtDecode(accessToken);
        store("user_id", decoded.sub);
        dispatch(
          setUserInfo({
            userId: decoded.sub,
            country: res.countryCode || usableCountries[0],
            verified: res.isPhoneVerified,
            avatarUrl: res.avatarUrl,
            canChangeCountryCode: res.canChangeCountryCode,
          }),
        );
        setDataFetched(true);
      });
    }
  }, [xhr.headers.Authorization, initialized]);

  useEffect(() => {
    setDot("pv", "pv_switch_country_page", "", "");
    setGaDot({ action: "pv_switch_country_page", value: {} });
  }, []);

  const setCountry = useCallback(
    (country: string) => {
      if (!dataFetched) return;
      if (canChangeCountry) {
        dispatch(setUserCountry({ country })).then(() => {
          window.location.href = "/";
        });
        return;
      } else {
        dispatch(logout());
        removeCookie("auth_accessToken");
        removeCookie("auth_refreshToken");

        if ((window as Client).shakewin) {
          store("chosen_country", country);
          store("country", country);
          window.location.href = "/";
          return;
        }
        createXhr({
          headers: {
            "X-CountryCode": country,
          },
        })
          .getAnonUser()
          .then((res: Tokens) => {
            store("access_token", res.accessToken);
            store("refresh_token", res.refreshToken);
            store("selected_country", country);
            store("chosen_country", country);
            window.location.href = "/";
          })
          .catch((err: { message: string }) => {
            (window as any)?.showToast(err.message);
          });
      }
    },
    [canChangeCountry, accessToken, xhr, dataFetched],
  );

  return (
    <div
      style={{
        padding: "32px 0",
      }}
    >
      <h2 style={{ textAlign: "center", fontSize: "16px", fontWeight: 700 }}>
        {t("selectCountry")}
      </h2>

      <section
        style={{
          margin: "24px 16px",
        }}
      >
        <h2 style={{ fontSize: "16px", fontWeight: 700 }}>{t("chooseYourCountryOfResidence")}</h2>

        <div style={{ marginTop: "16px" }}>
          {Object.keys(countries).map((country: string, index: any) => (
            <div
              key={countries[country].code || index}
              style={{
                padding: "12px 16px",
                borderRadius: "8px",
                background: "#431F70",
                margin: "8px 0",
                display: currentCountry === countries[country].code ? "none" : "flex",
                alignItems: "center",
              }}
              onClick={() => {
                setCountry(countries[country].code);
              }}
            >
              <div
                style={{
                  overflow: "hidden",
                  borderRadius: "22px",
                  width: "22px",
                  height: "22px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ReactCountryFlag
                  countryCode={countries[country].code}
                  key={countries[country].code + "__"}
                  svg
                  style={{
                    width: "30px",
                    height: "30px",
                  }}
                />
              </div>

              <span
                style={{
                  marginLeft: "8px",
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "22px",
                }}
              >
                {countries[country].name}
              </span>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};
