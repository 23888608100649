import React, { useState } from "react";
import styles from "../styles/mission_card.module.scss";
import { useTranslation } from "react-i18next";
import { InformationNotification } from "./InformationNotification";
import { browserName, getClientName } from "../../config";

interface MissionProp {
  title: string;
  description: string;
  hint: string;
  showHint?: boolean;
  showHintFunc?: () => any;
  finishedCount?: number;
  allCount?: number;
  needUpdate: boolean;
  button: {
    name: string;
    done: boolean;
    callback: () => void;
  }[];
  status?: string;
}
export const MissionCard: React.FC<MissionProp> = (mission) => {
  const client = getClientName();
  const { t } = useTranslation();
  const [showHint, setShowHint] = useState(false);

  return (
    <div className={styles.quest_card}>
      <div className={styles.quest_main}>
        {showHint ? (
          <>
            <div className={styles.triangle} />
            <div
              className={styles.info}
              onClick={() => {
                setShowHint(false);
              }}
            >
              {mission?.allCount
                ? t(mission.hint, { count: mission.allCount })
                : mission.hint || ""}
            </div>
          </>
        ) : null}
        <div style={{ width: "100%" }}>
          <div className={styles.quest_title}>
            <span>{mission.title}</span>
            <img
              src={require("../../assets/images/theme/question.png")}
              alt={"info"}
              onClick={() => {
                setShowHint(true);
                setTimeout(() => {
                  setShowHint(false);
                }, 3000);
              }}
            />
          </div>
          <div className={styles.quest_description}>
            {mission?.allCount
              ? t(mission.description, { count: mission.allCount })
              : mission.description}
          </div>
          {mission.needUpdate ? (
            <InformationNotification
              message={t("operaUpdateRequired", {
                client: getClientName() === "mini" ? "Opera Mini" : "Opera",
              })}
            />
          ) : null}

          {mission.allCount ? (
            <div
              className={styles.quest_bar}
              style={{
                color:
                  (mission.finishedCount || 0) / mission.allCount >= 0.5
                    ? "#fff"
                    : "rgba(255,255,255,0.5)",
              }}
            >
              <span>{`${mission.finishedCount} / ${mission.allCount}`}</span>
              <div
                className={styles.bar}
                style={{
                  background: "#0B8351",
                  width: ((mission.finishedCount || 0) / mission.allCount) * 100 + "%",
                }}
              ></div>
            </div>
          ) : null}

          <div>
            {mission.needUpdate ? (
              <div
                className={styles.quest_button}
                style={{
                  background: "",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => {
                  if (client === "mini") {
                    if ((window as Client).shakewin?.getClientName()?.includes("beta")) {
                      (window as Client).shakewin?.openOperaMiniDeepLink(
                        "opera-mini://open?url=https://play.google.com/store/apps/details?id=com.opera.mini.native.beta",
                        true,
                      );
                    } else {
                      (window as Client).shakewin?.openOperaMiniDeepLink(
                        "opera-mini://open?url=https://play.google.com/store/apps/details?id=com.opera.mini.native",
                        true,
                      );
                    }
                  }
                  if (client === "ofa") {
                    if ((window as Client).shakewin?.getClientName()?.includes("beta")) {
                      (window as Window).location.href =
                        "https://play.google.com/store/apps/details?id=com.opera.browser.beta";
                    } else {
                      (window as Window).location.href =
                        "https://play.google.com/store/apps/details?id=com.opera.browser";
                    }
                  }
                  return;
                }}
                key={mission.hint + "_update"}
              >
                <span>{t("updateAppBtn", { client: browserName })}</span>
              </div>
            ) : (
              mission.button.map(
                (btn: {
                  done: any;
                  callback: React.MouseEventHandler<HTMLDivElement> | undefined;
                  name: string;
                }) => {
                  return (
                    <div
                      className={styles.quest_button}
                      style={{
                        background: btn.done ? "#0B8351" : "",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={btn.callback}
                      key={btn.name}
                    >
                      <span>
                        {[
                          "FAIL_WALLET_CREATED_BEFORE_USER",
                          "FAIL_WALLET_RESTORED_FROM_BACKUP",
                        ].includes(mission.status || "")
                          ? "Previously Completed"
                          : btn.done
                          ? t("completed")
                          : btn.name}
                      </span>
                      {btn.done ? (
                        <img
                          src={require("../../assets/images/shake_win_br/white_tick.png")}
                          style={{ width: "22px", height: "22px", margin: "0 8px" }}
                          alt=""
                        />
                      ) : null}
                    </div>
                  );
                },
              )
            )}
            <img
              style={{
                display: "none",
              }}
              src={require("../../assets/images/shake_win/lock.png")}
              alt=""
              className={styles.quest_lock}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
