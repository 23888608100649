import React from "react";
import { SWLogo } from "../components/SWLogo";
import { useTranslation } from "react-i18next";

export const NotRightCountry: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        minHeight: "100vh",
        paddingTop: "70px",
        boxSizing: "border-box",
        width: "100%",
        background: `url(${require("../assets/images/shake_win_br2/bg.jpg")}) no-repeat 50%`,
        overflow: "hidden",
      }}
    >
      <img
        src={require("../assets/images/opera_logo.png")}
        alt=""
        style={{
          height: "36px",
          width: "auto",
          display: "block",
          margin: "16px auto",
        }}
      />
      <SWLogo />
      <img
        src={require("../assets/images/error_white.png")}
        style={{
          width: "38px",
          height: "auto",
          display: "block",
          margin: "16px auto",
        }}
        alt=""
      />
      <p
        style={{
          textAlign: "center",
        }}
      >
        <span
          style={{
            fontWeight: 700,
          }}
        >
          {t("notInCountry")}
        </span>
      </p>
    </div>
  );
};
