import getShakes from "./getShakes";
import { getPrizes } from "./getPrizes";
import { getMissionIds } from "./getMissionIds";
import tokenUpdate from "./tokenUpdate";
import onRouteUpdate from "./onRouteUpdate";
import { useEffect } from "react";
import { useSelector } from "../store";
import store from "store2";
import { useDispatch } from "react-redux";
import { logout } from "../store/state";
import jwtDecode from "jwt-decode";

export const DataUpdate = () => {
  const id = useSelector((state) => state.data.authId);
  const loggedIn = useSelector((state) => state.data.loggedIn);
  const dispatch = useDispatch();

  getShakes();
  getPrizes();
  getMissionIds();
  tokenUpdate();
  onRouteUpdate();

  useEffect(() => {
    if (!(window as any).shakewin?.requestSocialLogin) return;
    if (
      [
        "/shakewin/callback",
        "/shakewin/callback/",
        "/login/onetime",
        "/login/onetime/",
        "/login",
        "/login/",
        "/login/verify/",
        "/login/verify",
      ].includes(location.pathname)
    )
      return;
    if (!loggedIn || !id) return;
    const storedAccount: string = store("googleAccount") || "";
    const credential = (window as any)?.shakewin?.getSocialAccount() || "";
    if (!credential || !storedAccount) return;

    try {
      const { email }: { email: unknown } = jwtDecode(credential);
      if (email !== storedAccount) {
        (window as any)?.showToast("Account change detected, please log in again.");

        dispatch(logout());
        setTimeout(() => {
          window.location.href = "/";
        }, 1500);
      }
    } catch (e) {
      (window as any)?.showToast("Invalid credential from client.");
      dispatch(logout());
      setTimeout(() => {
        window.location.href = "/";
      }, 1500);
    }
  }, [loggedIn, id]);
};
