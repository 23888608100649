import React, { useEffect, useState } from "react";
import styles from "../styles/verify.module.scss";
import { SpinnerCircular } from "spinners-react";
import { useDispatch } from "react-redux";
import { useSelector } from "../../store";
import { setVerified } from "../../store/state";
import { setDot } from "../../utils/dot";
import { setGaDot } from "../../utils/gaDot";
import { useTranslation } from "react-i18next";
import { PhoneVerifyV2 } from "../../components/v2/PhoneVerifyV2";
import { SWButton } from "../../components/SWButton";
import { useUtmParamNavigate } from "../../utils/utmParamNavigate";
import { useSearchParams } from "react-router-dom";
// import { SWLogo } from "../../components/SWLogo";

/**
 * @description The page to verify the user phone.
 * @author ziwenw@opera.com
 * @since 31/08/2022
 */
export const VerifyPhoneV2: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useSelector((state) => state.data.theme);
  const verified = useSelector((state) => state.data.verified);
  const navigate = useUtmParamNavigate();
  const [inputting, setInputting] = useState<boolean>(false);
  const [verifying, setVerifying] = useState(false);
  const [phone, setPhone] = useState<string>("");
  const [title, setTitle] = useState<string>(t("enterYourPhoneNumberToContinue") || "");
  const [subtitle, setSubtitle] = useState(<div style={{ margin: "8px 16px" }}>{""}</div>);
  const [params] = useSearchParams();
  const prizeId = params.get("prize_id") || "";
  const prizeType = params.get("prize_type") || "";

  useEffect(() => {
    setDot("pv", "pv_phone_verify_page", "", "");
    setGaDot({ action: "pv_phone_verify_page", value: {} });
  }, []);

  // handle the intro texts and elements according to the user input
  useEffect(() => {
    if (inputting) {
      setTitle(t("verificationSMS") || "Verification SMS sent to:");
      setSubtitle(<div>{phone}</div>);
    } else if (verifying) {
      dispatch(
        setVerified({
          verified: true,
        }),
      );
      setTitle(t("verifyingCode") || "Verifying code...");
      setSubtitle(
        <div
          style={{
            textAlign: "center",
          }}
        >
          <SpinnerCircular
            size={22}
            thickness={180}
            speed={100}
            color="#FFB906"
            secondaryColor="rgba(0, 0, 0, 0)"
          />
        </div>,
      );
    }
  }, [dispatch, inputting, phone, verifying]);

  return (
    <div className={styles["main"]}>
      <div className={styles["title-area"]}>
        {/* <SWLogo /> */}
        <img
          src={require("../../assets/images/opera_logo.png")}
          alt=""
          style={{
            display: "block",
            margin: "0 auto",
            height: "30px",
            width: "auto",
          }}
        />
        <div className={styles["title"]}>
          {verified && !verifying ? "This account is verified." : title}
        </div>
        {verified && !verifying ? (
          <div
            style={{
              margin: "16px",
            }}
          >
            <SWButton
              text={prizeId && prizeType ? t("claimYourReward") : "Go to prizes"}
              background={theme.buttonColor}
              color={theme.buttonFontColor}
              callback={() => {
                if (prizeId && prizeType) {
                  navigate(`/prize/claim?prize_id=${prizeId}&prize_type=${prizeType}`);
                  return;
                }
                navigate("/prizes");
              }}
            />
          </div>
        ) : null}

        <div className={styles["subtitle"]}>{verified && !verifying ? "" : subtitle}</div>
      </div>
      <div className={styles["input-area"]}></div>
      {verified && !verifying ? (
        <div></div>
      ) : (
        <PhoneVerifyV2
          dataTube={(data: { inputting: boolean; phone: string; verifying: boolean }) => {
            setInputting(data.inputting);
            setPhone(data.phone);
            setVerifying(data.verifying);
          }}
        />
      )}
    </div>
  );
};
