import React, { useEffect, useState } from "react";
import styles from "./styles/claimForm.module.scss";
import { SWButton } from "../components/SWButton";
import { useUtmParamNavigate } from "../utils/utmParamNavigate";
import { useTranslation } from "react-i18next";

type ClaimStatus = "unknown" | "success" | "fail";

export const ClaimResult: React.FC<{
  success: boolean;
  prize?: Reward;
  claimedDate?: string;
  toggleFunction?: () => void;
}> = (props) => {
  const navigate = useUtmParamNavigate();
  const { t } = useTranslation();
  const [orderDate, setOrderDate] = useState<string>("");
  const [status, setStatus] = useState<ClaimStatus>("unknown");
  const [dom, setDom] = useState(<div></div>);
  const [success] = useState(props.success);
  const [prize] = useState(props.prize);
  const toggleFunction = props.toggleFunction;
  const timeFormat = (date: Date) => {
    const tsDay = date.getDate();
    const tsMonth = date.getMonth() + 1;
    const tsH = date.getHours();
    const tsM = date.getMinutes();
    const tsS = date.getSeconds();
    const day = tsDay > 9 ? tsDay : `0${tsDay}`;
    const month = tsMonth > 9 ? tsMonth : `0${tsMonth}`;
    const year = date.getFullYear() % 100;
    const hours = tsH > 9 ? tsH : `0${tsH}`;
    const minutes = tsM > 9 ? tsM : `0${tsM}`;
    const seconds = tsS > 9 ? tsS : `0${tsS}`;

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  };

  useEffect(() => {
    const date = new Date(props.claimedDate || "");
    setOrderDate(timeFormat(date));
    setStatus(success ? "success" : "fail");
  }, []);

  useEffect(() => {
    switch (status) {
      case "fail":
        setDom(
          <div className={styles.result}>
            <img src={require("../assets/images/fail.png")} alt="" className={styles.result_icon} />
            <br />
            <h3 className={styles.result_title_fail}>{t("Submission failed")}</h3>
            <p className={styles.result_text_fail}>
              {t("There was a problem submitting your details. Please try again.")}
            </p>
            <SWButton
              text={t("tryAgain") || "Try again"}
              disabled={false}
              background={"#FFB906"}
              disabledBackground={""}
              color={"#000000"}
              disabledColor={""}
              size={"medium"}
              callback={() => {
                toggleFunction?.();
              }}
            />
          </div>,
        );
        break;
      case "success":
        setDom(
          <div className={styles.result}>
            <img
              src={require("../assets/images/success.png")}
              alt=""
              className={styles.result_icon}
            />
            <br />
            <h3 className={styles.result_title_success}>{t("Submitted successfully")}</h3>
            <h3 className={styles.result_subtitle_success}>{`${t("Order date:")} ${orderDate}`}</h3>
            {/*<p className={styles.result_text_success}>*/}
            {/*  {t(*/}
            {/*    "Thank you for submitting your details. Your claim may take 5 - 7 days to process.",*/}
            {/*  )}*/}
            {/*</p>*/}
            <SWButton
              text={"OK"}
              disabled={false}
              background={"#FFB906"}
              disabledBackground={""}
              color={"#000000"}
              disabledColor={""}
              size={"medium"}
              callback={() => {
                navigate(prize ? `/won?prize_id=${prize.id}` : "/prizes");
              }}
            />
          </div>,
        );
        break;
      default:
        return;
    }
  }, [status, orderDate]);
  return <div>{dom}</div>;
};
