import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "../../store";
import store from "store2";
import { useSearchParams } from "react-router-dom";
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { setDot } from "../../utils/dot";
import { setGaDot } from "../../utils/gaDot";
import { availableCountries } from "../../config";
import { getCountriesList } from "../../utils/countries";
import createXhr from "../../api";
import {
  logout,
  setAuthXhr,
  setTmpPassword,
  setToken,
  setVerified,
  setXhr,
} from "../../store/state";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { SWInputBar } from "../../components/SWInputBar";
import { SpinnerCircular } from "spinners-react";

export const PostVerify: React.FC = () => {
  const countries = getCountriesList(availableCountries);
  const theme = useSelector((state) => state.data.theme);
  const country = useSelector((state) => state.data.country);
  const language = useSelector((state) => state.data.lang);
  const app = useSelector((state) => state.data.firebase);
  const xhr = useSelector((state) => state.data.xhr);
  const authAccessToken = useSelector((state) => state.data.authAccessToken);
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [prizeId, prizeType] = [searchParams.get("prize_id"), searchParams.get("prize_type")];

  const phoneNumber = store("formCache")?.phoneNumber || "";
  const phoneNumberData = phoneNumber?.replaceAll(" ", "");
  const [currentCountry] = useState(countries[country]);
  const [confirmationResult, setConfirmationResult] = useState<any>();
  const [isCodeError, setIsCodeError] = useState(false);
  const [error, setError] = useState({ code: "", phone: "" });
  const { t } = useTranslation();
  const [timeLeft, setTimeLeft] = useState(0);
  const [verifying, setVerifying] = useState(false);
  const [testingBot, setTestingBot] = useState(false);
  const [sentCode, setSentCode] = useState(false);

  const sendCode = useCallback(
    (id = "bot-check") => {
      if (app) {
        const auth = getAuth(app);
        (window as any).recaptchaVerifier = new RecaptchaVerifier(id, {}, auth);
        const appVerifier = (window as any).recaptchaVerifier;
        setTestingBot(true);
        signInWithPhoneNumber(auth, phoneNumberData, appVerifier)
          .then((confirmationResult) => {
            setDot("click", "phone_verify_request_send", "phone_num", `+${currentCountry.phone}`);
            setGaDot({
              action: "phone_verify_request_send",
              value: {
                phone_num: `+${currentCountry.phone}`,
              },
            });
            store("next_send_time", new Date().getTime() + 60000);
            setTestingBot(false);
            setSentCode(true);
            return setConfirmationResult(confirmationResult);
          })
          .catch((err: any) => {
            console.error(err);
            (window as any)?.showToast(err.message);
            (window as any).recaptchaVerifier?.clear();
            setTestingBot(false);
            setSentCode(true);
          });
      }
    },
    [app, prizeId, prizeType],
  );

  const verifyCode = useCallback(
    (code: string) => {
      if (/^\d{6}$/.test(code)) {
        confirmationResult
          .confirm(code)
          .then(
            (res: {
              _tokenResponse: {
                idToken: string;
                refreshToken: string;
                expiresIn: string;
                localId: string;
                isNewUser: boolean;
                phoneNumber: string;
              };
            }) => {
              const idToken = res._tokenResponse.idToken;
              setVerifying(true);
              (authAccessToken
                ? createXhr({
                    headers: {
                      Authorization: `Bearer ${authAccessToken}`,
                    },
                  }).postConnectPhoneNumber({
                    idToken,
                    phoneNumber: phoneNumberData,
                  })
                : createXhr({}).postPhoneVerifyV2({
                    idToken,
                    phoneNumber: phoneNumberData,
                  })
              )
                .then((res: { accessToken: any; refreshToken: any }) => {
                  if (res.accessToken) {
                    const mobileAuthAccessToken = res.accessToken;
                    const mobileAuthXhr = createXhr({
                      headers: {
                        Authorization: "Bearer " + mobileAuthAccessToken,
                        "X-CountryCode": country,
                        "Accept-Language": language,
                      },
                    });
                    store("auth_accessToken", res.accessToken);
                    store("auth_refreshToken", res.refreshToken);

                    dispatch(
                      setAuthXhr({
                        mobileAuthXhr,
                      }),
                    );
                    setDot(
                      "click",
                      "phone_verify_success",
                      "phone_num",
                      `+${currentCountry.phone}`,
                    );
                    setGaDot({
                      action: "phone_verify_success",
                      value: {
                        phone_num: `+${currentCountry.phone}`,
                      },
                    });

                    setSWTokens(mobileAuthAccessToken);
                  } else {
                    alert(
                      `Invalid value from mobilePhoneLogin Api: \n idToken: ${idToken} \n accessToken: ${res.accessToken} \n refreshToken: ${res.refreshToken}`,
                    );
                    dispatch(logout());
                    window.location.href = "";
                  }

                  // setIsError(true);
                })
                .catch((e: { status: number; message: string }) => {
                  console.error(e);
                  if (JSON.stringify(e)?.includes("too-many-requests")) {
                    setError({
                      ...error,
                      phone: t("Too many requests, try later."),
                    });
                  } else if (
                    JSON.stringify(e)?.includes("attempts") ||
                    JSON.stringify(e)?.includes("ATTEMPTS")
                  ) {
                    setError({
                      ...error,
                      phone: t("Too many attempts, please try again later."),
                    });
                  } else {
                    setError({
                      ...error,
                      code: `${e.status}: ${
                        e.message || t("Server internal error or bad network. Please try later.")
                      }`,
                    });
                  }

                  setIsCodeError(true);
                });
            },
          )
          .catch((err: { code: string | string[] }) => {
            console.error(err);
            setIsCodeError(true);

            if (
              err.code.includes("internal-error") ||
              err.code.includes("network-request-failed")
            ) {
              setError({
                ...error,
                code:
                  t("Server internal error or bad network. Please try later.") ||
                  "Server internal error or bad network. Please try later.",
              });

              (window as any)?.showServerError({
                title: "Internal Error",
                text: "Network request failed. Please check the network or try again.",
                url: "firebase",
              });
            } else if (err.code.includes("expired")) {
              setError({
                ...error,
                phone: t("Session expired. Please retry."),
              });
            } else if (err.code.includes("too-many-requests")) {
              setError({
                ...error,
                phone: t("Too many requests, try later."),
              });
            } else if (err.code?.includes("attempts") || err.code?.includes("ATTEMPTS")) {
              setError({
                ...error,
                phone: t("Too many attempts, please try again later."),
              });
            } else {
              setError({
                ...error,
                code: t("Invalid code.") + " - " + err.code,
              });
            }
          });
      } else {
        setVerifying(false);
        setError({ ...error });
      }
    },
    [phoneNumberData, app, confirmationResult],
  );

  const setSWTokens = useCallback(
    (mobileAuthAccessToken: string) => {
      xhr
        .postMobileAuthLogin({ mobileAuthAccessToken })
        .then((r: { accessToken: any; refreshToken: any }) => {
          if (r.accessToken && r.refreshToken) {
            if ((window as any)?.shakewin) {
              (window as any)?.shakewin?.onPhoneVerification(r.accessToken, r.refreshToken);
            }
            store("access_token", r.accessToken);
            store("refresh_token", r.refreshToken);
            store("next_send_time", 0);
            dispatch(
              setToken({
                accessToken: r.accessToken,
                refreshToken: r.refreshToken,
              }),
            );
            dispatch(
              setVerified({
                verified: true,
              }),
            );
            setDot("click", "phone_verify_finished", "phone_num", `+${currentCountry.phone}`);
            setGaDot({
              action: "phone_verify_finished",
              value: {
                phone_num: `+${currentCountry.phone}`,
              },
            });
            dispatch(
              setTmpPassword({
                phoneNum: "",
                email: "",
                signupMethod: "",
                tmpPassword: "",
              }),
            );
            if (store("formCache") && prizeId && prizeType) {
              const data = store("formCache");
              createXhr({
                headers: {
                  Authorization: `Bearer ${r.accessToken}`,
                },
              })
                .postClaimForm(
                  {
                    prize_type: prizeType,
                    prize_id: prizeId,
                  },
                  { data },
                )
                .then(() => {
                  setDot("click", "claim_form_submitted", "status", "success");
                  setDot("click", "claim_form_submitted", "", "");
                  setGaDot({
                    action: "claim_form_submitted",
                    value: {
                      status: "success",
                    },
                  });
                  if ((window as any)?.shakewin) {
                    (window as any)?.shakewin?.requestAccessToken(true);
                    setTimeout(() => {
                      dispatch(
                        setTmpPassword({
                          phoneNum: "",
                          email: "",
                          signupMethod: "",
                          tmpPassword: "",
                        }),
                      );
                      window.location.href = prizeId
                        ? `/won?prize_id=${prizeId}`
                        : theme.afterLoginUrl;
                    }, 250);
                    return;
                  }
                })
                .catch((e: any) => {
                  setDot("click", "claim_form_submitted", "status", "failed");
                  setDot("click", "claim_form_submitted", "", "");
                  setGaDot({
                    action: "claim_form_submitted",
                    value: {
                      status: "failed",
                    },
                  });
                  console.error(e);
                  if (e?.message) {
                    (window as any)?.showToast(e?.message);
                  }
                  setTimeout(() => {
                    window.location.href = prizeId
                      ? `/won?prize_id=${prizeId}`
                      : theme.afterLoginUrl;
                  }, 500);
                });
              return;
            }

            dispatch(
              setXhr({
                xhr: createXhr({
                  headers: {
                    Authorization: "Bearer " + r.accessToken,
                    "X-CountryCode": country,
                    "Accept-Language": language,
                  },
                }),
              }),
            );
          }
        })
        .catch((err: { message: any }) => {
          (window as any)?.showToast(err.message);
          setVerifying(false);
          dispatch(logout());
          window.location.href = "/";
        });
    },
    [xhr, dispatch, country, language, prizeType, prizeId],
  );

  useEffect(() => {
    if (!phoneNumber) return;
    sendCode();
  }, [phoneNumber, prizeId, prizeType]);

  useEffect(() => {
    setInterval(() => {
      setTimeLeft(
        Math.round(Math.max(0, ((store("next_send_time") || 0) - new Date().getTime()) / 1000)),
      );
    }, 1000);
  }, []);

  return (
    <div style={{ padding: "16px", textAlign: "center" }}>
      <img
        src={require("../../assets/images/opera_logo.png")}
        alt={"opera"}
        style={{
          width: "108px",
          marginBottom: "16px",
        }}
      />
      <section style={{ marginBottom: "16px", minHeight: "60px" }}>
        <h3 style={{ fontSize: "16px", fontWeight: 700 }}>
          {verifying ? t("verifyingCode") : t("verificationSMS")}
        </h3>
        <div style={{ display: verifying ? "none" : "", height: "24px" }}>{phoneNumber}</div>
        <div
          style={{
            display: verifying ? "" : "none",
            textAlign: "center",
            height: "24px",
          }}
        >
          <SpinnerCircular
            size={22}
            thickness={180}
            speed={100}
            color="#FFB906"
            secondaryColor="rgba(0, 0, 0, 0)"
          />
        </div>
      </section>

      <div
        style={{
          display: sentCode ? "" : "none",
          textAlign: "left",
        }}
      >
        <SWInputBar
          type={"number"}
          bigTitle={t("enterSMSCode") || "Enter code from SMS"}
          placeholder={""}
          passDataFunction={verifyCode}
          onError={isCodeError}
          keyword={"code"}
          key={"code"}
          errorMessage={error.code}
          autofocus={true}
          background={theme.inputBackgroundColor}
          color={theme.inputFontColor}
          placeholderColor={"rgba(255,255,255,0.5)"}
        />
        <div
          style={{
            color: "#ffffff",
            textAlign: "center",
            fontSize: "12px",
            marginTop: "16px",
            fontWeight: 600,
            textDecoration: "underline",
          }}
          onClick={() => {
            if (store("next_send_time") - new Date().getTime() <= 0 && !testingBot) {
              sendCode("bot-check-1");
            }
          }}
        >
          <span
            style={{
              color: store("next_send_time") - new Date().getTime() <= 0 ? "#ffffff" : "#dddddd",
            }}
          >
            {t("resendSMS") || "Resend SMS"}
          </span>{" "}
          <span
            style={{
              display: timeLeft <= 0 ? "none" : "",
            }}
          >
            {" "}
            ({timeLeft}s){" "}
          </span>
        </div>
      </div>
      <div id={"bot-check"} style={{ display: sentCode ? "none" : "" }}></div>
      <div id={"bot-check-1"} style={{ display: sentCode ? "none" : "" }}></div>
    </div>
  );
};
