import React, { useEffect, useState } from "react";
import styles from "./styles/input.module.scss";
import { useSelector } from "../store";

interface InputProp {
  text: string;
  disabled?: boolean;
  background?: string;
  disabledBackground?: string;
  color?: string;
  disabledColor?: string;
  locked?: boolean;
  size?: "big" | "medium" | "small";
  fontSize?: string;
  fontWeight?: number;
  callback: () => void;
}

export const SWButton: React.FC<InputProp> = (props: InputProp) => {
  const theme = useSelector((state) => state.data.theme);
  const {
    text,
    disabled,
    background,
    color,
    disabledBackground,
    disabledColor,
    size,
    callback,
    locked,
    fontSize,
    fontWeight,
  } = props;
  const [sizeClass, setSizeClass] = useState<string>("big-button");
  const submit = () => {
    if (disabled) {
      return;
    }
    callback();
  };
  useEffect(() => {
    if (!size || size === "medium") {
      setSizeClass("medium-button");
    }
    if (size === "small") {
      setSizeClass("small-button");
    }
  }, [size]);
  return !disabled ? (
    <button
      onClick={submit}
      style={{
        background: background || theme.buttonColor || "grey",
        color: color || theme.buttonFontColor || "white",
      }}
      className={styles[sizeClass]}
    >
      <span
        style={{
          fontSize,
          fontWeight,
        }}
      >
        {text}
      </span>
    </button>
  ) : (
    <button
      onClick={() => {
        return;
      }}
      disabled
      aria-disabled
      style={{
        background: disabledBackground || background || theme.buttonColor || "grey",
        color: disabledColor || color || theme.buttonFontColor || "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      className={styles[sizeClass]}
    >
      <span
        style={{
          fontSize,
          fontWeight,
        }}
      >
        {text}
      </span>
      <img
        style={{
          display: locked ? "" : "none",
          width: "22px",
          height: "22px",
          marginLeft: "8px",
        }}
        src={require("../assets/images/shake_win/lock.png")}
        alt=""
      />
    </button>
  );
};
