import React, { useCallback, useEffect, useMemo, useState } from "react";
import { SWInputBar } from "../components/SWInputBar";
import styles from "./styles/claimForm.module.scss";
import rules from "./styles/rules.module.scss";
import { SelectList } from "../components/SelectList";
import { getCountriesList } from "../utils/countries";
import { useSelector } from "../store";
import { Link, useSearchParams } from "react-router-dom";
import { ClaimResult } from "./ClaimResult";
import { setGaDot } from "../utils/gaDot";
import { setDot } from "../utils/dot";
import { availableCountries, oprVersion } from "../config";
import { SWButton } from "../components/SWButton";
import accountProviders from "../constants/accountProviders";
import { Trans, useTranslation } from "react-i18next";
import { closeModal, setModalData, showModal } from "../store/state";
import { useDispatch } from "react-redux";
import { useUtmParamNavigate } from "../utils/utmParamNavigate";
import store from "store2";
import { InformationNotification } from "../components/v2/InformationNotification";
import { SWCheckbox } from "../components/SWCheckbox";
import { openOuterTab } from "utils/schema";

const DOB_Validation = (input: string): boolean => {
  const reg = /(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d/;
  return !!input?.match(reg);
};

const MIN_MINIPAY_CLIAM_VERSION = "84";
const isMiniVersionTooSmall = () => {
  if (!((window as Client).shakewin?.getClientName() || "").includes("mini")) return false;
  return oprVersion < MIN_MINIPAY_CLIAM_VERSION;
};
/**
 * @description The page to claim the prize.
 * @author ziwenw@opera.com
 * @since 31/08/2022
 */
export const ClaimForm: React.FC = () => {
  const [params] = useSearchParams();
  const [confirmedAge, setConfirmedAge] = useState<boolean>(false);
  const [confirmedTerm, setConfirmedTerm] = useState<boolean>(false);
  const wonPrizes = useSelector((state) => state.data.wonPrizes);
  const defaultCountry = useSelector((state) => state.data.country);
  const countries = getCountriesList(availableCountries);
  const [claimType, setClaimType] = useState<"" | ClaimType>("");
  const [name, setName] = useState<string>("");
  const [fullName, setFullName] = useState<string>("");
  const [country, setCountry] = useState<string>(defaultCountry);
  const [phonePrefix, setPhonePrefix] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [whatsAppPhoneNumber, setWhatsAppPhoneNumber] = useState<string>("");
  const [alternativePhone, setAlternativeAppPhone] = useState<string>("");
  const [deliveryAddress, setDeliveryAddress] = useState<string>("");
  const [stateAddress, setStateAddress] = useState<string>("");
  const [cpf, setCPF] = useState<string>("");
  const [bet9jAccountId, setBet9jAccountId] = useState("");
  const [finished, setFinished] = useState<boolean>(false);
  const [prizeId] = useState(params.get("prize_id") || "");
  const [prizeType] = useState(params.get("prize_type") || "");
  const [loaded, setLoaded] = useState<boolean>(false);
  const [prize, setPrize] = useState<Reward | undefined>(undefined);
  const [claimedDate, setClaimedDate] = useState<string>("");
  const [dobError, setDobError] = useState(false);
  const [bet9jaError, setBet9jaError] = useState(false);
  const theme = useSelector((state) => state.data.theme);
  const verified = useSelector((state) => state.data.verified);
  const dispatch = useDispatch();

  useEffect(() => {
    setPrize(
      wonPrizes.find((el: Reward) => {
        return el.id === prizeId;
      }),
    );
  }, [wonPrizes]);

  const { t } = useTranslation();

  const [availableProviders, setAvailableProviders] = useState(accountProviders);
  const [accountProvider, setAccountProvider] = useState("OPay");
  const [accountName, setAccountName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [walletAddress, setWalletAddress] = useState(store("walletId") || "");
  const xhr = useSelector((state) => state.data.xhr);
  const [schemaKeys, setSchemaKeys] = useState<string[]>([]);
  const [requiredKeys, setRequiredKeys] = useState<string[]>([]);
  const navigate = useUtmParamNavigate();
  const [claimRequest] = useState<ClaimRequest>({
    fullName: "",
    email: "",
    mtnPhoneNumber: "",
    phoneNumber: "",
    prizeId: prizeId,
    accountProvider: "",
    accountNumber: "",
    accountName: "",
    deliveryAddress: "",
    alternativePhoneNumber: "",
    whatsAppPhoneNumber: "",
    state: "",
    country: "",
    cpf: "",
    name: "",
    dateOfBirth: "",
    idNumber: "",
    walletAddress: store("walletId") || "",
    bet9jAccountId: "",
  });

  const [submitted, setSubmitted] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const hasMiniPay = useSelector((state) => state.data.hasMiniPay);
  const upText = useMemo(() => {
    if (isMiniVersionTooSmall()) {
      return {
        title: "Please update Opera Mini to continue",
        btn: "Update Now",
      };
    }
    return {
      title: "Please activate your MiniPay Wallet to continue",
      btn: "Activate MiniPay",
    };
  }, [oprVersion, hasMiniPay]);

  // const numberWithCommas = (x: number) => {
  //   return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // };

  const postClaimGift = useCallback(() => {
    dispatch(
      setModalData({
        modalData: {
          title: t("Are you sure?") || "Are you sure?",
          text:
            t("Please check that all your information is correct before submitting") ||
            "Please check that all your information is correct before submitting",
          background: theme.secondaryBackgroundColor,
          options: [
            {
              text: t("submit"),
              color: theme.buttonFontColor,
              background: theme.buttonColor,
              cb: () => {
                const data: { [key: string]: any } = {};
                Object.keys(claimRequest).forEach((key) => {
                  if (schemaKeys.includes(key)) {
                    if (claimType === "Cash Claim form ZA") {
                      if (key === "accountProvider") {
                        return;
                      }
                      if (["accountName", "accountNumber"].includes(key)) {
                        data[key] = "";
                        return;
                      }
                    }
                    data[key] = claimRequest[key];
                  }
                });
                store("formCache", data);
                if (!verified && country === "BR") {
                  store("prizeType", prizeType);
                  store("prizeId", prizeId);
                  dispatch(closeModal());
                  navigate(`/prize/claim/verify?prize_id=${prizeId}&prize_type=${prizeType}`);
                  return;
                }

                xhr
                  .postClaimForm(
                    {
                      prize_type: prizeType,
                      prize_id: prizeId,
                    },
                    { data },
                  )
                  .then((r: { claimedDate: string }) => {
                    const date = r.claimedDate;
                    setClaimedDate(date);
                    setDot("click", "claim_form_submitted", "status", "success");
                    setDot("click", "claim_form_submitted", "", "");
                    setGaDot({
                      action: "claim_form_submitted",
                      value: {
                        status: "success",
                      },
                    });
                    setSuccess(true);
                    navigate(prize ? `/won?prize_id=${prize.id}` : "/prizes");
                    dispatch(closeModal());
                  })
                  .catch((e: any) => {
                    setDot("click", "claim_form_submitted", "status", "failed");
                    setDot("click", "claim_form_submitted", "", "");
                    setGaDot({
                      action: "claim_form_submitted",
                      value: {
                        status: "failed",
                      },
                    });
                    console.error(e);
                    if (JSON.stringify(e)?.includes("bet9jAccountId was used by")) {
                      (window as any)?.showToast("Bet9jAccountId was used by other user");
                    } else if (e?.message) {
                      (window as any)?.showToast(e?.message);
                    }

                    dispatch(closeModal());
                  })
                  .finally(() => {
                    setSubmitted(true);
                  });
              },
            },
            {
              text: t("Edit details"),
              color: "#FFFFFF",
              background: "#393C5C",
              cb: () => {
                dispatch(closeModal());
              },
            },
          ],
        },
      }),
    );
    dispatch(showModal());
  }, [claimRequest, schemaKeys, xhr, verified]);

  const getClaimForm = useCallback(() => {
    xhr
      .getClaimForm({
        prize_type: prizeType,
        prize_id: prizeId,
      })
      .then(
        (r: {
          schema: {
            properties: any;
            required: any;
          };
          claimedDate?: string;
          name?:
            | ""
            | "Other Claim form"
            | "Airtime Claim form"
            | undefined
            | "Cash Claim form ZA"
            | "Cash Claim form NG"
            | "Cash Claim form GH"
            | "Cash Claim form KE"
            | "MiniPay"
            | "bet9j";
        }) => {
          const { schema, claimedDate, name } = r;
          if (claimedDate) {
            setSuccess(true);
            setClaimedDate(claimedDate);
            setSubmitted(true);
            return;
          }
          setClaimType(name || "Other Claim form");
          setDot("pv", "claim_prize_start", "", "");

          setGaDot({
            action: "claim_prize_start",
            value: {},
          });
          setSchemaKeys(Object.keys(schema?.properties || {}));
          setRequiredKeys(schema?.required || []);

          if (
            Array.isArray(schema?.properties?.accountProvider?.enum) &&
            schema?.properties?.accountProvider?.enum[0]
          ) {
            const tmp: {
              [key: string]: any;
            } = {};
            schema?.properties?.accountProvider?.enum.forEach((code: string) => {
              tmp[code] = accountProviders[code] || {};
            });
            setAvailableProviders(tmp);
            setAccountProvider(schema?.properties?.accountProvider?.enum[0]);
          }
          if (country === "NG") {
            setAvailableProviders({
              OPay: {
                code: "OPay",
                name: "OPay",
                img: require("../assets/images/opay.png"),
              },
            });
            setAccountProvider("OPay");
          }

          if (country === "GH") {
            setAvailableProviders({
              "MTN MoMo": {
                code: "MTN Mo Mo",
                name: "MTN Money Mobile",
                img: require("../assets/images/mtn.png"),
              },
            });
            setAccountProvider("MTN Mo Mo");
          }

          if (country === "KE") {
            setAvailableProviders({
              MPesa: {
                code: "MPesa",
                name: "M-Pesa",
                img: require("../assets/images/pesa.png"),
              },
            });
            setAccountProvider("MPesa");
          }

          setLoaded(true);
        },
      )
      .catch((e: any) => {
        console.error(e);
        setLoaded(true);
        // navigate("/prizes");
      });
  }, [country, xhr]);

  useEffect(() => {
    getClaimForm();
  }, [xhr]);

  useEffect(() => {
    claimRequest.whatsAppPhoneNumber = whatsAppPhoneNumber;
    claimRequest.deliveryAddress = deliveryAddress;
    claimRequest.alternativePhoneNumber = alternativePhone;
    claimRequest.state = stateAddress;
    claimRequest.cpf = cpf;
  }, [whatsAppPhoneNumber, alternativePhone, deliveryAddress, stateAddress, cpf]);

  useEffect(() => {
    setPhonePrefix(countries[country]?.phone ? `+${countries[country]?.phone}` : "");
    claimRequest.phoneNumber = phone;
    claimRequest.email = email;
    claimRequest.fullName = fullName;
    claimRequest.name = name;
    claimRequest.country = country;
  }, [country, email, name, fullName]);

  useEffect(() => {
    claimRequest.phoneNumber = phone;
    store("phoneToVerify", phone);
  }, [phone]);

  useEffect(() => {
    claimRequest.accountProvider = accountProvider;
  }, [accountProvider]);

  useEffect(() => {
    claimRequest.accountName = accountName;
  }, [accountName]);

  useEffect(() => {
    claimRequest.accountNumber = accountNumber;
  }, [accountNumber]);

  useEffect(() => {
    claimRequest.idNumber = idNumber;
  }, [idNumber]);

  useEffect(() => {
    claimRequest.walletAddress = walletAddress;
  }, [walletAddress]);

  useEffect(() => {
    claimRequest.bet9jAccountId = bet9jAccountId;
    const reg = /^[\d]+$/;
    setBet9jaError(!reg.test(bet9jAccountId));
  }, [bet9jAccountId]);

  useEffect(() => {
    claimRequest.dateOfBirth = dateOfBirth;
  }, [dateOfBirth]);

  useEffect(() => {
    let completed = true;
    requiredKeys.forEach((key: string) => {
      // @ts-ignore
      switch (key) {
        case "fullName":
          completed = completed && !!fullName;
          break;
        case "phoneNumber":
          completed = completed && !!(phone && phone.length > phonePrefix.length + 1);
          break;
        case "email":
          completed = completed && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
          break;
        case "accountProvider":
          completed = completed && (!!accountProvider || claimType === "Cash Claim form ZA");
          break;
        case "accountNumber":
          completed = completed && (!!accountNumber || claimType === "Cash Claim form ZA");
          break;
        case "accountName":
          completed = completed && (!!accountName || claimType === "Cash Claim form ZA");
          break;
        case "whatsAppPhoneNumber":
          completed =
            completed &&
            !!(whatsAppPhoneNumber && whatsAppPhoneNumber.length > phonePrefix.length + 1);
          break;
        case "alternativePhoneNumber":
          completed =
            completed && !!(alternativePhone && alternativePhone.length > phonePrefix.length + 1);
          break;
        case "deliveryAddress":
          completed = completed && !!deliveryAddress;
          break;
        case "state":
          completed = completed && !!stateAddress;
          break;
        case "cpf":
          completed = completed && !!cpf;
          break;
        case "bet9jAccountId":
          completed = completed && !!bet9jAccountId && /^[\d]+$/.test(bet9jAccountId);
          break;
        case "name":
          completed = completed && !!name;
          break;
        case "dateOfBirth":
          completed = completed && !!dateOfBirth;
          break;
        case "idNumber":
          completed = completed && !!idNumber;
          break;
        case "walletAddress":
          completed = completed && !!walletAddress;
          break;
        default:
          completed = false;
          break;
      }
    });
    setFinished(completed);
  }, [
    name,
    phone,
    email,
    deliveryAddress,
    stateAddress,
    accountName,
    accountNumber,
    claimType,
    country,
    whatsAppPhoneNumber,
    alternativePhone,
    cpf,
    loaded,
    fullName,
    dateOfBirth,
    walletAddress,
    idNumber,
    bet9jAccountId,
  ]);

  return submitted ? (
    <ClaimResult
      success={success}
      prize={prize}
      claimedDate={claimedDate}
      toggleFunction={() => {
        setSubmitted(false);
      }}
    />
  ) : (
    <div
      className={styles.main}
      style={{
        display: !loaded ? "none" : "",
      }}
    >
      <h2 className={styles.title}>{t("claimYourPrize")}</h2>
      <div
        className={styles.info_area}
        style={{ background: theme.brandBackgroundColor, borderColor: "transparent" }}
      >
        <img src={prize ? prize.brandLogo : ""} alt={prize ? prize.brandName : ""} />
        <div className={styles.info_area_text}>
          <h3>{prize ? prize.name : ""}</h3>
          <p>{prize ? prize.brandName : ""}</p>
        </div>
      </div>
      {claimType === "MiniPay" && (!hasMiniPay || isMiniVersionTooSmall()) ? (
        <div>
          <p
            style={{
              textAlign: "center",
              fontSize: "14px",
              margin: "8px 0",
              color: "rgba(255, 255, 255, 0.75)",
            }}
          >
            {upText.title}
          </p>
          <SWButton
            text={upText.btn}
            background={theme.buttonColor}
            disabledBackground={"rgba(255, 255, 255, 0.2)"}
            color={theme.buttonFontColor}
            disabledColor={"rgba(255, 255, 255, 0.5)"}
            size={"medium"}
            callback={() => {
              if (!isMiniVersionTooSmall() && (window as Client).shakewin?.getMiniPayStatus) {
                (window as Client).shakewin?.openOperaMiniDeepLink("opera-mini://minipay", true);
                return;
              }
              if ((window as Client).shakewin?.getClientName() === "mini-beta") {
                (window as Client).shakewin?.openOperaMiniDeepLink(
                  "opera-mini://open?url=https://play.google.com/store/apps/details?id=com.opera.mini.native.beta",
                  true,
                );
                return;
              }
              if ((window as Client).shakewin?.getClientName() === "mini") {
                (window as Client).shakewin?.openOperaMiniDeepLink(
                  "opera-mini://open?url=https://play.google.com/store/apps/details?id=com.opera.mini.native",
                  true,
                );
                return;
              }
              (window as Window).location.href =
                "https://play.google.com/store/apps/details?id=com.opera.mini.native";
            }}
          />
        </div>
      ) : (
        <>
          <div
            className={styles.input_area}
            style={{
              display: schemaKeys.includes("fullName") ? "" : "none",
            }}
          >
            <SWInputBar
              type={"text"}
              bigTitle={"First and last name"}
              placeholder={"e.g. John Smith"}
              keyword={"name"}
              key={"fullName"}
              necessary={requiredKeys.includes("fullName")}
              passDataFunction={(data) => {
                setFullName(data);
              }}
              background={theme.inputBackgroundColor}
              color={theme.inputFontColor}
              placeholderColor={theme.placeholder}
            />
          </div>
          <div
            className={styles.input_area}
            style={{
              display: schemaKeys.includes("name") ? "" : "none",
            }}
          >
            <SWInputBar
              type={"text"}
              bigTitle={t("name") || "Name"}
              placeholder={t("typeYourName") || "Type your name"}
              keyword={t("name") || "name"}
              key={"name_br"}
              necessary={requiredKeys.includes("name")}
              passDataFunction={(data) => {
                setName(data);
              }}
              background={theme.inputBackgroundColor}
              color={theme.inputFontColor}
              placeholderColor={theme.placeholder}
            />
          </div>
          <div
            className={styles.input_area}
            style={{
              display: defaultCountry === "BR" ? "none" : "",
            }}
          >
            <SelectList
              title={"Country of residence"}
              currentItem={countries[defaultCountry]}
              items={countries}
              type={"countries"}
              selectCb={(data) => {
                setCountry(data);
              }}
              disabled={true}
              background={theme.secondaryBackgroundColor}
              color={theme.inputFontColor}
              placeholderColor={theme.placeholder}
              theme={"dark"}
            />
          </div>
          <div
            className={styles.input_area}
            style={{
              display: schemaKeys.includes("phoneNumber") ? "" : "none",
            }}
          >
            <SWInputBar
              bigTitle={
                claimType !== "Airtime Claim form"
                  ? t("Phone Number") || "Phone Number"
                  : prize?.brandName + " " + t("Phone Number") || "Phone Number"
              }
              key={"phone"}
              type={"phone"}
              inputTitle={phonePrefix}
              placeholder={
                claimType !== "Airtime Claim form"
                  ? t(`Enter phone number`) || `Enter phone number`
                  : `Enter ${prize?.brandName} number to load or gift`
              }
              necessary={requiredKeys.includes("phoneNumber")}
              passDataFunction={(phone) => {
                setPhone(`${phonePrefix} ${phone}`);
                store("phoneBody", phone);
              }}
              keyword={t("phone") || "phone"}
              background={theme.inputBackgroundColor}
              color={theme.inputFontColor}
              placeholderColor={theme.placeholder}
            />
            <div
              style={{
                display: claimType !== "Airtime Claim form" ? "none" : "flex",
                alignItems: "center",
                marginTop: "8px",
              }}
            >
              <img
                src={require("../assets/images/info.png")}
                alt=""
                style={{
                  width: "12px",
                  height: "12px",
                }}
              />
              <p
                style={{
                  color: "rgba(255, 255, 255, 0.75)",
                  fontSize: "12px",
                  lineHeight: "12px",
                  height: "12px",
                  fontWeight: "400",
                  margin: "0 8px",
                }}
              >
                Mast be a valid {prize?.brandName} phone number
              </p>
            </div>
          </div>
          <div
            className={styles.input_area}
            style={{
              display: schemaKeys.includes("whatsAppPhoneNumber") ? "" : "none",
            }}
          >
            <SWInputBar
              bigTitle={"WhatsApp Phone Number"}
              key={"phone"}
              type={"phone"}
              inputTitle={phonePrefix}
              placeholder={"Enter whatsApp phone number"}
              necessary={requiredKeys.includes("whatsAppPhoneNumber")}
              passDataFunction={(phone) => {
                setWhatsAppPhoneNumber(`${phonePrefix} ${phone}`);
              }}
              keyword={"whatsapp phone"}
              background={theme.inputBackgroundColor}
              color={theme.inputFontColor}
              placeholderColor={theme.placeholder}
            />
          </div>
          <div
            className={styles.input_area}
            style={{
              display: schemaKeys.includes("email") ? "" : "none",
            }}
          >
            <SWInputBar
              bigTitle={t("Email Address") || "Email Address"}
              type={"email"}
              placeholder={t("e.g. john@company.com")}
              passDataFunction={(mail) => {
                setEmail(mail);
              }}
              keyword={t("email") || "email"}
              necessary={requiredKeys.includes("email")}
              onError={!!email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)}
              errorMessage={
                !!email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
                  ? "Invalid email"
                  : "Please enter your email."
              }
              background={theme.inputBackgroundColor}
              color={theme.inputFontColor}
              placeholderColor={theme.placeholder}
            />
          </div>
          <div
            className={styles.input_area}
            style={{
              display:
                claimType !== "Cash Claim form ZA" && schemaKeys.includes("accountProvider")
                  ? ""
                  : "none",
            }}
          >
            <SelectList
              title={"Account Provider"}
              currentItem={accountProviders[accountProvider]}
              items={availableProviders}
              type={"payment"}
              selectCb={(data) => {
                setAccountProvider(data);
              }}
              key={accountProvider}
              background={theme.inputBackgroundColor}
              color={theme.inputFontColor}
              placeholderColor={theme.placeholder}
              theme={"dark"}
            />
          </div>
          <div
            className={styles.input_area}
            style={{
              display:
                claimType !== "Cash Claim form ZA" && schemaKeys.includes("accountName")
                  ? ""
                  : "none",
            }}
          >
            <SWInputBar
              bigTitle={`${accountProviders[accountProvider]?.name || ""} Account Name`}
              type={"text"}
              placeholder={"e.g. John Smith"}
              necessary={requiredKeys.includes("accountName")}
              passDataFunction={(accountName) => {
                setAccountName(accountName);
              }}
              keyword={"account name"}
              background={theme.inputBackgroundColor}
              color={theme.inputFontColor}
              placeholderColor={theme.placeholder}
            />
          </div>
          <div
            className={styles.input_area}
            style={{
              display:
                claimType !== "Cash Claim form ZA" && schemaKeys.includes("accountNumber")
                  ? ""
                  : "none",
            }}
          >
            <SWInputBar
              bigTitle={`${accountProviders[accountProvider]?.name || ""} Account Number`}
              type={["OPay", "MPesa", "MTN MoMo"].includes(accountProvider) ? "number" : "text"}
              placeholder={
                country === "ZA"
                  ? "Enter your account number or email"
                  : "Enter your account number"
              }
              necessary={requiredKeys.includes("accountNumber")}
              passDataFunction={(accountNumber) => {
                setAccountNumber(accountNumber);
              }}
              keyword={"account number"}
              background={theme.inputBackgroundColor}
              color={theme.inputFontColor}
              placeholderColor={theme.placeholder}
            />
          </div>
          <div
            className={styles.input_area}
            style={{
              display: schemaKeys.includes("alternativePhoneNumber") ? "" : "none",
            }}
          >
            <SWInputBar
              bigTitle={"Alternative Phone Number"}
              key={"alternativePhone"}
              type={"phone"}
              inputTitle={phonePrefix}
              placeholder={"Enter alternative phone number"}
              necessary={requiredKeys.includes("alternativePhoneNumber")}
              passDataFunction={(phone) => {
                setAlternativeAppPhone(`${phonePrefix} ${phone}`);
              }}
              keyword={"alternative phone"}
              background={theme.inputBackgroundColor}
              color={theme.inputFontColor}
              placeholderColor={theme.placeholder}
            />
          </div>
          <div
            className={styles.input_area}
            style={{
              display: schemaKeys.includes("deliveryAddress") ? "" : "none",
            }}
          >
            <SWInputBar
              bigTitle={`Delivery address`}
              type={"text"}
              placeholder={"Enter your delivery address"}
              necessary={requiredKeys.includes("deliveryAddress")}
              passDataFunction={(deliveryAddress) => {
                setDeliveryAddress(deliveryAddress);
              }}
              keyword={"delivery address"}
              background={theme.inputBackgroundColor}
              color={theme.inputFontColor}
              placeholderColor={theme.placeholder}
            />
          </div>
          <div
            className={styles.input_area}
            style={{
              display: schemaKeys.includes("state") ? "" : "none",
            }}
          >
            <SWInputBar
              bigTitle={`State`}
              type={"text"}
              placeholder={"Enter your state"}
              necessary={requiredKeys.includes("state")}
              passDataFunction={(stateAddress) => {
                setStateAddress(stateAddress);
              }}
              keyword={"state"}
              background={theme.inputBackgroundColor}
              color={theme.inputFontColor}
              placeholderColor={theme.placeholder}
            />
          </div>
          <div
            className={styles.input_area}
            style={{
              display: schemaKeys.includes("walletAddress") ? "" : "none",
            }}
          >
            <SWInputBar
              bigTitle={t(`walletAddress`) || "Wallet address"}
              type={"text"}
              placeholder={"Enter your Wallet address"}
              necessary={requiredKeys.includes("walletAddress")}
              passDataFunction={(address) => {
                setWalletAddress(address);
              }}
              keyword={t(`walletAddress`) || "Wallet address"}
              background={theme.inputBackgroundColor}
              color={theme.inputFontColor}
              placeholderColor={theme.placeholder}
              defaultVal={walletAddress}
              immutable={true}
            />
          </div>
          <div
            className={styles.input_area}
            style={{
              display: schemaKeys.includes("idNumber") ? "" : "none",
            }}
          >
            <SWInputBar
              bigTitle={t(`idNumber`) || "Id Number"}
              type={"text"}
              placeholder={"Enter your Id number"}
              necessary={requiredKeys.includes("idNumber")}
              passDataFunction={(id) => {
                setIdNumber(id);
              }}
              keyword={t(`idNumber`) || "Id Number"}
              background={theme.inputBackgroundColor}
              color={theme.inputFontColor}
              placeholderColor={theme.placeholder}
            />
          </div>
          <div
            className={styles.input_area}
            style={{
              display: schemaKeys.includes("dateOfBirth") ? "" : "none",
            }}
          >
            <SWInputBar
              bigTitle={t(`dateOfBirth`) || "Date of Birth"}
              type={"text"}
              placeholder={"DD/MM/YYYY"}
              necessary={requiredKeys.includes("dateOfBirth")}
              passDataFunction={(dob) => {
                setDateOfBirth(dob);
              }}
              keyword={t(`dateOfBirth`) || "Date of Birth"}
              background={theme.inputBackgroundColor}
              color={theme.inputFontColor}
              placeholderColor={theme.placeholder}
              onError={dobError}
              errorMessage={"Please enter DD/MM/YYYY"}
              blurCallback={() => {
                setDobError(!DOB_Validation(dateOfBirth));
              }}
            />
          </div>
          <div
            className={styles.input_area}
            style={{
              display: schemaKeys.includes("cpf") ? "" : "none",
            }}
          >
            <SWInputBar
              bigTitle={t(`CPF`) || `CPF`}
              type={"text"}
              placeholder={t("Enter your CPF")}
              necessary={requiredKeys.includes("cpf")}
              passDataFunction={(cpf) => {
                setCPF(cpf);
              }}
              keyword={t(`CPF`) || `CPF`}
              background={theme.inputBackgroundColor}
              color={theme.inputFontColor}
              placeholderColor={theme.placeholder}
            />
          </div>
          <div
            className={styles.input_area}
            style={{
              display: schemaKeys.includes("bet9jAccountId") ? "" : "none",
            }}
          >
            <SWInputBar
              bigTitle={t(`bet9jAccountId`) || `bet9jAccountId`}
              type={"text"}
              placeholder={"Enter your Bet9j Account Id"}
              necessary={requiredKeys.includes("bet9jAccountId")}
              passDataFunction={(id) => {
                setBet9jAccountId(id);
              }}
              keyword={"bet9jAccountId"}
              background={theme.inputBackgroundColor}
              color={theme.inputFontColor}
              placeholderColor={theme.placeholder}
              onError={bet9jaError}
              errorMessage={"Please input a valid Bet9ja account ID."}
            />
            <InformationNotification
              transparentBg
              message={"Must be a valid Bet9ja ID and not a phone number"}
            />
            <p
              style={{
                color: "rgba(255,255,255,0.75)",
                fontSize: "12px",
                textAlign: "center",
              }}
            >
              To find your Bet9ja ID number, first{" "}
              <b
                style={{ color: "#FFF", textDecoration: "underline" }}
                onClick={() => {
                  openOuterTab("https://www.bet9ja.com/?site_id=304554");
                }}
              >
                log into your Bet9ja account{" "}
              </b>
              , then tap on {'"my account"'} located at the top right corner; once the page loads,
              simply scroll down.
            </p>
          </div>
          <div
            className={styles.input_area}
            style={{
              display: ["Other Claim form"].includes(claimType || "") ? "" : "none",
            }}
          >
            <SWInputBar
              bigTitle={`Prize type`}
              type={"text"}
              placeholder={""}
              passDataFunction={() => {
                return;
              }}
              keyword={"prize type"}
              defaultVal={prize?.name}
              immutable={true}
              background={theme.inputBackgroundColor}
              color={theme.inputFontColor}
              placeholderColor={theme.placeholder}
            />
          </div>
          <div
            className={styles.input_area}
            style={{
              display: [
                "Cash Claim form NG",
                "Cash Claim form GH",
                "Cash Claim form KE",
                "Cash Claim form ZA",
                "Airtime Claim form",
              ].includes(claimType || "")
                ? ""
                : "none",
            }}
          >
            <SWInputBar
              bigTitle={`Prize amount`}
              type={"text"}
              placeholder={""}
              passDataFunction={() => {
                return;
              }}
              keyword={"prize amount"}
              defaultVal={prize?.name}
              immutable={true}
              background={theme.inputBackgroundColor}
              color={theme.inputFontColor}
              placeholderColor={theme.placeholder}
            />
          </div>
          {country === "ZA" ? (
            <div className={styles.info_message}>
              By submitting this form, you agree to allow us to share the information you provide
              with{" "}
              <a
                onClick={(e) => {
                  e.preventDefault();
                  if (typeof (window as Client)?.shakewin?.openOperaMiniDeepLink === "function") {
                    // @ts-ignore
                    (window as Client)?.shakewin?.openOperaMiniDeepLink(
                      "opera-mini://open?url=www.upit.co.za/",
                      true,
                    );
                    return;
                  }
                  window.location.href = "https://www.upit.co.za/";
                }}
                target="_blank"
                rel="noreferrer"
              >
                our agents
              </a>{" "}
              for the purpose of processing your claims and delivering your reward
            </div>
          ) : null}
          <ul className={rules.statements}>
            <li>
              <div
                onClick={() => {
                  setConfirmedAge((state) => !state);
                }}
              >
                <SWCheckbox checked={confirmedAge} />
              </div>
              <span>{t("confirmAge")}</span>
            </li>
            <li>
              <div
                onClick={() => {
                  setConfirmedTerm((state) => !state);
                }}
              >
                <SWCheckbox checked={confirmedTerm} />
              </div>

              <span>
                <Trans
                  components={{
                    1: <Link to={"/tc"} />,
                  }}
                >
                  confirmCondition
                </Trans>
              </span>
            </li>
          </ul>
          <div className={styles.input_area_button}>
            <SWButton
              text={t("submit")}
              disabled={!finished || !confirmedAge || !confirmedTerm}
              locked={!finished}
              background={theme.buttonColor}
              disabledBackground={"rgba(255, 255, 255, 0.2)"}
              color={theme.buttonFontColor}
              disabledColor={"rgba(255, 255, 255, 0.5)"}
              size={"medium"}
              callback={postClaimGift}
            />
            <p
              style={{
                display: schemaKeys.includes("bet9jAccountId") ? "" : "none",
                color: "rgba(255,255,255,0.75)",
                fontSize: "12px",
                marginTop: "8px",
                textAlign: "center",
              }}
            >
              {"Don't have an account?"}{" "}
              <b
                style={{ color: "#FFF", textDecoration: "underline" }}
                onClick={() => {
                  openOuterTab("https://rt.bet9ja.click/o/Vixpd4?lpage=WK3XQp&site_id=304554");
                }}
              >
                Create one
              </b>
            </p>
          </div>
        </>
      )}
    </div>
  );
};
