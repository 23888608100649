import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useSelector as rawUseSelector } from "react-redux";
import stateReducer from "./state";

export const index = configureStore({
  reducer: {
    data: stateReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof index.dispatch;
export type RootState = ReturnType<typeof index.getState>;
export const useSelector: TypedUseSelectorHook<RootState> = rawUseSelector;
