import React, { useEffect, useMemo, useState } from "react";
import styles from "./styles/result.module.scss";
import { useSelector } from "../store";
import { useSearchParams } from "react-router-dom";
import { SWButton } from "../components/SWButton";
import { ApexReward } from "../components/Reward";
import { ClaimSteps } from "../components/ClaimSteps";
import { useUtmParamNavigate } from "../utils/utmParamNavigate";
import { useTranslation } from "react-i18next";
import { getClientName } from "../config";
import store from "store2";
import usePostClaim from "../utils/postClaim";
import { setWon } from "../store/state";
import { useDispatch } from "react-redux";

export const PrizeWonDetail: React.FC = () => {
  const { t, i18n } = useTranslation();
  const pendingReward: Reward = {
    id: "pending",
    type: "pending",
    title: "",
    prizeTypeId: "",
    subtitle: "",
    brandLogo: "",
    height: 1,
    width: 1,
    wonPuzzlePieces: [],
    giftPicture: "",
    brandName: "",
    name: "",
  };
  const navigate = useUtmParamNavigate();
  const postClaim = usePostClaim();
  const [params] = useSearchParams();
  const shakes = useSelector((state) => state.data.shakes);
  const verified = useSelector((state) => state.data.verified);
  const wonPrizes: Reward[] = useSelector((state) => state.data.wonPrizes);
  const winnablePrizes: Reward[] = useSelector((state) => state.data.winnablePrizes);
  const puzzles: Reward[] = useSelector((state) => state.data.puzzles);
  const xhr = useSelector((state) => state.data.xhr);
  const country = useSelector((state) => state.data.country);
  const phase = useSelector((state) => state.data.phase);
  const [prizeId] = useState(params.get("prize_id") || "");
  const [claimSteps, setClaimSteps] = useState(<div></div>);
  const [rewardInfo, setRewardInfo] = useState<Reward>(pendingReward);
  // const [_, setCode] = useState("");
  const loggedIn = useSelector((state) => state.data.loggedIn);
  const dispatch = useDispatch();

  useEffect(() => {
    if (rewardInfo.type === "immediate" || rewardInfo.type === "puzzle") {
      if (
        ((rewardInfo.isClaimFormRequired && rewardInfo.isClaimed) ||
          !rewardInfo.isClaimFormRequired) &&
        country
      ) {
        let couponCode = "";
        xhr
          .getPrizeCouponCode({
            prize_id: rewardInfo.id,
            prize_type: rewardInfo.type,
          })
          .then((res: { couponCode: string }) => {
            // setCode(res.couponCode || "");
            couponCode = res.couponCode || "";
          })
          .catch(() => {
            // setCode("");
          })
          .finally(() => {
            const stepInfo = rewardInfo.claimSteps
              ? rewardInfo.claimSteps.map((el: string, index) => {
                  if (rewardInfo.redirectUrl && getClientName() === "mini") {
                    if (index === 0 && country === "BR") {
                      return t("Go to shopee.com.br");
                    }
                  }
                  if (el.includes("#code##")) {
                    return el.replace("#code##", couponCode);
                  }
                  return el;
                })
              : [];
            setClaimSteps(
              <div>
                <ClaimSteps steps={stepInfo} />
              </div>,
            );
            window.scrollTo(0, 99999);
          });
      }
    }
  }, [rewardInfo, country]);

  useEffect(() => {
    if (prizeId) {
      const tmp = puzzles?.find((el) => {
        if (el) {
          return el?.id === prizeId;
        }
      });
      if (tmp) {
        setRewardInfo({
          ...tmp,
          won: wonPrizes.find((el: Reward) => {
            return el.id === prizeId;
          })?.won,
        });
      } else {
        const prize: Reward | undefined = wonPrizes.find((el: Reward) => {
          return el.id === prizeId;
        });
        setRewardInfo(prize || pendingReward);
      }
    }

    if (!prizeId) {
      setRewardInfo(pendingReward);
    }
  }, [prizeId, puzzles, winnablePrizes, wonPrizes]);

  useEffect(() => {
    setReward(<ApexReward reward={rewardInfo} />);
  }, [rewardInfo]);

  const [intro, setIntro] = useState<{
    logo: string;
    title: string;
    brandName: string;
    name: string;
    subtitle: string;
    description: string;
    styleName: string;
  }>({
    logo: "",
    title: "",
    subtitle: "",
    description: "",
    styleName: "",
    name: "",
    brandName: "",
  });
  const [buttons, setButtons] = useState(<div className={styles.button}></div>);
  const [reward, setReward] = useState(<ApexReward reward={rewardInfo} />);
  const needToVerifyPhone = useMemo(
    () => !verified && rewardInfo.isVerifiedPhoneRequired,
    [verified, rewardInfo],
  );

  useEffect(() => {
    const introObj = {
      title: "",
      subtitle: "",
      brandName: "",
      name: "",
      logo: "",
      description: "",
      styleName: styles.title_text,
    };
    if (rewardInfo.type === "puzzle") {
      introObj.title = rewardInfo.title || t("congratulations");
      introObj.logo = rewardInfo.brandLogo;
      introObj.brandName = rewardInfo.brandName;
      introObj.name = rewardInfo.name;
    } else if (rewardInfo.type === "immediate") {
      introObj.title = rewardInfo.title || t("congratulations");
      introObj.subtitle = rewardInfo.subtitle || "";
      introObj.description = rewardInfo.subtitle || "";
      introObj.logo = rewardInfo.brandLogo;
      introObj.styleName = styles.title_text_immediate;
      introObj.brandName = rewardInfo.brandName;
      introObj.name = rewardInfo.name;
    }
    setIntro(introObj);
  }, [rewardInfo]);

  useEffect(() => {
    if (phase === "FINISHED") {
      return;
    }
    if (rewardInfo.isClaimed && rewardInfo.redirectUrl) {
      setButtons(
        <div className={styles.button}>
          {getClientName() != "mini" ? (
            <SWButton
              text={rewardInfo.redirectButtonTitle || t("claimYourPrize") || "Claim your prize"}
              locked={false}
              callback={() => {
                window.open(rewardInfo.redirectUrl + "", "_blank");
              }}
            />
          ) : null}
        </div>,
      );
      return;
    }
    if (rewardInfo.isClaimed && !rewardInfo.redirectUrl) {
      setButtons(<></>);
      return;
    }
    if (!rewardInfo.isClaimFormRequired) {
      setButtons(<></>);
      return;
    }
    setButtons(
      <div
        className={styles.button}
        style={!rewardInfo.isClaimFormRequired ? { marginTop: "8px" } : {}}
      >
        {!rewardInfo.isClaimFormRequired ? (
          <div
            style={{
              display: rewardInfo.redirectUrl || !rewardInfo.isClaimed || !loggedIn ? "" : "none",
            }}
          >
            <SWButton
              text={
                needToVerifyPhone
                  ? t("verifyToClaimIt")
                  : rewardInfo.redirectButtonTitle || t("claimYourPrize") || "Claim your prize"
              }
              locked={false}
              callback={() => {
                if (!loggedIn) {
                  navigate("/login");
                  return;
                }
                if (needToVerifyPhone) {
                  navigate(`/login/verify?prize_id=${rewardInfo.id}&prize_type=${rewardInfo.type}`);
                  return;
                }
                if (rewardInfo.redirectUrl) {
                  window.location.href = rewardInfo.redirectUrl;
                  return;
                }
                navigate(`/prize/claim?prize_id=${rewardInfo.id}&prize_type=${rewardInfo.type}`);
              }}
            />
          </div>
        ) : (
          <SWButton
            text={
              (!loggedIn || needToVerifyPhone) && rewardInfo.type === "puzzle"
                ? t("verifyToClaimIt")
                : rewardInfo.redirectButtonTitle || t("claimYourPrize") || "Claim your prize"
            }
            locked={false}
            callback={() => {
              if (!loggedIn) {
                navigate("/login");
                return;
              }
              if (needToVerifyPhone && country !== "BR") {
                navigate(`/login/verify?prize_id=${rewardInfo.id}&prize_type=${rewardInfo.type}`);
                return;
              }
              if (verified && country === "BR" && store("formCache")) {
                postClaim(
                  {
                    prizeId: rewardInfo.id,
                    prizeType: rewardInfo.type,
                    data: store("formCache"),
                  },
                  true,
                ).then(() => {
                  xhr.getPrizesWon().then((res: any) => {
                    const wonPrizes: Reward[] = Array.isArray(res?.prizesWon)
                      ? res?.prizesWon?.map((prize: Prize) => {
                          const reward: Reward = {
                            id: prize.prizeId || "",
                            prizeId: prize.prizeId || "",
                            brandName: prize.brandName,
                            name: prize.name,
                            prizeTypeId: prize.prizeTypeId || "",
                            brandLogo: prize.thumbnailUrl,
                            type: prize.type,
                            title: prize.title,
                            subtitle: prize.subtitle,
                            giftPicture: prize.imageUrl,
                            height: prize.type === "puzzle" ? 2 : 0,
                            width: prize.type === "puzzle" ? 3 : 0,
                            wonPuzzlePieces: prize.puzzlePieces || [],
                            claimSteps: prize.claimSteps || [],
                            won: true,
                            prizeWonTime: prize.prizeWonTime || "",
                            redirectButtonTitle: prize.redirectButtonTitle,
                            redirectUrl: prize.redirectUrl,
                            isClaimFormRequired: prize.isClaimFormRequired,
                            isClaimed: prize.isClaimed,
                            couponCode: prize.couponCode,
                            expiresAt: prize.expiresAt
                              ? new Date(prize.expiresAt).toLocaleDateString(i18n.language)
                              : i18n.language === "pt-BR"
                              ? "01/05/23"
                              : "05/01/23",
                          };
                          return reward;
                        })
                      : [];
                    dispatch(
                      setWon({
                        wonPrizes,
                      }),
                    );
                  });
                });
                return;
              }
              navigate(`/prize/claim?prize_id=${rewardInfo.id}&prize_type=${rewardInfo.type}`);
            }}
          />
        )}
      </div>,
    );
  }, [shakes, needToVerifyPhone, verified, rewardInfo, loggedIn]);

  return rewardInfo.type === "pending" ? (
    <div></div>
  ) : (
    <div>
      <div className={styles.main}>
        <div
          className={styles.title}
          style={{
            display: rewardInfo.type === "none" ? "none" : "",
          }}
        >
          <img className={styles.logo} src={rewardInfo.brandLogo} alt={rewardInfo.brandName} />
          <div className={styles.title_name}>{intro.brandName}</div>
        </div>
        <div
          className={styles.intro}
          style={{
            display: rewardInfo.type !== "none" ? "" : "none",
          }}
        >
          <div className={intro.styleName}>{intro.title}</div>
          <div className={styles.intro_guide}>{intro.subtitle}</div>
        </div>
        {reward}
        {!needToVerifyPhone &&
        ((rewardInfo.isClaimFormRequired && rewardInfo.isClaimed) ||
          !rewardInfo.isClaimFormRequired)
          ? claimSteps
          : null}
        {buttons}
      </div>
    </div>
  );
};
