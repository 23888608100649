import React, { useEffect, useState } from "react";
import { ShakeWinRewardPuzzle } from "./RewardPuzzle";
import { useTranslation } from "react-i18next";
import styles from "./styles/shake_puzzle.module.scss";
import { useSelector } from "../store";
import { FailedShakeImage } from "./v2/FailedShakeImage";

interface props {
  reward: Reward;
  rewardedPiece?: number;
  notObtained?: boolean;
}

export const ApexReward: React.FC<props> = ({ reward, rewardedPiece, notObtained }) => {
  const [rewardDom, setRewardDom] = useState(
    <div
      style={{
        marginTop: "20vh",
      }}
    ></div>,
  );
  const theme = useSelector((state) => state.data.theme);
  // const giftBg = require("../assets/images/shake_win_br2/bg_square.jpg");
  const country = useSelector((state) => state.data.country);
  const { t } = useTranslation();
  const verified = useSelector((state) => state.data.verified);

  const share = async () => {
    const shareData = {
      title:
        t("wonSharingMessage", { rewardName: reward.name }) ||
        "Come to join it, shake your phone and Win!",
      // files: filesArray,
      url: "https://shakewin.opera.com",
    };
    try {
      await (window as any).navigator.share(shareData);
      (window as any).showToast("Shake&Win shared successfully");
    } catch (err) {
      (window as any).showToast(`Error: ${err}`);
    }
  };

  useEffect(() => {
    switch (reward?.type) {
      case "puzzle":
        setRewardDom(<ShakeWinRewardPuzzle rewardInfo={reward} receivedPiece={rewardedPiece} />);
        break;
      case "immediate":
        setRewardDom(
          <div>
            <div
              style={{
                boxSizing: "border-box",
                width: "100%",
                minHeight: "200px",
                overflow: "hidden",
                position: "relative",
                background: `${theme.backgroundImage} center 50% no-repeat`,
                backgroundSize: `cover`,
                borderRadius: "8px",
              }}
            >
              <img
                style={{
                  width: "100%",
                  height: "auto",
                }}
                src={reward.giftPicture}
                alt=""
              />
              <div
                style={{
                  display: notObtained ? "" : "none",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  borderRadius: "8px",
                  background: "rgba(255,255,255, 0.60)",
                }}
              ></div>
            </div>
            <div
              style={{
                width: "200px",
                justifyContent: "center",
                alignItems: "center",
                padding: "8px",
                borderRadius: "32px",
                background: "#431F70",
                margin: "16px auto",
                display:
                  verified && reward.id && typeof window?.navigator?.share === "function"
                    ? "flex"
                    : "none",
              }}
              onClick={share}
            >
              <img
                src={require("../assets/images/theme/share.png")}
                alt=""
                style={{
                  width: "22px",
                  height: "22px",
                  marginRight: "4px",
                }}
              />
              <span
                style={{
                  fontSize: "14px",
                }}
              >
                {t("shareWithYourFriends")}
              </span>
            </div>
            {reward.isClaimed ? (
              <div
                className={styles.puzzle_info}
                style={{
                  display: reward.id && reward.isClaimFormRequired ? "" : "none",
                }}
              >
                <img src={require("../assets/images/info.png")} alt="info" />
                <span>
                  {reward.id && reward.brandName?.includes("et9j")
                    ? t("shoppingBefore", {
                        date: "25/09/24",
                      })
                    : country === "BR"
                    ? t("claimBefore", {
                        expired: "05.01.24",
                      })
                    : ""}
                </span>
              </div>
            ) : (
              <div style={{ height: "16px" }}></div>
            )}
          </div>,
        );
        break;
      case "none":
        setRewardDom(
          <div
            style={{
              marginTop: 0,
            }}
          >
            <div
              style={{
                fontSize: "16px",
                fontWeight: "600",
                height: "22px",
                lineHeight: "27px",
              }}
            >
              {reward.title || t("sorry")}
            </div>
            <div
              style={{
                margin: "16px 0",
                fontSize: "14px",
                height: "22px",
                lineHeight: "22px",
              }}
            >
              {reward.subtitle || t("notWin")}
            </div>
            {reward.giftPicture ? (
              <img
                src={reward.giftPicture}
                alt=""
                style={{
                  width: "100%",
                  height: "auto",
                }}
              />
            ) : (
              <FailedShakeImage />
            )}
          </div>,
        );
        break;
      default:
        setRewardDom(
          <div
            style={{
              marginTop: 0,
            }}
          ></div>,
        );
        break;
    }
  }, [reward, rewardedPiece, notObtained, country, theme]);

  return <div>{rewardDom}</div>;
};
