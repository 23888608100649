const accountProviders: {
  [code: string]: {
    code: string;
    name: string;
    img: string;
  };
} = {
  OPay: {
    code: "OPay",
    name: "OPay",
    img: require("../assets/images/opay.png"),
  },
  "MTN MoMo": {
    code: "MTN Mo Mo",
    name: "MTN Money Mobile",
    img: require("../assets/images/mtn.png"),
  },
  MPesa: {
    code: "MPesa",
    name: "M-Pesa",
    img: require("../assets/images/pesa.png"),
  },
  PayPal: {
    code: "PayPal",
    name: "PayPal",
    img: require("../assets/images/paypal.png"),
  },
  Ozow: {
    code: "Ozow",
    name: "Ozow",
    img: require("../assets/images/ozow.png"),
  },
  PayU: {
    code: "PayU",
    name: "PayU",
    img: require("../assets/images/payu.png"),
  },
  Prepaid24: {
    code: "Prepaid24",
    name: "Prepaid24",
    img: require("../assets/images/pre.png"),
  },
};

export default accountProviders;
