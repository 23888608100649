import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "../../store";
import { SWButton } from "../../components/SWButton";
import { useTranslation } from "react-i18next";
import { useUtmParamNavigate } from "../../utils/utmParamNavigate";
import { CountryList } from "../../components/v2/CountryList";
import store from "store2";
import {
  // closeModal,
  logout,
  setAuthId,
  setAuthUser,
  setCountry,
  setLoggingIn,
  // setModalData,
  setUserInfo,
  setXhr,
  // showModal,
  updateChosenCountry,
} from "../../store/state";
import createXhr from "../../api";
import { availableCountries, getClientName, usableCountries } from "../../config";
import { errorHandler } from "../../api/errorHandler";
import { useDispatch } from "react-redux";
// import { SwitchToggle } from "../../components/v2/SwitchToggle";
// import { generateCodeChallengeFromVerifier } from "../../utils/PKCE";
import { useCookies } from "react-cookie";
import { setDot } from "../../utils/dot";
import { setGaDot } from "../../utils/gaDot";

type MetaData = { email: string; provider: string };

export const Profile: React.FC = () => {
  const shakes = useSelector((state) => state.data.shakes);
  const theme = useSelector((state) => state.data.theme);
  const xhr = useSelector((state) => state.data.xhr);
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.data.accessToken);
  const language = useSelector((state) => state.data.lang);
  const [chosenCountry, setChosenCountry] = useState("");
  const authUser = useSelector((state) => state.data.authUserInfo);
  const navigate = useUtmParamNavigate();
  const [_, __, removeCookie] = useCookies();
  const { t } = useTranslation();
  const mobileAuthXhr = useSelector((state) => state.data.mobileAuthXhr);
  // const [connecting, setConnecting] = useState(false);
  const loggedIn = useSelector((state) => state.data.loggedIn);
  const country = useSelector((state) => state.data.country);
  const authId = useSelector((state) => state.data.authId);
  const [userMetadata, setUserMetadata] = useState<MetaData[]>([]);
  // const [email, setEmail] = useState<string>("");

  useEffect(() => {
    if (chosenCountry) {
      selectCountry(chosenCountry);
    }
  }, [chosenCountry]);

  const selectCountry = useCallback(
    (selectedCountry: string) => {
      xhr
        ?.postUserCountry({ countryCode: selectedCountry })
        .then(() => {
          if ((window as Client).shakewin) {
            store("chosen_country", selectedCountry);
          } else {
            store("chosen_country", selectedCountry);
          }

          dispatch(
            setCountry({
              country: selectedCountry,
            }),
          );
          dispatch(updateChosenCountry());
          const xhr = createXhr({
            headers: {
              Authorization: "Bearer " + accessToken,
              "X-CountryCode": chosenCountry,
              "Accept-Language": language,
              "X-Browser": getClientName(),
            },
          });
          xhr
            .getUserInfo()
            .then((res: UserInfo) => {
              dispatch(setCountry({ country: res.countryCode || usableCountries[0] }));
              dispatch(
                setUserInfo({
                  country: res.countryCode || usableCountries[0],
                  verified: res.isPhoneVerified,
                  avatarUrl: res.avatarUrl,
                  canChangeCountryCode: res.canChangeCountryCode,
                }),
              );
              dispatch(
                setXhr({
                  xhr,
                }),
              );
            })
            .catch((reason: any) => {
              errorHandler(reason, dispatch);
            });
        })
        .catch((e: any) => {
          console.error(e);
          if (JSON.stringify(e).includes("alter")) {
            if ((window as any).showToast) {
              (window as any).showToast(
                t(
                  `Already selected {{country}} as the default country, reinstall the app if you selected the wrong country`,
                  { country: "another country" },
                ),
              );
              if ((window as Client).shakewin) {
                store("chosen_country", selectedCountry);
              } else {
                store("chosen_country", selectedCountry);
              }

              dispatch(
                setCountry({
                  country: selectedCountry,
                }),
              );
              dispatch(updateChosenCountry());
            }
          } else if (JSON.stringify(e).includes("No matching campaign for country")) {
            if ((window as any).showToast) {
              (window as any).showToast("No matching campaign for this country.");
              if ((window as Client).shakewin) {
                store("chosen_country", selectedCountry);
              } else {
                store("chosen_country", selectedCountry);
              }
            }
          }
        });
    },
    [xhr.headers.Authorization, language, authId],
  );

  useEffect(() => {
    setDot("pv", "pv_profile_page", "", "");
    setGaDot({ action: "pv_profile_page", value: {} });
  }, []);

  useEffect(() => {
    if (
      !loggedIn ||
      !mobileAuthXhr?.headers.Authorization ||
      mobileAuthXhr?.headers.Authorization?.length < 10
    )
      return;
    mobileAuthXhr
      .getUser()
      .then(
        (res: {
          email: string;
          emailVerified: boolean;
          phoneNumber: string;
          registeredOAuth2Providers: string[];
          id: string;
          userMetadata: { email: string; provider: string }[];
        }) => {
          dispatch(
            setAuthUser({
              authUserInfo: res,
            }),
          );
          dispatch(
            setAuthId({
              authId: res.id,
            }),
          );
          // setEmail(res.email);
          setUserMetadata(res.userMetadata || []);
          const googleAccount = res.userMetadata?.find((el) => el.provider === "google")?.email;
          store("googleAccount", googleAccount);
        },
      )
      .catch((err: { message: any }) => {
        (window as any).showToast(err?.message || "Failed on fetching user info");
      })
      .finally(() => {
        dispatch(setLoggingIn({ loggingIn: false }));
      });
  }, [mobileAuthXhr, mobileAuthXhr?.headers.Authorization, loggedIn]);

  useEffect(() => {
    if (!xhr?.headers.Authorization || xhr?.headers.Authorization?.length < 10) return;
    xhr
      .getUserInfo()
      .then((res: UserInfo) => {
        dispatch(
          setUserInfo({
            country: res.countryCode || availableCountries[0],
            avatarUrl: res.avatarUrl,
            verified: res.isPhoneVerified,
            canChangeCountryCode: res.canChangeCountryCode,
          }),
        );
      })
      .catch((err: { message: any }) => {
        (window as any).showToast(err?.message || "Failed on fetching user info");
      })
      .finally(() => {
        dispatch(setLoggingIn({ loggingIn: false }));
      });
  }, [xhr?.headers.Authorization]);

  // const providers = [
  //   {
  //     key: "google",
  //     logo: require("../../assets/images/theme/google.png"),
  //     name: "Google",
  //   },
  //   {
  //     key: "opera",
  //     logo: require("../../assets/images/theme/opera.png"),
  //     name: "Opera",
  //   },
  // ];

  // const toggleAccount = useCallback(
  //   (providerId: string) => {
  //     if (connecting) return;
  //     setConnecting(true);
  //     if (!(authUser.registeredOAuth2Providers as string[]).includes(providerId)) {
  //       generateCodeChallengeFromVerifier().then((res) => {
  //         const { codeChallenge, codeChallengeMethod, codeVerifier } = res;
  //         const API_BASE_URL = process.env.REACT_APP_PUBLIC_AUTH_URL;
  //         const state = String(Math.floor(Math.random() * 1000000));
  //         const url = new URL(API_BASE_URL + "/api/v2/auth/oauth/shakewin/" + providerId);
  //         setCookie("state", state, { maxAge: 900, path: "/" });
  //         setCookie("codeVerifier", codeVerifier, { maxAge: 900, path: "/" });
  //         setCookie("behaviour", "connect", { maxAge: 900, path: "/" });
  //         setCookie("providerId", providerId, { maxAge: 900, path: "/" });
  //         url.searchParams.append("state", state);
  //         url.searchParams.append("redirect_uri", window.location.origin + "/shakewin/callback/");
  //         url.searchParams.append("code_challenge", codeChallenge);
  //         url.searchParams.append("code_challenge_method", codeChallengeMethod);
  //         setDot("click", "account_connection", "", "");
  //         setGaDot({ action: "account_connection", value: { provider: providerId } });
  //         window.location.href = url.toString();
  //       });
  //     } else {
  //       if ((authUser.registeredOAuth2Providers as string[]).includes(providerId)) return;
  //       setDot("click", "account_disconnection", "", "");
  //       setGaDot({ action: "account_disconnection", value: { provider: providerId } });
  //       dispatch(
  //         setModalData({
  //           modalData: {
  //             title: "Remove Account?",
  //             text: "This account will no longer be associated with your profile.",
  //             background: theme.secondaryBackgroundColor,
  //             options: [
  //               {
  //                 text: "Remove",
  //                 background: theme.buttonColor,
  //                 color: theme.buttonFontColor,
  //                 cb: () => {
  //                   if (
  //                     !loggedIn ||
  //                     !mobileAuthXhr?.headers.Authorization ||
  //                     mobileAuthXhr?.headers.Authorization?.length < 10
  //                   )
  //                     return;
  //                   mobileAuthXhr
  //                     .postDisconnectAccount({ providerId })
  //                     .then(() => {
  //                       mobileAuthXhr
  //                         .getUser()
  //                         .then(
  //                           (res: {
  //                             email: string;
  //                             emailVerified: boolean;
  //                             phoneNumber: string;
  //                             registeredOAuth2Providers: string[];
  //                             id: string;
  //                           }) => {
  //                             dispatch(
  //                               setAuthUser({
  //                                 authUserInfo: res,
  //                               }),
  //                             );
  //                             dispatch(
  //                               setAuthId({
  //                                 authId: res.id,
  //                               }),
  //                             );
  //                           },
  //                         );
  //                     })
  //                     .catch((err: { message: any }) => {
  //                       (window as any).showToast(
  //                         err?.message || "Failed to disconnect the account",
  //                       );
  //                     })
  //                     .finally(() => {
  //                       setConnecting(false);
  //                       dispatch(setLoggingIn({ loggingIn: false }));
  //                       dispatch(closeModal());
  //                     });
  //                 },
  //               },
  //               {
  //                 text: "Cancel",
  //                 background: "rgba(255, 255, 255, 0.2)",
  //                 color: "#fff",
  //                 cb: () => {
  //                   setConnecting(false);
  //                   dispatch(closeModal());
  //                   return;
  //                 },
  //               },
  //             ],
  //           },
  //         }),
  //       );
  //       dispatch(showModal());
  //     }
  //     return;
  //   },
  //   [authUser.registeredOAuth2Providers, mobileAuthXhr, connecting],
  // );

  return !loggedIn ? (
    <div
      style={{
        paddingTop: "60px ",
        overflow: "auto",
      }}
    >
      <section
        style={{
          padding: "16px",
        }}
      >
        <h3
          style={{
            padding: "24px 0",
            fontSize: "16px",
            fontWeight: 700,
            textAlign: "center",
          }}
        >
          {"You've not logged in. Please log in or sign up."}
        </h3>
        <SWButton
          text={t("login")}
          background={theme.buttonColor}
          color={theme.buttonFontColor}
          callback={() => {
            removeCookie("auth_accessToken");
            removeCookie("auth_refreshToken");
            dispatch(logout());
            window.location.href = "/";
          }}
        />
      </section>
    </div>
  ) : (
    <div
      style={{
        paddingTop: "60px ",
        overflow: "auto",
      }}
    >
      <section
        style={{
          padding: "16px",
          display: theme.name === "giveaway" ? "none" : "",
        }}
      >
        {" "}
        {shakes <= 0 ? (
          <p
            style={{
              textAlign: "center",
              color: "rgba(255, 255, 255, 0.75)",
              fontSize: "16px",
              fontWeight: "700",
            }}
          >
            {t("ranOutShakes")}
          </p>
        ) : (
          <p
            style={{
              textAlign: "center",
              color: "#fff",
              fontSize: "16px",
              fontWeight: "700",
            }}
          >
            {t("youHaveAvailableShakes")}
          </p>
        )}
        <div
          style={{
            marginTop: "16px",
          }}
        >
          <SWButton
            text={shakes ? t("useNextShake") : t("earnMoreShakes")}
            background={theme.buttonColor}
            color={theme.buttonFontColor}
            callback={() => {
              shakes ? navigate("/shaking") : navigate("/missions");
            }}
          />
        </div>
      </section>
      <section
        style={{
          margin: "16px",
        }}
      >
        <h3
          style={{
            padding: "8px 0",
            fontSize: "14px",
            fontWeight: 400,
          }}
        >
          {t("countryOfResidence") || "Country of residence"}
        </h3>
        <CountryList
          callback={setChosenCountry}
          disabled={loggedIn}
          background={theme.menuColor}
          key={country}
        />
      </section>
      <section
        style={{
          margin: "16px",
        }}
      >
        <h3
          style={{
            padding: "8px 0",
            fontSize: "14px",
            fontWeight: 400,
          }}
        >
          {t("phoneNumber") || "Phone number"}
        </h3>
        <div
          style={{
            padding: "12px",
            background: theme.inputBackgroundColor,
            color: "rgba(255, 255, 255, 0.5)",
            borderRadius: "8px",
            lineHeight: "22px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span>{authUser.phoneNumber || ""}</span>
          <span style={{ height: "22px" }}></span>
          {/* <span
            onClick={() => {
              if (!authUser.phoneNumber) {
                navigate("/rules");
              }
            }}
            style={{
              color: "#fff",
              display: authUser.phoneNumber || production ? "none" : "",
            }}
          >
            Verify
          </span> */}
        </div>
      </section>
      {/* <section
        style={{
          margin: "16px",
          display: email ? "" : "none",
        }}
      >
        <h3
          style={{
            padding: "8px 0",
            fontSize: "14px",
            fontWeight: 400,
          }}
        >
          {"Email"}
        </h3>
        <div
          style={{
            padding: "12px",
            background: theme.inputBackgroundColor,
            color: "rgba(255, 255, 255, 0.5)",
            borderRadius: "8px",
            lineHeight: "22px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span>{email}</span>
        </div>
      </section> */}
      {userMetadata.map((data) => {
        return (
          <section
            key={data.provider}
            style={{
              margin: "16px",
              display: data.email ? "" : "none",
            }}
          >
            <h3
              style={{
                padding: "8px 0",
                fontSize: "14px",
                fontWeight: 400,
                textTransform: "capitalize",
              }}
            >
              {data.provider + " email"}
            </h3>
            <div
              style={{
                padding: "12px",
                background: theme.inputBackgroundColor,
                color: "rgba(255, 255, 255, 0.5)",
                borderRadius: "8px",
                lineHeight: "22px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span>{data.email}</span>
            </div>
          </section>
        );
      })}
      <section
        style={{
          margin: "16px",
          display: "none",
        }}
      >
        <h3
          style={{
            padding: "8px 0",
            fontSize: "14px",
            fontWeight: 400,
          }}
        >
          {t("Password") || "Password"}
        </h3>
        <div
          style={{
            padding: "12px",
            background: theme.inputBackgroundColor,
            color: theme.inputFontColor,
            borderRadius: "8px",
            lineHeight: "22px",
            height: "22px",
            overflow: "hidden",
          }}
          onClick={() => {
            navigate("/profile/password");
          }}
        >
          <span
            style={{
              lineHeight: "22px",
              height: "22px",
              display: "inline-block",
              verticalAlign: "middle",
              fontSize: "22px",
              position: "relative",
            }}
          >
            {"•••••••••"}
          </span>
        </div>
      </section>
      {/* <section
        style={{
          margin: "16px",
          display: (window as Client).shakewin?.requestSocialLogin ? "" : "none",
        }}
      >
        <h3
          style={{
            padding: "8px 0",
            fontSize: "16px",
            fontWeight: 600,
          }}
        >
          {t("connectAccounts") || "Connect Accounts"}
        </h3>

        <>
          {providers.map((item) => {
            return (
              <div
                style={{
                  padding: "12px",
                  background: theme.inputBackgroundColor,
                  color: theme.inputFontColor,
                  borderRadius: "8px",
                  lineHeight: "22px",
                  height: "46px",
                  overflow: "hidden",
                  width: "100%",
                  display: item.key === "opera" ? "none" : "flex",
                  alignItems: "center",
                  boxSizing: "border-box",
                  justifyContent: "space-between",
                  marginBottom: "16px",
                }}
                key={item.key}
              >
                <img
                  src={item.logo}
                  alt=""
                  style={{
                    width: "22px",
                    height: "22px",
                  }}
                />
                <span style={{ flex: 1, margin: "0 12px" }}>{item.name}</span>
                <SwitchToggle
                  on={(authUser.registeredOAuth2Providers as string[]).includes(item.key)}
                  switchCb={() => {
                    toggleAccount(item.key);
                  }}
                  disabled={
                    connecting ||
                    (authUser.registeredOAuth2Providers as string[]).includes(item.key)
                  }
                />
              </div>
            );
          })}
        </>
      </section> */}
      <section
        style={{
          margin: "16px",
        }}
      >
        <SWButton
          text={t("logOut")}
          callback={() => {
            removeCookie("auth_accessToken");
            removeCookie("auth_refreshToken");
            dispatch(logout());
            window.location.href = "/";
          }}
        />
      </section>
    </div>
  );
};
