import React, { useState } from "react";
import { useTranslation } from "react-i18next";

type FileUploaderProps = {
  fileCount?: number;
  type?: string;
  setValueCb: (param: any) => void;
  background?: string;
};

export const FileUploader: React.FC<FileUploaderProps> = ({
  fileCount,
  type,
  setValueCb,
  background,
}) => {
  const allowCount = fileCount || 1;
  const fileType = type || "";
  const { t } = useTranslation();
  const [uploadedFile, setFile] = useState<File | undefined>(undefined);

  const callbackFn = (event: any) => {
    if (uploadedFile) {
      return;
    }
    const file = event.target.files[0];
    setFile(file);
    setValueCb(file);
  };

  return (
    <div
      style={{
        boxSizing: "border-box",
        width: "100%",
        display: "flex",
        alignItems: "center",
        height: "46px",
        borderRadius: "4px",
        background: background || "#fff",
        padding: "12px",
      }}
    >
      <label
        htmlFor={"screenshot"}
        style={{
          width: "22px",
          height: "22px",
          color: "#000",
          marginRight: "8px",
        }}
      >
        {uploadedFile ? (
          <img
            src={require("../assets/images/shake_win_br2/uploaded_white.png")}
            alt={"uploaded"}
            style={{
              width: "22px",
              height: "22px",
              color: "#fff",
            }}
          />
        ) : (
          <img
            src={require("../assets/images/shake_win_br2/upload_white.png")}
            alt={"upload"}
            style={{
              width: "22px",
              height: "22px",
            }}
          />
        )}
      </label>
      <label
        htmlFor={"screenshot"}
        style={{
          flex: "1",
          height: "22px",
          lineHeight: "22px",
          color: uploadedFile?.name ? "#fff" : "rgba(255, 255, 255, 0.5)",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        {uploadedFile?.name || t("Upload a screenshot")}
      </label>
      <img
        src={require("../assets/images/shake_win_br2/remove.png")}
        alt="remove"
        style={{
          width: "22px",
          height: "22px",
          display: uploadedFile ? "" : "none",
        }}
        onClick={() => {
          setFile(undefined);
        }}
      />
      <input
        id="screenshot"
        type={"file"}
        style={{ display: "none" }}
        accept={fileType}
        multiple={!!allowCount}
        onChange={callbackFn}
      />
    </div>
  );
};
