import createXhr from "../api";
import store from "store2";
import { logout, setToken } from "../store/state";
import { setDot } from "./dot";
import { setGaDot } from "./gaDot";
import { Dispatch } from "redux";
import { useEffect } from "react";
import { useSelector } from "../store";
import { useDispatch } from "react-redux";

export const onGoogleLogin = (
  credential: string,
  dispatch: Dispatch,
  accessToken: string,
  afterLoginUrl: string,
) => {
  createXhr({})
    .postAuthLoginGoogleSignIn({ credential, projectId: "shakewin" })
    .then((res: Tokens) => {
      createXhr({
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .postMobileAuthLogin({ mobileAuthAccessToken: res.accessToken })
        .then((r: Tokens) => {
          if (r.accessToken && r.refreshToken) {
            store("access_token", r.accessToken);
            store("refresh_token", r.refreshToken);
            store("auth_accessToken", res.accessToken);
            store("auth_refreshToken", res.refreshToken);
            dispatch(
              setToken({
                accessToken: r.accessToken,
                refreshToken: r.refreshToken,
              }),
            );
            setDot("click", "third_party_login_success", "", "");
            setGaDot({ action: "third_party_login_success", value: {} });
            (window as Client).shakewin?.onPhoneVerification(r.accessToken, r.refreshToken);
            setTimeout(() => {
              window.location.href = afterLoginUrl;
            }, 200);
          }
        })
        .catch((err: { message: any }) => {
          (window as any).showToast(`/api/v2/user/mobile-auth/login \n ${err.message}`);
          setDot("click", "third_party_login_fail", "endpoint", "postMobileAuthLogin");
          setGaDot({
            action: "third_party_login_fail",
            value: { endpoint: "postMobileAuthLogin" },
          });
          // Allow user to select country
          store.remove("selected_country");
          dispatch(logout());
          setTimeout(() => {
            window.location.href = "/";
          }, 200);
        })
        .finally(() => {
          (window as any).loadingLogin?.close();
        });
    })
    .catch((err: { message: any }) => {
      (window as any).showToast(`/api/v2/auth/login/google-signin \n ${err.message}`);
      setDot("click", "third_party_login_fail", "endpoint", "postAuthLoginGoogleSignIn");
      setGaDot({
        action: "third_party_login_fail",
        value: { endpoint: "postAuthLoginGoogleSignIn" },
      });
      // Allow user to select country
      store.remove("selected_country");
      dispatch(logout());
      setTimeout(() => {
        window.location.href = "/";
      }, 200);
    })
    .finally(() => {
      (window as any).loadingLogin?.close();
    });
};

export function configSocialLogin() {
  const accessToken = useSelector((state) => state.data.accessToken);
  const initialized = useSelector((state) => state.data.initialized);
  const theme = useSelector((state) => state.data.theme);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!accessToken || !(window as Client).shakewinweb || !initialized) return;
    // @ts-ignore
    (window as Client).shakewinweb.onSocialLogin = (credential: string) => {
      if (!credential || credential === 'null') {
        setDot("click", "third_party_login_fail", "endpoint", "postMobileAuthLogin");
        setGaDot({
          action: "third_party_login_fail",
          value: { endpoint: "shakewinweb.onSocialLogin" },
        });
        (window as any)?.showToast(
          `Please try again. If the error still appear, please feedback to us with a screenshot. Sorry for the inconvenience!`,
        );
        (window as any).loadingLogin?.close();
        return;
      }
      createXhr({})
        .postAuthLoginGoogleSignIn({ credential, projectId: "shakewin" })
        .then((res: Tokens) => {
          store("auth_accessToken", res.accessToken);
          store("auth_refreshToken", res.refreshToken);
          createXhr({
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
            .postMobileAuthLogin({ mobileAuthAccessToken: res.accessToken })
            .then((r: Tokens) => {
              if (r.accessToken && r.refreshToken) {
                store("access_token", r.accessToken);
                store("refresh_token", r.refreshToken);
                setDot("click", "third_party_login_success", "", "");
                setGaDot({
                  action: "third_party_login_success",
                  value: {},
                });
                (window as Client).shakewin?.onPhoneVerification(r.accessToken, r.refreshToken);
                setTimeout(() => {
                  window.location.href = theme.afterLoginUrl;
                }, 50);
              }
            })
            .catch((err: { message: any }) => {
              (window as any).showToast(err.message);
              setDot("click", "third_party_login_fail", "endpoint", "postMobileAuthLogin");
              setGaDot({
                action: "third_party_login_fail",
                value: { endpoint: "postMobileAuthLogin" },
              });
              dispatch(logout());
              window.location.href = "/";
            });
        })
        .catch((err: any) => {
          console.error(err);
          setDot("click", "third_party_login_fail", "endpoint", "postMobileAuthLogin");
          setGaDot({
            action: "third_party_login_fail",
            value: { endpoint: "postAuthLoginGoogleSignIn" },
          });
          (window as any)?.showToast(
            `Error on postAuthLoginGoogleSignIn: ${err?.message}. idToken: ${credential}`,
          );
          (window as any).loadingLogin?.close();
        });
    };
  }, [accessToken, initialized]);
}
