import React, { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./styles/result.module.scss";
import { useSelector } from "../store";
import { useSearchParams } from "react-router-dom";
import { SWButton } from "../components/SWButton";
import { ApexReward } from "../components/Reward";
import { useUtmParamNavigate } from "../utils/utmParamNavigate";
import { useTranslation } from "react-i18next";
import { couponOptionsBR } from "../constants/couponOptions";

export const PrizeWinnableDetail: React.FC = () => {
  const pendingReward: Reward = {
    id: "",
    type: "pending",
    title: "",
    prizeTypeId: "",
    subtitle: "",
    brandLogo: "",
    height: 1,
    width: 1,
    wonPuzzlePieces: [],
    giftPicture: "",
    brandName: "",
    name: "",
  };
  const navigate = useUtmParamNavigate();
  const [params] = useSearchParams();
  const { t } = useTranslation();
  const shakes = useSelector((state) => state.data.shakes);
  const verified = useSelector((state) => state.data.verified);
  const wonPrizes: Reward[] = useSelector((state) => state.data.wonPrizes);
  const winnablePrizes: Reward[] = useSelector((state) => state.data.winnablePrizes);
  const puzzles: Reward[] = useSelector((state) => state.data.puzzles);
  const [prizeTypeId] = useState(params.get("prize_type_id") || "");
  const [rewardInfo, setRewardInfo] = useState<Reward>(pendingReward);
  const loggedIn = useSelector((state) => state.data.loggedIn);

  useEffect(() => {
    if (prizeTypeId) {
      const prize: Reward | undefined = winnablePrizes.find((el: Reward) => {
        return el.prizeTypeId === prizeTypeId;
      });
      const won: Reward | undefined = wonPrizes.find((el: Reward) => {
        return el.prizeTypeId === prizeTypeId && !el.isClaimed;
      });

      const tmpPrize: Reward = prize ? { ...prize } : pendingReward;
      if (won && prize) {
        tmpPrize.id = won?.id || won.prizeId || "";
        tmpPrize.isClaimed = won?.isClaimed;
        tmpPrize.isClaimFormRequired = won.isClaimFormRequired;
        tmpPrize.isVerifiedPhoneRequired = won.isVerifiedPhoneRequired;
      }
      setRewardInfo(tmpPrize);
    }
  }, [prizeTypeId, puzzles, winnablePrizes, wonPrizes]);

  useEffect(() => {
    setReward(<ApexReward reward={rewardInfo} notObtained={!obtained} />);
  }, [rewardInfo]);

  const [intro, setIntro] = useState<{
    logo: string;
    title: string;
    brandName: string;
    name: string;
    subtitle: string;
    description: string;
    styleName: string;
  }>({
    logo: "",
    title: "",
    subtitle: "",
    description: "",
    styleName: "",
    name: "",
    brandName: "",
  });
  const [buttons, setButtons] = useState(<div className={styles.button}></div>);
  const [reward, setReward] = useState(<ApexReward reward={rewardInfo} />);
  const [obtained, setObtained] = useState(
    !!wonPrizes.find((prize) => {
      return prize.prizeTypeId === prizeTypeId;
    }) ||
      !!puzzles.find((prize) => {
        return prize.prizeTypeId === prizeTypeId;
      }),
  );
  const [wonThis, setWonThis] = useState(false);
  const needToVerifyPhone = useMemo(
    () => !verified && rewardInfo.isVerifiedPhoneRequired,
    [verified, rewardInfo],
  );

  useEffect(() => {
    setWonThis(
      !!wonPrizes.find((prize) => {
        return prize.prizeTypeId === prizeTypeId && !prize.isClaimed;
      }),
    );
  }, [winnablePrizes, wonPrizes]);

  /*
   * To do quests to earn more shakes
   * */
  const getMoreShakes = useCallback(() => {
    navigate("/missions");
  }, []);

  /*
   * To consume the next shake
   * */
  const useNextShake = useCallback(() => {
    navigate(`/shaking`);
  }, []);

  /*
   * To claim the prize
   * */
  const claimPrize = useCallback(() => {
    if (!loggedIn) {
      navigate({
        pathname: "/login",
      });
      return;
    }
    if (rewardInfo.id && wonThis) {
      if (needToVerifyPhone) {
        navigate(`/login/verify?prize_id=${rewardInfo.id}&prize_type=${rewardInfo.type}`);
        return;
      }
      navigate(`/prize/claim?prize_id=${rewardInfo.id}&prize_type=${rewardInfo.type}`);
      // navigate(`/won?prize_id=${rewardInfo.id}`);
    }
  }, [needToVerifyPhone, rewardInfo, prizeTypeId, loggedIn]);

  useEffect(() => {
    setObtained(
      !!wonPrizes.find((prize) => {
        return prize.prizeTypeId === prizeTypeId;
      }) ||
        !!puzzles.find((prize) => {
          return prize.prizeTypeId === prizeTypeId;
        }),
    );
  }, [puzzles, wonPrizes, wonThis, rewardInfo]);

  // Update the introduction text
  useEffect(() => {
    const introObj = {
      title: "",
      subtitle: "",
      brandName: "",
      name: "",
      logo: "",
      description: "",
      styleName: styles.title_text,
    };
    if (rewardInfo.type === "puzzle") {
      introObj.title = wonThis ? rewardInfo.title : "";
      introObj.subtitle =
        (rewardInfo.progress?.total || 0) - (rewardInfo.progress?.current || 0) > 0
          ? t("findRemainingPieces", {
              count: (rewardInfo.progress?.total || 0) - (rewardInfo.progress?.current || 0),
            })
          : "";
      introObj.description =
        (rewardInfo.progress?.total || 0) - (rewardInfo.progress?.current || 0) > 0
          ? t("findRemainingPieces", {
              count: (rewardInfo.progress?.total || 0) - (rewardInfo.progress?.current || 0),
            })
          : "";
      introObj.logo = rewardInfo.brandLogo;
      introObj.brandName = rewardInfo.brandName;
      introObj.name = rewardInfo.name;
    } else if (rewardInfo.type === "immediate") {
      introObj.title = wonThis ? rewardInfo.title : `${rewardInfo.brandName} ${rewardInfo.name}`;
      introObj.subtitle = wonThis ? rewardInfo.subtitle || "" : "";
      introObj.description = rewardInfo.subtitle || "";
      introObj.logo = rewardInfo.brandLogo;
      introObj.styleName = styles.title_text_immediate;
      introObj.brandName = rewardInfo.brandName;
      introObj.name = rewardInfo.name;
    }
    setIntro(introObj);
  }, [rewardInfo, wonThis]);

  // Handle the button according to the user and prize status
  useEffect(() => {
    if (prizeTypeId) {
      if (rewardInfo.type === "none") {
        setButtons(
          <div className={styles.button}>
            <SWButton
              text={shakes === 0 ? t("earnMoreShakes") : t("useNextShake")}
              background={"rgba(255, 255, 255, 0.2)"}
              color={"#ffffff"}
              locked={false}
              callback={useNextShake}
            />
          </div>,
        );
        return;
      }
      if (rewardInfo.type === "puzzle") {
        setButtons(
          <div className={styles.button}>
            <div
              style={{ display: couponOptionsBR[rewardInfo.name] ? "" : "none", margin: "16px 0" }}
            >
              <SWButton
                text={t("seeRewardOptions")}
                locked={false}
                callback={() => {
                  navigate("/winnable/options?name=" + rewardInfo.name);
                }}
              />
            </div>
            {!loggedIn ? (
              <SWButton text={t("saveYourProgress")} locked={false} callback={claimPrize} />
            ) : needToVerifyPhone ? (
              <SWButton
                text={t("verifyToClaimIt")}
                background={wonThis ? "" : "rgba(255, 255, 255, 0.2)"}
                color={wonThis ? "" : "#ffffff"}
                disabled={!wonThis}
                locked={!wonThis}
                callback={claimPrize}
              />
            ) : (
              <SWButton
                text={t("claimYourWonPrize")}
                background={rewardInfo.id && wonThis ? "" : "rgba(255, 255, 255, 0.2)"}
                color={rewardInfo.id && wonThis ? "" : "#ffffff"}
                disabled={!(rewardInfo.id && wonThis)}
                locked={!(rewardInfo.id && wonThis)}
                callback={claimPrize}
              />
            )}
          </div>,
        );
      }

      if (rewardInfo.type === "immediate") {
        setButtons(
          <div className={styles.button} style={{ marginTop: "8px" }}>
            <div
              style={{ display: couponOptionsBR[rewardInfo.name] ? "" : "none", margin: "16px 0" }}
            >
              <SWButton
                text={t("seeRewardOptions")}
                locked={false}
                callback={() => {
                  navigate("/winnable/options?name=" + rewardInfo.name);
                }}
              />
            </div>
            <SWButton
              text={t("claimYourWonPrize")}
              background={!wonThis ? "" : "#FFB906"}
              color={!wonThis ? "" : "#000000"}
              disabled={!wonThis}
              locked={!wonThis}
              callback={claimPrize}
            />
          </div>,
        );
        return;
      }
      return;
    }
  }, [
    shakes,
    verified,
    rewardInfo,
    claimPrize,
    getMoreShakes,
    useNextShake,
    wonThis,
    puzzles,
    loggedIn,
  ]);

  return rewardInfo.type === "pending" ? (
    <div></div>
  ) : (
    <div>
      <div className={styles.main}>
        <div
          className={styles.title}
          style={{
            display: rewardInfo.type === "none" ? "none" : "",
          }}
        >
          <img className={styles.logo} src={rewardInfo.brandLogo} alt={rewardInfo.brandName} />
          <div className={styles.title_name}>{intro.brandName}</div>
        </div>
        <div
          className={styles.intro}
          style={{
            display: rewardInfo.type !== "none" ? "" : "none",
          }}
        >
          <div className={intro.styleName}>{intro.title}</div>
          <div className={styles.intro_guide}>{intro.subtitle}</div>
        </div>
        {reward}
        {buttons}
      </div>
    </div>
  );
};
