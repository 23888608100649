import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./styles/missions.module.scss";
import { SWButton } from "../components/SWButton";
import copy from "copy-to-clipboard";
import { useSelector } from "../store";
import { errorHandler } from "../api/errorHandler";
import { useDispatch } from "react-redux";
import { SWLogo } from "../components/SWLogo";
import { Trans, useTranslation } from "react-i18next";

export const MissionDetail: React.FC = () => {
  const { t } = useTranslation();
  const [allowSharing] = useState(!!window.navigator.share);
  const questDetails: { [key: string]: any } = {
    "mini-referral": {
      name: "Opera Mini",
      icon: require("../assets/images/opera_mini_logo.png"),
    },
    "ofa-referral": {
      name: "Opera",
      icon: require("../assets/images/opera_logo.png"),
    },
  };
  const { questId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [copied, setCopied] = useState<boolean>(false);
  const xhr = useSelector((state) => state.data.xhr);
  const [mission, setMission] = useState<Mission>({
    referralLink: t("fetching..."),
    numCompleted: 0,
    id: "",
    name: "",
  });

  useEffect(() => {
    if (!questId) {
      return;
    }
    xhr
      .getMissionsReferral({
        missionId: questId,
      })
      .then((res: Mission) => {
        setMission(res);
      })
      .catch((e: any) => {
        errorHandler(e, dispatch);
        setMission({
          referralLink: "failed",
          numCompleted: 0,
          id: "",
          name: "",
        });
      });
  }, []);

  return (
    <div className={styles.detail_main}>
      <SWLogo size={"MEDIUM"} />
      <div className={styles.detail_with}>{t("with")}</div>
      <img
        className={styles.detail_mini_logo}
        src={questDetails[questId || "ofa-referral"]?.icon || ""}
        alt=""
      />
      <Trans
        components={{
          1: <p className={styles.detail_description} />,
          2: <span className={styles.detail_description_white} />,
          3: <span className={styles.detail_description_color} />,
        }}
        values={{
          browser: questDetails[questId || "ofa-referral"]?.name || "",
        }}
      >
        referralMissionText
      </Trans>
      <div className={styles.detail_link_title}>{t("uniqueReferralLink")}</div>
      <div className={styles.detail_link_main}>
        <img
          src={require("../assets/images/source.png")}
          alt=""
          className={styles.detail_link_url}
        />
        <div className={styles.detail_link_text}>
          <span
            style={{
              background: copied ? "rgba(14,158,211,0.3)" : "",
              whiteSpace: "nowrap",
            }}
          >
            {mission.referralLink || ""}
          </span>
        </div>
        <div
          className={styles.detail_link_copy}
          style={{
            background: copied ? "#5262AE" : "#FFB906",
          }}
          onClick={async () => {
            if (allowSharing) {
              const shareData = {
                title: "Chacoalhe & Concorra",
                text: "Você pode jogar Chacoalhe & Concorra e ter a chance de ganhar prêmios!",
                url: mission.referralLink,
              };
              try {
                await window.navigator.share(shareData);
                // (window as any).showToast("Shake&Win shared successfully");
              } catch (err) {
                (window as any).showToast(`Error: ${err}`);
              }
              return;
            }
            copy(mission.referralLink);
            setCopied(true);
          }}
        >
          {allowSharing ? t("share") : copied ? t("copied") : t("copy")}
        </div>
      </div>
      <Trans
        components={{
          1: <div className={styles.detail_link_earn} />,
          2: (
            <span
              style={{
                color: mission.numCompleted > 0 ? "#FFB906" : "",
              }}
            />
          ),
        }}
        values={{
          count: mission.numCompleted || 0,
        }}
      >
        youEarnedShakes
      </Trans>
      <div className={styles.detail_link_btn}>
        <SWButton
          text={t("earnMoreShakes")}
          disabled={false}
          background={"#FFB906"}
          disabledBackground={""}
          color={"#000000"}
          disabledColor={""}
          size={"medium"}
          callback={() => {
            navigate("/missions");
          }}
        />
      </div>
    </div>
  );
};
