import React, { useEffect, useState } from "react";
import { useSelector } from "../store";

export const ShakingIcon: React.FC = () => {
  const theme = useSelector((state) => state.data.theme);
  const [deg, setDeg] = useState(0);
  const [face, setFace] = useState(require("../assets/images/shake_win_br2/open_eyes.png"));
  const [showShakingLine, setShowShakingLine] = useState(false);
  const [timer, setTimer] = useState(
    setInterval(() => {
      return;
    }, 1),
  );

  const shaking = () => {
    let i = 0;
    if (theme.name !== "giveaway") {
      const tmp = setInterval(() => {
        if (i === 5) {
          setFace(require("../assets/images/shake_win_br2/wink_partial.png"));
          setDeg(10);
        }
        if (i === 6) {
          setFace(require("../assets/images/shake_win_br2/wink_closed.png"));
          setDeg(15);
        }
        if (i === 16) {
          setFace(require("../assets/images/shake_win_br2/wink_partial.png"));
          setDeg(10);
        }
        if (i === 17) {
          setFace(require("../assets/images/shake_win_br2/open_eyes.png"));
          setDeg(0);
        }
        if (i === 22) {
          setDeg(-15);
        }
        if (i === 27) {
          setFace(require("../assets/images/shake_win_br2/close_eyes.png"));
          setShowShakingLine(true);
          setDeg(-15);
        }
        if (i === 32) {
          setDeg(30);
        }
        if (i === 37) {
          setDeg(-15);
        }
        if (i === 42) {
          setDeg(30);
        }
        if (i === 47) {
          setDeg(-15);
        }
        if (i === 52) {
          setFace(require("../assets/images/shake_win_br2/open_eyes.png"));
          setShowShakingLine(false);
          setDeg(-15);
        }

        if (i > 72) {
          setDeg(0);
          i = 0;
        }

        i++;
      }, 36);
      setTimer(tmp);
    } else {
      const tmp = setInterval(() => {
        if (i === 2) {
          setDeg(10);
        }
        if (i === 4) {
          setDeg(-10);
        }
        if (i === 6) {
          setDeg(0);
        }
        if (i === 8) {
          setDeg(10);
        }
        if (i === 10) {
          setDeg(0);
        }
        if (i === 12) {
          setDeg(-10);
          i = 0;
        }
        i++;
      }, 28);
      setTimer(tmp);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      shaking();
    }, 200);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <>
      <div
        style={{
          position: "relative",
          transform: `rotate(${deg}deg)`,
          transition: "transform 250ms ",
          width: "200px",
        }}
      >
        <img
          src={theme.shakingIcon}
          alt=""
          style={{
            width: theme.name === "giveaway" ? "142px" : "200px",
            height: theme.name === "giveaway" ? "124px" : "200px",
          }}
        />
        <img
          src={face}
          style={{
            display: theme.name === "giveaway" ? "none" : "",
            position: "absolute",
            width: "200px",
            height: "200px",
            left: 0,
          }}
          alt=""
        />
        <img
          src={require("../assets/images/shake_win_br2/shaking_line.png")}
          style={{
            position: "absolute",
            width: "200px",
            height: "200px",
            left: 0,
            display: showShakingLine && theme.name !== "giveaway" ? "" : "none",
          }}
          alt=""
        />
      </div>
    </>
  );
};
