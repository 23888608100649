import React, { useEffect, useState } from "react";
import styles from "./styles/loading.module.scss";
import { useSelector } from "../store";

interface LoadingProps {
  isError: boolean;
}

export const Loading: React.FC<LoadingProps> = (props) => {
  const [error, setError] = useState(props.isError);
  const errorMsg = useSelector((state) => state.data.error) || "";
  useEffect(() => {
    setError(props.isError);
  }, [props.isError]);
  return (
    <div className={styles.loading}>
      {error ? (
        <div className={styles.network_error}>
          <img
            className={styles.network_error_img}
            src={require("../assets/images/error.png")}
            alt=""
          />
          <div className={styles.network_error_text}>{errorMsg}</div>
          <div
            className={styles.network_error_button}
            onClick={() => {
              window.location.reload();
            }}
          >
            {"Try again >"}
          </div>
        </div>
      ) : (
        <div className={styles.progress_wrapper}>
          <div className={styles.progress_bar}></div>
        </div>
      )}
    </div>
  );
};
