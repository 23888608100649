import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface ClaimProps {
  steps: string[];
}

export const ClaimSteps: React.FC<ClaimProps> = (props) => {
  const [claimInfo] = useState({
    steps: props.steps,
  });
  const [stepDom, setStepDom] = useState(<div></div>);
  const { t } = useTranslation();
  useEffect(() => {
    setStepDom(
      <div>
        <div
          style={{
            textAlign: "center",
            padding: "16px",
          }}
        >
          <img
            style={{
              width: "22px",
              height: "22px",
            }}
            src={require("../assets/images/shake_win/double_down_green_arrow.png")}
            alt=""
          />
          <br />
          <div
            style={{
              fontWeight: 700,
              color: "#ffffff",
              fontSize: "18px",
              marginTop: "12px",
            }}
          >
            {t("How do I claim my prize?")}
          </div>
        </div>
        {claimInfo.steps.map((el, index) => (
          <div
            key={`step__${el}`}
            style={{
              background: "rgba(172, 120, 252, 0.2)",
              border: "1px solid #AC78FC",
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.08)",
              borderRadius: "16px",
              padding: "12px",
              marginBottom: "16px",
              textAlign: "center",
            }}
          >
            <div
              style={{
                color: "#FFB906",
                fontWeight: "600",
                fontSize: "12px",
              }}
            >
              {t("STEP", { x: index + 1 })}
            </div>
            <div
              style={{
                marginTop: "4px",
                color: "#ffffff",
                fontWeight: "700",
                wordBreak: "break-all",
              }}
            >
              {el}
            </div>
          </div>
        ))}
      </div>,
    );
  }, [claimInfo.steps]);

  return <div>{stepDom}</div>;
};
