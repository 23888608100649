import React, { useEffect, useState } from "react";
import { selectableLanguageItems } from "../config";
import { useSelector } from "../store";
import { useDispatch } from "react-redux";
import { setLanguage } from "../store/state";

interface LanguagesProp {
  languages: Languages[];
  chosen: Languages;
}

const LanguageListItem: React.FC<LanguagesProp> = (props: LanguagesProp) => {
  const { languages, chosen } = props;
  const theme = useSelector((state) => state.data.theme);
  const dispatch = useDispatch();
  return (
    <div>
      {languages.map((lang, index) => (
        <div
          style={{
            boxSizing: "border-box",
            height: "46px",
            zIndex: 99,
            padding: "12px 16px",
            background: theme.menuColor,
            borderRadius: "0 0 4px 4px",
            display: chosen === lang ? "none" : "",
          }}
          key={`${lang}_${index}`}
          onClick={() => {
            dispatch(
              setLanguage({
                lang,
              }),
            );
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={require("../assets/images/international.png")}
              alt={"lang"}
              style={{
                width: "22px",
                height: "22px",
                marginRight: "16px",
              }}
            />
            <span>{selectableLanguageItems.get(lang)?.name}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export const LanguageSelect: React.FC = () => {
  const [fold, setFold] = useState(true);
  const [waiting, setWaiting] = useState(false);
  const language: Languages = useSelector((state) => state.data.lang);
  const theme = useSelector((state) => state.data.theme);
  const country = useSelector((state) => state.data.country);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;
    if (waiting) {
      timer = setTimeout(() => {
        setWaiting(false);
      }, 2500);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [waiting]);
  return (
    <div
      onClick={() => {
        if (waiting) {
          return;
        }
        setFold((val) => !val);
        if (!fold) {
          setWaiting(true);
        }
      }}
      style={{
        background: theme.menuColor,
        borderRadius: "8px",
        margin: "0 16px",
        transition: "height 1s ease",
        display: country === "BR" ? "" : "none",
      }}
    >
      <div
        style={{
          boxSizing: "border-box",
          height: "46px",
          zIndex: 99,
          padding: "12px 16px",
          position: "relative",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "calc(100% - 40px)",
          }}
        >
          <img
            src={require("../assets/images/international.png")}
            alt={"lang"}
            style={{
              width: "22px",
              height: "22px",
              marginRight: "16px",
            }}
          />
          <span>{selectableLanguageItems.get(language)?.name}</span>
          <img
            src={require("../assets/images/unfold.png")}
            alt=""
            style={{
              display: !fold || waiting ? "none" : "",
              width: "22px",
              height: "22px",
              position: "absolute",
              top: "12px",
              right: "16px",
            }}
          />
        </div>
      </div>
      <div style={{ display: fold ? "none" : "" }}>
        <LanguageListItem
          languages={Array.from(selectableLanguageItems.keys())}
          chosen={language}
        />
      </div>
    </div>
  );
};
