import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styles from "./styles/user_header.module.scss";
import { FunctionItem } from "./FunctionItem";
import { useSelector } from "../store";
import { usableCountries } from "../config";
import { useUtmParamNavigate } from "../utils/utmParamNavigate";
import store from "store2";

export const UserHeader: React.FC<{ fullScreen: boolean }> = ({ fullScreen }) => {
  const location = useLocation();
  // const phase = useSelector((state) => state.data.phase);
  const shakes = useSelector((state) => state.data.shakes);
  // const gift = useSelector((state) => state.data.newGift);
  const country = useSelector((state) => state.data.country);
  const phase = useSelector((state) => state.data.phase);
  const theme = useSelector((state) => state.data.theme);
  const loggedIn = useSelector((state) => state.data.loggedIn);
  const hasChosenCountry = !!useSelector((state) => state.data.chosenCountry);
  const [freshNew, setFreshNew] = useState(
    ["/", ""].includes(location.pathname) &&
      !hasChosenCountry &&
      !loggedIn &&
      !store("selected_country"),
  );
  const navigate = useUtmParamNavigate();
  const client = window as Client;
  const goBack = useCallback(() => {
    if (
      [
        "/missions",
        "/missions/",
        "",
        "/",
        "/not-in-opera",
        "/not-in-opera/",
        "/shaking",
        "/shaking/",
        "/login",
        "/login/",
        "/prizes/",
        "/prizes",
      ].includes(location.pathname) ||
      !usableCountries.includes(country)
    ) {
      if (client.shakewin?.close) {
        client.shakewin.close();
        return;
      }
      if ((window as any).news_data_h5) {
        window.close();
        return;
      }
    }

    if (["/profile/password", "/profile/password/"].includes(location.pathname)) {
      navigate("/profile");
      return;
    }

    if (window.location.pathname === "/not-in-opera") {
      window.history.go(-3);
      return;
    }
    window.history.back();
  }, [location.pathname]);

  useEffect(() => {
    setFreshNew(
      ["/", ""].includes(location.pathname) &&
        !hasChosenCountry &&
        !loggedIn &&
        !store("selected_country"),
    );
  }, [location.pathname, hasChosenCountry, loggedIn, store("selected_country")]);

  return (
    <div>
      <div className={styles["nav"]}>
        {
          <div
            className={styles.go_back}
            onClick={goBack}
            style={{
              backgroundColor: theme.secondaryBackgroundColor,
              display: loggedIn && (window as any).news_data_h5 ? "none" : "",
            }}
          >
            <img
              style={{
                width: "22px",
                height: "22px",
              }}
              src={
                ([
                  "/missions",
                  "/missions/",
                  "",
                  "/",
                  "/not-in-opera",
                  "/not-in-opera/",
                  "/shaking",
                  "/shaking/",
                ].includes(location.pathname) ||
                  !usableCountries.includes(country)) &&
                // @ts-ignore
                client.shakewin?.close
                  ? require("../assets/images/shake_win_br2/close_white.png")
                  : require("../assets/images/back_arrow_white.png")
              }
              alt=""
            />
          </div>
        }
        <div
          className={styles["functions"]}
          style={{
            display:
              fullScreen || !usableCountries.includes(country) || phase === "NOT_START"
                ? "none"
                : "",
          }}
        >
          {phase != "IN_PROGRESS" || freshNew || theme.name === "giveaway" ? null : (
            <FunctionItem type={"shaking"} amount={shakes} />
          )}
          {(phase != "IN_PROGRESS" && country !== "BR") ||
          freshNew ||
          theme.name === "giveaway" ? null : (
            <FunctionItem type={"missions"} amount={0} />
          )}
          {(phase != "IN_PROGRESS" && country !== "BR") ||
          freshNew ||
          theme.name === "giveaway" ? null : (
            <FunctionItem type={"prizes"} amount={0} />
          )}
          {theme.name !== "giveaway" ? null : <FunctionItem type={"giveaway"} amount={shakes} />}
          {phase == "FINISHED" || freshNew ? null : <FunctionItem type={"aviator"} amount={0} />}
        </div>
      </div>
    </div>
  );
};
