import React from "react";

type InfoProps = {
  message: string;
  transparentBg?: boolean;
};
export const InformationNotification: React.FC<InfoProps> = (info: InfoProps) => {
  return (
    <div
      style={{
        margin: "8px 0",
        padding: "6px",
        border: "1px solid #EB3661",
        display: "flex",
        alignItems: "center",
        borderRadius: "8px",
        fontSize: "12px",
        background: info.transparentBg ? "" : "rgba(235,54,97,0.2)",
        color: info.transparentBg ? "rgba(255,255,255,0.75)" : "#ffffff",
      }}
    >
      <img
        src={require("../../assets/images/info.png")}
        alt="info"
        style={{
          width: "12px",
          height: "12px",
          margin: "0 4px",
        }}
      />
      <span>{info.message}</span>
    </div>
  );
};
