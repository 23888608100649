import React from "react";
import { useSelector } from "../store";
import { useTranslation } from "react-i18next";

interface WarningProps {
  text?: string;
  noBg?: boolean;
  margin?: string;
}

export const Warning: React.FC<WarningProps> = (props) => {
  const verified = useSelector((state) => state.data.verified);
  const { t } = useTranslation();
  return (
    <div
      style={{
        padding: "8px 12px",
        minHeight: "20px",
        background: props.noBg ? "" : "rgba(0, 0, 0, 0.4)",
        borderRadius: "8px",
        margin: props.margin || "8px auto",
        display: verified ? "none" : "flex",
        alignItems: "center",
        width: "max-content",
        maxWidth: "100%",
        boxSizing: "border-box",
      }}
    >
      <img
        src={require("../assets/images/warning.png")}
        alt=""
        style={{
          width: "22px",
          height: "22px",
          margin: "0",
        }}
      />
      <span
        style={{
          fontSize: "12px",
          lineHeight: "14px",
          fontWeight: "500",
          marginLeft: "4px",
          textAlign: "left",
        }}
      >
        {props.text || t("hintToLogin")}
      </span>
    </div>
  );
};
