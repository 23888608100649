import React from "react";
import styles from "./styles/missions.module.scss";
import { useSelector } from "../store";
import { useTranslation } from "react-i18next";
import { MissionCard } from "../components/v2/MissionCard";

/**
 * @description It is the page for user to earn shakes.
 * @author ziwenw@opera.com
 * @since 31/08/2022
 */
export const Missions: React.FC<{
  missionDetails: {
    [key: string]: MissionDetail;
  };
}> = ({ missionDetails }) => {
  const { t } = useTranslation();
  const missionIds = useSelector((state: any) => state.data.missions);
  const theme = useSelector((state: any) => state.data.theme);
  const country = useSelector((state: any) => state.data.country);

  return (<>
    {
      missionIds && missionIds.length ?
      <div className={styles["main_no_quest"]}>
        <div className={styles.quest_area} style={{ background: theme.secondaryBackgroundColor }}>
          <h2>{country === "BR" ? t("earn250Mb") : t("earnMoreShakes")}</h2>
          <section
            style={{
              background: theme.menuColor,
              borderColor: theme.brandBackgroundColor,
              borderRadius: "16px",
              padding: "4px 16px",
              border: `1px solid ${theme.borderColor}`,
              display: missionIds.length ? "" : "none",
            }}
          >
            {missionIds.map((el: any, index: number) => {
              const mission = missionDetails[el.id];
  
              return !mission ? (
                <div key={el.id}></div>
              ) : (
                <div
                  key={el.id}
                  style={{
                    borderBottom:
                      index < missionIds.length - 1 ? "4px rgba(255, 255, 255, 0.1) solid" : "",
                  }}
                >
                  <MissionCard
                    title={mission.title || ""}
                    description={mission.description || ""}
                    hint={mission.hint || ""}
                    showHint={mission.showHint}
                    showHintFunc={mission.showHintFunc}
                    button={mission.button}
                    needUpdate={el.needUpdate}
                    finishedCount={el.grantedShakes || 0}
                    allCount={el.availableShakes || 0}
                    status={el.status}
                  />
                </div>
              );
            })}
          </section>
        </div>
      </div> : null
    }
    </>
  );
};
