// import ReactGA from "react-ga";
import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getAnalytics, setUserProperties } from "firebase/analytics";
import { useDispatch } from "react-redux";
import { setFirebase } from "../store/state";
import { useEffect, useState } from "react";
import { useSelector } from "../store";
import store from "store2";
import jwtDecode from "jwt-decode";

export const createFirebaseClient: () => any = () => {
  const country = useSelector((state) => state.data.country);
  const loggedIn = useSelector((state) => state.data.loggedIn);
  const userId = useSelector((state) => state.data.userId);
  const phase = useSelector((state) => state.data.phase);
  const xhr = useSelector((state) => state.data.xhr);
  const dispatch = useDispatch();
  const production = process.env.REACT_APP_MODE === "production";
  const currentCampaign = useSelector((state) => state.data.campaign);
  const accessToken = useSelector((state) => state.data.accessToken);
  const verified = useSelector((state) => state.data.verified);
  const [analytics, setAnalytics] = useState<any>();
  const isShaking = useSelector((state) => state.data.isShaking);
  useEffect(() => {
    const firebaseConfig = production
      ? {
          apiKey: "AIzaSyBJqwEW4lIiGFm9v9X4W6VcBUqT7QBInM4",
          authDomain: "mobile-campaigns.firebaseapp.com",
          projectId: "mobile-campaigns",
          storageBucket: "mobile-campaigns.appspot.com",
          messagingSenderId: "524988944300",
          appId: "1:524988944300:web:22fa56404b05641cb8b409",
          measurementId: "G-FSE96J79YT",
        }
      : {
          apiKey: "AIzaSyBXmyMTpDaqYC0jTBl9o3lckcGMgyq41IU",
          authDomain: "mobile-campaigns-dev.firebaseapp.com",
          projectId: "mobile-campaigns-dev",
          storageBucket: "mobile-campaigns-dev.appspot.com",
          messagingSenderId: "925667946368",
          appId: "1:925667946368:web:7782bf8410a508afd21b65",
          measurementId: "G-8XM2PRE2VD",
        };
    const app = initializeApp(firebaseConfig);
    const tmp = getAnalytics(app);
    setAnalytics(tmp);
    if (production) {
      initializeAppCheck(app, {
        provider: new ReCaptchaV3Provider(process.env.REACT_APP_SITE_KEY || ""),
        isTokenAutoRefreshEnabled: true,
      });
    }

    dispatch(
      setFirebase({
        firebase: app,
      }),
    );
  }, []);

  useEffect(() => {
    const campaign = currentCampaign || store("campaign");
    if (!xhr.headers.Authorization) {
      return;
    }
    const decoded: { exp: number; sub: string } = jwtDecode(accessToken);
    store("user_id", decoded.sub);
    const uid = decoded.sub;
    if (analytics && phase === "IN_PROGRESS" && country && campaign && (userId || uid)) {
      // @ts-ignore
      let clientName = "web";

      // @ts-ignore
      if (typeof (window as any)?.shakewin?.getClientName === "function") {
        // @ts-ignore
        clientName = (window as any)?.shakewin?.getClientName();
        if ((clientName + "").toLowerCase().includes("mini")) {
          clientName = "mini-native";
        } else {
          clientName = "ofa-native";
        }
      }
      if ((window as any)?.news_data_h5) {
        clientName = "news";
      }
      let userStatus = verified ? "phone_verify" : loggedIn ? "logged_in" : "anno_user",
        shakeStatus = store("shakeStatus") || "";
      const productObj = {
        Auth_id: userId || uid,
        user_status: userStatus,
        shaking_status: shakeStatus,
        // @ts-ignore
        product: clientName,
        campaign,
        region: country,
      };
      setUserProperties(analytics, { ...productObj });
      xhr.getUserStats().then((res: { labels: (UserStats | ShakeStats)[] }) => {
        (res.labels || []).forEach((label) => {
          if (
            ["anon", "mobileAuth", "mobileAuthEmailVerified", "mobileAuthPhoneVerified"].includes(
              label,
            )
          ) {
            if (!userStatus.length) {
              userStatus += label;
            } else {
              userStatus += "_" + label;
            }
          }
          if (["noShakesMade", "onlyOneShakeMade", "multipleShakesMade"].includes(label)) {
            shakeStatus = label;
            store("shakeStatus", label);
          }
          const productObj = {
            Auth_id: userId || uid,
            user_status: userStatus,
            shaking_status: shakeStatus,
            // @ts-ignore
            product: clientName,
            campaign,
            region: country,
          };
          setUserProperties(analytics, { ...productObj });
        });
      });
    }
  }, [
    country,
    analytics,
    loggedIn,
    userId,
    phase,
    currentCampaign,
    accessToken,
    xhr,
    verified,
    isShaking,
  ]);
};
