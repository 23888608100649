import React, { useCallback, useState } from "react";
import { SWInputBar } from "../../components/SWInputBar";
import { getCountriesList } from "../../utils/countries";
import { useSelector } from "../../store";
import { availableCountries, clientName } from "../../config";
import { useTranslation } from "react-i18next";
import store from "store2";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import { generateCodeChallengeFromVerifier } from "../../utils/PKCE";
import { setDot } from "../../utils/dot";
import { setGaDot } from "../../utils/gaDot";
import { CountryList } from "../../components/v2/CountryList";
import { SWButton } from "../../components/SWButton";
import { Footer } from "../../components/Footer";
import { useUtmParamNavigate } from "../../utils/utmParamNavigate";
import GoogleLogin from "react-google-login-ng";
import { onGoogleLogin } from "../../utils/navtiveSocialLogin";

export const AuthFlow: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useUtmParamNavigate();
  const [_, setCookie] = useCookies([
    "email",
    "phone",
    "password",
    "codeVerifier",
    "code",
    "state",
    "providerId",
    "behaviour",
    "auth_accessToken",
    "auth_refreshToken",
  ]);
  const [phone, setPhone] = useState("");
  const country = availableCountries?.includes(useSelector((state) => state.data.country))
    ? useSelector((state) => state.data.country)
    : availableCountries[0];
  const mobileAuthXhr = useSelector((state) => state.data.mobileAuthXhr);
  const theme = useSelector((state) => state.data.theme);
  const accessToken = useSelector((state) => state.data.accessToken);
  const sponsorImageUrl = useSelector((state) => state.data.sponsorImageUrl);
  const countries = getCountriesList(availableCountries);
  const [currentCountry] = useState(countries[country]);
  const [isPhoneError] = useState(false);
  const [ErrorMessages] = useState({
    phone: "Invalid phone number",
    email: "Invalid email",
    password:
      "Invalid password. A valid password must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters.",
  });

  const googleLogin = () => {
    if ((window as any).shakewin?.requestSocialLogin) {
      store("access_token", accessToken);
      (window as any).shakewin.requestSocialLogin();
      return;
    }
    if ((window as any)?.news_data_h5?.login) {
      (window as any)?.news_data_h5?.login("", "shakewin");
      return;
    }
    // if (getClientName() === "mini") {
    //   if ((window as Client).shakewin?.getClientName()?.includes("beta")) {
    //     window.location.href = "/rules";
    //     return;
    //   }
    // }

    bindAccount("google", "login");
  };

  const login = useCallback(() => {
    if ((window as any).shakewin?.requestSocialLogin) {
      (window as any).loadingLogin?.open();
      googleLogin();
    } else {
      navigate("/login/onetime");
    }
  }, [mobileAuthXhr, phone, currentCountry?.phone]);

  const bindAccount = useCallback(
    (providerId: string, behaviour: string) => {
      generateCodeChallengeFromVerifier().then((res) => {
        const { codeChallenge, codeChallengeMethod, codeVerifier } = res;
        const API_BASE_URL = process.env.REACT_APP_PUBLIC_AUTH_URL;
        const state = String(Math.floor(Math.random() * 1000000));
        const url = new URL(API_BASE_URL + "/api/v2/auth/oauth/shakewin/" + providerId);
        setCookie("state", state, { maxAge: 900, path: "/" });
        setCookie("codeVerifier", codeVerifier, { maxAge: 900, path: "/" });
        setCookie("behaviour", behaviour, { maxAge: 900, path: "/" });
        setCookie("providerId", providerId, { maxAge: 900, path: "/" });
        url.searchParams.append("state", state);
        url.searchParams.append("redirect_uri", window.location.origin + "/shakewin/callback/");
        url.searchParams.append("code_challenge", codeChallenge);
        url.searchParams.append("code_challenge_method", codeChallengeMethod);
        setDot("click", "account_connection", "", "");
        setGaDot({ action: "account_connection", value: { provider: providerId } });
        window.location.href = url.toString();
      });
    },
    [mobileAuthXhr],
  );

  return (
    <div
      style={{
        padding: "16px",
      }}
    >
      <img
        src={sponsorImageUrl || require("../../assets/images/opera_logo.png")}
        alt={"opera"}
        style={{
          width: "auto",
          height: "32px",
          margin: "0 auto",
          display: "block",
        }}
      />
      <h2
        style={{
          padding: "16px 0",
          fontSize: "16px",
          fontWeight: 700,
          textAlign: "center",
        }}
      >
        {((window as any).google && clientName === "ofa") ||
        (window as any).shakewin?.requestSocialLogin
          ? t("loginViaGoogle")
          : t("loginViaEmail")}
      </h2>
      <section style={{ display: clientName === "ofa" && window.google ? "none" : "" }}>
        <CountryList disabled={true} background={theme.inputBackgroundColor} />
        <div
          style={{
            margin: "16px 0",
          }}
        >
          <div
            style={{
              display: "none",
            }}
          >
            <SWInputBar
              type={"phone"}
              passDataFunction={setPhone}
              key={"phone"}
              onError={isPhoneError}
              errorMessage={ErrorMessages["phone"]}
              inputTitle={`+${currentCountry?.phone}`}
              placeholder={t("phoneNumber") || "Phone number"}
              background={theme.inputBackgroundColor}
              color={theme.inputFontColor}
              placeholderColor={theme.placeholder}
              keyword={"phone number"}
              autofocus={true}
            />
          </div>
        </div>
        {process.env.NODE_ENV !== "development" && (
          <SWButton
            text={t("login")}
            background={theme.buttonColor}
            color={theme.buttonFontColor}
            callback={login}
          />
        )}
      </section>
      {(window.google && clientName === "ofa") || process.env.NODE_ENV === "development" ? (
        <GoogleLogin
          client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID || ""}
          successCallback={({ credential }) => {
            (window as any).loadingLogin?.open();
            onGoogleLogin(
              credential,
              dispatch,
              accessToken,
              theme.name === "giveaway" ? "/giveaway" : "/prizes",
            );
          }}
        />
      ) : null}
      <Footer />
    </div>
  );
};
