import React, { useEffect, useState } from "react";
import "./App.css";
import store from "store2";
import { AppDispatch, useSelector } from "./store";
import { Route, Routes, useLocation, useSearchParams } from "react-router-dom";
import { UserHeader } from "./components/UserHeader";
import { Footer } from "./components/Footer";
import RouteTable from "./routes";
import { Loading } from "./components/Loading";
import { createFirebaseClient } from "./utils/FirebaseConfig";
import {
  closeModal,
  getMissionStats,
  initialize,
  logout,
  setAuthId,
  setAuthUser,
  setAuthXhr,
  setLoggingIn,
  setModalData,
  setPhase,
  setTheme,
  setUserInfo,
  setUtmSearch,
  setXhr,
  showModal,
} from "./store/state";
import { useDispatch } from "react-redux";
import { fullScreenPath, listPath, noFooterPath, noHeaderPath } from "./constants/paths";
import { getClientName, getPhase, production, usableCountries } from "./config";
import { Init } from "./utils/Init";
import { ClientInit } from "./utils/ClientInit";
import createXhr from "./api";
import { useTranslation } from "react-i18next";
import ModalGlobal from "components/Modal/Global";
import { DefaultBrowserWatcher } from "./components/DefaultBrowserWatcher";
// import { generateCodeChallengeFromVerifier } from "./utils/PKCE";
// import { setDot } from "./utils/dot";
// import { setGaDot } from "./utils/gaDot";
// import { useCookies } from "react-cookie";
import { DataUpdate } from "./model";
import newsConfig from "./utils/newsConfig";
import { configSocialLogin } from "./utils/navtiveSocialLogin";
import { useUtmParamNavigate } from "./utils/utmParamNavigate";

function App() {
  const { t } = useTranslation();
  // const [_, setCookie] = useCookies();
  const { i18n } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const [search] = useSearchParams({});
  const error = useSelector((state) => state.data.error) || "";
  const [fullScreen, setFullScreen] = useState<boolean>(fullScreenPath.includes(location.pathname));
  const [noFooter, setNoFooter] = useState<boolean>(noFooterPath.includes(location.pathname));
  const [noHeader, setNoHeader] = useState<boolean>(noHeaderPath.includes(location.pathname));
  const initialized = useSelector((state) => state.data.initialized);
  const phase = useSelector((state) => state.data.phase);
  let initPhase: Phase = "PENDING";
  const navigate = useUtmParamNavigate();
  const isShaking = useSelector((state) => state.data.isShaking);
  const verified = useSelector((state) => state.data.verified);
  const authAccessToken = useSelector((state) => state.data.authAccessToken);
  const country = useSelector((state) => state.data.country);
  const accessToken = useSelector((state) => state.data.accessToken);
  const language = useSelector((state) => state.data.lang);
  const theme = useSelector((state) => state.data.theme);
  const mobileAuthXhr = useSelector((state) => state.data.mobileAuthXhr);
  const showFullModal = useSelector((state) => state.data.showFullscreenModal);
  const modalData = useSelector((state) => state.data.modalData);
  const loggedIn = useSelector((state) => state.data.loggedIn);
  const xhr = useSelector((state) => state.data.xhr);
  const wonPrizes = useSelector((state) => state.data.wonPrizes);
  const authId = useSelector((state) => state.data.authId);
  const campaign = useSelector((state) => state.data.campaign);
  const [routes, setRoutes] = useState<Route[]>([
    { path: "*", component: <Loading isError={false} /> },
  ]);
  const notInOpera = !(window as Client)?.shakewin && !(window as any)?.news_data_h5;

  const loadingScreen = document.getElementById("fresh-loading");
  if (loadingScreen) {
    document.body.removeChild(loadingScreen);
  }

  if ((window as any).news_data_h5) {
    Init(store("access_token") || "");
    createFirebaseClient();
    DataUpdate();
  } else if (notInOpera) {
    Init(store("access_token") || "");
    createFirebaseClient();
    DataUpdate();
    if (production && !store("debug")) {
      initPhase = "NOT_OPR";
    }
  } else {
    ClientInit();
    createFirebaseClient();
    DataUpdate();
  }

  newsConfig();
  configSocialLogin();

  useEffect(() => {
    if (initPhase === "NOT_OPR") {
      dispatch(
        setPhase({
          phase: initPhase,
        }),
      );
      dispatch(initialize());
    }
  }, [initPhase]);

  useEffect(() => {
    const keys = search.keys();
    const utmQueries: { [key: string]: string } = {};
    while (true) {
      const key = keys.next();
      if (typeof key.value === "string") {
        if (key.value.includes("utm_")) {
          utmQueries[key.value] = search.get(key.value) || "";
        }
      }
      if (key.done) {
        break;
      }
    }
    dispatch(
      setUtmSearch({
        utm: utmQueries,
      }),
    );
  }, []);

  useEffect(() => {
    if (!loggedIn) return;
    if (
      xhr.headers.Authorization &&
      initialized &&
      ["/won", "/result", "/winnable", "/profile", "/prizes", "/missions"].includes(
        location.pathname,
      )
    ) {
      xhr.getUserInfo().then((res: UserInfo) => {
        dispatch(
          setUserInfo({
            country: res.countryCode || usableCountries[0],
            verified: res.isPhoneVerified,
            avatarUrl: res.avatarUrl,
            canChangeCountryCode: res.canChangeCountryCode,
          }),
        );
        // if (!res.isPhoneVerified && wonPrizes.length) {
        //   dispatch(
        //     setModalData({
        //       modalData: {
        //         title: t("verifyYourDetails") || "",
        //         background: theme.secondaryBackgroundColor,
        //         text: t("completeVerificationToClaim") || "",
        //         options: [
        //           {
        //             text: t("verifyToClaim") || "Verify to Claim",
        //             background: theme.buttonColor,
        //             color: theme.buttonFontColor,
        //             cb: () => {
        //               window.location.href = "/login/verify";
        //             },
        //           },
        //         ],
        //       },
        //     }),
        //   );
        //   dispatch(showModal());
        // }
      });
    }
  }, [location.pathname, xhr.headers.Authorization, wonPrizes, authId, country, loggedIn]);

  useEffect(() => {
    if (loggedIn || !initialized || phase !== "CLAIM_ONLY") return;
    if (["/login", "/login/verify", "/login/onetime"].includes(location.pathname)) return;
    dispatch(
      setModalData({
        modalData: {
          title: t("pleaseLogIn") || "",
          background: theme.secondaryBackgroundColor,
          text:
            t("Log in to claim your rewards or earn free data") ||
            "Log in to claim your rewards or earn free data",
          options: [
            {
              text: "Log in",
              background: theme.buttonColor,
              color: theme.buttonFontColor,
              cb: () => {
                dispatch(closeModal());
                navigate("/login");
              },
            },
            {
              text: t("close"),
              background: theme.brandBackgroundColor,
              color: "#fff",
              cb: () => {
                dispatch(closeModal());
              },
            },
          ],
        },
      }),
    );
    dispatch(showModal());
  }, [phase, loggedIn, initialized, location.pathname]);

  useEffect(() => {
    if (xhr.headers?.Authorization) dispatch(getMissionStats());
  }, [xhr.headers?.Authorization]);

  useEffect(() => {
    if (mobileAuthXhr.headers.Authorization && mobileAuthXhr.headers.Authorization.length > 10) {
      mobileAuthXhr
        .getUser()
        .then(
          (res: {
            email: string;
            emailVerified: boolean;
            phoneNumber: string;
            registeredOAuth2Providers: string[];
            id: string;
            userMetadata: { provider: string; email: string }[];
          }) => {
            dispatch(
              setAuthUser({
                authUserInfo: res,
              }),
            );
            dispatch(
              setAuthId({
                authId: res.id,
              }),
            );
            const googleAccount = res.userMetadata?.find((el) => el.provider === "google")?.email;
            store("googleAccount", googleAccount);
            if (location.pathname?.includes("/shakewin/callback")) return;
          },
        )
        .catch((err: { message: string }) => {
          if (err.message?.includes("match the user country")) {
            (window as any)?.showToast(err.message);
          }
          console.error(err);
        })
        .finally(() => {
          dispatch(setLoggingIn({ loggingIn: false }));
        });
    }
  }, [mobileAuthXhr, verified, location.pathname]);

  useEffect(() => {
    if (initPhase === "NOT_OPR") return;
    const phase = getPhase(country);
    dispatch(
      setPhase({
        phase,
      }),
    );
  }, [country]);

  useEffect(() => {
    if (!authAccessToken) return;
    const newAuthXhr = createXhr({
      headers: {
        Authorization: "Bearer " + authAccessToken,
        "X-Browser": getClientName(),
        "Accept-Language": language,
        "X-CountryCode": country,
      },
    });
    dispatch(
      setAuthXhr({
        mobileAuthXhr: newAuthXhr,
      }),
    );
  }, [authAccessToken, language, country, initialized]);

  useEffect(() => {
    if ((window as Client).shakewin_report?.reportInteraction && loggedIn) {
      if (verified) {
        (window as Client).shakewin_report?.reportInteraction("user_logged_in_visit");
      } else {
        (window as Client).shakewin_report?.reportInteraction("user_logged_in_visit_social_only");
      }
    }
  }, [verified, loggedIn]);

  useEffect(() => {
    if (campaign === "africa-q4-2023-ng") {
      dispatch(logout());
      setTimeout(() => {
        window.location.href = "/";
      }, 100);
      return;
    }
  }, [country, campaign, phase]);

  useEffect(() => {
    i18n.changeLanguage(language).then(() => {
      store("lang", language);
    });
  }, [language]);

  useEffect(() => {
    dispatch(setTheme({ country: country }));
  }, [country]);

  useEffect(() => {
    setFullScreen(
      fullScreenPath.includes(location.pathname) || location.pathname.includes("/missions/"),
    );
    setNoFooter(noFooterPath.includes(location.pathname));
    setNoHeader(noHeaderPath.includes(location.pathname));
  }, [location.pathname]);

  useEffect(() => {
    if (accessToken && country) {
      dispatch(
        setXhr({
          xhr: createXhr({
            headers: {
              Authorization: "Bearer " + accessToken,
              "X-CountryCode": country,
              "Accept-Language": i18n.language,
            },
          }),
        }),
      );
    }
  }, [country, accessToken, i18n.language]);

  useEffect(() => {
    if (initPhase === "NOT_OPR") {
      return setRoutes(RouteTable.get(phase) || []);
    }
    if (initialized) {
      if (!usableCountries.includes(country)) {
        return setRoutes(RouteTable.get("NOT_IN_COUNTRY") || []);
      }
      return setRoutes(RouteTable.get(phase) || []);
    }
  }, [phase, country, initialized, i18n.language]);

  return !initialized ? (
    <Loading isError={!!error}></Loading>
  ) : (
    <div className="App">
      <div
        className="mask"
        style={{
          minHeight: "100%",
          opacity: listPath.includes(location.pathname) ? "1" : "",
          background: theme ? theme.backgroundColor : "",
        }}
      ></div>
      <div className="header">
        <UserHeader fullScreen={noHeader} />
      </div>
      <main
        className="main_content"
        style={{
          minHeight: "calc(100vh - 300px)",
        }}
      >
        <Routes>
          {routes.map((route) => (
            <Route path={route.path} element={route.component} key={`${phase}_${route.path}`} />
          ))}
        </Routes>
        <div
          style={{
            display: noFooter || isShaking ? "none" : "block",
            position: fullScreen ? "relative" : "absolute",
            bottom: "0",
            zIndex: 3,
            width: "100vw",
          }}
        >
          <Footer />
        </div>
      </main>
      {showFullModal && <ModalGlobal {...modalData} />}
      {initialized && <DefaultBrowserWatcher />}
    </div>
  );
}

export default App;
