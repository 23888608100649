import React from "react";

export const QaPtBr: React.FC = () => {
  const client = window as Client;
  const goToMail = () => {
    if (client.OperaFootballJsApi?.sendEmail) {
      client.OperaFootballJsApi.sendEmail(
        "sw-help@opera.com",
        "Requests and feedbacks for Opera S&W",
      );
      return;
    }
    window.location.href = "mailto:sw-help@opera.com";
  };
  return (
    <div
      style={{
        margin: "0 16px 0 16px",
        padding: "70px 0",
      }}
    >
      <h2
        style={{
          color: "#FFB906",
          fontWeight: 900,
          fontSize: "18px",
        }}
      >
        Perguntas Frequentes
      </h2>
      <section style={{ padding: "16px 0" }}>
        <h2
          style={{
            color: "#ffffff",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "18px",
          }}
        >
          Eu indiquei meus amigos, mas ainda não recebi o bônus de internet grátis.
        </h2>
        <p
          style={{
            color: "rgba(255,255,255,0.75)",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            marginTop: "16px",
          }}
        >
          Você receberá seu bônus quando eles jogarem Chacoalhe e Concorra e verificarem o número de
          telefone. Ao ter seu bônus desbloqueado, não se esqueça de seguir os passos para
          resgatá-lo. Ao finalizar os passos, você receberá um SMS e e-mail de confirmação no seu
          celular. Cada operadora possui limitações específicas, incluindo restrições à acumulação
          com outras promoções, tipos de plano e um limite mensal para bônus. O bônus não é válido
          para determinados planos ou serviços. Consulte as regras detalhadas de cada operadora no
          anexo do regulamento.
        </p>
      </section>
      <section style={{ padding: "16px 0" }}>
        <h2
          style={{
            color: "#ffffff",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "18px",
          }}
        >
          Não sou cliente Claro, Vivo ou Tim, posso ganhar o bônus?
        </h2>
        <p
          style={{
            color: "rgba(255,255,255,0.75)",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            marginTop: "16px",
          }}
        >
          Não, apenas clientes dessas operadoras podem ganhar o bônus.
        </p>
      </section>
      <section style={{ padding: "16px 0" }}>
        <h2
          style={{
            color: "#ffffff",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "18px",
          }}
        >
          Como posso ganhar meu bônus de internet?
        </h2>
        <p
          style={{
            color: "rgba(255,255,255,0.75)",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            marginTop: "16px",
          }}
        >
          Você deverá indicar dois amigos que baixem o Opera, verifiquem seus telefones e participem
          do Chacoalhe e Concorra. Ao completar a missão, siga os passos para resgatar seu bônus.
          <br />
          No caso dos serviços prestados pelas operadoras de telefonia Móvel, as regras gerais de
          fruição e uso dos referidos serviços incluem que o Bônus Celular é uma recompensa para os
          usuários das principais operadoras de qualquer DDD (Vivo, TIM e Claro), desde que o
          número, não esteja bloqueado, desativado ou cancelado, e seja de pessoa física. Assim que
          o prêmio for entregue, o participante receberá automaticamente um SMS (mensagem) de
          confirmação no seu celular. Cada operadora possui Regras e Condições de Uso dos Prêmios,
          que se aplicam a cada tipo de plano. Em caso de dúvidas, consulte o Anexo do regulamento e
          procure por sua operadora e plano.
        </p>
      </section>
      <section style={{ padding: "16px 0" }}>
        <h2
          style={{
            color: "#ffffff",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "18px",
          }}
        >
          Existem limitações ou restrições para o resgate do bônus de internet?
        </h2>
        <p
          style={{
            color: "rgba(255,255,255,0.75)",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            marginTop: "16px",
          }}
        >
          Cada operadora possui limitações específicas, incluindo restrições à acumulação com outras
          promoções, tipos de plano e um limite mensal para bônus. O bônus não é válido para
          determinados planos ou serviços. Consulte as regras detalhadas de cada operadora no anexo
          do regulamento.
        </p>
      </section>
      <section style={{ padding: "16px 0" }}>
        <h2
          style={{
            color: "#ffffff",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "18px",
          }}
        >
          Ganhei um prêmio e escolhi minha recompensa, como posso utilizá-la?
        </h2>
        <p
          style={{
            color: "rgba(255,255,255,0.75)",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            marginTop: "16px",
          }}
        >
          As Regras e Condições de Uso dos Prêmios estão disponíveis no anexo do regulamento, por
          favor consulte o documento no caso de dúvidas sobre como resgatar sua recompensa.
        </p>
      </section>
      <section style={{ padding: "16px 0" }}>
        <h2
          style={{
            color: "#ffffff",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "18px",
          }}
        >
          Eu tenho uma conta verificada, mas perdi meu progresso!
        </h2>
        <p
          style={{
            color: "rgba(255,255,255,0.75)",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            marginTop: "16px",
          }}
        >
          É possível que sua conta tenha sido desconectada. Não se preocupe, seu progresso ainda
          está lá, basta usar o botão de login na página inicial para verificar sua conta novamente
          e ver seus prêmios antigos. Como alternativa, você pode seguir estas etapas:
        </p>
        <ul
          style={{
            color: "rgba(255,255,255,0.75)",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            marginTop: "16px",
            marginLeft: "16px",
          }}
        >
          <li>
            Usando um navegador Opera em um dispositivo Android, acesse shakewin.opera.com/verify
          </li>
          <li>Defina seu país</li>
          <li>Toque no botão Chacoalhar</li>
          <li>Toque no botão salvar progresso depois de ver sua recompensa.</li>
          <li>Conclua o processo de verificação usando o mesmo número de telefone</li>
          <li>Verifique seus prêmios disponíveis e “ganhos”!</li>
        </ul>
      </section>
      <section style={{ padding: "16px 0" }}>
        <h2
          style={{
            color: "#ffffff",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "18px",
          }}
        >
          {`O que significa "em andamento"?`}
        </h2>
        <p
          style={{
            color: "rgba(255,255,255,0.75)",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            marginTop: "16px",
          }}
        >
          Isso significa que você ainda não coletou todas as peças necessárias para resgatar um
          prêmio específico. Você pode ver o número de peças restantes para resgatar esse prêmio
          tocando no nome do prêmio na seção Prêmios Disponíveis.
        </p>
      </section>
      <section style={{ padding: "16px 0" }}>
        <h2
          style={{
            color: "#ffffff",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "18px",
          }}
        >
          O que significa “peças duplicadas contam para recompensas adicionais”?
        </h2>
        <p
          style={{
            color: "rgba(255,255,255,0.75)",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            marginTop: "16px",
          }}
        >
          Isso significa que você pode ganhar mais de uma unidade desse prêmio se conseguir coletar
          mais de um conjunto completo das peças necessárias. Quanto mais vezes você coletar todas
          as peças, mais unidades poderá reivindicar.
        </p>
      </section>
      <section style={{ padding: "16px 0" }}>
        <h2
          style={{
            color: "#ffffff",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "18px",
          }}
        >
          Indiquei meus amigos, mas não usei meu link de indicação, ainda receberei a recompensa?
        </h2>
        <p
          style={{
            color: "rgba(255,255,255,0.75)",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            marginTop: "16px",
          }}
        >
          Não. A única maneira de obter a recompensa da missão de indicação é usar seu link de
          indicação exclusivo. Para obter seu link exclusivo, basta visitar a página da missão de
          indicação e clicar em copiar.
        </p>
      </section>
      <section style={{ padding: "16px 0" }}>
        <h2
          style={{
            color: "#ffffff",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "18px",
          }}
        >
          Por que meu número de telefone é inválido? Por que não recebi meu código?
        </h2>
        <p
          style={{
            color: "rgba(255,255,255,0.75)",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            marginTop: "16px",
          }}
        >
          Isso geralmente acontece se houver outra conta Chacoalhe & Concorra associada ao seu
          número. Por favor, verifique se você digitou o número de telefone correto e se seu celular
          está ativo na operadora, assim como o sinal da mesma. Isso também pode acontecer quando
          muitas pessoas estão tentando acessar o Chacoalhe & Concorra ao mesmo tempo. Se o problema
          persistir, envie-nos um e-mail para{" "}
          <span
            style={{
              textDecoration: "underline",
              color: "#ffffff",
              fontWeight: "700",
            }}
            onClick={goToMail}
          >
            sw-help@opera.com
          </span>{" "}
          com o número de telefone afetado para uma investigação mais aprofundada e possível
          resolução.
        </p>
      </section>
      <section style={{ padding: "16px 0" }}>
        <h2
          style={{
            color: "#ffffff",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "18px",
          }}
        >
          Por que não consigo encontrar meus prêmios?
        </h2>
        <p
          style={{
            color: "rgba(255,255,255,0.75)",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            marginTop: "16px",
          }}
        >
          Você perderá seu progresso e prêmios salvos se desinstalar seu navegador Opera antes de
          verificar sua conta Chacoalhe & Concorra.
        </p>
      </section>
    </div>
  );
};
