import React from "react";
import style from "./styles/placeholder.module.scss";
import { useTranslation } from "react-i18next";

type SWTextAreaProps = {
  setValue: (val: string) => void;
  background?: string;
  color?: string;
  theme?: "light" | "dark";
  necessary?: true;
};

export const SWTextArea: React.FC<SWTextAreaProps> = ({ setValue, background, color, theme }) => {
  const { t } = useTranslation();
  return (
    <textarea
      className={theme === "dark" ? style.dark : ""}
      style={{
        boxSizing: "border-box",
        border: "none",
        borderRadius: "4px",
        display: "block",
        width: "100%",
        minHeight: "106px",
        maxHeight: "206px",
        outline: "none",
        padding: "12px",
        fontSize: "16px",
        lineHeight: "22px",
        resize: "vertical",
        background: background || "#fff",
        color: color || "#000",
      }}
      placeholder={
        t("Describe the issue you are experiencing") || "Describe the issue you are experiencing"
      }
      onInput={(e) => {
        setValue(e.currentTarget.value);
      }}
      maxLength={999999}
    />
  );
};
