import { useEffect, useState } from "react";
import store from "store2";
import createXhr from "../api";
import { setToken } from "../store/state";
import { setDot } from "./dot";
import { setGaDot } from "./gaDot";
import { useSelector } from "../store";
import { useDispatch } from "react-redux";
import { useUtmParamNavigate } from "./utmParamNavigate";

export default function newsConfig() {
  const accessToken = useSelector((state) => state.data.accessToken);
  const initialized = useSelector((state) => state.data.initialized);
  const theme = useSelector((state) => state.data.theme);
  const dispatch = useDispatch();
  const navigate = useUtmParamNavigate();
  const [newsToken, setNewsToken] = useState<string>();
  useEffect(() => {
    if (!initialized) return;
    (window as any).__social_evt__ = {};
    (window as any).__social_evt__.updateSocialAccountInfo = async (result: any) => {
      const token = result.token;
      setNewsToken(token);
      store("newsToken", token);
    };
    (window as any).__social_evt__.updateSocialUserInfo = () => {
      return;
    };
  }, [initialized]);

  useEffect(() => {
    if (!newsToken || !accessToken || !initialized) return;
    const xhr = createXhr({});
    xhr
      .postAuthLoginOperaNews({ operaNewsAccessToken: newsToken })
      .then((res: Tokens) => {
        createXhr({
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .postMobileAuthLogin({ mobileAuthAccessToken: res.accessToken })
          .then((r: Tokens) => {
            if (r.accessToken && r.refreshToken) {
              store("access_token", r.accessToken);
              store("refresh_token", r.refreshToken);
              store("auth_accessToken", res.accessToken);
              store("auth_refreshToken", res.refreshToken);
              dispatch(
                setToken({
                  accessToken: r.accessToken,
                  refreshToken: r.refreshToken,
                }),
              );
              setDot("click", "third_party_login_success", "", "");
              setGaDot({ action: "third_party_login_success", value: {} });
              if ((window as Client).shakewin) {
                (window as Client).shakewin?.onPhoneVerification(r.accessToken, r.refreshToken);
              }

              setTimeout(() => {
                window.location.href = theme.afterLoginUrl;
              }, 200);
            }
          })
          .catch((err: any) => {
            (window as any)?.showToast(err.error || err.message || err);
            (window as any).loadingLogin?.close();
          });
      })
      .catch((err: any) => {
        if (JSON.stringify(err)?.includes("neither Google account")) {
          setNewsToken("");
          navigate("/login/onetime");
        } else if (JSON.stringify(err)?.includes("invalid chars")) {
          setNewsToken("");
          (window as any)?.showToast(
            "Opera News authentication with Google account has been updated for this campaign, please relogin in Opera News Client.",
          );
        } else {
          (window as any)?.showToast(err.error || err.message);
        }
        (window as any).loadingLogin?.close();
      });
  }, [newsToken, accessToken, initialized]);
  return;
}
