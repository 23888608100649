import React, { useCallback, useEffect, useState } from "react";
import styles from "./styles/home.module.scss";
import { ApexDisplaySlides } from "../components/DisplaySlides";
import { useSelector } from "../store";
import { SWButton } from "../components/SWButton";
import store from "store2";
import { SelectList } from "../components/SelectList";
import { getCountriesList } from "../utils/countries";
import { availableCountries, clientName, getClientName, usableCountries } from "../config";
import { useUtmParamNavigate } from "../utils/utmParamNavigate";
import { useDispatch } from "react-redux";
import { setCountry, setUserInfo, setXhr, updateChosenCountry } from "../store/state";
import { setDot } from "../utils/dot";
import { setGaDot } from "../utils/gaDot";
import { Footer } from "../components/Footer";
import { SWLogo } from "../components/SWLogo";
import { useTranslation } from "react-i18next";
import { StartCupCountDown } from "../components/StartCountDown";
import { ParticipantsCount } from "../components/ParticipantsCount";
import createXhr from "../api";
import { errorHandler } from "../api/errorHandler";

export const Home: React.FC = () => {
  const images = useSelector((state) => state.data.prizeImages);
  const sponsorImageUrl = useSelector((state) => state.data.sponsorImageUrl);
  const verified = useSelector((state) => state.data.verified);
  const ipCountry = useSelector((state) => state.data.country);
  const xhr = useSelector((state) => state.data.xhr);
  const phase = useSelector((state) => state.data.phase);
  const accessToken = useSelector((state) => state.data.accessToken);
  const hasChosenCountry = !useSelector((state) => state.data.canChangeCountryCode);
  const language = useSelector((state) => state.data.lang);
  const theme = useSelector((state) => state.data.theme);
  const shakes = useSelector((state) => state.data.shakes);
  const loggedIn = useSelector((state) => state.data.loggedIn);
  const navigate = useUtmParamNavigate();
  const missionStats = useSelector((state) => state.data.stats);
  const country = useSelector((state) => state.data.country);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [chosenCountry, setChosenCountry] = useState(
    availableCountries.includes(store("chosen_country"))
      ? store("chosen_country")
      : availableCountries.includes(ipCountry)
      ? ipCountry
      : availableCountries[0],
  );
  const countries = getCountriesList(availableCountries);
  const [disableBtn, setDisableBtn] = useState(false);
  const [shakeWinLogo, setShakewinLogo] = useState(<div></div>);
  const [chooseCountry, setChooseCountry] = useState(<div></div>);

  const [buttons, setButtons] = useState(<div></div>);
  const enter = useCallback(() => {
    setDot("click", "enter_shake_and_win", "", "");
    setDot("click", "enter_shake_and_win", "button_name", "enter");
    setGaDot({
      action: "enter_shake_and_win",
      value: {
        button_name: "enter",
      },
    });
    if (shakes === 0 && theme.name !== "giveaway") {
      navigate("/missions");
      return;
    }
    if (chosenCountry === "BR" && clientName === "ofa") {
      navigate("/welcome");
      return;
    }
    navigate(theme.shakingUrl);
  }, [shakes, chosenCountry, theme]);
  const login = () => {
    setDot("click", "enter_shake_and_win", "", "");
    setDot("click", "enter_shake_and_win", "button_name", "Continue with your account");
    setGaDot({
      action: "enter_shake_and_win",
      value: {
        button_name: "Continue with your account",
      },
    });
    navigate("/login");
  };

  const updateCountry = useCallback(
    (chosenCountry: string, shaking = true) => {
      if (xhr) {
        setDot("click", "enter_shake_and_win", "", "");
        setDot("click", "enter_shake_and_win", "button_name", "continue");
        setGaDot({
          action: "enter_shake_and_win",
          value: {
            button_name: "continue",
          },
        });
        xhr
          ?.postUserCountry({ countryCode: chosenCountry })
          .then(() => {
            if ((window as Client).shakewin) {
              store("chosen_country", chosenCountry);
            } else {
              store("chosen_country", chosenCountry);
            }

            dispatch(
              setCountry({
                country: chosenCountry,
              }),
            );

            store("selected_country", chosenCountry);
            dispatch(updateChosenCountry());
            const xhr = createXhr({
              headers: {
                Authorization: "Bearer " + accessToken,
                "X-CountryCode": chosenCountry,
                "Accept-Language": language,
                "X-Browser": getClientName(),
              },
            });
            setDisableBtn(false);
            xhr
              .getUserInfo()
              .then((res: UserInfo) => {
                dispatch(setCountry({ country: res.countryCode || usableCountries[0] }));
                dispatch(
                  setUserInfo({
                    country: res.countryCode || usableCountries[0],
                    verified: res.isPhoneVerified,
                    avatarUrl: res.avatarUrl,
                    canChangeCountryCode: res.canChangeCountryCode,
                  }),
                );
                dispatch(
                  setXhr({
                    xhr,
                  }),
                );
                if (chosenCountry === "BR") {
                  navigate("/welcome");
                  return;
                }
                setTimeout(() => {
                  if (shaking) navigate(theme.shakingUrl);
                });
              })
              .catch((reason: any) => {
                errorHandler(reason, dispatch);
              });
          })
          .catch((e: any) => {
            console.error(e);
            if (JSON.stringify(e).includes("alter")) {
              if ((window as any).showToast) {
                (window as any).showToast(
                  t(
                    `Already selected {{country}} as the default country, reinstall the app if you selected the wrong country`,
                    { country: ipCountry },
                  ),
                );
                if ((window as Client).shakewin) {
                  store("chosen_country", chosenCountry);
                } else {
                  store("chosen_country", chosenCountry);
                }

                dispatch(
                  setCountry({
                    country: chosenCountry,
                  }),
                );
                dispatch(updateChosenCountry());
                if (chosenCountry === "BR") {
                  navigate("/welcome");
                  return;
                }
                navigate(theme.shakingUrl);
              }
            } else if (JSON.stringify(e).includes("No matching campaign for country")) {
              if ((window as any).showToast) {
                (window as any).showToast("No matching campaign for this country.");
                if ((window as Client).shakewin) {
                  store("chosen_country", chosenCountry);
                } else {
                  store("chosen_country", chosenCountry);
                }
              }
            }
          });
      }
    },
    [xhr, theme],
  );

  useEffect(() => {
    hasChosenCountry || availableCountries.includes(store("selected_country"))
      ? setChooseCountry(<div></div>)
      : setChooseCountry(
          <div
            style={{
              margin: "16px",
              display: hasChosenCountry || phase !== "IN_PROGRESS" ? "none" : "",
            }}
          >
            <SelectList
              title={t("selectYourCountry") || "Select your country of residence"}
              currentItem={countries[chosenCountry]}
              items={countries}
              type={"countries"}
              selectCb={(data) => {
                setChosenCountry(data);
              }}
              background={theme.menuColor}
              color={"#fff"}
              placeholderColor={"rgba(255,255,255,0.5)"}
              theme={"dark"}
            />
          </div>,
        );
  }, [hasChosenCountry, theme]);

  useEffect(() => {
    if (location.pathname === "/") {
      let user_status = "anno_user";

      if (verified) {
        user_status = "phone_verify";
      }

      setDot("pv", "pv_start_page", "user_status", user_status);
      setDot("pv", "pv_start_page", "", "");
      setGaDot({
        action: "pv_start_page",
        value: { user_status: user_status },
      });
    }
  }, []);

  useEffect(() => {
    // show welcome page to the winning country in afcon 2024 users
    const resultTs = 1707778800;
    if (missionStats.length <= 1) return;
    let topCountry = "";
    const topCampaign = missionStats
      .filter((el: { campaign: string; count: number }) => {
        const key = el.campaign?.split("-")?.pop()?.toLowerCase();
        return key && key !== "campaign";
      })
      .map((el: { campaign: string; count: number }) => {
        const country = el.campaign?.split("-")?.pop()?.toLowerCase();
        return { country: country?.toUpperCase(), count: el.count, code: country || "" };
      })
      .sort((e1, e2) => {
        return e2.count - e1.count;
      });
    topCountry = topCampaign[0].country || "";

    if (
      !store("welcomed") &&
      store("selected_country") &&
      store("campaign")?.includes("afcon-q1-2024") &&
      country === topCountry &&
      Date.now() / 1000 > resultTs
    ) {
      navigate("/welcome");
    }
  }, [missionStats, country, store("welcomed"), store("selected_country")]);

  useEffect(() => {
    setShakewinLogo(
      <div className={styles.logo}>
        <SWLogo size={"BIG"} />
        {theme.name !== "giveaway" ? (
          <div className={styles.logo_text}>
            {chosenCountry === "BR" ? t("OverNPrizesToWinBR") : t("OverNPrizesToWin")}
          </div>
        ) : (
          <div style={{ textAlign: "center", marginTop: "8px" }}>
            <p style={{ fontWeight: 900 }}>MORE THAN</p>
            <img
              style={{
                fontWeight: 900,
                height: "32px",
                margin: "4px",
                color: "#FFC700",
              }}
              alt={"₦8,000,000"}
              src={require("../assets/images/giveaway/N8000000.png")}
            />
            <p style={{ fontWeight: 900 }}>IN PRIZES TO BE WON!</p>
          </div>
        )}
      </div>,
    );
    if (phase === "NOT_START") {
      setButtons(<StartCupCountDown />);
      return;
    } else {
      setButtons(
        <div className={styles.buttons}>
          <SWButton
            text={t("continue")}
            background={"#FFB906"}
            color={"#000000"}
            callback={() => updateCountry(chosenCountry)}
          />
        </div>,
      );
    }
    if (hasChosenCountry || availableCountries.includes(store("selected_country"))) {
      setButtons(
        <div className={styles.buttons}>
          <SWButton
            text={theme.name === "giveaway" ? t("openAGift") : t("enter")}
            background={theme.buttonColor}
            color={theme.buttonFontColor}
            callback={enter}
            disabled={disableBtn}
            disabledBackground={"rgba(255,255,255,0.5)"}
            disabledColor={"#ffffff"}
          />
          {!loggedIn ? (
            <div
              style={{
                margin: "12px 0",
              }}
            >
              <SWButton
                text={t("continueWithAccount")}
                background={"#3c3f3f"}
                color={"#fff"}
                callback={login}
                disabled={disableBtn}
                disabledBackground={"rgba(255,255,255,0.5)"}
                disabledColor={"#ffffff"}
              />
            </div>
          ) : (
            <div></div>
          )}
        </div>,
      );
    } else {
      setButtons(
        <div className={styles.buttons}>
          <SWButton
            text={t("continue")}
            background={theme.buttonColor}
            color={theme.buttonFontColor}
            callback={() => updateCountry(chosenCountry)}
          />
        </div>,
      );
    }
  }, [chosenCountry, i18n.language, phase, shakes, theme]);

  const [displayPrizes, setDisplayPrizes] = useState(<div className={styles.prizes}></div>);

  useEffect(() => {
    setDisplayPrizes(
      <div className={styles.prizes}>
        <ApexDisplaySlides name={"shakewin"} img={images} />
      </div>,
    );
  }, [images]);

  return (
    <div
      className={styles.home}
      style={{
        backgroundImage: theme.backgroundImage,
      }}
    >
      <img
        src={sponsorImageUrl || require("../assets/images/opera_logo.png")}
        alt=""
        style={{
          display: "block",
          margin: "0 auto",
          height: "30px",
          width: "auto",
        }}
      />
      {shakeWinLogo}
      {displayPrizes}
      {chooseCountry}
      {buttons}
      {phase === "IN_PROGRESS" ? (
        <div
          style={{
            margin: "0 16px",
          }}
        >
          <ParticipantsCount />
        </div>
      ) : null}

      <Footer />
    </div>
  );
};
