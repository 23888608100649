import React, { useCallback, useEffect, useState } from "react";
import { SWInputBar } from "../../components/SWInputBar";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../store";
import { SWButton } from "../../components/SWButton";
import { Footer } from "../../components/Footer";
import { useDispatch } from "react-redux";
import { setAuthXhr, setToken, setXhr, updateAuth } from "../../store/state";
import createXhr from "../../api";
import store from "store2";
import { useUtmParamNavigate } from "../../utils/utmParamNavigate";
import { setGaDot } from "../../utils/gaDot";
import { setDot } from "../../utils/dot";

export const OneTimePassword: React.FC = () => {
  const { t } = useTranslation();
  const theme = useSelector((state) => state.data.theme);
  const xhr = useSelector((state) => state.data.xhr);
  const country = useSelector((state) => state.data.country);
  const language = useSelector((state) => state.data.lang);
  const [inputtingComponent, setInputtingComponent] = useState(<div></div>);
  const [email, setEmail] = useState("");
  const [sentOtp, setSentOtp] = useState(false);
  const [sendingOtp, setSendingOtp] = useState(false);
  const [code, setCode] = useState("");
  const [verifying, setVerifying] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [codeError, setCodeError] = useState("");
  const dispatch = useDispatch();
  const navigate = useUtmParamNavigate();
  const [timeLeft, setTimeLeft] = useState(0);
  const emailReg = /^[a-zA-Z0-9._%+-]+@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;

  useEffect(() => {
    if (emailReg.test(email)) {
      setEmailError("");
      return;
    }
    setEmailError(
      "Please input a valid email address. Example of correct email address: xxxxx@xxxx.xxx",
    );
  }, [email]);

  useEffect(() => {
    setInterval(() => {
      setTimeLeft(
        Math.round(Math.max(0, ((store("next_send_time") || 0) - new Date().getTime()) / 1000)),
      );
    }, 1000);
  }, []);

  useEffect(() => {
    if (!sentOtp) {
      setInputtingComponent(
        <div>
          <SWInputBar
            type={"email"}
            placeholder={t("emailAddress")}
            background={theme.inputBackgroundColor}
            color={theme.inputFontColor}
            placeholderColor={theme.placeholder}
            passDataFunction={setEmail}
            onError={!!emailError}
            errorMessage={emailError}
          />
        </div>,
      );
    } else {
      setInputtingComponent(
        <div>
          <SWInputBar
            type={"text"}
            placeholder={t("")}
            background={theme.inputBackgroundColor}
            color={theme.inputFontColor}
            placeholderColor={theme.placeholder}
            passDataFunction={setCode}
            defaultVal={""}
            key={"token"}
            onError={!!codeError}
            errorMessage={codeError}
          />
          <div style={{ fontSize: "12px", padding: "8px 0" }}>
            <span
              onClick={() => {
                if (store("next_send_time") - new Date().getTime() <= 0) {
                  sendToken();
                }
              }}
              style={{
                color: store("next_send_time") - new Date().getTime() <= 0 ? "#ffffff" : "#dddddd",
              }}
            >
              {t("resendSMS") || "Resend SMS"}
            </span>{" "}
            <span
              style={{
                display: timeLeft <= 0 ? "none" : "",
              }}
            >
              {" "}
              ({timeLeft}s){" "}
            </span>
          </div>
        </div>,
      );
    }
  }, [sentOtp, code, timeLeft, email, emailError]);

  useEffect(() => {
    if (code.length === 6) {
      verifyCode(code);
    }
  }, [code]);

  const sendToken = useCallback(() => {
    if (!emailReg.test(email) || sendingOtp) return;
    setSendingOtp(true);

    xhr
      .postAuthLoginEmailOtpRequest({ email, projectId: "shakewin" })
      .then(() => {
        store("next_send_time", new Date().getTime() + 60000);
        setSentOtp(true);
        setEmailError("");
      })
      .catch((err: unknown) => {
        console.error(err);
        setEmailError("Error on sending OTP, please try later.");
      })
      .finally(() => {
        setSendingOtp(false);
      });
  }, [xhr, email]);

  const verifyCode = useCallback(
    (code: string) => {
      setVerifying(true);
      xhr
        .postAuthLoginEmailOtp({ email, token: code })
        .then((res: { accessToken: string; refreshToken: string }) => {
          const { accessToken, refreshToken } = res;
          const mobileAuthXhr = createXhr({
            headers: {
              Authorization: "Bearer " + accessToken,
              "X-CountryCode": country,
              "Accept-Language": language,
            },
          });
          dispatch(
            updateAuth({
              authAccessToken: accessToken,
              authRefreshToken: refreshToken,
            }),
          );

          dispatch(
            setAuthXhr({
              mobileAuthXhr,
            }),
          );
          xhr
            .postMobileAuthLogin({ mobileAuthAccessToken: accessToken })
            .then((r: { accessToken: any; refreshToken: any }) => {
              const { accessToken, refreshToken } = r;
              if (accessToken && refreshToken) {
                dispatch(
                  setToken({
                    accessToken,
                    refreshToken,
                  }),
                );
              }
              const newXhr = createXhr({
                headers: {
                  Authorization: "Bearer " + accessToken,
                  "X-CountryCode": country,
                  "Accept-Language": language,
                },
              });
              dispatch(setXhr({ xhr: newXhr }));
              // dispatch(setVerified({ verified: true }));
              if ((window as any)?.shakewin) {
                (window as any)?.shakewin?.onPhoneVerification(accessToken, refreshToken);
              }
              store("access_token", accessToken);
              store("refresh_token", refreshToken);
              setDot("click", "email_login_success", "", "");
              setGaDot({
                action: "email_login_success",
              });

              navigate(theme.afterLoginUrl);
            })
            .catch((err: any) => {
              setVerifying(false);
              setCodeError(
                err?.message?.includes("match the user country")
                  ? "Country does not match account."
                  : "Failed to verify the code.",
              );
              console.error(err);
              setDot("click", "email_login_fail", "email", email);
              setGaDot({
                action: "email_login_fail",
                value: { email },
              });
            });
        })
        .catch((err: any) => {
          setVerifying(false);
          setCodeError(
            err?.message?.includes("match the user country")
              ? "Country does not match account."
              : "Failed to verify the code.",
          );
          console.error(err);
          setDot("click", "email_login_fail", "email", email);
          setGaDot({
            action: "email_login_fail",
            value: { email },
          });
          (window as any)?.showToast(err?.message || "Failed to verify the code.");
        });
    },
    [email, country, language, xhr, theme],
  );

  return (
    <div>
      <main
        style={{
          padding: "0 16px",
          paddingTop: "16px",
          overflow: "auto",
        }}
      >
        <div
          style={{
            textAlign: "center",
            margin: "8px",
          }}
        >
          <img
            src={require("../../assets/images/opera_logo.png")}
            alt=""
            style={{
              height: "30px",
              width: "auto",
            }}
          />
        </div>
        <h2
          style={{
            textAlign: "center",
            margin: "24px 8px 8px 24px",
            fontSize: "16px",
            lineHeight: "20px",
            fontWeight: 700,
          }}
        >
          {t("enterYourEmail")}
        </h2>
        <p
          style={{
            textAlign: "center",
            fontWeight: 400,
            fontSize: "14px",
          }}
        >
          {verifying ? "Verifying..." : t("We'll send a one-time password to your email ")}
        </p>
        <section
          style={{
            margin: "24px 0",
          }}
        >
          {inputtingComponent}
        </section>
        <section
          style={{
            margin: "24px 0",
            display: sentOtp ? "none" : "",
          }}
        >
          <SWButton
            text={t("continue")}
            background={theme.buttonColor}
            color={theme.buttonFontColor}
            callback={() => {
              sendToken();
              return;
            }}
            disabledColor="grey"
            disabled={!emailReg.test(email)}
          />
        </section>
      </main>
      <Footer />
    </div>
  );
};
