import React, { useEffect, useState } from "react";
import styles from "./styles/shake_puzzle.module.scss";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "../store";

interface PuzzleProps {
  rewardInfo: Reward;
  receivedPiece?: number;
}

export const ShakeWinRewardPuzzle: React.FC<PuzzleProps> = (props) => {
  const [params] = useSearchParams();
  const { t } = useTranslation();
  const verified = useSelector((state) => state.data.verified);
  // @ts-ignore
  const [showThings, setShowTings] = useState(window.androidVersion <= "7");
  const { rewardInfo, receivedPiece } = props;
  const [prizeId] = useState(params.get("prize_id") || "");
  const country = useSelector((state) => state.data.country);
  const theme = useSelector((state) => state.data.theme);
  const [piecesDom, setPiecesDom] = useState(
    <div
      style={{
        width: `${100 / rewardInfo.width}%`,
        height: `${100 / rewardInfo.height}%`,
      }}
    ></div>,
  );

  const share = async () => {
    const shareData = {
      title:
        t("wonSharingMessage", { rewardName: rewardInfo.name }) ||
        "Come to join it, shake your phone and Win!",
      // files: filesArray,
      url: "https://shakewin.opera.com",
    };
    try {
      await (window as any).navigator.share(shareData);
      (window as any).showToast("Shake&Win shared successfully");
    } catch (err) {
      (window as any).showToast(`Error: ${err}`);
    }
  };

  useEffect(() => {
    const pieces = rewardInfo.wonPuzzlePieces;
    const virtualDoms = [];
    const length = rewardInfo.progress?.total || 0;
    if (pieces) {
      for (let index = 0; index < length; index++) {
        virtualDoms.push({
          id: index,
          background: pieces.some((piece) => {
            return piece.count > 0 && index + 1 === piece.id;
          })
            ? receivedPiece && index + 1 === receivedPiece
              ? "rgba(255, 185, 6, 0.2)"
              : ""
            : "rgba(255,255,255, 0.66)",
          width: `calc(${100 / rewardInfo.width}% - 2px)`,
          height: `calc(${100 / rewardInfo.height}% - 3px)`,
          border: receivedPiece && index + 1 === receivedPiece ? "1px solid #FFB906" : "",
          borderRadius: receivedPiece && index + 1 === receivedPiece ? "2px" : "0",
          borderBottomLeftRadius:
            index % rewardInfo.width === 0 && index / rewardInfo.width === rewardInfo.height - 1
              ? "8px"
              : "0",
          borderBottomRightRadius: index === rewardInfo.width * rewardInfo.height - 1 ? "8px" : "0",
          borderTopLeftRadius: index === 0 ? "8px" : "0",
          borderTopRightRadius: index === rewardInfo.width - 1 ? "8px" : "0",
        });
      }
    }
    const dom = !prizeId ? (
      virtualDoms.map((pieceDom, index) => (
        <div
          className={styles.puzzle_pieces}
          key={`${pieceDom.id}`}
          style={{
            width: pieceDom.width,
            height: pieceDom.height,
            background: pieceDom.background,
            border: pieceDom.border,
            borderRadius: pieceDom.borderRadius,
            borderBottomLeftRadius: pieceDom.borderBottomLeftRadius,
            borderBottomRightRadius: pieceDom.borderBottomRightRadius,
            borderTopLeftRadius: pieceDom.borderTopLeftRadius,
            borderTopRightRadius: pieceDom.borderTopRightRadius,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxSizing: "border-box",
          }}
        >
          <div
            className={`${styles.number} ${pieceDom.border ? "light-green-bg" : ""}`}
            style={{
              display: pieces.some((piece) => {
                return piece.count > 0 && index + 1 === piece.id;
              })
                ? ""
                : "none",
            }}
          >
            {"x" +
              pieces.find((piece) => {
                return piece.count > 0 && index + 1 === piece.id;
              })?.count}
          </div>
        </div>
      ))
    ) : (
      <div></div>
    );
    // @ts-ignore
    setPiecesDom(dom);
  }, [receivedPiece, rewardInfo, rewardInfo.wonPuzzlePieces]);

  useEffect(() => {
    setTimeout(() => {
      setShowTings(true);
    }, 250);
  }, []);

  return (
    <div className={styles.main}>
      <div
        className={styles.puzzle}
        style={{
          visibility: showThings ? "visible" : "hidden",
          opacity: showThings ? "100%" : "0",
          background: `${theme.backgroundImage} center 50% no-repeat`,
          backgroundSize: `cover`,
        }}
      >
        <img className={styles.gift} src={rewardInfo.giftPicture} alt="gift" />
        <div className={styles.puzzle_pieces}>{piecesDom}</div>
      </div>
      <div
        style={{
          width: "200px",
          justifyContent: "center",
          alignItems: "center",
          padding: "8px",
          borderRadius: "32px",
          background: "#431F70",
          margin: "16px auto",
          display:
            verified && rewardInfo.id && typeof window?.navigator?.share === "function"
              ? "flex"
              : "none",
        }}
        onClick={share}
      >
        <img
          src={require("../assets/images/theme/share.png")}
          alt=""
          style={{
            width: "22px",
            height: "22px",
            marginRight: "4px",
          }}
        />
        <span
          style={{
            fontSize: "14px",
          }}
        >
          {t("shareWithYourFriends")}
        </span>
      </div>
      {rewardInfo.isClaimed && rewardInfo.brandName?.includes("et9j") ? (
        <div className={styles.puzzle_info}>
          <img src={require("../assets/images/info.png")} alt="info" />
          <span>
            {country === "BR"
              ? t("claimBefore", {
                  expired: "29.02.24",
                })
              : t("shoppingBefore", {
                  date: "25/09/24",
                })}
          </span>
        </div>
      ) : (
        <div className={styles.puzzle_info}>
          <img src={require("../assets/images/info.png")} alt="info" />
          <span>
            {t("claimBefore", {
              expired: "25.09.24",
            })}
          </span>
        </div>
      )}
    </div>
  );
};
