export const couponOptionsBR: { [brand: string]: string[] } = {
  "R$100 Shopping Voucher": ["Americanas Card", "Assaí", "Carrefour", "Extra Card", "Netshoes"],
  "R$50 Shopping Voucher": ["Uber", "IFood", "McDonald's", "Razer Gold"],
  "R$30 Shopping Voucher": ["McDonald's", "Razer Gold", "Carrefour"],
  "Fun Voucher": [
    "PrimePass - 2 ingressos de cinema",
    "Bob's - Cheddar Australiano",
    "Bob's - Big Bob",
    "Mobile Data (1.25GB)",
  ],
  "R$25 Shopping Voucher": ["Uber", "Rappi Card", "Submarino Card", "Americanas Card"],
  "R$20 Shopping Voucher": ["Uber", "IFood", "Carrefour"],
  "R$15 Shopping Voucher": ["McDonald's", "Uber", "Rappi Card", "IFood"],
  "Surprise Voucher": ["Uber", "IFood", "Razer Gold"],
  "R$100 Vale compras": ["Americanas Card", "Assaí", "Carrefour", "Extra Card", "Netshoes"],
  "R$50 Vale compras": ["Uber", "IFood", "McDonald's", "Razer Gold"],
  "R$30 Vale compras": ["McDonald's", "Razer Gold", "Carrefour"],
  "Vale Diversão": [
    "PrimePass - 2 ingressos de cinema",
    "Bob's - Cheddar Australiano",
    "Bob's - Big Bob",
    "Mobile Data (1.25GB)",
  ],
  "R$25 Vale compras": ["Uber", "Rappi Card", "Submarino Card", "Americanas Card"],
  "R$20 Vale compras": ["Uber", "IFood", "Carrefour"],
  "R$15 Vale compras": ["McDonald's", "Uber", "Rappi Card", "IFood"],
  "Vale Surpresa": ["Uber", "IFood", "Razer Gold"],
  "Test - R$10 Shopping Voucher": ["Uber", "IFood", "Razer Gold"],
};

export const brandLogo: { [brand: string]: string } = {
  "Americanas Card": require("../assets/images/brand_logos/Americanas.png"),
  Assaí: require("../assets/images/brand_logos/Assai.png"),
  Carrefour: require("../assets/images/brand_logos/Carrefour.png"),
  "Extra Card": require("../assets/images/brand_logos/Extra.png"),
  Netshoes: require("../assets/images/brand_logos/Netshoes.png"),
  Uber: require("../assets/images/brand_logos/Uber.png"),
  IFood: require("../assets/images/brand_logos/Ifood.png"),
  "McDonald's": require("../assets/images/brand_logos/McDonalds.png"),
  "Razer Gold": require("../assets/images/brand_logos/Razer.png"),
  "PrimePass - 2 ingressos de cinema": require("../assets/images/brand_logos/Primepass.png"),
  "Bob's - Cheddar Australiano": require("../assets/images/brand_logos/Bobs.png"),
  "Bob's - Big Bob": require("../assets/images/brand_logos/Bobs.png"),
  "Mobile Data (1.25GB)": require("../assets/images/brand_logos/Opera.png"),
  "Rappi Card": require("../assets/images/brand_logos/Rappi.png"),
  "Submarino Card": require("../assets/images/brand_logos/Submarino.png"),
};
